import { useTheme } from '@mui/material';
import { useContext } from 'react';
import { CheckoutContext, CheckoutContextProps } from '../../../context/CheckoutContext';
import { UserData } from '../../../hooks/useUser';

export const useUserInformationSection = (user: UserData, authToken: string) => {
  const { id } = user || {};
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;
  const theme = useTheme();
  const qrCodeValue = `${window.location.origin}/CheckIn?companyId=${company?.id}&userId=${id}&authToken=${authToken}`;

  return { qrCodeValue, theme };
};
