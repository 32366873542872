import { useSlotSelector } from '../../hooks/useSlotSelector';
import { ISlotSelectorProps } from '../../interfaces';
import SlotsSection from './SlotsSection';
import SlotConfirmation from './SlotConfirmation';
import { Box } from '@mui/material';

const SlotSelectorTheme2: React.FC<ISlotSelectorProps> = ({
  selectedSlot,
  setSelectedSlot,
  slots,
  label,
  handleAddToCart,
  icon,
  initialVisibleCount,
}) => {
  const { areAllOffPeakSlots, timezone } = useSlotSelector(slots);
  return (
    <Box sx={{ pb: '82px' }}>
      <SlotsSection
        slots={slots}
        selectedSlot={selectedSlot}
        setSelectedSlot={setSelectedSlot}
        handleAddToCart={handleAddToCart}
        label={label}
        areAllOffPeakSlots={areAllOffPeakSlots}
        timezone={timezone}
        icon={icon}
        initialVisibleCount={initialVisibleCount}
      />
      <SlotConfirmation handleAddToCart={handleAddToCart} selectedSlot={selectedSlot} />
    </Box>
  );
};

export default SlotSelectorTheme2;
