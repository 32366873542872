import React, { useContext } from 'react';
import { formatPrice } from '../../../../../Utils/format';
import { CheckoutContext, CheckoutContextProps } from '../../../../../context/CheckoutContext';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { StyledPricingRow, RowLabel, RowValue } from './style';

interface PricingCardRowProps {
  label: string;
  value: number;
  currency: string;
  isDiscount: boolean;
  isTotal?: boolean;
}

const PricingCardRow: React.FC<PricingCardRowProps> = ({
  label,
  value,
  currency,
  isDiscount,
  isTotal = false,
}) => {
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;

  return (
    <StyledPricingRow>
      <RowLabel isTotal={isTotal}>
        {label}
        {isDiscount && <LocalOfferIcon />}
      </RowLabel>
      <RowValue isTotal={isTotal} isDiscount={isDiscount} data-testid="pricing-card-row-value">
        {formatPrice(value, company)}
      </RowValue>
    </StyledPricingRow>
  );
};

export default PricingCardRow;
