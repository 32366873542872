import { Stack, Typography, useMediaQuery } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import { DANUBE_SPORTS_WORLD_COMPANY_ID } from '../../../Utils/constants';
import Carousel from 'react-material-ui-carousel';

// Import all sport images
import padelImg from '../../../assets/danube/cursoal/padel.png';
import badmintonImg from '../../../assets/danube/cursoal/badminton.png';
import tableTennisImg from '../../../assets/danube/courts/table-tennis.png';
import cricketImg from '../../../assets/danube/courts/cricket.png';
import footballImg from '../../../assets/danube/courts/football.png';
import tennisImg from '../../../assets/danube/courts/tennis.png';
import basketballImg from '../../../assets/danube/courts/basketball.png';
import pickleballImg from '../../../assets/danube/courts/pickleball.png';

const carouselItems = [
  {
    id: 'badminton',
    title: 'Introducing Badminton',
    image: badmintonImg,
    description: 'State-of-the-art badminton facilities for everyone',
    badge: 'NEW SPORTS',
    bookingUrl: `/calendar?companyId=${DANUBE_SPORTS_WORLD_COMPANY_ID}&serviceId=6cc3095857ab49e1b9b58b3d952190b5`,
  },
  {
    id: 'padel',
    title: 'Padel Courts',
    image: padelImg,
    description: 'Experience state-of-the-art padel courts with professional facilities',
    badge: 'FEATURED',
    bookingUrl: `/calendar?companyId=${DANUBE_SPORTS_WORLD_COMPANY_ID}&serviceId=7411a4098f3049b0807a9432af552a3b`,
  },
  // {
  //   id: 'cricket',
  //   title: 'Cricket Grounds',
  //   image: cricketImg,
  //   description: 'World-class cricket facilities for both practice and matches',
  //   badge: 'POPULAR'
  // },
  // {
  //   id: 'football',
  //   title: 'Football Pitches',
  //   image: footballImg,
  //   description: 'Premium football pitches for the beautiful game',
  //   badge: 'NEW'
  // },
  // {
  //   id: 'basketball',
  //   title: 'Basketball Courts',
  //   image: basketballImg,
  //   description: 'Professional basketball courts for all skill levels',
  //   badge: 'FEATURED'
  // },

  // {
  //   id: 'tennis',
  //   title: 'Tennis Courts',
  //   image: tennisImg,
  //   description: 'Professional tennis courts for players of all levels',
  //   badge: 'POPULAR'
  // },
  // {
  //   id: 'tableTennis',
  //   title: 'Table Tennis',
  //   image: tableTennisImg,
  //   description: 'Indoor table tennis facilities with professional equipment',
  //   badge: 'NEW'
  // },
  // {
  //   id: 'pickleball',
  //   title: 'Pickleball Courts',
  //   image: pickleballImg,
  //   description: 'Modern pickleball courts for this exciting sport',
  //   badge: 'NEW SPORTS'
  // }
];

const StaticHeader = () => {
  const isMd = useMediaQuery('(min-width:600px)');
  const isLg = useMediaQuery('(min-width:1024px)');
  const navigate = useNavigate();

  return (
    <Stack
      component={'header'}
      sx={{
        position: 'relative',
        width: '100%',
        height: '700px',
        padding: { xs: '0', md: '20px' },
        alignItems: 'center',
        overflow: 'hidden',
      }}
    >
      <Carousel
        sx={{
          width: '100%',
          height: '100%',
          '& .MuiPaper-root': {
            borderRadius: { xs: '0', md: '24px' },
          },
        }}
        interval={6000}
        animation="slide"
        indicators={true}
        navButtonsAlwaysInvisible={true}
        indicatorContainerProps={{
          style: {
            position: 'absolute',
            bottom: '40px',
            zIndex: 1,
          },
        }}
        indicatorIconButtonProps={{
          style: {
            color: 'rgba(255, 255, 255, 0.4)',
            margin: '0 4px',
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {
            color: '#FFFFFF',
          },
        }}
      >
        {carouselItems.map((item, index) => (
          <Stack
            key={item.id}
            sx={{
              padding: '15px',
              width: '100%',
              height: '700px',
              position: 'relative',
            }}
          >
            <Stack
              sx={{
                width: '100%',
                height: '100%',
                borderRadius: '35px',
                overflow: 'hidden',
                position: 'relative',
              }}
            >
              <img
                src={item.image}
                alt={item.title}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />

              {/* Gradient Overlay */}
              <Stack
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background:
                    'linear-gradient(180deg, rgba(30, 80, 170, 0.6) 0%, rgba(255, 0, 0, 0.6) 100%)',
                }}
              />
            </Stack>

            {/* Content */}
            <Stack
              spacing={4}
              sx={{
                position: 'absolute',
                top: '194px',
                left: '16px',
                right: '16px',
                color: 'white',
              }}
            >
              {/* Badge */}
              <Stack direction="row" spacing={1} alignItems="center" sx={{ alignSelf: 'center' }}>
                <Stack direction="row" spacing={0.5}>
                  <div
                    style={{
                      width: 5,
                      height: 20,
                      background: '#D61803',
                      borderRadius: 10,
                      transform: 'rotate(-15deg)',
                    }}
                  />
                  <div
                    style={{
                      width: 5,
                      height: 20,
                      background: '#D61803',
                      borderRadius: 10,
                      transform: 'rotate(-15deg)',
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: 'white',
                    fontSize: '16px',
                    fontWeight: 600,
                    fontFamily: 'Dubai-Bold',
                    textTransform: 'uppercase',
                    letterSpacing: '1.5px',
                    textShadow: '0 2px 4px rgba(0,0,0,0.2)',
                  }}
                >
                  {item.badge}
                </Typography>
              </Stack>

              {/* Title */}
              <Typography
                variant="h1"
                sx={{
                  fontSize: { xs: '42px', md: '56px' },
                  fontWeight: 700,
                  textAlign: 'center',
                  lineHeight: 1.1,
                  fontFamily: 'Dubai-Bold',
                  letterSpacing: '-0.5px',
                  textShadow: '0 2px 10px rgba(0,0,0,0.3)',
                  marginTop: '8px',
                  position: 'relative',
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: '-12px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '60px',
                    height: '3px',
                    background: '#FF0000',
                    borderRadius: '2px',
                  },
                }}
              >
                {item.title}
              </Typography>

              {/* Description */}
              <Typography
                sx={{
                  fontSize: { xs: '16px', md: '18px' },
                  textAlign: 'center',
                  maxWidth: '400px',
                  alignSelf: 'center',
                  fontFamily: 'Dubai-Regular',
                  lineHeight: 1.6,
                  letterSpacing: '0.2px',
                  opacity: 0.95,
                  textShadow: '0 1px 3px rgba(0,0,0,0.2)',
                  marginTop: '16px',
                }}
              >
                {item.description}
              </Typography>

              {/* CTA Button */}
              <Stack
                direction="row"
                alignItems="center"
                onClick={() => navigate(item.bookingUrl)}
                sx={{
                  bgcolor: '#FF0000',
                  borderRadius: '28px',
                  padding: '10px 20px',
                  width: 'fit-content',
                  alignSelf: 'center',
                  cursor: 'pointer',
                  boxShadow: '0px 4px 15px rgba(255, 0, 0, 0.2)',
                  transition: 'all 0.2s ease',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0px 6px 20px rgba(255, 0, 0, 0.3)',
                    bgcolor: '#E60000',
                    '& .arrow-circle': {
                      transform: 'translateX(4px)',
                    },
                  },
                }}
              >
                <Typography
                  sx={{
                    color: 'white',
                    marginRight: 2,
                    fontSize: '15px',
                    fontWeight: 600,
                    letterSpacing: '0.3px',
                    fontFamily: 'Dubai-Bold',
                  }}
                >
                  Book Now
                </Typography>
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  className="arrow-circle"
                  sx={{
                    width: 28,
                    height: 28,
                    bgcolor: 'white',
                    borderRadius: '50%',
                    transition: 'transform 0.2s ease',
                    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.08)',
                  }}
                >
                  <ArrowForwardIcon sx={{ color: '#FF0000', fontSize: 18 }} />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        ))}
      </Carousel>
    </Stack>
  );
};

export default StaticHeader;
