import dayjs from 'dayjs';
import { AddonPurchase, Booking } from '../hooks/useCart';
import { getCurrency } from './format';

export function calculateTotals(cart: any) {
  const total = cart?.newCartTotalBeforeRefunds || cart?.newCartTotal || 0;
  const subTotal = cart?.originalCartTotalBeforeRefunds || cart?.originalCartTotal || 0;
  const discount = subTotal - total;
  return { total, subTotal, discount };
}

export function mapBookingsWithAddons(bookings: Booking[], addonPurchases: AddonPurchase[]) {
  return bookings.map(booking => ({
    ...booking,
    addonPurchases: addonPurchases?.filter(addon => addon.linkedBookingId === booking.id) || null,
  }));
}

export function generateCancellationMessage(
  date: string,
  price: number,
  currency: string,
  isUnpaidBooking: boolean
) {
  if (isUnpaidBooking) {
    return `Your booking on ${dayjs(date, 'YYYYMMDD').format('DD/MM/YYYY')} has been cancelled.`;
  }
  return `Your booking on ${dayjs(date, 'YYYYMMDD').format('DD/MM/YYYY')} for ${price} ${getCurrency(currency)} is cancelled and a refund is on the way to original mode of payment, please allow up to 14 days for the refund to be processed.`;
}
