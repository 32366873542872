import { useContext, useEffect, useState } from 'react';
import { CheckoutContext, CheckoutContextProps } from '../context/CheckoutContext';

import axiosInstance from '../services/axiosInstance';
import dayjs from 'dayjs';
import {
  AvailableStartingTimesOutput,
  AvailableStartingTimesResource,
  AvailabilitySlot,
  DayAvailability,
} from './useAvailableStartingTimes';

interface MutliCompaniesAvailabilityInput {
  sport: string;
  location: string;
  date: string;
  marketplaceName: string;
}

const useMutliCompaniesAvailability = ({
  date,
  marketplaceName,
  sport,
  location,
}: MutliCompaniesAvailabilityInput) => {
  const { setAvailableStartingTimesResource, setSlotsMap, setCurrentRangeAvailability } =
    useContext(CheckoutContext) as CheckoutContextProps;

  const [companiesAvailability, setCompaniesAvailability] = useState<
    {
      availableStartingTimesResource: AvailableStartingTimesResource;
      slotsMap: Map<string, AvailabilitySlot[]>;
    }[]
  >();

  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    const abortController = new AbortController();

    const fetchAvailableStartingTimes = async () => {
      setLoading(true);
      try {
        let output;

        const { data: response } = await axiosInstance.post(
          `/booking/getAvailabilityForMultipleServices`,
          {
            date,
            marketplaceName,
            sport,
            location,
          },
          {
            signal: abortController.signal,
          }
        );

        const currentCompaniesAvailabilities: {
          availableStartingTimesResource: AvailableStartingTimesResource;
          slotsMap: Map<string, AvailabilitySlot[]>;
        }[] = [];
        response.data?.forEach((output: any) => {
          const availableStartingTimes = getResourceAvailableStartingTimes(output.availability, '');

          const slotsMap = getAvailableBookingSlots(availableStartingTimes);

          if (output.availability) {
            currentCompaniesAvailabilities.push({
              availableStartingTimesResource: availableStartingTimes,
              slotsMap,
            });
          }
        });

        setCompaniesAvailability(currentCompaniesAvailabilities);

        setLoading(false);
      } catch (e: any) {
        setCurrentRangeAvailability([]);
        setAvailableStartingTimesResource(undefined);
        setSlotsMap(new Map<string, AvailabilitySlot[]>());
        setCompaniesAvailability([]);
        if (e.code !== 'ERR_CANCELED') setLoading(false);
      }
    };

    if (sport && date && marketplaceName) {
      fetchAvailableStartingTimes();
    }

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sport, location, date, marketplaceName]);
  return { loading, companiesAvailability };
};

const getResourceAvailableStartingTimes = (
  availableStartingTimesOutput: AvailableStartingTimesOutput,
  selectedResource: string
): AvailableStartingTimesResource => {
  // sort by duration
  availableStartingTimesOutput?.service?.duration.sort((a, b) => {
    return a.durationTime - b.durationTime;
  });

  const resourceAvailableStartingTimes: AvailableStartingTimesResource = {
    duration: availableStartingTimesOutput?.service?.duration,
    id: selectedResource,
    companyName: availableStartingTimesOutput?.companyName,
    companyLogo: availableStartingTimesOutput?.companyLogo,
    service: availableStartingTimesOutput?.service,
    timezone: availableStartingTimesOutput?.timezone,
    area: availableStartingTimesOutput?.area,
  };

  return resourceAvailableStartingTimes;
};

const getAvailableBookingSlots = (
  availableStartingTimesResource: AvailableStartingTimesResource
) => {
  const bookingSlots: Map<string, AvailabilitySlot[]> = new Map();
  availableStartingTimesResource?.duration?.forEach(duration => {
    const availableSlots: AvailabilitySlot[] = [];
    duration.slots.forEach(slot => {
      if (slot.available <= 0) return;
      availableSlots.push(slot);
    });
    bookingSlots.set(duration.id, availableSlots);
  });

  return bookingSlots;
};

const shouldRefetchAvailability = (
  currentRangeAvailability: DayAvailability[],
  selectedWeekStartDate: Date,
  serviceId?: string,
  currentService?: string,
  resourceId?: string | null,
  currentResource?: string | null
) => {
  if (serviceId !== currentService) return true;
  if (resourceId !== currentResource) return true;
  for (let index = 0; index < 7; index++) {
    const currentDate = dayjs(selectedWeekStartDate).add(index, 'days').format('YYYYMMDD');
    const currentDayAvailability = currentRangeAvailability.filter(
      (dayAvailability: any) => dayAvailability.date === currentDate
    )[0];
    if (!currentDayAvailability) return true;
  }
  return false;
};

export default useMutliCompaniesAvailability;
