import React from 'react';
import { useSearchParams } from 'react-router-dom';
import useTitle from '../../hooks/useTitle';

import { CancellationWrapper } from './style';
import CartCheckoutDetails from '../../components/cart/CartCheckoutDetails';
import Heading from '../../components/shared/Heading';
import BackToHomeButton from '../../components/shared/BackToHomeButton';

interface IProps {
  title: string;
}
const Cancellation: React.FC<IProps> = ({ title }) => {
  useTitle(title);
  const [searchParams] = useSearchParams();
  const cartId = searchParams.get('cartId');
  const bookingId = searchParams.get('bookingId');

  return (
    <CancellationWrapper>
      <Heading heading="Booking Cancellation" sx={{ marginBottom: '32px', marginTop: '32px' }} />
      {cartId && (
        <CartCheckoutDetails
          cartId={cartId}
          bookingId={bookingId || ''}
          data-testid="cart-checkout-details"
        />
      )}
      <BackToHomeButton />
    </CancellationWrapper>
  );
};

export default Cancellation;
