import {
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  useTheme,
} from '@mui/material';
import { DeleteAccountButtonWrapper, StyledDialog } from './style';
import { useDeleteAccountButton } from '../../useDeleteAccountButton';

const DeleteAccountButtonTheme2 = () => {
  const { showDialog, handleOpenDialog, handleCloseDialog, handleDeleteAccount } =
    useDeleteAccountButton();
  const theme = useTheme();

  return (
    <>
      <DeleteAccountButtonWrapper theme={theme}>
        <span className="delete-account">Delete account</span>
        <button className="delete-button" onClick={handleOpenDialog}>
          <span className="button-text">Delete account</span>
        </button>
      </DeleteAccountButtonWrapper>

      <StyledDialog open={showDialog} onClose={handleCloseDialog}>
        <DialogTitle className="dialog-title">
          Are you sure you would like to delete your account?
        </DialogTitle>
        <DialogContent className="dialog-content">
          <DialogContentText>
            This action will permanently delete your account and all associated data, including any
            existing bookings.
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-actions">
          <Button onClick={handleCloseDialog} className="cancel-btn">
            Cancel
          </Button>
          <Button onClick={handleDeleteAccount} className="delete-btn" autoFocus>
            Delete Account
          </Button>
        </DialogActions>
      </StyledDialog>
    </>
  );
};

export default DeleteAccountButtonTheme2;
