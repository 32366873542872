import React, { useContext, useState } from 'react';
import { Package, ValidationOutput } from '../../hooks/usePackage';
import { Typography } from '@mui/material';
import { DiscountRedemptionWrapper } from './style';
import PromocodeRedemption from './promocode';
import { CheckoutContext, CheckoutContextProps } from '../../context/CheckoutContext';
import PackageSelector from './packageSelector';
import SelectedPackageDetails from './selectedPackageDetails';

interface IProps {
  packages: Package[];

  selectedPromocode: string;
  setSelectedPromocode: React.Dispatch<React.SetStateAction<string>>;
  selectedPackage: string;
  setSelectedPackage: React.Dispatch<React.SetStateAction<string>>;
  packageValidationOutput: ValidationOutput | undefined;
  setPackageValidationOutput: React.Dispatch<React.SetStateAction<ValidationOutput | undefined>>;
}
const DiscountRedemption: React.FC<IProps> = ({
  packages,
  selectedPackage,
  setSelectedPackage,
  packageValidationOutput,
  setPackageValidationOutput,
  selectedPromocode,
  setSelectedPromocode,
}) => {
  const [isValidating, setIsValidating] = useState<boolean>(false);

  const { company } = useContext(CheckoutContext) as CheckoutContextProps;

  return (
    <DiscountRedemptionWrapper>
      <Typography className="header">Packages & Promo Codes</Typography>
      {company?.hasAuthentication ? (
        <>
          {
            <PackageSelector
              packages={packages}
              selectedPackage={selectedPackage}
              setSelectedPackage={setSelectedPackage}
              setIsValidating={setIsValidating}
              setPackageValidationOutput={setPackageValidationOutput}
            />
          }
          {selectedPackage &&
            selectedPackage !== 'promo-code' &&
            selectedPackage !== 'membership' && (
              <SelectedPackageDetails
                selectedPackage={selectedPackage}
                isValidating={isValidating}
                packageValidationOutput={packageValidationOutput}
                setSelectedPackage={setSelectedPackage}
                setPackageValidationOutput={setPackageValidationOutput}
              />
            )}
          {selectedPackage && selectedPackage === 'promo-code' && (
            <PromocodeRedemption
              promocode={selectedPromocode}
              setPromocode={setSelectedPromocode}
              validationOutput={packageValidationOutput}
              setPackageValidationOutput={setPackageValidationOutput}
              type={'Promocode'}
            />
          )}

          {selectedPackage === 'membership' && <Typography>Membership is Applied</Typography>}
        </>
      ) : (
        <>
          <PromocodeRedemption
            promocode={selectedPackage}
            setPromocode={setSelectedPackage}
            validationOutput={packageValidationOutput}
            setPackageValidationOutput={setPackageValidationOutput}
            type={'Package'}
          />

          {selectedPackage &&
            packageValidationOutput &&
            packageValidationOutput.type !== 'promocode' && (
              <SelectedPackageDetails
                selectedPackage={selectedPackage}
                isValidating={isValidating}
                packageValidationOutput={packageValidationOutput}
                setSelectedPackage={setSelectedPackage}
                setPackageValidationOutput={setPackageValidationOutput}
              />
            )}

          {selectedPackage &&
            packageValidationOutput &&
            packageValidationOutput.type === 'promocode' && (
              <Typography style={{ marginTop: '10px' }}>Promocode is applied</Typography>
            )}
        </>
      )}
    </DiscountRedemptionWrapper>
  );
};

export default DiscountRedemption;
