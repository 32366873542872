import { useEffect, useState } from 'react';

import axiosInstance from '../services/axiosInstance';

export interface Addon {
  id: string;
  name: string;
  price: number;
  currency: string;
  isRent: boolean;
  companyId: string;
  maxQuantity: number;
  image: string;
  serviceDuration?: number;
  enableServiceDuration?: boolean;
}

const useAddons = (serviceId: string) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [addons, setAddons] = useState<any[]>([]);

  useEffect(() => {
    const fetchAddons = async () => {
      try {
        setIsLoading(true);
        const { data: response } = await axiosInstance.get('/cart/addons', {
          params: {
            serviceId,
          },
        });
        setAddons(response.data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchAddons();
  }, [serviceId]);

  return { isLoading, addons };
};

export default useAddons;
