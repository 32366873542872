import React, { useContext } from 'react';
import { ISlotSelectorProps } from './interfaces';
import { CheckoutContext } from '../../../context/CheckoutContext';
import { CheckoutContextProps } from '../../../context/CheckoutContext';
import { ThemeVariant } from '../../../hooks/useCompany';
import SlotSelectorTheme2 from './variants/theme2';
import SlotSelectorTheme1 from './variants/theme1';
import SlotSelectorSkeleton from './loading-skeleton';

const variantComponents: Record<ThemeVariant, React.ComponentType<any>> = {
  default: SlotSelectorTheme1,
  theme1: SlotSelectorTheme1,
  theme2: SlotSelectorTheme2,
};

const SlotSelector: React.FC<ISlotSelectorProps & { isLoading?: boolean }> = ({
  slots,
  selectedSlot,
  setSelectedSlot,
  label,
  handleAddToCart,
  icon,
  initialVisibleCount,
  isLoading,
}) => {
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;
  const companyTheme = company?.theme;
  const Component = variantComponents[(companyTheme as ThemeVariant) || 'default'];

  if (isLoading) {
    return <SlotSelectorSkeleton label={label} />;
  }

  return (
    <Component
      slots={slots}
      selectedSlot={selectedSlot}
      setSelectedSlot={setSelectedSlot}
      label={label}
      handleAddToCart={handleAddToCart}
      icon={icon}
      initialVisibleCount={initialVisibleCount}
    />
  );
};

export default SlotSelector;
