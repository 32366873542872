import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const CancellationWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 85%;
`;
