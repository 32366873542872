import React from 'react';
import StaticHeader from '../../components/Home/Danube Sports World/StaticHeader';
import Categories from '../../components/Home/Categories';
import { categories, data } from '../../components/Home/FakeData';
import { useAuth } from '../../context/AuthContext';
import {
  BaseUrlToCompany,
  DSW_COMPANY_ID,
  WINTER_CAMP_COMPANY_ID,
  RegistrationStep,
} from '../../Utils/constants';
import { isValidUser } from '../../Utils/validate';
import ContentLoader from '../../components/shared/ContentLoader';
import Register from '../register';
import MakeBooking from '../../components/Home/MakeBooking';
import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import Footer from '../../components/shared/footer';
import { useSearchParams } from 'react-router-dom';
import instaImg1 from '../../assets/instagram/1-md.jpg';
import instaImg2 from '../../assets/instagram/2-md.jpg';
import instaImg3 from '../../assets/instagram/3-md.jpg';
import { ReactComponent as InstagramIcon } from '../../assets/instagram/icon.svg';
import ActivityOptions from '../../components/Home/Dubai Fitness Challenge/ActivityOptions';
import FeaturedItems from '../../components/Home/Dubai Fitness Challenge/FeaturedItems';
import SponsersPanel from '../../components/Home/Danube Sports World/SponsersPanel';
import Courts from '../../components/Home/Danube Sports World/Courts';
import Academies from '../../components/Home/Danube Sports World/Academies';
import Promos from '../../components/Home/Danube Sports World/Promos';

const DanubeSportsWorldHome = () => {
  const { user, isAuthLoading } = useAuth();

  return (
    <ContentLoader isLoading={isAuthLoading}>
      <Stack sx={{ width: '100%', overflow: 'hidden' }}>
        <StaticHeader />

        {!isValidUser(user) && (
          <>
            <Box
              sx={{
                paddingX: '24px',
                paddingY: '50px',
                paddingBottom: '20px',
              }}
            >
              <Register registrationStep={RegistrationStep.Login} redirectToCalendar={false} />
            </Box>
          </>
        )}

        <Courts />
        <Academies />
        <Promos />

        {/* <SponsersPanel /> */}

        {/* <FeaturedItems /> */}

        {/* <Footer
          socialLinks={{
            instagram: "https://www.instagram.com/danubesportsworld/",
            facebook: "https://www.facebook.com/danubesportsworld",
            youtube: "https://www.youtube.com/channel/UCpJdtL_MTDOKj38cSQSCY2w",
            tiktok: "https://www.tiktok.com/@danubesportsworld/"
          }}
          hideCancellationPolicy={true}
          hideAboutUs={true}
          hidePhoneNumber={true}
          hideWhatsapp={true} 
        />       */}
      </Stack>
    </ContentLoader>
  );
};

export default DanubeSportsWorldHome;
