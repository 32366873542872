import React, { useContext } from 'react';
import { CheckoutContext, CheckoutContextProps } from '../../../context/CheckoutContext';
import { ThemeVariant } from '../../../hooks/useCompany';
import PromocodeRedemptionTheme1 from './variants/theme1';
import { IPromocodeRedemptionProps } from './interfaces';
import PromocodeRedemptionTheme2 from './variants/theme2';

const variantComponents: Record<ThemeVariant, React.ComponentType<IPromocodeRedemptionProps>> = {
  default: PromocodeRedemptionTheme1,
  theme1: PromocodeRedemptionTheme1,
  theme2: PromocodeRedemptionTheme2,
};

const PromocodeRedemption: React.FC<IPromocodeRedemptionProps> = props => {
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;
  const companyTheme = company?.theme;
  const Component = variantComponents[(companyTheme as ThemeVariant) || 'default'];

  return <Component {...props} />;
};

export default PromocodeRedemption;
