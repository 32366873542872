import { Stack, Typography } from '@mui/material';
import PrimaryBtn from '../../components/FormCollection/PrimaryBtn';

const Error = () => {
  return (
    <Stack
      sx={{
        flex: 1,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '24px',
      }}
    >
      <Typography sx={{ fontSize: '56px' }}>404</Typography>
      <Typography sx={{ fontSize: '24px' }}>Page Not Found</Typography>
      <PrimaryBtn text="Go Home" href="/" />
    </Stack>
  );
};

export default Error;
