import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Box, Grid, Typography } from '@mui/material';
import { ISlotSelectorProps } from '../interfaces';

const SlotSelectorSkeleton: React.FC<Partial<ISlotSelectorProps>> = ({ label }) => {
  return (
    <Box sx={{ width: '100%', p: 2 }}>
      {label && (
        <Typography variant="h6" sx={{ mb: 2 }}>
          {label}
        </Typography>
      )}

      <Grid container spacing={2}>
        {/* Simulate 6 slot items */}
        {Array.from({ length: 6 }).map((_, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box
              sx={{
                p: 2,
                borderRadius: 1,
                border: '1px solid #e0e0e0',
              }}
            >
              <Skeleton height={24} width="60%" style={{ marginBottom: 8 }} />
              <Skeleton height={20} width="40%" />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default SlotSelectorSkeleton;
