import React, { useContext } from 'react';
import { CheckoutContext, CheckoutContextProps } from '../../context/CheckoutContext';

import { formatPhoneNumber } from '../../Utils/format';
import UserInformationForm, { IUserForm } from '../shared/userInformationForm';

interface IProps {
  confirmBooking: any;
  bookingError: any;
  bookingResponse: any;
  isLoading: boolean;
  isPackageCodeValid: boolean;
  packageCode: string;
}

const RiderInformation: React.FC<IProps> = ({
  confirmBooking,
  bookingError,
  isLoading,
  isPackageCodeValid,
  packageCode,
}) => {
  const {
    selectedSlot,
    selectedDuration,
    selectedResource,
    selectedService,
    quantity,
    isAllResourcesOptionEnabled,
  } = useContext(CheckoutContext) as CheckoutContextProps;

  const proceedToPayment = async ({ firstName, lastName, email, phone, country }: IUserForm) => {
    await confirmBooking({
      selectedResource: isAllResourcesOptionEnabled ? undefined : selectedResource,
      selectedServiceId: selectedService,
      selectedSlot: selectedSlot!,
      selectedDuration: selectedDuration!,
      customerInformation: {
        firstName,
        lastName,
        email,
        phone: formatPhoneNumber(phone, country),
      },
      packageCode: isPackageCodeValid ? packageCode : '',
      quantity,
    });
  };

  return (
    <UserInformationForm
      error={bookingError}
      isLoading={isLoading}
      userType={'Rider'}
      buttonLabel={isPackageCodeValid ? 'Book With Credits' : 'Proceed to Payment'}
      onClick={proceedToPayment}
    />
  );
};

export default RiderInformation;
