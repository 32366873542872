import { useContext, useEffect, useRef } from 'react';
import { CheckoutContext, CheckoutContextProps } from '../context/CheckoutContext';

const useGTMDataLayer = (event: string, data?: any) => {
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;

  const hasCalledGTM = useRef(false);
  useEffect(() => {
    if (hasCalledGTM.current || !company?.gtmId) return;
    window.dataLayer?.push({
      event,
      data,
    });
    hasCalledGTM.current = true;
  }, [company?.gtmId]);
};

export default useGTMDataLayer;
