import { MouseEventHandler } from 'react';
import PrimaryBtn from '../../FormCollection/PrimaryBtn';
import { Box, useTheme, Zoom } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

interface IProps {
  handleClick: MouseEventHandler<HTMLButtonElement>;
  label: string;
}

const PurchasePackageButton: React.FC<IProps> = ({ handleClick, label }) => {
  return (
    <Zoom in timeout={500}>
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <PrimaryBtn
          text={label}
          onClick={handleClick}
          data-testid="purchase-package-button"
          startIcon={<ShoppingCartIcon />}
          sx={{
            marginTop: '20px',
            borderRadius: '25px',
            textTransform: 'none',
            fontSize: '16px',
            padding: '10px 30px',
            boxShadow: '0 2px 8px rgba(51, 51, 79, 0.15)'          }}
        />
      </Box>
    </Zoom>
  );
};

export default PurchasePackageButton;
