import { useTheme } from '@mui/material/styles';
import ContentLoader from '../../../../shared/ContentLoader';
import { useTimeSelector } from '../../hooks/useTimeSelector';
import { ITimeSelectorProps } from '../../interfaces';
import { TimeSelectorWrapper } from './style';
import DurationSelector from '../../../DurationSelector';
import { Box, Typography } from '@mui/material';
import PricingCard from '../../../PricingCard';
import SlotSelector from '../../../SlotSelector';
import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined';
import NightsStayOutlinedIcon from '@mui/icons-material/NightsStayOutlined';
import { getResourceDisclaimer } from '../../../../../Utils/extract';

const TimeSelectorTheme2: React.FC<ITimeSelectorProps> = ({
  isLoadingAvailabilitySlots,
  showDurationFilter,
  timeUnit,
  startTime,
  endTime,
  availableStartingTimes,
}) => {
  const theme = useTheme();
  const {
    morningSlots,
    afternoonSlots,
    hasAvailableSlots,
    handleAddToCart,
    selectedDuration,
    setSelectedDuration,
    selectedSlot,
    setSelectedSlot,
    company,
    selectedResource,
    resourcesMap,
  } = useTimeSelector(startTime, endTime);

  if (!hasAvailableSlots && !isLoadingAvailabilitySlots) {
    return (
      <TimeSelectorWrapper theme={theme}>
        <Typography className="no-slots-label">
          Ughh, Seems like we are fully booked today. Join on us on another day?
        </Typography>
      </TimeSelectorWrapper>
    );
  }

  const slots = [...morningSlots, ...afternoonSlots];
  const resource = resourcesMap?.get(selectedResource);

  return (
    <ContentLoader isLoading={isLoadingAvailabilitySlots}>
      <TimeSelectorWrapper theme={theme}>
        {showDurationFilter && (
          <DurationSelector
            durations={availableStartingTimes?.duration || []}
            setSelectedDuration={setSelectedDuration}
            selectedDuration={selectedDuration}
          />
        )}

        <PricingCard slots={slots} timeUnit={timeUnit} />
        <Box className="slots-container">
          {slots.length > 0 && (
            <SlotSelector
              label={resource?.name || ''}
              slots={slots}
              selectedSlot={selectedSlot}
              setSelectedSlot={setSelectedSlot}
              handleAddToCart={handleAddToCart}
              initialVisibleCount={10}
            />
          )}
        </Box>
        <Typography className="resource-disclaimer">
          {getResourceDisclaimer(company!, selectedResource)}
        </Typography>
      </TimeSelectorWrapper>
    </ContentLoader>
  );
};

export default TimeSelectorTheme2;
