import { Button } from '@mui/material';
import React from 'react';
import { PackageValidationDetailsWrapper } from './style';
import PackageDetailsItem from './PackageDetailsItem';
import { ValidationOutput } from '../../../../../hooks/usePackage';


interface IProps {
  currentPackageAmount: string;
  amountRedeemed: string;
  newBalance: string;
  setSelectedPackage: React.Dispatch<React.SetStateAction<string>>;
  setPackageValidationOutput: React.Dispatch<React.SetStateAction<ValidationOutput | undefined>>;
}
const PackageValidationDetails: React.FC<IProps> = ({
  currentPackageAmount,
  amountRedeemed,
  newBalance,
  setSelectedPackage,
  setPackageValidationOutput
}) => {
  return (
    <PackageValidationDetailsWrapper>
      <PackageDetailsItem
        label="Current Package Amount"
        value={currentPackageAmount}
        valueColor="#5182FF"
      />

      <PackageDetailsItem label="Amount Redeemed" value={amountRedeemed} valueColor="#EB3D3D" />

      <PackageDetailsItem label="New Balance" value={newBalance} valueColor="#5182FF" />

      <Button
        variant="contained"
        color="error"
        className="cancel-button"
        onClick={() => {
          setSelectedPackage('');
          setPackageValidationOutput(undefined);
        }}
      >
        Cancel
      </Button>
    </PackageValidationDetailsWrapper>
  );
};

export default PackageValidationDetails;
