import React from 'react';
import Box from '@mui/material/Box';
import { Typography, useMediaQuery, Card } from '@mui/material';
import { Stack } from '@mui/material';
import SportsTennisIcon from '@mui/icons-material/SportsTennis';
import SportsCricketIcon from '@mui/icons-material/SportsCricket';
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import SportsGymnasticsIcon from '@mui/icons-material/SportsGymnastics';
import SportsKabaddiIcon from '@mui/icons-material/SportsKabaddi';
import { keyframes } from '@mui/system';

// Import academy SVG images
import padelAcademySvg from '../../../assets/danube/academies/danube-padel-academy.svg';
import cricketAcademySvg from '../../../assets/danube/academies/danube-criket-academy.svg';
import tennisSvg from '../../../assets/danube/academies/sania-mirza-tennis-academy.svg';
import footballSvg from '../../../assets/danube/academies/juventus-academy-dubai.svg';
import tableTennisSvg from '../../../assets/danube/academies/racquet-science-academy.svg';
import cosmosSvg from '../../../assets/danube/academies/cosmos-sports-academy.svg';
import littleLegendsSvg from '../../../assets/danube/academies/little-legends.svg';
import gymnasticsClubSvg from '../../../assets/danube/academies/natalia-gymnastics-club.svg';
import judoSvg from '../../../assets/danube/academies/judo-academy.svg';
import fencingSvg from '../../../assets/danube/academies/fencing-academy-dubai.svg';
import { DANUBE_SPORTS_WORLD_COMPANY_ID } from '../../../Utils/constants';

interface AcademyOption {
  id: string;
  title: string;
  imageUrl: string;
  bookingUrl: string;
  altText: string;
}

const academyOptions: AcademyOption[] = [
  {
    id: 'padel',
    title: 'PADEL',
    imageUrl: padelAcademySvg,
    bookingUrl: `/memberships?companyId=${DANUBE_SPORTS_WORLD_COMPANY_ID}&providerId=1`,
    altText: 'Danube Padel Academy',
  },
  {
    id: 'tennis',
    title: 'TENNIS',
    imageUrl: tennisSvg,
    bookingUrl: `/memberships?companyId=${DANUBE_SPORTS_WORLD_COMPANY_ID}&providerId=3`,
    altText: 'Sania Mirza Tennis Academy',
  },
  {
    id: 'rhythmicGymnastics',
    title: 'RHYTHMIC GYMNASTICS',
    imageUrl: gymnasticsClubSvg,
    bookingUrl: `/memberships?companyId=${DANUBE_SPORTS_WORLD_COMPANY_ID}&providerId=2`,
    altText: 'Natalia Gymnastics Club',
  },
  {
    id: 'badminton',
    title: 'BADMINTON',
    imageUrl: cosmosSvg,
    bookingUrl: `/memberships?companyId=${DANUBE_SPORTS_WORLD_COMPANY_ID}&providerId=8`,
    altText: 'Cosmos Sports Academy',
  },
  {
    id: 'cricket',
    title: 'CRICKET',
    imageUrl: cricketAcademySvg,
    bookingUrl: `/memberships?companyId=${DANUBE_SPORTS_WORLD_COMPANY_ID}&providerId=10`,
    altText: 'Danube Cricket Academy',
  },
  {
    id: 'football',
    title: 'FOOTBALL',
    imageUrl: footballSvg,
    bookingUrl: `/memberships?companyId=${DANUBE_SPORTS_WORLD_COMPANY_ID}&providerId=9`,
    altText: 'Juventus Academy Dubai',
  },
  {
    id: 'judo',
    title: 'JUDO',
    imageUrl: judoSvg,
    bookingUrl: `/memberships?companyId=${DANUBE_SPORTS_WORLD_COMPANY_ID}&providerId=6`,
    altText: 'Aurora Judo Academy',
  },
  {
    id: 'tableTennis',
    title: 'TABLE TENNIS',
    imageUrl: tableTennisSvg,
    bookingUrl: `/memberships?companyId=${DANUBE_SPORTS_WORLD_COMPANY_ID}&providerId=5`,
    altText: 'Racquet Science Academy',
  },
  {
    id: 'multiSports',
    title: 'BASKETBALL, TENNIS, TAEKWONDO',
    imageUrl: littleLegendsSvg,
    bookingUrl: `/memberships?companyId=${DANUBE_SPORTS_WORLD_COMPANY_ID}&providerId=7`,
    altText: 'Little Legends Academy',
  },
  {
    id: 'fencing',
    title: 'FENCING',
    imageUrl: fencingSvg,
    bookingUrl: `/memberships?companyId=${DANUBE_SPORTS_WORLD_COMPANY_ID}&providerId=4`,
    altText: 'Dubai Academy Fencing',
  },
];

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Academies: React.FC = () => {
  const isMd = useMediaQuery('(min-width:600px)');
  const isLg = useMediaQuery('(min-width:1024px)');

  return (
    <Box sx={{ py: 4, px: { xs: 2, md: 4 } }}>
      <Box
        sx={{
          maxWidth: '1400px',
          margin: '0 auto',
          width: '100%',
        }}
      >
        <Stack spacing={3.75} sx={{ mb: 4 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.625 }}>
            <Box sx={{ display: 'flex', gap: 0 }}>
              {[1, 2].map((_, i) => (
                <Box
                  key={i}
                  sx={{
                    width: 4,
                    height: 16,
                    bgcolor: '#D61904',
                    transform: 'rotate(15deg)',
                    borderRadius: 10,
                    mx: 0.5,
                  }}
                />
              ))}
            </Box>
            <Typography
              sx={{
                color: '#000',
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '17px',
                letterSpacing: '0.1em',
                fontFamily: 'Dubai-Bold',
              }}
            >
              SPORTS ACADEMIES
            </Typography>
          </Box>

          <Typography
            variant="h1"
            sx={{
              color: '#5C0000',
              fontSize: '32px',
              fontWeight: 700,
              lineHeight: '38px',
              fontFamily: 'Dubai-Bold',
              maxWidth: { xs: '343px', md: 'none' },
              whiteSpace: { md: 'nowrap' },
            }}
          >
            Choose your Sports Academy
          </Typography>

          <Typography
            sx={{
              color: '#473D3D',
              fontSize: '16px',
              lineHeight: '24px',
              opacity: 0.9,
              fontFamily: 'Dubai-Regular',
              maxWidth: { xs: '343px', md: '600px' },
            }}
          >
            Select the sports academy you are interested in and book your session today.
          </Typography>
        </Stack>

        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: 'repeat(2, 1fr)',
              sm: 'repeat(3, 1fr)',
              md: 'repeat(5, 1fr)',
            },
            gap: { xs: 1.5, md: 2 },
            width: '100%',
          }}
        >
          {academyOptions.map((academy, index) => (
            <Box
              key={academy.id}
              component="a"
              href={academy.bookingUrl}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textDecoration: 'none',
                animation: `${fadeIn} 0.6s ease-out forwards`,
                animationDelay: `${index * 0.1}s`,
                opacity: 0,
                transform: 'translateY(20px)',
                transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                '&:hover': {
                  transform: 'translateY(-8px)',
                },
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  aspectRatio: '1/1',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '10px',
                }}
              >
                <Box
                  component="img"
                  src={academy.imageUrl}
                  alt={academy.altText}
                  sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                />
              </Box>

              <Typography
                sx={{
                  fontWeight: 900,
                  fontSize: { xs: '0.875rem', md: '1.25rem' },
                  color: '#000',
                  fontFamily: 'Dubai-Bold',
                  textTransform: 'uppercase',
                  textAlign: 'center',
                  marginTop: '8px',
                }}
              >
                {academy.title}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Academies;
