import styled from '@emotion/styled';
import { Box } from '@mui/material';

interface IProps {
  theme: any;
}

export const UserVoucherCardWrapper = styled(Box)<IProps>`
  width: 100%;
  margin: 13px 0;
  padding: 0 12px;
  border-radius: 12px;
  box-shadow: 0 6px 40px rgba(208, 208, 208, 0.2);

  .title {
    color: ${({ theme }) => theme.palette.text.primary};
    font-size: 20px;
    text-align: left;
    font-weight: 700;
  }

  .details-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    height: 40px;
    text-align: left;
  }

  .detail-label {
    font-size: 18px;
    margin-right: 24px;
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  .detail-value {
    color: ${({ theme }) => theme.palette.text.primary};
  }

  .divider {
    width: 50%;
    margin: 8px auto;
  }
`;

export const UserVoucherSectionWrapper = styled(Box)<IProps>`
  width: 100%;
  max-width: 900px;
  background-color: #fff;
  border: 1px solid rgba(240, 240, 240, 1);
  border-radius: 12px;
  padding: 16px 16px 48px;
  box-sizing: border-box;
  margin-bottom: 16px;
  height: auto;

  .section-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .section-title {
    color: rgba(12, 12, 12, 1);
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    text-align: left;
  }

  @media (max-width: 900px) {
    width: 100%;
    margin-left: 16px;
    margin-right: 16px;
  }
`;

export const EmptyStateWrapper = styled(Box)<IProps>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;

  .icon-wrapper {
    width: 40px;
    height: 40px;
    background-color: transparent;
    position: relative;

    .empty-state-icon {
      position: absolute;
      top: 1.66px;
      left: 3.28px;
      fill: rgba(48, 102, 197, 1);
      width: 33.44px;
      height: 35.07px;
    }
  }

  .content-wrapper {
    width: 256px;
    height: 72px;
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  .empty-state-text {
    color: rgba(36, 32, 57, 1);
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
  }

  .empty-state-subtext {
    color: rgba(36, 32, 57, 0.6);
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
  }
`;
