import { 
  Typography, 
  Stack, 
  Chip, 
  Box, 
  Skeleton,
  ButtonGroup,
  Button
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { AddonItemWrapper } from './style';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';

import { Addon } from '../../hooks/useAddons';

interface IProps {
  addon: Addon;
  setSelectedAddonsQuantities: React.Dispatch<React.SetStateAction<Map<string, number>>>;
  selectedDurationTime?: number;
}

const AddonItem: React.FC<IProps> = ({
  addon,
  setSelectedAddonsQuantities,
  selectedDurationTime,
}) => {
  const [quantity, setQuantity] = useState<number>(0);
  const [imageError, setImageError] = useState<boolean>(false);
  const [imageLoading, setImageLoading] = useState<boolean>(true);

  // Update the quantity in parent component whenever it changes
  useEffect(() => {
    setSelectedAddonsQuantities(oldQuantities => {
      if(quantity === 0) {
        oldQuantities.delete(addon.id);
        return oldQuantities;
      }
      const newQuantities = new Map(oldQuantities);
      newQuantities.set(addon.id, quantity);
      return newQuantities;
    });
  }, [quantity, addon.id, setSelectedAddonsQuantities]);

  const handleIncrement = () => {
    if (quantity < addon.maxQuantity) {
      setQuantity(prev => prev + 1);
    }
  };

  const handleDecrement = () => {
    if (quantity > 0) {
      setQuantity(prev => prev - 1);
    }
  };

  const handleImageError = () => {
    setImageError(true);
    setImageLoading(false);
  };

  const handleImageLoad = () => {
    setImageLoading(false);
  };

  const addonPrice = addon.enableServiceDuration
    ? addon.price * (selectedDurationTime! / addon.serviceDuration!)
    : addon.price;

  return (
    <AddonItemWrapper data-testid="addon-item">
      <Box className="chip-container">
        <Chip
          label={addon?.isRent ? 'Rent' : 'Purchase'}
          color="primary"
          variant="outlined"
          className="chip"
          size="small"
        />
      </Box>

      {addon.image && !imageError && (
        <Box className="image-container">
          {imageLoading && (
            <Skeleton variant="rectangular" width="100%" height={120} animation="wave" />
          )}
          
          <img 
            src={addon.image} 
            alt={addon.name} 
            onError={handleImageError}
            onLoad={handleImageLoad}
            style={{ display: imageLoading ? 'none' : 'block' }}
          />
        </Box>
      )}

      <Box className="content-container">
        <Typography className="addon-name" title={addon.name}>
          {addon.name}
        </Typography>

        <Typography className="addon-price">
          {`${addonPrice.toFixed(2)} ${addon.currency}`}
        </Typography>

        <Box className="quantity-controls">
          <Typography component="span" className="quantity-label">
            Quantity
          </Typography>
          
          <Box className="quantity-buttons-container">
            <ButtonGroup size="small" aria-label="quantity control" className="quantity-button-group">
              <Button 
                onClick={handleDecrement}
                disabled={quantity <= 0}
                className="quantity-button"
                data-testid="decrease-quantity-button"
              >
                <RemoveIcon fontSize="small" />
              </Button>
              
              <Button disableRipple className="quantity-display" data-testid="quantity-display">
                {quantity}
              </Button>
              
              <Button 
                onClick={handleIncrement}
                disabled={quantity >= addon.maxQuantity}
                className="quantity-button"
                data-testid="increase-quantity-button"
              >
                <AddIcon fontSize="small" />
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
      </Box>
    </AddonItemWrapper>
  );
};

export default AddonItem;
