import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../../context/AuthContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { getUserSchema } from '../../../Utils/validate';

import useUser from '../../../hooks/useUser';
import { useForm } from 'react-hook-form';
import FormUI from './FormUI';
import { ICompleteProfile, IFormTypes } from './Types';
import { CheckoutContext, CheckoutContextProps } from '../../../context/CheckoutContext';
import dayjs from 'dayjs';

const CompleteProfile: React.FC<ICompleteProfile> = ({
  isSocialLogin,
  email,
  redirectToLogin,
  redirectToCalendar,
}) => {
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;
  const navigate = useNavigate();
  const { user, setUser, signUp } = useAuth();
  const { updateUser, sendWelcomeEmail } = useUser();

  const [hasChildren, setHasChildren] = useState(false);
  const [hasPods, setHasPods] = useState(false);

  const completeProfileForm = useForm<IFormTypes>({
    resolver: yupResolver(getUserSchema(!isSocialLogin, company!, hasChildren, hasPods)),
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (email) completeProfileForm.setValue('email', email);
  }, [email, completeProfileForm]);

  const onSubmit = async (data: IFormTypes) => {
    setIsLoading(true);
    if (isSocialLogin) {
      try {
        if (!user) throw new Error('You should Login first');

        const response = await updateUser({
          ...user,
          ...data,
          isProfileCompleted: true,
          favoriteSports: data.favoriteSports?.filter(val => val !== undefined),
          dateOfBirth: data.dateOfBirth ? dayjs(data.dateOfBirth).format('YYYY-MM-DD') : undefined,
        });
        setUser(response);
        await sendWelcomeEmail(user.id!);
        if (redirectToCalendar) navigate(`/?companyId=${company?.id}`);
      } catch (error: any) {
        completeProfileForm.setError('privacy', { message: error.message });
      }
    } else {
      console.log('Mahmoud', data);
      const response = await signUp({
        ...data,
        isEmailVerified: false,
        isProfileCompleted: true,
        tenantId: '',
      });

      if (response?.error) {
        completeProfileForm.setError('privacy', {
          message: response.error.message,
        });
      } else {
        if (!redirectToCalendar) {
          navigate(`/my-profile?companyId=${company?.id}`);
        }
      }
    }
    setIsLoading(false);
  };

  return (
    <FormUI
      form={completeProfileForm}
      isSignupWithEmailAndPassword={!isSocialLogin}
      onSubmit={onSubmit}
      isLoading={isLoading}
      hasChildren={hasChildren}
      setHasChildren={setHasChildren}
      hasPods={hasPods}
      setHasPods={setHasPods}
    />
  );
};

export default CompleteProfile;
