import { Box, Button } from '@mui/material';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckoutContext, CheckoutContextProps } from '../../context/CheckoutContext';
import ServiceSelector from '../../components/Calendar/ServiceSelector';

const WinterCampHome = () => {
  const navigate = useNavigate();
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;
  const [showServices, setShowServices] = useState<boolean>(false);

  const handleSelectService = (selectedService: string) => {
    navigate(`/class?companyId=${company?.id}&serviceId=${selectedService}`);
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      {showServices ? (
        <ServiceSelector type="class" serviceId={''} uponSelectCallback={handleSelectService} />
      ) : (
        <Button variant="contained" color="primary" onClick={() => setShowServices(true)}>
          Book Now
        </Button>
      )}
    </Box>
  );
};

export default WinterCampHome;
