import React, { useContext } from 'react';
import { CheckoutContext, CheckoutContextProps } from '../../../context/CheckoutContext';
import { ThemeVariant } from '../../../hooks/useCompany';
import UserPackagesSectionTheme2 from './variants/theme2/UserPackagesSectionTheme2';
import { UserVoucherSectionProps } from './interfaces';
import UserPackagesSectionTheme1 from './variants/theme1/UserPackagesSectionTheme1';

const variantComponents: Record<ThemeVariant, React.ComponentType<UserVoucherSectionProps>> = {
  default: UserPackagesSectionTheme1,
  theme1: UserPackagesSectionTheme1,
  theme2: UserPackagesSectionTheme2,
};

const UserPackagesSection: React.FC<UserVoucherSectionProps> = props => {
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;
  const companyTheme = company?.theme;
  const Component = variantComponents[(companyTheme as ThemeVariant) || 'default'];

  return <Component {...props} />;
};

export default UserPackagesSection;
