import { Box, styled } from '@mui/material';
import { down } from 'styled-breakpoints';
export const CheckoutWrapper = styled(Box)`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;

  ${down('sm')} {
    width: 85%;
  }
`;
