import { useContext, useMemo } from 'react';
import { CheckoutContextProps, CheckoutContext } from '../../../../context/CheckoutContext';
import { isInBookingRange } from '../../../../Utils/validate';
import {
  isAfterCutOff,
  isWithinAdvanceBookingDays,
  withinWorkingHours,
} from '../../../../Utils/format';
import { AvailabilitySlot } from '../../../../hooks/useAvailableStartingTimes';
import { useNavigate } from 'react-router-dom';
import useCart from '../../../../hooks/useCart';
import { toast } from 'react-toastify';

export const useTimeSelector = (startTime?: string, endTime?: string) => {
  const navigate = useNavigate();
  const { addToCart } = useCart();
  const {
    company,
    slotsMap,
    quantity,
    selectedService,
    servicesMap,
    selectedResource,
    setSelectedSlot,
    setSelectedSession,
    selectedDuration,
    setSelectedDuration,
    selectedSlot,
    resourcesMap,
  } = useContext(CheckoutContext) as CheckoutContextProps;
  const selectedDurationSlots = slotsMap.get(selectedDuration?.id!);
  const service = servicesMap?.get(selectedService);

  const filterSlots = (isAfternoon: boolean) => {
    if (!selectedDurationSlots || !selectedDuration) return [];

    return selectedDurationSlots.filter(slot => {
      const withinRange = isInBookingRange(company, slot.fullDate);
      const meetsQuantity = slot.available >= quantity;
      const timeCheck = isAfternoon ? slot.time >= '1200' : slot.time < '1200';
      const afterCutOff = isAfterCutOff(service?.cutoff, slot.fullDate, company?.timezone);
      const isActive = service?.isActive !== false;
      const withinHours = withinWorkingHours(slot.time, startTime, endTime);
      const withinAdvanceBookingDays = isWithinAdvanceBookingDays(
        slot.fullDate,
        service?.advanceBookingDays,
        company?.timezone
      );

      return (
        withinRange &&
        meetsQuantity &&
        timeCheck &&
        afterCutOff &&
        isActive &&
        withinHours &&
        withinAdvanceBookingDays
      );
    });
  };

  const morningSlots = useMemo(
    () => filterSlots(false),
    [slotsMap, selectedDuration, company, quantity, service, startTime, endTime]
  );
  const afternoonSlots = useMemo(
    () => filterSlots(true),
    [slotsMap, selectedDuration, company, quantity, service, startTime, endTime]
  );
  const hasAvailableSlots = morningSlots.length > 0 || afternoonSlots.length > 0;

  const handleAddToCart = async (slot: AvailabilitySlot) => {
    const service = servicesMap?.get(selectedService);
    if (service?.subType === 'kids') {
      navigate(`/KidsBooking?companyId=${company?.id}`);
    } else if (service?.addons && service.addons.length > 0) {
      navigate(`/AddOns?companyId=${company?.id}`);
    } else {
      const addedToCart = await addToCart({
        selectedService,
        selectedDuration,
        selectedSlot: slot,
        quantity: quantity || 1,
        selectedSession: undefined,
        addonsList: [],
        childrenInformation: [],
        selectedResource,
        timezone: company?.timezone,
      });
      if (addedToCart) {
        setSelectedSession(undefined);
        setSelectedSlot(undefined);
        navigate(`/CartCheckout?companyId=${company?.id}`);
      } else {
        toast('Error while adding the item to the cart', {
          theme: 'colored',
          type: 'error',
        });
      }
    }
  };

  return {
    morningSlots,
    afternoonSlots,
    hasAvailableSlots,
    handleAddToCart,
    selectedDuration,
    setSelectedDuration,
    selectedSlot,
    setSelectedSlot,
    company,
    selectedResource,
    resourcesMap,
  };
};
