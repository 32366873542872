import styled from '@emotion/styled';
import { Box } from '@mui/material';

interface IProps {
  theme: any;
}

export const UserInformationContainer = styled(Box)<IProps>`
  display: flex;
  gap: 24px;
  width: 100%;
  max-width: 900px;
  margin-bottom: 16px;

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 16px;
  }
`;

export const ProfileInformationWrapper = styled(Box)<IProps>`
  flex: 1;
  background-color: #fff;
  border: 1px solid rgba(240, 240, 240, 1);
  border-radius: 12px;
  padding: 32px;
  box-sizing: border-box;
  text-align: left;

  .profile-information-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .title {
    font-size: 16px;
    color: rgba(12, 12, 12, 1);
    letter-spacing: -0.4px;
    margin: 0;
  }

  .info-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .name-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .label {
    font-size: 14px;
    color: rgba(36, 36, 36, 0.6);
  }

  .value {
    font-size: 16px;
    color: rgba(36, 36, 36, 1);
  }

  @media (max-width: 900px) {
    width: 100%;
  }

  @media (max-width: 442px) {
    padding: 16px;
  }
`;

export const QRCodeWrapper = styled(Box)<IProps>`
  flex: 1;
  background-color: #f0f0f0;
  border: 1px solid rgba(240, 240, 240, 1);
  border-radius: 12px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;

  .qr-code-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  .qr-code-title {
    color: #0c0c0c;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    letter-spacing: -0.4px;
    margin: 0;
    width: 100%;
    text-align: center;
  }

  .qr-code-image {
    width: 170px;
    height: 170px;
    background-color: #333;
  }

  @media (max-width: 900px) {
    width: 100%;
  }

  @media (max-width: 442px) {
    padding: 16px;
  }
`;
