import { useContext } from 'react';
import { CheckoutContext, CheckoutContextProps } from '../../../../context/CheckoutContext';
import useCart, { Booking } from '../../../../hooks/useCart';

export const useBookingCard = (
  booking: Booking,
  onCancelBookingHandler?: (booking: Booking) => void
) => {
  const { isCartLoading, company } = useContext(CheckoutContext) as CheckoutContextProps;
  const { removeFromCart } = useCart();

  const handleRemoveFromCart = () => {
    console.log('booking', booking);
    if (booking) removeFromCart(booking);
  };
  const month = booking.date.slice(4, 6);
  const day = booking.date.slice(6);

  const handleCancel = () => {
    if (onCancelBookingHandler) {
      onCancelBookingHandler(booking);
    }
  };
  return {
    handleRemoveFromCart,
    isCartLoading,
    company,
    handleCancel,
    month,
    day,
  };
};
