import { useContext, useEffect, useState } from 'react';
import { useAuth } from '../../../../context/AuthContext';
import useUser from '../../../../hooks/useUser';
import { IFormTypes } from '../Types';
import { CheckoutContextProps } from '../../../../context/CheckoutContext';
import { CheckoutContext } from '../../../../context/CheckoutContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { getRegisterationSchema } from '../../../../Utils/validate';
import { RegistrationStep } from '../../../../Utils/constants';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { FacebookAuthProvider, GoogleAuthProvider } from 'firebase/auth';

export const useAuthenticationForm = (
  currentStep: RegistrationStep,
  redirectToCompleteProfile: (val: boolean, email?: string) => void,
  redirectToCalendar: boolean
) => {
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSocialLoading, setIsSocialLoading] = useState<boolean>(false);
  const { signUp, signInWithProvider, setUser, signIn } = useAuth();
  const { isEmailExists } = useUser();

  const [isAndroid, setIsAndroid] = useState(false);

  useEffect(() => {
    if (/Android/i.test(navigator.userAgent)) {
      setIsAndroid(true);
    }
  }, []);

  const signUpForm = useForm<IFormTypes>({
    resolver: yupResolver(getRegisterationSchema(currentStep === RegistrationStep.Login)),
  });

  const signUpWithEmailAndPassword = async (data: IFormTypes) => {
    setIsLoading(true);
    const isExists = await isEmailExists(data.email, company);
    if (isExists) {
      signUpForm.setError('email', {
        message: 'Email already exists. Please log in.',
      });
    } else {
      redirectToCompleteProfile(false, data.email);
    }
    setIsLoading(false);
  };

  const signInWithEmailAndPassword = async (data: IFormTypes) => {
    try {
      setIsLoading(true);
      const signInResponse = await signIn(data.email, data.password);
      await handleLoginResponse(signInResponse);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log('DEBUG', error);
    }
  };

  const handleLoginResponse = async (signInResponse: any) => {
    if (signInResponse.error) {
      signUpForm.setError('password', {
        message: 'Incorrect email or password',
      });
    } else if (!signInResponse.isEmailVerified) {
      signUpForm.setError('password', {
        message: 'Email not verified, please check your email.',
      });
    } else if (!signInResponse.isProfileCompleted) {
      setUser(signInResponse);
      redirectToCompleteProfile(true);
      toast('Please Complete Your Profile', {
        type: 'info',
      });
    } else {
      setUser(signInResponse);

      if (redirectToCalendar) navigate(`/calendar?companyId=${company?.id}`);
    }
  };

  const loginWithSocialMedia = async (provider: GoogleAuthProvider | FacebookAuthProvider) => {
    setIsSocialLoading(true);
    const response = await signInWithProvider(provider, isAndroid);
    await handleLoginResponse(response);
    setIsSocialLoading(false);
  };

  const supportSocialAuthentication = company?.supportSocialAuthentication;

  return {
    isLoading,
    isSocialLoading,
    signUpWithEmailAndPassword,
    signInWithEmailAndPassword,
    signUpForm,
    loginWithSocialMedia,
    supportSocialAuthentication,
  };
};
