import styled from '@emotion/styled';
import { Box, Stack } from '@mui/material';

export const PackageDetailsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0 16px;

  .package-details-label {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #33334f;
    text-align: center;
    margin-bottom: 24px;
  }

  .package-details-items {
    width: 100%;
    box-shadow: 0px 6px 40px rgb(208 208 208 / 40%);
    border-radius: 16px;
    background-color: #fff;
    padding: 10px 20px;
    margin-top: 10px;
  }

  .package-code-container {
    position: relative;
    width: 100%;
    margin-bottom: 16px;
  }

  .package-code-chip {
    width: 100%;
    height: 44px;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.4));
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.4);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.1);
    transition: all 0.3s ease;
    border-radius: 22px;
    margin-top: 10px;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.2);
      background: linear-gradient(135deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.5));
      border-color: rgba(255, 255, 255, 0.6);
    }

    .MuiChip-label {
      font-size: 16px;
      font-weight: 500;
      background: linear-gradient(135deg, #2d3748, #4a5568);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      letter-spacing: 0.5px;
    }

    .MuiChip-deleteIcon {
      color: #4a5568;
      opacity: 0.8;
      transition: opacity 0.2s ease;

      &:hover {
        opacity: 1;
      }
    }
  }
`;

export const PackageValidationDetailsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 16px;

  .cancel-button {
    margin-top: 10px;
    width: 100%;
  }
`;

interface IProps {
  valueColor: string;
}

export const PackageDetailsItemWrapper = styled(Stack)<IProps>`
  margin-top: 15px;
  width: 100%;
  padding: 16px;
  border-radius: 12px;
  transition: all 0.3s ease;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }

  &.highlighted {
    background: linear-gradient(135deg, rgba(51, 51, 79, 0.1), rgba(51, 51, 79, 0.05));
    border: 1px solid rgba(51, 51, 79, 0.2);
  }

  .package-details-item-label {
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    font-size: 16px;
    color: #33334f;
    display: flex;
    align-items: center;
  }

  .package-details-item-value {
    color: ${props => props.valueColor};
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    text-align: right;
    font-size: 16px;
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;

    svg {
      font-size: 20px;
      color: #33334f;
    }
  }
`;
