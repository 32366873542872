import styled from '@emotion/styled';
import { Box, AppBar, Stack, Button } from '@mui/material';

export const StyledAppBar = styled(AppBar)<{ isHome: boolean }>`
  box-shadow: none;
  width: ${({ isHome }) => (isHome ? 'calc(100% - 40px)' : '100%')};
  padding: 0 15px;
  background: #fff;
`;

export const HeaderStack = styled(Stack)`
  padding: 5px 0;
  background: transparent;
  width: 100%;
`;

export const CartButtonWrapper = styled(Button)`
  padding: 0;
  min-width: 0;
  &:hover {
    background-color: transparent;
  }
`;

export const CountdownContainer = styled(Box)`
  margin-top: 8px;
`;

export const ControlledCountdownWrapper = styled(Box)`
  color: red;
`;
