import React from 'react';
import { Divider, Typography } from '@mui/material';
import { UserVoucherSectionWrapper, EmptyStateWrapper } from './style';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import { useMembershipsSection } from '../../hooks/useMembershipsSection';
import MembershipDetails from '../../../../discount/membership/MembershipDetails';
import { UserVoucherSectionProps } from '../../interfaces';

export const UserMembershipsSectionTheme2: React.FC<UserVoucherSectionProps> = ({ vouchers }) => {
  const { servicesMap, theme, memberships } = useMembershipsSection(vouchers);

  console.log('memberships', memberships);

  return (
    <UserVoucherSectionWrapper theme={theme}>
      <div className="section-content">
        <Typography className="section-title">Memberships</Typography>
        {memberships.length ? (
          memberships.map((membership, index) => (
            <React.Fragment key={membership.code}>
              <MembershipDetails
                membershipServiceName={servicesMap?.get(membership.serviceId || '')?.name || ''}
                membershipPrice={membership.price}
                membershipCode={membership.code}
                endDate={membership.endDate}
                membershipRemainingValue={membership.maxUsage ? membership.maxUsage - (membership.usedCount || 0) : undefined}
                title={membership.name}
              />
              {index < memberships.length - 1 && <Divider />}
            </React.Fragment>
          ))
        ) : (
          <EmptyStateWrapper theme={theme}>
            <div className="icon-wrapper">
              <CardMembershipIcon className="empty-state-icon" />
            </div>
            <div className="content-wrapper">
              <Typography className="empty-state-text">No Memberships Available</Typography>
              <Typography className="empty-state-subtext">
                Purchase a membership to unlock exclusive benefits and discounts
              </Typography>
            </div>
          </EmptyStateWrapper>
        )}
      </div>
    </UserVoucherSectionWrapper>
  );
};

export default UserMembershipsSectionTheme2;
