import { Typography } from '@mui/material';
import React from 'react';
import { PackageDetailsItemWrapper } from './style';

interface IProps {
  label: string;
  value: string;
  valueColor?: string;
}
const PackageDetailsItem: React.FC<IProps> = ({ label, value, valueColor }) => {
  return (
    <PackageDetailsItemWrapper valueColor={valueColor || '#080133'}>
      <Typography className="package-details-item-label">{label}</Typography>
      <Typography className="package-details-item-value">{value}</Typography>
    </PackageDetailsItemWrapper>
  );
};

export default PackageDetailsItem;
