import React, { useContext, useMemo } from 'react';
import dayjs from 'dayjs';
import LandingPageTitle from '../../../../shared/LandingPageTitle';
import { Typography } from '@mui/material';
import UserVoucherCard from './UserVoucherCard';
import { UserVoucherSectionProps } from '../../interfaces';
import { VoucherData } from '../../../../../hooks/useProfile';
import { getCurrency } from '../../../../../Utils/format';
import { CheckoutContext, CheckoutContextProps } from '../../../../../context/CheckoutContext';

export const UserPackagesSectionTheme1: React.FC<UserVoucherSectionProps> = ({ vouchers }) => {
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;

  const packageDetails = useMemo(() => {
    return vouchers
      .filter(voucher => voucher.type === 'credit')
      .map(voucher => createPackageDetails(voucher, company?.currency));
  }, [vouchers, company?.currency]);

  return (
    <>
      <LandingPageTitle title="Packages" />
      {packageDetails.length ? (
        packageDetails.map(packageDetail => (
          <UserVoucherCard
            key={packageDetail.code}
            title={packageDetail.name || 'Package for cancelled Booking'}
            details={packageDetail.details}
          />
        ))
      ) : (
        <Typography>No Packages Available</Typography>
      )}
    </>
  );
};

const createPackageDetails = (voucher: VoucherData, currency?: string) => {
  const commonDetails = [
    { label: 'Package Code', value: voucher.code },
    {
      label: 'Remaining Value',
      value: `${voucher.totalCredit - voucher.usedCredit} ${getCurrency(currency)}`,
    },
  ];

  if (!voucher.price) return { ...voucher, details: commonDetails };

  return {
    ...voucher,
    details: [
      ...commonDetails,
      {
        label: 'Package Value',
        value: `${voucher.totalCredit} ${getCurrency(currency)}`,
      },
      {
        label: 'Redeemed Value',
        value: `${voucher.usedCredit} ${getCurrency(currency)}`,
      },
      {
        id: 'price',
        label: 'Price of Package',
        value: `${voucher.price} ${getCurrency(currency)}`,
      },
      {
        label: 'Purchase Date',
        value: dayjs(voucher.createdAt).format('MMM DD, YYYY'),
      },
    ],
  };
};

export default UserPackagesSectionTheme1;
