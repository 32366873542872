import React from 'react';
import { RequestStatus, STATUS_MESSAGES } from '../../Utils/constants';
import BookingDetails from '../shared/bookingDetails';
import StatusHeader from './StatusHeader';
import { SuccessfulStatusWrapper } from './style';

interface IProps {
  locationState: any;
}
const SuccessfulStatus: React.FC<IProps> = ({ locationState }) => {
  return (
    <SuccessfulStatusWrapper>
      <StatusHeader
        header="Cancel Booking"
        title={STATUS_MESSAGES.get(RequestStatus.SuccessfulCancellation)!}
      />
      <BookingDetails
        details={[
          {
            items: [
              { label: 'Company', value: locationState.companyName },
              { label: 'Location', value: locationState.locationName },
            ],
          },
          {
            items: [
              { label: 'Date', value: locationState.date },
              { label: 'Service', value: locationState.serviceName },
            ],
          },
          {
            items: [
              { label: 'Start Time', value: locationState.startTime },
              { label: 'Duration', value: locationState.duration },
            ],
          },
          {
            items: [
              // { label: "Price", value: locationState.price },
              { label: 'BookingId', value: locationState.bookingId },
            ],
          },
        ]}
      />
    </SuccessfulStatusWrapper>
  );
};

export default SuccessfulStatus;
