import React from 'react';
import ResourceCard from './ResourceCard';
import { ResourcesListWrapper } from './style';

interface IProps {
  resourceOptions: {
    value: string;
    label: string;
  }[];
  selectedResource: string;
  onResourceChange: (event: React.SyntheticEvent, value: string) => void;
}
const ResourcesList = ({ resourceOptions, selectedResource, onResourceChange }: IProps) => {
  return (
    <ResourcesListWrapper>
      <div>
        {resourceOptions.map(option => (
          <ResourceCard
            key={option.value}
            title={option.label}
            isSelected={selectedResource === option.value}
            onClick={ev => onResourceChange(ev, option.value)}
          />
        ))}
      </div>
    </ResourcesListWrapper>
  );
};

export default ResourcesList;
