import React, { useContext } from 'react';
import { CheckoutContext, CheckoutContextProps } from '../../../context/CheckoutContext';
import { ThemeVariant } from '../../../hooks/useCompany';
import { ISelectedPackageDetails } from './interface';
import SelectedPackageDetailsTheme1 from './variants/theme1';
import SelectedPackageDetailsTheme2 from './variants/theme2';

const variantComponents: Record<ThemeVariant, React.ComponentType<ISelectedPackageDetails>> = {
  default: SelectedPackageDetailsTheme1,
  theme1: SelectedPackageDetailsTheme1,
  theme2: SelectedPackageDetailsTheme2,
};

const SelectedPackageDetails: React.FC<ISelectedPackageDetails> = props => {
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;
  const companyTheme = company?.theme;
  const Component = variantComponents[(companyTheme as ThemeVariant) || 'default'];
  console.log("Component",Component);
  return <Component {...props} />;
};

export default SelectedPackageDetails;
