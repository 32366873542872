import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { CheckoutContext, CheckoutContextProps } from '../../../context/CheckoutContext';
import PrimaryBtn from '../../FormCollection/PrimaryBtn';
import { DUBAI_FITNESS_CHALLENGE_COMPANY_ID, DANUBE_SPORTS_WORLD_COMPANY_ID } from '../../../Utils/constants';

const BackToHomeButton = () => {
  const navigate = useNavigate();
  const { company, marketplaceCompany } = useContext(CheckoutContext) as CheckoutContextProps;

  const handleClick = () => {
    if ([DUBAI_FITNESS_CHALLENGE_COMPANY_ID, DANUBE_SPORTS_WORLD_COMPANY_ID].includes(company?.id || '')) {
      navigate(`/?companyId=${company?.id}`);
    } else {
      window.open(marketplaceCompany?.website || company?.website || '', '_self');
    }
  };

  return (
    <PrimaryBtn
      data-testid="back-to-home-button"
      sx={{ mt: '15px' }}
      text="Back To Home"
      onClick={handleClick}
    />
  );
};

export default BackToHomeButton;
