import React from 'react';
import { IconButton, useTheme } from '@mui/material';
import FormInput from '../../../../FormCollection/FormInput';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useLoginForm } from '../../hooks/useLoginForm';
import { UseFormReturn } from 'react-hook-form';
import { FormContainer, FormDescription, ForgotPasswordButton } from './style';
import PrimaryBtn from '../../../../FormCollection/PrimaryBtn';

interface IFormTypes {
  email: string;
  password: string;
}

interface ILoginFormProps {
  form: UseFormReturn<IFormTypes, any>;
  onSubmit: (data: IFormTypes) => void;
  isLoading: boolean;
}

const LoginForm: React.FC<ILoginFormProps> = ({ form, onSubmit, isLoading }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = form;


  const theme = useTheme();
  const { handleResetPassword, isResetButtonDisabled, showPassword, setShowPassword } =
    useLoginForm();

  return (
    <FormContainer
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <FormDescription theme={theme}>
        Login with your details and book your court today.
      </FormDescription>

      <FormInput
        error={errors.email}
        placeholder={'Email Address*'}
        register={register('email')}
        id="email"
        type="email"
        dataTestId="email-input"
      />

      <FormInput
        error={errors.password}
        placeholder={'Password*'}
        register={register('password')}
        id="password"
        type={showPassword ? 'text' : 'password'}
        endAdornment={
          <IconButton onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        }
        dataTestId="password-input"
      />

      <ForgotPasswordButton
        theme={theme}
        variant="text"
        onClick={handleSubmit(handleResetPassword)}
        disabled={isResetButtonDisabled}
        data-testid="forgot-password-button"
      >
        {isResetButtonDisabled ? 'Reset email sent' : 'Forgot Password?'}
      </ForgotPasswordButton>

      <PrimaryBtn
        isLoading={isLoading}
        type="submit"
        text="Login"
        dataTestId="submit-button"
        disabled={isLoading}
      />
    </FormContainer>
  );
};

export default LoginForm;
