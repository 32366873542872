import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const PackageSelectorWrapper = styled(Box)`
  width: 80%;
  margin: 10px 0;

  .select-container {
    width: 100%;
    height: 56px;
    background-color: #ffffff;
    border-radius: 1000px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    cursor: pointer;

    .select-content {
      width: 100%;
      height: 56px;
      padding: 16px 0;
      display: flex;
      align-items: center;
      gap: 8px;

      .select-icon {
        color: rgba(0, 0, 0, 0.56);
      }

      .select-field-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;

        .MuiSelect-select {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.87);
          padding: 0;
          min-height: unset !important;
          background: none !important;
          padding-right: 24px !important;

          &.placeholder {
            color: rgba(0, 0, 0, 0.6);
            letter-spacing: -0.4px;
          }
        }

        .MuiSelect-icon {
          color: rgba(0, 0, 0, 0.56);
          right: 0;
        }

        fieldset {
          display: none;
        }
      }
    }
  }

  .MuiPaper-root {
    margin-top: 8px;
    border-radius: 16px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);

    .MuiMenuItem-root {
      font-size: 14px;
      min-height: 48px;
      padding: 12px 16px;
      color: rgba(0, 0, 0, 0.87);
      letter-spacing: -0.4px;
      font-family: 'Roboto', sans-serif;

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }

      &.Mui-selected {
        background-color: rgba(255, 0, 0, 0.08);
        color: #ff0000;

        &:hover {
          background-color: rgba(255, 0, 0, 0.12);
        }
      }
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;
