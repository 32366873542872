import styled from '@emotion/styled';
import { Box, Button, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';

interface IProps {
  theme: Theme;
}

export const TimeSelectorWrapper = styled(Box)<IProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 20px;
  margin-top: 5px;
  padding: 10px;
  transition: all 0.3s ease;

  .slots-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    margin-top: 8px;
  }

  .no-slots-label {
    color: ${props => props.theme.palette.text.secondary};
    padding: 24px 16px;
    font-size: 1rem;
    text-align: center;
    font-weight: 500;
    line-height: 1.5;
    background: ${props => props.theme.palette.background.default};
    border-radius: 12px;
    margin: 16px 0;
    animation: fadeIn 0.4s ease-out;
  }

  .resource-disclaimer {
    color: ${props => props.theme.palette.text.secondary};
    font-size: 0.875rem;
    text-align: center;
    font-weight: 500;
    line-height: 1.5;
    margin-top: 16px;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;
