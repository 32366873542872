import React, { useContext } from 'react';
import {
  Box,
  SwipeableDrawer,
  List,
  Typography,
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
  Divider,
} from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SideBarHeader from './SidebarHeader';
import SidebarLink from './SidebarLink';
import { SidebarProps } from './Type';
import { useAuth } from '../../../context/AuthContext';
import PrimaryBtn from '../../FormCollection/PrimaryBtn';
import { useNavigate } from 'react-router-dom';
import { isValidUser } from '../../../Utils/validate';
import { RegistrationStep } from '../../../Utils/constants';
import { CheckoutContext, CheckoutContextProps } from '../../../context/CheckoutContext';

const Sidebar: React.FC<SidebarProps> = ({ isOpen, toggleDrawer }) => {
  const { logout, user } = useAuth();
  const navigate = useNavigate();

  const { company } = useContext(CheckoutContext) as CheckoutContextProps;

  const handleLogout = async () => {
    await logout();
  };

  const handleWhatsAppHelp = () => {
    window.open(`https://wa.me/${company?.whatsappHelpPhoneNumber}`, '_blank');
  };

  const handlePhoneCall = () => {
    window.open(`tel:${company?.companyPhoneNumber}`, '_blank');
  };

  const handleMapOpen = () => {
    window.open(company?.mapLink, '_blank');
  };

  const list = () => (
    <Box
      sx={{
        maxWidth: 350,
        width: '90vw',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: '44px 38px',
      }}
      role="presentation"
      onClick={() => toggleDrawer(false)}
      onKeyDown={() => toggleDrawer(false)}
    >
      {isValidUser(user) && (
        <Typography sx={{ textAlign: 'center' }}> Welcome {user?.firstName}! </Typography>
      )}
      <List
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        <SidebarLink name="Home" path={`/?companyId=${company?.id}`} />
        {isValidUser(user) && (
          <SidebarLink name="My Profile" path={`/my-profile?companyId=${company?.id}`} />
        )}

        {company?.sidebarContent?.map(sidebarItem => (
          <SidebarLink
            key={sidebarItem.name}
            name={sidebarItem.name}
            path={`/${sidebarItem.path}?companyId=${company?.id}${sidebarItem.params ? `&${sidebarItem.params}` : ''}`}
          />
        ))}

        {/* WhatsApp help button removed as it's now available as an icon */}
      </List>
      {/* <a
        href="https://www.dubaisportsworld.ae/"
        rel="noreferrer"
        target="_blank"
        style={{ color: "#808080", textAlign: "center", marginBottom: "10px" }}
      >
        dubaisportsworld.aeimport WhatsAppIcon from '@mui/icons-material/WhatsApp';

      </a> */}

      {company?.showSidebarContactIcons && (
        <>
          <Divider sx={{ my: 2 }} />
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mb: 2 }}>
            {company?.whatsappHelpPhoneNumber && (
              <IconButton 
                onClick={handleWhatsAppHelp}
                aria-label="WhatsApp Contact"
                sx={{ 
                  backgroundColor: '#25D366', 
                  color: '#fff',
                  '&:hover': { backgroundColor: '#1DA851' },
                  width: 40,
                  height: 40
                }}
              >
                <WhatsAppIcon />
              </IconButton>
            )}
            {company?.companyPhoneNumber && (
              <IconButton 
                onClick={handlePhoneCall}
                aria-label="Phone Contact"
                sx={{ 
                  backgroundColor: '#007bff', 
                  color: '#fff',
                  '&:hover': { backgroundColor: '#0056b3' },
                  width: 40,
                  height: 40
                }}
              >
                <PhoneIcon />
              </IconButton>
            )}
            {company?.mapLink && (
              <IconButton 
                onClick={handleMapOpen}
                aria-label="Location Map"
                sx={{ 
                  backgroundColor: '#dc3545', 
                  color: '#fff',
                  '&:hover': { backgroundColor: '#c82333' },
                  width: 40,
                  height: 40
                }}
              >
                <LocationOnIcon />
              </IconButton>
            )}
          </Box>
        </>
      )}

      {company?.hasAuthentication &&
        (isValidUser(user) ? (
          <PrimaryBtn text="Log Out" onClick={handleLogout} dataTestId="logout-button" />
        ) : (
          <PrimaryBtn
            text="Login"
            onClick={() =>
              navigate(`/Register?companyId=${company?.id}`, {
                state: { registrationStep: RegistrationStep.Login },
              })
            }
            dataTestId="login-button"
          />
        ))}

      {company?.showSKMDLogoInSidebar && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            mt: 3,
          }}
        >
          <Typography variant="caption" sx={{ color: 'text.secondary' }}> 
            Powered by 
          </Typography>
          <Box>
            <a href="http://skmd.app/" target="_blank" rel="noopener noreferrer">
              <img
                src={`${process.env.PUBLIC_URL}/skmd_logo2.svg`}
                alt="SKMD Logo"
                style={{ width: '50px', height: '32px', marginLeft: '8px', marginTop: '3px' }}
              />
            </a>
          </Box>
        </Box>
      )}

      {/* <Button
        onClick={handleWhatsAppHelp}
        sx={{
          backgroundColor: "#25D366",
          color: "#fff",
          '&:hover': {
            backgroundColor: "#1DA851",
          },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "8px",
          margin: "20px 0",
        }}
      >
        <WhatsAppIcon />
        WhatsApp Help
      </Button> */}
    </Box>
  );

  return (
    <SwipeableDrawer
      anchor="left"
      open={isOpen}
      onClose={() => toggleDrawer(false)}
      onOpen={() => toggleDrawer(true)}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          padding: '8px 20px',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <SideBarHeader toggleDrawer={() => toggleDrawer(false)} />
      </Box>
      {list()}
    </SwipeableDrawer>
  );
};

export default Sidebar;
