import React from 'react';
import { HeaderContainer, MonthText, NavigationButton } from './style';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Dayjs } from 'dayjs';
import CustomizedDatePicker from '../../../../DatePicker';
import { Box } from '@mui/material';

interface DaySelectorHeaderProps {
  selectedWeekStartDate: Dayjs;
  handlePrevWeek: () => void;
  handleNextWeek: () => void;
  isBackDisabled: boolean;
  isNextDisabled: boolean;
  daysList: Dayjs[];
  onlySevenDaysInAdvance?: boolean;
  setSelectedWeekStartDate: (date: Date) => void;
  setSelectedDay: (day: string) => void;
  getMonths: string;
}

const DaySelectorHeader: React.FC<DaySelectorHeaderProps> = ({
  selectedWeekStartDate,
  handlePrevWeek,
  handleNextWeek,
  isBackDisabled,
  isNextDisabled,
  daysList,
  onlySevenDaysInAdvance,
  setSelectedWeekStartDate,
  setSelectedDay,
  getMonths,
}) => {
  return (
    <HeaderContainer>
      <NavigationButton onClick={handlePrevWeek} disabled={isBackDisabled}>
        <NavigateBeforeIcon />
      </NavigationButton>
      <Box sx={{ display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <MonthText>{getMonths}</MonthText>
          {!onlySevenDaysInAdvance && (
            <CustomizedDatePicker
              selectedWeekStartDate={selectedWeekStartDate.toDate()}
              setSelectedWeekStartDate={setSelectedWeekStartDate}
              setSelectedDay={setSelectedDay}
            />
          )}
        </Box>
      </Box>
      <NavigationButton onClick={handleNextWeek} disabled={isNextDisabled}>
        <NavigateNextIcon />
      </NavigationButton>
    </HeaderContainer>
  );
};

export default DaySelectorHeader;
