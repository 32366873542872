import React, { useContext } from 'react';
import { Box, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { SideBarHeaderProps } from './Type';
import { CheckoutContext, CheckoutContextProps } from '../../../../context/CheckoutContext';

const SideBarHeader: React.FC<SideBarHeaderProps> = ({ toggleDrawer }) => {
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;

  return (
    <>
      <IconButton
        size="large"
        edge="start"
        color="default"
        aria-label="menu"
        onClick={toggleDrawer}
        sx={{ height: '48px' }}
        data-testid="sidebar-menu-button"
      >
        <MenuIcon />
      </IconButton>
      <Box 
        flex={1} 
        textAlign={'center'}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: 140,
            height: company?.logoDimensions?.height ? Math.round(Number(company.logoDimensions.height) * 0.5) : 50,
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img 
            src={company?.logo} 
            alt={`${company?.name} Logo`} 
            style={{
              objectFit: 'cover',
              objectPosition: 'center',
              width: '100%',
              height: '100%',
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default SideBarHeader;
