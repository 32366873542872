import React from 'react';
import { Box } from '@mui/material';
import { HeaderProps } from '../../interfaces';
import ControlledCountdown from './ControlledCountdown';
import SidebarToggleButton from './SidebarToggleButton';
import LogoSection from './LogoSection';
import CartButton from './CartButton';
import useHeader from '../../hooks/useHeader';
import { StyledAppBar, HeaderStack, CountdownContainer } from './style';

const HeaderTheme1: React.FC<HeaderProps> = ({ company, toggleDrawer }) => {
  const { showCart, handleNavigateToCart, handleLogoClick, bookingCart, countdownApi, location } =
    useHeader(company);

  return (
    <>
      <StyledAppBar position="static" isHome={location.pathname === '/'} className="app-header">
        <HeaderStack direction="row" justifyContent="space-between" alignItems="center">
          <SidebarToggleButton showSidebar={company?.showSidebar} toggleDrawer={toggleDrawer} />
          <LogoSection company={company} onClick={handleLogoClick} />
          <CartButton
            showCart={showCart}
            cartItemsCount={bookingCart?.length}
            onClick={handleNavigateToCart}
          />
        </HeaderStack>
      </StyledAppBar>
      {showCart && (
        <CountdownContainer>
          <ControlledCountdown countdownApi={countdownApi} />
        </CountdownContainer>
      )}
    </>
  );
};

export default HeaderTheme1;
