import React, { useContext, useState } from 'react';
import { Typography, Chip, Box, IconButton, Tooltip } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import SpaIcon from '@mui/icons-material/Spa';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PaymentsIcon from '@mui/icons-material/Payments';

import PackageDetailsItem from './PackageDetailsItem';
import { PackageDetailsWrapper } from './style';
import { formatDuration, formatPrice, getCurrency } from '../../../Utils/format';
import { CheckoutContext, CheckoutContextProps } from '../../../context/CheckoutContext';

interface IProps {
  packageValue: number;
  packagePrice: number;
  packageCode?: string;
  packageName?: string;
  packageDuration?: number;
  remainingValue?: number;
  type: string;
}

const PackageDetails: React.FC<IProps> = ({
  packageValue,
  packagePrice,
  packageCode,
  packageName,
  packageDuration,
  type,
  remainingValue,
}) => {
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    if (packageCode) {
      navigator.clipboard.writeText(packageCode);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };

  const formatTheValue = () => {
    if (type === 'credit') {
      return formatPrice(packageValue, company);
    }

    if (type === 'duration') {
      return formatDuration(packageValue, company?.serviceDurationMultiples!);
    }
  };

  return (
    <PackageDetailsWrapper data-testid="package-details">
      <Typography
        variant="h4"
        data-testid="package-details-label"
        className="package-details-label"
        sx={{
          fontWeight: 600,
          background: 'linear-gradient(45deg, #33334f 30%, #5B5B8D 90%)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          mb: 2,
        }}
      >
        {packageName}
      </Typography>

      

      {!!packageValue && (
        <PackageDetailsItem
          label="Package Value"
          value={formatTheValue()!}
          icon={<PaymentsIcon />}
          data-testid="package-item-value"
        />
      )}

      <PackageDetailsItem
        label="Package Price"
        value={`${formatPrice(packagePrice, company)}`}
        icon={<LocalOfferIcon />}
        highlight
        data-testid="package-item-price"
      />

      {!!packageDuration && packageDuration > 0 && (
        <PackageDetailsItem
          label="Package Duration"
          value={`${packageDuration} day${packageDuration > 1 ? 's' : ''}`}
          icon={<AccessTimeIcon />}
          data-testid="package-item-duration"
        />
      )}

      {!!remainingValue && (
        <PackageDetailsItem
          label="Remaining Value"
          value={type === 'credit' ? formatPrice(remainingValue, company) : formatDuration(remainingValue, company?.serviceDurationMultiples!)}
          icon={<AccountBalanceWalletIcon />}
          data-testid="package-item-remaining-value"
        />
      )}

{packageCode && (
        <Box className="package-code-container">
          <Chip
            label={`Code: ${packageCode}`}
            className="package-code-chip"
            variant="outlined"
            deleteIcon={
              <Tooltip title={copied ? 'Copied!' : 'Copy code'}>
                <IconButton
                  size="small"
                  sx={{
                    mr: '4px',
                    color: copied ? '#4CAF50' : '#33334f',
                    '&:hover': {
                      background: 'rgba(51, 51, 79, 0.05)',
                    },
                  }}
                >
                  {copied ? <CheckIcon /> : <ContentCopyIcon />}
                </IconButton>
              </Tooltip>
            }
            onDelete={handleCopy}
            data-testid="package-item-code"
          />
        </Box>
      )}  
    </PackageDetailsWrapper>
  );
};

export default PackageDetails;
