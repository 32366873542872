import { useNavigate } from 'react-router-dom';
import axiosInstance from '../services/axiosInstance';
import { CustomerInformation } from './useCart';
import { toast } from 'react-toastify';

export interface Package {
  id: string;
  code: string;
  totalCredit: number;
  usedCredit: number;
  type: string;
  isActive: boolean;
  serviceId?: string;
  name?: string;
}

export interface PackageValidationInput {
  cartId: string;
  companyId: string;
  voucherCode: string;
}

export interface PackageValidationOutput {
  valid: boolean;
  type: string;
  totalCredit: number;
  remainingCreditBeforeBooking: number;
  remainingCreditAfterBooking: number;
  cartTotalBeforeApplyingVoucher: number;
  cartTotalAfterApplyingVoucher: number;

  remainingDurationBeforeBooking: number;
  remainingDurationAfterBooking: number;

  remainingSessionsBefore: number;
  remainingSessionsAfter: number;
}

export interface PromocodeValidationOutput {
  valid: boolean;
  type: string;
  cartTotalBeforeApplyingVoucher: number;
  cartTotalAfterApplyingVoucher: number;
}

export interface MembershipValidationOutput {
  valid: boolean;
  type: string;
  cartTotalBeforeApplyingVoucher: number;
  cartTotalAfterApplyingVoucher: number;
  dicountedServiceId: string;
}
export type ValidationOutput =
  | PackageValidationOutput
  | PromocodeValidationOutput
  | MembershipValidationOutput;

const usePackage = () => {
  const navigate = useNavigate();

  const getPackages = async () => {
    try {
      const { data: response } = await axiosInstance.get('/users/getVouchers');
      return response.vouchers as Package[];
    } catch (error) {
      throw error;
    }
  };

  const checkPackageValidity = async (input: PackageValidationInput) => {
    console.log(input);
    try {
      const { data: response } = await axiosInstance.post('/booking/checkVoucherValidity', {
        ...input,
      });
      return response.data as PackageValidationOutput;
    } catch (error) {
      throw error;
    }
  };

  const purchasePackage = async (
    companyId: string,
    packageId: string,
    customerInformation?: CustomerInformation,
    marketplaceName?: string
  ) => {
    try {
      const { data: response } = await axiosInstance.post(`/booking/voucher`, {
        companyId,
        requestedVoucherId: packageId,
        ...customerInformation,
        marketplaceName,
      });
      if (response.data.paymentUrl === 'false' || !response.data.paymentUrl) {
        navigate(`/PackageConfirmation?${response.data.confirmationQuery}`);
      } else {
        window.location.replace(response.data.paymentUrl);
      }
      return response.data;
    } catch (error) {
      toast('An error has occurred while processing your booking payment', {
        theme: 'colored',
        type: 'error',
      });
    }
  };

  return {
    getPackages,
    checkPackageValidity,
    purchasePackage,
  };
};

export default usePackage;
