import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { useContext, useMemo } from 'react';
import { CheckoutContext } from '../../../../context/CheckoutContext';
import { CheckoutContextProps } from '../../../../context/CheckoutContext';

export const useDaySelector = (
  selectedWeekStartDate: Date,
  setSelectedWeekStartDate: React.Dispatch<React.SetStateAction<Date>>
) => {
  const { selectedService, servicesMap, setSelectedDay, selectedDay } = useContext(
    CheckoutContext
  ) as CheckoutContextProps;

  const service = servicesMap?.get(selectedService);

  const daysList = useMemo(() => {
    const days: Dayjs[] = [];
    for (let i = 0; i < 7; i++) {
      days.push(dayjs(selectedWeekStartDate).add(i, 'day'));
    }
    return days;
  }, [selectedWeekStartDate]);

  const handlePrevWeek = () => {
    const today = dayjs();
    let updatedSelectedPickerDate = dayjs(selectedWeekStartDate).subtract(7, 'day');
    updatedSelectedPickerDate = updatedSelectedPickerDate.isAfter(today)
      ? updatedSelectedPickerDate
      : today;
    setSelectedWeekStartDate(updatedSelectedPickerDate.toDate());
    setSelectedDay(updatedSelectedPickerDate.format('DD/MM/YYYY'));
  };

  const handleNextWeek = () => {
    const updatedSelectedPickerDate = dayjs(selectedWeekStartDate).add(7, 'day');
    setSelectedWeekStartDate(updatedSelectedPickerDate.toDate());
    setSelectedDay(updatedSelectedPickerDate.format('DD/MM/YYYY'));
  };

  const isBackDisabled = useMemo(() => {
    return dayjs(selectedWeekStartDate)
      .subtract(7, 'day')
      .startOf('day')
      .isBefore(dayjs().startOf('day'));
  }, [selectedWeekStartDate]);

  const isNextDisabled = useMemo(() => {
    if (!service?.advanceBookingDays) return false;
    return dayjs(selectedWeekStartDate)
      .add(7, 'day')
      .startOf('day')
      .isAfter(dayjs().add(service?.advanceBookingDays!, 'day'));
  }, [selectedWeekStartDate, service?.advanceBookingDays]);

  const getMonths = useMemo(() => {
    const months = new Set();

    for (let i = 0; i < 7; i++) {
      months.add(dayjs(selectedWeekStartDate).add(i, 'day').format('MMM'));
    }

    const monthsArray = Array.from(months);

    return monthsArray.join(' | ');
  }, [selectedWeekStartDate]);

  return {
    daysList,
    selectedWeekStartDate,
    setSelectedWeekStartDate,
    getMonths,
    isBackDisabled,
    isNextDisabled,
    handlePrevWeek,
    handleNextWeek,
    selectedDay,
    setSelectedDay,
  };
};
