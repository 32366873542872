import dayjs from 'dayjs';

export const GOOGLE_ANALYTICS_ID_PROD = 'G-3C57FNQETF';
export const GOOGLE_ANALYTICS_ID_DEV = 'G-PYM7SBGB3J';

export const BookingRange = {
  start: dayjs('20230601', 'YYYYMMDD').startOf('day'),
  end: dayjs('20230910', 'YYYYMMDD').endOf('day'),
};

export const RequestStatus = {
  FailedCancellation: 'FailedCancellation',
  SuccessfulCancellation: 'SuccessfulCancellation',
  UnSuccessfulPayment: 'UnSuccessfulPayment',
  FailedPackageCancellation: 'FailedPackageCancellation',
  SuccessfulPackageCancellation: 'SuccessfulPackageCancellation',
};

export const VoucherErrors = {
  DURATION: 'DURATION',
  INVALID_VOUCHER: 'INVALID_VOUCHER',
};

export const TimeUnits = {
  SESSION: 'session',
  HOUR: 'hour',
};

export const SESSION_DURATION = 15;

export const DEFAULT_MAX_QUANTITY = 6;

export const STATUS_MESSAGES: Map<string, string> = new Map([
  [
    RequestStatus.FailedCancellation,
    'Booking cannot be cancelled because the booking start time is after the cancellation policy limit.',
  ],
  [
    RequestStatus.SuccessfulCancellation,
    'Your booking is cancelled and a refund to your original mode of payment is being processed. Refunds can take 7-14 working days to reflect in your account .',
  ],
  [
    RequestStatus.UnSuccessfulPayment,
    'Unfortunately we were not able to process your payment. Please try again.',
  ],
  [RequestStatus.FailedPackageCancellation, 'Package cannot be cancelled once used.'],
]);

export enum RegistrationStep {
  SignUp = 'SignUp',
  Login = 'Login',
  CompleteProfile = 'CompleteProfile',
}

export const ROUTES_WITHOUT_CART_ICON = ['/confirmation', '/cancellation', '/packageconfirmation'];

export const DSW_COMPANY_ID = 'RLa9ofEY89ZEiMQgCFsc';

export const WINTER_CAMP_COMPANY_ID = 'a83015bb1d544ebd96c526742e199d54';

export const DUBAI_FITNESS_CHALLENGE_COMPANY_ID = 'c932bf3ec1fb4c49b0865fe8cc600ec7';

export const DANUBE_SPORTS_WORLD_COMPANY_ID = '5ec87dccb71c410bb4ff449d0ebb4d34';


// export const DANUBE_SPORTS_WORLD_COMPANY_ID = 'be0a55ecf3844df8a683a62c85bec69e';

export const BaseUrlToCompany = new Map([
  ['https://staging.dubaisportsworld.ae', 'RLa9ofEY89ZEiMQgCFsc'],
  ['https://booking.dubaisportsworld.ae', 'RLa9ofEY89ZEiMQgCFsc'],
  ['https://booking.dubaifitnesschallenge.com', DUBAI_FITNESS_CHALLENGE_COMPANY_ID],
  ['https://booking.danubesportsworld.com', DANUBE_SPORTS_WORLD_COMPANY_ID],
]);

export const MarketPlaceNameToCompanyId = new Map([
  ['RealSportsClub', '94b835de17c14fb4ac74bd31cdcc252c'],
]);

export const DEFAULT_DURATION_MULTIPLES = 15;
