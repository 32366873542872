import React from 'react';
import Box from '@mui/material/Box';
import { Typography, useMediaQuery } from '@mui/material';
import { Stack } from '@mui/material';
import { keyframes } from '@mui/system';

// Import court SVG images
import padelSvg from '../../../assets/danube/courts/padel.svg';
import tennisSvg from '../../../assets/danube/courts/tennis.svg';
import badmintonSvg from '../../../assets/danube/courts/badminton.svg';
import cricketSvg from '../../../assets/danube/courts/cricket.svg';
import footballSvg from '../../../assets/danube/courts/football.svg';
import basketballSvg from '../../../assets/danube/courts/basketball.svg';
import tableTennisSvg from '../../../assets/danube/courts/table-tennis.svg';
import pickleballSvg from '../../../assets/danube/courts/pickleball.svg';
import { DANUBE_SPORTS_WORLD_COMPANY_ID } from '../../../Utils/constants';

interface CourtOption {
  id: string;
  title: string;
  imageUrl: string;
  bookingUrl: string;
  altText: string;
}

const courtOptions: CourtOption[] = [
  {
    id: 'padel',
    title: 'PADEL',
    imageUrl: padelSvg,
    bookingUrl: `/calendar?companyId=${DANUBE_SPORTS_WORLD_COMPANY_ID}&serviceId=7411a4098f3049b0807a9432af552a3b`,
    altText: 'Padel Court',
  },
  {
    id: 'tennis',
    title: 'TENNIS',
    imageUrl: tennisSvg,
    bookingUrl: `/calendar?companyId=${DANUBE_SPORTS_WORLD_COMPANY_ID}&serviceId=6991a62f74284ab599c8b720e6dff8d9`,
    altText: 'Tennis Court',
  },
  {
    id: 'badminton',
    title: 'BADMINTON',
    imageUrl: badmintonSvg,
    bookingUrl: `/calendar?companyId=${DANUBE_SPORTS_WORLD_COMPANY_ID}&serviceId=6cc3095857ab49e1b9b58b3d952190b5`,
    altText: 'Badminton Court',
  },
  {
    id: 'cricket',
    title: 'CRICKET',
    imageUrl: cricketSvg,
    bookingUrl: `/calendar?companyId=${DANUBE_SPORTS_WORLD_COMPANY_ID}&serviceId=6c3b2e0de51442f4abcc1fe478759e35`,
    altText: 'Cricket Court',
  },
  {
    id: 'football',
    title: 'FOOTBALL',
    imageUrl: footballSvg,
    bookingUrl: `/calendar?companyId=${DANUBE_SPORTS_WORLD_COMPANY_ID}&serviceId=f146bf3a228c4d7e934d19e8da488662`,
    altText: 'Football Court',
  },
  {
    id: 'basketball',
    title: 'BASKETBALL',
    imageUrl: basketballSvg,
    bookingUrl: `/calendar?companyId=${DANUBE_SPORTS_WORLD_COMPANY_ID}&serviceId=5f6f0b7f0b694d0d8659874da35d1916`,
    altText: 'Basketball Court',
  },
  {
    id: 'tableTennis',
    title: 'TABLE TENNIS',
    imageUrl: tableTennisSvg,
    bookingUrl: `/calendar?companyId=${DANUBE_SPORTS_WORLD_COMPANY_ID}&serviceId=d889c1bf58ea4184a30dbdccf7dcd30b`,
    altText: 'Table Tennis Court',
  },
  {
    id: 'pickleball',
    title: 'PICKLEBALL',
    imageUrl: pickleballSvg,
    bookingUrl: `/calendar?companyId=${DANUBE_SPORTS_WORLD_COMPANY_ID}&serviceId=1048ea9ec067421fa4841c0dff4d0417`,
    altText: 'Pickleball Court',
  },
];

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Courts: React.FC = () => {
  const isMd = useMediaQuery('(min-width:600px)');
  const isLg = useMediaQuery('(min-width:1024px)');

  return (
    <Box sx={{ py: 4, px: { xs: 2, md: 4 } }}>
      <Box
        sx={{
          maxWidth: '1400px',
          margin: '0 auto',
          width: '100%',
        }}
      >
        <Stack spacing={3.75} sx={{ mb: 4 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.625 }}>
            <Box sx={{ display: 'flex', gap: 0 }}>
              {[1, 2].map((_, i) => (
                <Box
                  key={i}
                  sx={{
                    width: 4,
                    height: 16,
                    bgcolor: '#D61904',
                    transform: 'rotate(15deg)',
                    borderRadius: 10,
                    mx: 0.5,
                  }}
                />
              ))}
            </Box>
            <Typography
              sx={{
                color: '#000',
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '17px',
                letterSpacing: '0.1em',
                fontFamily: 'Dubai-Bold',
              }}
            >
              SELECT YOUR SPORT
            </Typography>
          </Box>

          <Typography
            variant="h1"
            sx={{
              color: '#5C0000',
              fontSize: '32px',
              fontWeight: 700,
              lineHeight: '38px',
              fontFamily: 'Dubai-Bold',
              maxWidth: { xs: '343px', md: 'none' },
              whiteSpace: { md: 'nowrap' },
            }}
          >
            Choose your Sport
          </Typography>

          <Typography
            sx={{
              color: '#473D3D',
              fontSize: '16px',
              lineHeight: '24px',
              opacity: 0.9,
              fontFamily: 'Dubai-Regular',
              maxWidth: { xs: '343px', md: '600px' },
            }}
          >
            Select the sport you are interested in and book your session today.
          </Typography>
        </Stack>

        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: 'repeat(2, 1fr)',
              sm: 'repeat(3, 1fr)',
              md: 'repeat(5, 1fr)',
            },
            gap: { xs: 1.5, md: 2 },
            width: '100%',
          }}
        >
          {courtOptions.map((court, index) => (
            <Box
              key={court.id}
              component="a"
              href={court.bookingUrl}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textDecoration: 'none',
                animation: `${fadeIn} 0.6s ease-out forwards`,
                animationDelay: `${index * 0.1}s`,
                opacity: 0,
                transform: 'translateY(20px)',
                transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                '&:hover': {
                  transform: 'translateY(-8px)',
                },
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  aspectRatio: '1/1',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '10px',
                }}
              >
                <Box
                  component="img"
                  src={court.imageUrl}
                  alt={court.altText}
                  sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                />
              </Box>

              <Typography
                sx={{
                  fontWeight: 900,
                  fontSize: { xs: '0.875rem', md: '1.25rem' },
                  color: '#000',
                  fontFamily: 'Dubai-Bold',
                  textTransform: 'uppercase',
                  textAlign: 'center',
                  marginTop: '8px',
                }}
              >
                {court.title}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Courts;
