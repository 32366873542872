import React from 'react';

const ErrorMessage: React.FC = () => (
  <div>
    <span className="error-message error-message__card-number"></span>
    <span className="error-message error-message__expiry-date"></span>
    <span className="error-message error-message__cvv"></span>
  </div>
);

export default ErrorMessage;
