import React, { useContext } from 'react';
import AuthenticationFormTheme1 from './variants/theme1';
import { ThemeVariant } from '../../../hooks/useCompany';
import { IAuthenticationFormProps } from './Types';
import { CheckoutContextProps } from '../../../context/CheckoutContext';
import { CheckoutContext } from '../../../context/CheckoutContext';

const variantComponents: Record<ThemeVariant, React.ComponentType<any>> = {
  default: AuthenticationFormTheme1,
  theme1: AuthenticationFormTheme1,
  theme2: AuthenticationFormTheme1,
};

const AuthenticationForm: React.FC<IAuthenticationFormProps> = ({
  redirectToCompleteProfile,
  currentStep,
  redirectToCalendar,
  setCurrentStep,
}) => {
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;
  const companyTheme = company?.theme;
  const Component = variantComponents[(companyTheme as ThemeVariant) || 'default'];

  return (
    <Component
      redirectToCompleteProfile={redirectToCompleteProfile}
      currentStep={currentStep}
      redirectToCalendar={redirectToCalendar}
      setCurrentStep={setCurrentStep}
    />
  );
};

export default AuthenticationForm;
