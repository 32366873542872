import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const MembershipDetailsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 25px;
  width: 100%;
  align-items: flex-start;
  margin-bottom: 20px;

  .package-details-label {
    color: #080133;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
  }

  .package-details-items {
    width: 100%;
    box-shadow: 0px 6px 40px rgb(208 208 208 / 40%);
    border-radius: 16px;
    background-color: #fff;
    padding: 10px 20px;
    margin-top: 10px;
  }

  .membership-code-container {
    position: relative;
    width: 100%;
    margin-bottom: 5px;
    margin-top: 15px;
  }

  .membership-code-chip {
    width: 100%;
    height: 44px;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.4));
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.4);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.1);
    transition: all 0.3s ease;
    border-radius: 22px;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.2);
      background: linear-gradient(135deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.5));
      border-color: rgba(255, 255, 255, 0.6);
    }

    .MuiChip-label {
      font-size: 16px;
      font-weight: 500;
      background: linear-gradient(135deg, #2d3748, #4a5568);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      letter-spacing: 0.5px;
    }

    .MuiChip-deleteIcon {
      color: #4a5568;
      opacity: 0.8;
      transition: opacity 0.2s ease;

      &:hover {
        opacity: 1;
      }
    }
  }
`;
