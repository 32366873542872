import React, { useContext } from 'react';
import { CheckoutContext, CheckoutContextProps } from '../../../context/CheckoutContext';
import { ThemeVariant } from '../../../hooks/useCompany';
import { IUserInformationSectionProps } from './interfaces';
import UserInformationSectionTheme1 from './variants/theme1';
import UserInformationSectionTheme2 from './variants/theme2';

const variantComponents: Record<ThemeVariant, React.ComponentType<IUserInformationSectionProps>> = {
  default: UserInformationSectionTheme1,
  theme1: UserInformationSectionTheme1,
  theme2: UserInformationSectionTheme2,
};

const UserInformationSection: React.FC<IUserInformationSectionProps> = props => {
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;
  const companyTheme = company?.theme;
  const Component = variantComponents[(companyTheme as ThemeVariant) || 'default'];

  return <Component {...props} />;
};

export default UserInformationSection;
