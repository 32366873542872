import React from 'react';
import { Typography } from '@mui/material';
import { TimerResult } from 'react-timer-hook';
import { ControlledCountdownWrapper } from './style';

interface ControlledCountdownProps {
  countdownApi: TimerResult;
}

const ControlledCountdown: React.FC<ControlledCountdownProps> = ({ countdownApi }) => {
  const { minutes, seconds, isRunning } = countdownApi;

  return (
    <ControlledCountdownWrapper>
      {isRunning && (
        <Typography
          sx={{
            color: '#E50909',
            fontSize: '14px',
            textAlign: 'center',
            fontFamily: 'inter, sans-serif',
          }}
        >
          Items in cart for
          <Typography
            display={'block'}
            fontFamily={'inter, sans-serif'}
            component="span"
          >{`${minutes.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}:${seconds.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}`}</Typography>
        </Typography>
      )}
    </ControlledCountdownWrapper>
  );
};

export default ControlledCountdown;
