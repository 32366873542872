import React, { useContext } from 'react';
import { CheckoutContext, CheckoutContextProps } from '../../../context/CheckoutContext';
import { ThemeVariant } from '../../../hooks/useCompany';
import DeleteAccountButtonTheme1 from './variants/theme1/index';
import DeleteAccountButtonTheme2 from './variants/theme2';

const variantComponents: Record<ThemeVariant, React.ComponentType> = {
  default: DeleteAccountButtonTheme1,
  theme1: DeleteAccountButtonTheme1,
  theme2: DeleteAccountButtonTheme2,
};

const DeleteAccountButton: React.FC = () => {
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;
  const companyTheme = company?.theme;
  const Component = variantComponents[(companyTheme as ThemeVariant) || 'default'];

  return <Component />;
};

export default DeleteAccountButton;
