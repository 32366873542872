import React from 'react';
import { Typography } from '@mui/material';
import ContentLoader from '../../../../shared/ContentLoader';
import { ISelectedPackageDetails } from '../../interface';
import { useSelectedPackageDetails } from '../../hooks/useSelectedPackageDetails';
import PackageValidationDetails from './PackageValidationDetails';


const SelectedPackageDetailsTheme1: React.FC<ISelectedPackageDetails> = ({
  packageValidationOutput,
  selectedPackage,
  isValidating,
  setSelectedPackage,
  setPackageValidationOutput
}) => {

    const { currentPackageAmount, amountRedeemed, newBalance , isValid } = useSelectedPackageDetails(selectedPackage, packageValidationOutput);
  

  return (
    <ContentLoader isLoading={isValidating}>
      <>
        {isValid ? (
          <PackageValidationDetails
            currentPackageAmount={currentPackageAmount()!}
            amountRedeemed={amountRedeemed()!}
            newBalance={newBalance()!}
            setSelectedPackage={setSelectedPackage}
            setPackageValidationOutput={setPackageValidationOutput}
          />
        ) : (
          <Typography sx={{ color: 'red' , fontSize: '14px' , fontWeight: 'bold', textAlign: 'center' , margin: '10px' }}>
            {packageValidationOutput?.type === 'credit'
              ? `Not enough credits, remaining credits: ${currentPackageAmount}`
              : packageValidationOutput?.type === 'duration'
              ? `Not enough duration, remaining duration: ${currentPackageAmount}`
              : packageValidationOutput?.type === 'membership'
              ? `Not enough sessions, remaining sessions: ${currentPackageAmount}`
              : 'Voucher is not valid for this booking'}
          </Typography>
        )}
      </>
    </ContentLoader>
  );
};

export default SelectedPackageDetailsTheme1;
