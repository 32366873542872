import React from 'react';
import { Typography } from '@mui/material';
import { UserVoucherSectionWrapper, EmptyStateWrapper } from './style';
import RedeemIcon from '@mui/icons-material/Redeem';
import { usePackagesSection } from '../../hooks/usePackagesSection';
import { UserVoucherSectionProps } from '../../interfaces';
import PackageDetails from '../../../../discount/package/PackageDetails';

export const UserPackagesSectionTheme2: React.FC<UserVoucherSectionProps> = ({ vouchers }) => {
  const { theme, packages } = usePackagesSection(vouchers);

  return (
    <UserVoucherSectionWrapper theme={theme}>
      <div className="section-content">
        <Typography className="section-title">Packages</Typography>
        {packages.length ? (
          packages.map(packageVoucher => (
            <PackageDetails
              key={packageVoucher.code}
              packageValue={packageVoucher.totalCredit}
              packagePrice={packageVoucher.price || 0}
              packageCode={packageVoucher.code}
              packageName={packageVoucher.name || 'Package for cancelled Booking'}
              remainingValue={packageVoucher.totalCredit - packageVoucher.usedCredit}
              type="credit"
            />
          ))
        ) : (
          <EmptyStateWrapper theme={theme}>
            <div className="icon-wrapper">
              <RedeemIcon className="empty-state-icon" />
            </div>
            <div className="content-wrapper">
              <Typography className="empty-state-text">No Packages Available</Typography>
              <Typography className="empty-state-subtext">
                Purchase a package to get credits at discounted rates
              </Typography>
            </div>
          </EmptyStateWrapper>
        )}
      </div>
    </UserVoucherSectionWrapper>
  );
};

export default UserPackagesSectionTheme2;
