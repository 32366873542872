import { Tab, Tabs, useTheme } from '@mui/material';
import React from 'react';

interface IScrollableTabsProps {
  value: string | undefined;
  onChange: ((event: React.SyntheticEvent<Element, Event>, value: string) => void) | undefined;
  data: {
    value: string;
    label: string;
  }[];
}
const ScrollableTabs: React.FC<IScrollableTabsProps> = ({ data, onChange, value }) => {
  const theme = useTheme();

  return (
    <Tabs
      value={value}
      onChange={onChange}
      variant="scrollable"
      scrollButtons="auto"
      allowScrollButtonsMobile
      aria-label="scrollable tabs"
      sx={{
        width: '100%',
        maxWidth: '100vw',
        position: 'relative',
        '& .MuiTabs-scroller': {
          overflow: 'auto !important',
          scrollBehavior: 'smooth',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          scrollbarWidth: 'none',
          padding: '0 28px',
        },
        '& .MuiTabs-flexContainer': {
          gap: '10px',
          justifyContent: 'center',
          paddingTop: '10px',
          paddingBottom: '10px',
          paddingX: '4px',
          flexWrap: 'nowrap',
          minWidth: 'max-content',
        },
        '& .MuiTabs-indicator': {
          backgroundColor: 'transparent',
        },
        '& .MuiTabScrollButton-root': {
          '&.Mui-disabled': {
            opacity: 0,
            pointerEvents: 'none',
          },
          position: 'absolute',
          width: '28px',
          height: '100%',
          zIndex: 2,
          backgroundColor: 'white',
          transition: 'opacity 0.2s',
          '&.MuiTabScrollButton-root:first-of-type': {
            left: 0,
            boxShadow: '4px 0px 4px rgba(0, 0, 0, 0.05)',
          },
          '&.MuiTabScrollButton-root:last-of-type': {
            right: 0,
            boxShadow: '-4px 0px 4px rgba(0, 0, 0, 0.05)',
          },
        },
        boxShadow: '0px 6px 40px 0px rgba(208, 208, 208, 0.2)',
      }}
    >
      {data?.map(sport => (
        <Tab
          key={sport.value}
          label={sport.label}
          value={sport.value}
          sx={{
            fontSize: {
              xs: '14px',
              sm: '16px',
            },
            minWidth: 'auto',
            padding: '6px 12px !important',
            borderRadius: '10px !important',
            height: '36px',
            textTransform: 'none',
            fontWeight: 400,
            textDecoration: 'none',
            color: '#808080 !important',
            background: '#fff',
            whiteSpace: 'nowrap',
            '&.Mui-selected': {
              background: theme.palette.primary.main,
              color: '#fff !important',
            },
          }}
        />
      ))}
    </Tabs>
  );
};

export default ScrollableTabs;
