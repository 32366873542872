import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {
  DaySelectorSkeletonWrapper,
  HeaderSkeletonContainer,
  DaysListSkeletonContainer,
  DayChipSkeletonWrapper,
} from './style';

const DaySelectorSkeleton: React.FC = () => {
  return (
    <DaySelectorSkeletonWrapper>
      <HeaderSkeletonContainer>
        {/* Navigation arrow */}
        <Skeleton circle width={35} height={35} />

        {/* Month text */}
        <Skeleton width={120} height={24} />

        {/* Navigation arrow */}
        <Skeleton circle width={35} height={35} />
      </HeaderSkeletonContainer>

      <DaysListSkeletonContainer>
        {/* Generate 7 day chips for the week */}
        {Array.from({ length: 7 }).map((_, index) => (
          <DayChipSkeletonWrapper key={index}>
            <Skeleton width={40} height={20} /> {/* Day name */}
            <Skeleton width={20} height={20} /> {/* Day number */}
          </DayChipSkeletonWrapper>
        ))}
      </DaysListSkeletonContainer>
    </DaySelectorSkeletonWrapper>
  );
};

export default DaySelectorSkeleton;
