import styled from '@emotion/styled';
import { Box } from '@mui/material';

interface IProps {
  theme: any;
}

export const UserInformationWrapper = styled(Box)<IProps>`
  width: 100%;
  padding: 20px;
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-radius: 12px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  margin-bottom: 24px;
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
    transform: translateY(-2px);
  }
`;

export const InfoBox = styled(Box)<IProps>`
  display: grid;
  grid-template-columns: 70px 1fr;
  gap: 4px;
  align-items: center;
  width: 100%;
  padding: 10px 14px;
  margin-bottom: 8px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.background.default};
  transition: all 0.2s ease-in-out;
  font-size: 14px;

  .label {
    color: ${({ theme }) => theme.palette.text.secondary};
    font-weight: 500;
    text-align: left;
  }

  .value {
    color: ${({ theme }) => theme.palette.text.primary};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const QRCodeWrapper = styled(Box)<IProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid ${({ theme }) => theme.palette.divider};

  .qr-label {
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: 13px;
    font-weight: 500;
  }

  .qr-code {
    padding: 12px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
    transition: all 0.2s ease-in-out;

    &:hover {
      transform: scale(1.02);
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
    }
  }
`;
