import styled from '@emotion/styled';

export const AddonItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 16px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.08);
  padding: 16px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  background-color: #ffffff;
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
  }

  .chip-container {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 12px;
  }

  .chip {
    background-color: #fde9f1;
    border: none;
    font-weight: 500;
    font-size: 11px;
  }

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
    height: 120px;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    background-color: #f8f8f8;

    img {
      width: auto;
      max-width: 100%;
      height: 100%;
      max-height: 120px;
      object-fit: contain;
      transition: transform 0.3s ease;
      
      &:hover {
        transform: scale(1.05);
      }
    }
    
    .fallback-image {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background-color: #f5f5f5;
      color: #9e9e9e;
      gap: 8px;
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .addon-name {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    margin-bottom: 6px;
    color: #333333;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .addon-price {
    font-size: 13px;
    font-weight: 700;
    line-height: 17px;
    color: #666666;
    margin-bottom: 16px;
  }

  .quantity-controls {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
  }

  .quantity-label {
    font-size: 12px;
    line-height: 17px;
    font-weight: 500;
    color: #666666;
  }
  
  .quantity-buttons-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  
  .quantity-button-group {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
  }
  
  .quantity-button {
    min-width: 36px;
    height: 36px;
    padding: 0;
    background-color: #f8f8f8;
    border-color: #e0e0e0;
    color: #333333;
    transition: background-color 0.2s ease;
    
    &:hover {
      background-color: #f0f0f0;
    }
    
    &:disabled {
      opacity: 0.5;
      color: #999999;
    }
  }
  
  .quantity-display {
    min-width: 48px;
    font-weight: 600;
    color: #333333;
    cursor: default;
    background-color: #ffffff;
    border-color: #e0e0e0;
    
    &:hover {
      background-color: #ffffff;
    }
  }
`;
