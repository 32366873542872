import { Card, CardProps } from '@mui/material';
import { styled } from '@mui/material/styles';

interface CartBookingCardWrapperProps extends CardProps {
  showCancelOption?: boolean;
}

export const CartBookingCardWrapper = styled(Card)<CartBookingCardWrapperProps>`
  position: relative;
  padding-right: 16px;
  box-shadow: 0 6px 40px rgba(208, 208, 208, 0.2);
  border: 0;
  border-radius: 0 16px 16px 0;
  width: 100%;
  overflow: visible;

  ${({ showCancelOption }) =>
    showCancelOption &&
    `
    margin-bottom: 20px;
  `}

  .cancel-button {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.palette.primary.main};
    font-size: 12px;
    font-weight: 500;
    background-color: #fde9f1;
    height: 20px;
    width: 130px;
    box-shadow: 0 6px 40px rgba(208, 208, 208, 0.2);
    border-radius: 6px;
    clip-path: polygon(0% 100%, 85% 100%, 100% 0%, 0% 0%);
    bottom: -20px;
    right: 0;
    text-transform: none;
    &:hover {
      background-color: #fde9f1;
    }
  }

  .content-wrapper {
    padding: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .title-wrapper {
    gap: 12px;
    flex-wrap: wrap;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }

  .title {
    text-align: left;
    font-size: 20px;
  }
`;
