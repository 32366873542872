import { DANUBE_SPORTS_WORLD_COMPANY_ID } from '../../../Utils/constants';

// Danube Sports World specific sports list
const danubeWorldSports = [
  { value: '', label: 'Select your option' },
  {
    label: 'Padel',
    value: 'Padel',
  },
  {
    label: 'Football',
    value: 'Football',
  },
  {
    label: 'Cricket',
    value: 'Cricket',
  },
  {
    label: 'Basketball',
    value: 'Basketball',
  },
  {
    label: 'Volleyball',
    value: 'Volleyball',
  },
  {
    label: 'Badminton',
    value: 'Badminton',
  },
  {
    label: 'Tennis',
    value: 'Tennis',
  },
  {
    label: 'Table Tennis',
    value: 'Table Tennis',
  },
  {
    label: 'Pickleball',
    value: 'Pickleball',
  },
  {
    label: 'Judo',
    value: 'Judo',
  },
  {
    label: 'Gymnastics',
    value: 'Gymnastics',
  },
  {
    label: 'Fencing',
    value: 'Fencing',
  },
  {
    label: 'Martial Arts',
    value: 'Martial Arts',
  },
];

const defaultSports = [
  { value: '', label: 'Select your option' },
  {
    label: 'Football',
    value: 'Football',
  },
  {
    label: 'Basketball',
    value: 'Basketball',
  },
  {
    label: 'Tennis',
    value: 'Tennis',
  },
  {
    label: 'Volleyball',
    value: 'Volleyball',
  },
  {
    label: 'Badminton',
    value: 'Badminton',
  },
  {
    label: 'Padel',
    value: 'Padel',
  },
  {
    label: 'Cricket',
    value: 'Cricket',
  },
  {
    label: 'Table Tennis',
    value: 'Table Tennis',
  },
  {
    label: 'Pickleball',
    value: 'Pickleball',
  },
  {
    label: 'Gym',
    value: 'Gym',
  },
  {
    label: 'Fitness Classes',
    value: 'Fitness Classes',
  },
  {
    label: 'Teqball',
    value: 'Teqball',
  },
  {
    label: 'Dubai Kids World',
    value: 'Dubai Kids World',
  },
];

export const sports = defaultSports;
export const danubeSports = danubeWorldSports;

export const getSportsList = (companyId: string) => {
  return companyId === DANUBE_SPORTS_WORLD_COMPANY_ID ? danubeWorldSports : defaultSports;
};

export const whereDidYouHearAboutUs = [
  { value: '', label: 'Select your option' },
  {
    label: 'Online Advertising',
    value: 'Online Advertising',
  },
  {
    label: 'Social Media',
    value: 'Social Media',
  },
  {
    label: 'Outdoor Ads',
    value: 'Outdoor Ads',
  },
  {
    label: 'Sports Academy',
    value: 'Sports Academy',
  },
  {
    label: 'Word of Mouth',
    value: 'Word of Mouth',
  },
];

export const Genders = [
  { value: '', label: 'Select your option' },
  {
    label: 'Male',
    value: 'Male',
  },
  {
    label: 'Female',
    value: 'Female',
  },
  {
    value: 'other',
    label: 'Prefer not to say',
  },
];

export const dubaiAreas = [
  { value: '', label: 'Select your option' },
  { value: 'Academi City', label: 'Academi City' },
  { value: 'Al Barsha', label: 'Al Barsha' },
  { value: 'Al Furjan', label: 'Al Furjan' },
  { value: 'Al Jaddaf', label: 'Al Jaddaf' },
  { value: 'Al Karama', label: 'Al Karama' },
  { value: 'Al Mizhar', label: 'Al Mizhar' },
  { value: 'Al Nahda', label: 'Al Nahda' },
  { value: 'Al Quoz', label: 'Al Quoz' },
  { value: 'Al Qusais', label: 'Al Qusais' },
  { value: 'Al Rashidiya', label: 'Al Rashidiya' },
  { value: 'Al Sufouh', label: 'Al Sufouh' },
  { value: 'Al Warqa', label: 'Al Warqa' },
  { value: 'Arabian Ranches', label: 'Arabian Ranches' },
  { value: 'Arjan', label: 'Arjan' },
  { value: 'Barsha Heights', label: 'Barsha Heights' },
  { value: 'Bur Dubai', label: 'Bur Dubai' },
  { value: 'Business Bay', label: 'Business Bay' },
  { value: 'Damac Hills', label: 'Damac Hills' },
  { value: 'Deira', label: 'Deira' },
  { value: 'Discovery Gardens', label: 'Discovery Gardens' },
  { value: 'Downtown Dubai', label: 'Downtown Dubai' },
  { value: 'Dubai Creek Harbour', label: 'Dubai Creek Harbour' },
  { value: 'Dubai Festival City', label: 'Dubai Festival City' },
  { value: 'Dubai Hills', label: 'Dubai Hills' },
  { value: 'Dubai Internet City', label: 'Dubai Internet City' },
  { value: 'Dubai Investment Park (DIP)', label: 'Dubai Investment Park (DIP)' },
  { value: 'Dubai Land', label: 'Dubai Land' },
  { value: 'Dubai Marina', label: 'Dubai Marina' },
  { value: 'Dubai Media City', label: 'Dubai Media City' },
  { value: 'Dubai Silicon Oasis', label: 'Dubai Silicon Oasis' },
  { value: 'Dubai South', label: 'Dubai South' },
  { value: 'Dubai Sports City', label: 'Dubai Sports City' },
  { value: 'Emirates Hills', label: 'Emirates Hills' },
  { value: 'Expo City', label: 'Expo City' },
  { value: 'International City', label: 'International City' },
  { value: 'Jebel Ali', label: 'Jebel Ali' },
  { value: 'Jumeirah', label: 'Jumeirah' },
  { value: 'Jumeirah Village Circle (JVC)', label: 'Jumeirah Village Circle (JVC)' },
  { value: 'Jumeirah Village Triangle (JVT)', label: 'Jumeirah Village Triangle (JVT)' },
  { value: 'Jumierah Islands', label: 'Jumierah Islands' },
  { value: 'Jumierah Lake Towers (JLT)', label: 'Jumierah Lake Towers (JLT)' },
  { value: 'Khawaneej', label: 'Khawaneej' },
  { value: 'Meydan', label: 'Meydan' },
  { value: 'Mirdif', label: 'Mirdif' },
  { value: 'Motor City', label: 'Motor City' },
  { value: 'Muhaisnah', label: 'Muhaisnah' },
  { value: 'Nad Al Sheba', label: 'Nad Al Sheba' },
  { value: 'Palm Jumeirah', label: 'Palm Jumeirah' },
  { value: 'Satwa', label: 'Satwa' },
  { value: 'Springs', label: 'Springs' },
  { value: 'The Gardens', label: 'The Gardens' },
  { value: 'Town Square', label: 'Town Square' },
  { value: 'Umm Ramool', label: 'Umm Ramool' },
  { value: 'Umm Suqieum', label: 'Umm Suqieum' },
  { value: 'Warsan', label: 'Warsan' },
  { value: 'Zaabeel', label: 'Zaabeel' },
];
