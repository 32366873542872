import { useSlotSelector } from '../../hooks/useSlotSelector';
import { ISlotSelectorProps } from '../../interfaces';
import SlotsSection from './SlotsSection';

const SlotSelectorTheme1: React.FC<ISlotSelectorProps> = ({
  selectedSlot,
  setSelectedSlot,
  slots,
  label,
  handleAddToCart,
  initialVisibleCount,
}) => {
  const { areAllOffPeakSlots, timezone } = useSlotSelector(slots);
  return (
    <SlotsSection
      slots={slots}
      selectedSlot={selectedSlot}
      setSelectedSlot={setSelectedSlot}
      handleAddToCart={handleAddToCart}
      label={label}
      areAllOffPeakSlots={areAllOffPeakSlots}
      timezone={timezone}
      initialVisibleCount={initialVisibleCount}
    />
  );
};

export default SlotSelectorTheme1;
