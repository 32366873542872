import React, { useContext, useState } from 'react';
import { Typography, Fade, Chip, IconButton, Tooltip, Box } from '@mui/material';
import PackageDetailsItem from '../package/PackageDetailsItem';
import { MembershipDetailsWrapper } from './style';
import dayjs from 'dayjs';
import { DSW_COMPANY_ID } from '../../../Utils/constants';
import { CheckoutContext, CheckoutContextProps } from '../../../context/CheckoutContext';
import { getCurrency } from '../../../Utils/format';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import SpaIcon from '@mui/icons-material/Spa';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import PaymentIcon from '@mui/icons-material/Payment';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';

interface IProps {
  membershipPrice: number;
  membershipServiceName: string;
  membershipCode?: string;
  membershipValue?: number;
  membershipDuration?: number;
  membershipRegistrationFees?: number;
  membershipRemainingValue?: number;
  membershipMaxUsage?: number;
  id?: string;
  title?: string;
  endDate?: string;
}

const MembershipDetails: React.FC<IProps> = ({
  membershipServiceName,
  membershipPrice,
  membershipCode,
  membershipValue,
  membershipDuration,
  membershipRegistrationFees,
  membershipRemainingValue,
  membershipMaxUsage,
  title,
  endDate,
}) => {
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    if (membershipCode) {
      navigator.clipboard.writeText(membershipCode);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };

  return (
    <MembershipDetailsWrapper>
      <Typography
        variant="h4"
        className="package-details-label"
        data-testid="membership-details-label"
        sx={{
          fontWeight: 600,
          background: 'linear-gradient(45deg, #33334f 30%, #5B5B8D 90%)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          mb: 2,
        }}
      >
        {title || ''}
      </Typography>

      <PackageDetailsItem label="Service" value={membershipServiceName} icon={<SpaIcon />} />


      <PackageDetailsItem
        label="Membership Price"
        value={`${membershipPrice} ${getCurrency(company?.currency)}`}
        icon={<LocalOfferIcon />}
        highlight
      />

      
      {membershipRegistrationFees !== undefined && (
        <PackageDetailsItem
          label="Registration Fees"
          value={membershipRegistrationFees === 0 ? 'Already paid' : `${membershipRegistrationFees} ${getCurrency(company?.currency)}`}
          icon={<PaymentIcon />}
        />
      )}

      {!!membershipValue && (
        <PackageDetailsItem
          label="Membership Value"
          value={`${membershipValue} Sessions`}
          icon={<AccessTimeIcon />}
        />
      )}

      {!!membershipRemainingValue && (
        <PackageDetailsItem
          label="Remaining Sessions"
          value={`${membershipRemainingValue} Sessions`}
          icon={<EventAvailableIcon />}
          data-testid="membership-remaining-value"
        />
      )}


      {!!membershipMaxUsage && (
        <PackageDetailsItem
          label="Number of Sessions"
          value={`${membershipMaxUsage} Sessions`}
          icon={<EventAvailableIcon />}
        />
      )}

      {!!membershipDuration && (
        <PackageDetailsItem
          label="Validity"
          value={
            membershipDuration === 100 && company?.id === DSW_COMPANY_ID
              ? 'Full Season'
              : `${membershipDuration} days`
          }
          icon={<AccessTimeIcon />}
        />
      )}

      {endDate && (
        <PackageDetailsItem
          label="Membership End Date"
          value={dayjs(endDate).format('DD/MM/YYYY')}
          icon={<AccessTimeIcon />}
        />
      )}

      {membershipCode && (
        <Box className="membership-code-container">
          <Chip
            label={`Code: ${membershipCode}`}
            className="membership-code-chip"
            variant="outlined"
            deleteIcon={
              <Tooltip title={copied ? 'Copied!' : 'Copy code'}>
                <IconButton
                  size="small"
                  sx={{
                    mr: '4px',
                    color: copied ? '#4CAF50' : '#33334f',
                    '&:hover': {
                      background: 'rgba(51, 51, 79, 0.05)',
                    },
                  }}
                >
                  {copied ? <CheckIcon /> : <ContentCopyIcon />}
                </IconButton>
              </Tooltip>
            }
            onDelete={handleCopy}
          />
        </Box>
      )}
    </MembershipDetailsWrapper>
  );
};

export default MembershipDetails;
