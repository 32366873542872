import React, { useContext } from 'react';
import { Membership } from '../../../hooks/useCompany';

import { useNavigate } from 'react-router-dom';
import { CheckoutContext, CheckoutContextProps } from '../../../context/CheckoutContext';
import MembershipDetails from './MembershipDetails';
import PurchasePackageButton from '../package/PurchasePackageButton';
import { Box, Card } from '@mui/material';

interface IProps {
  membership: Membership;
}
const MembershipPurchaseDetails: React.FC<IProps> = ({ membership }) => {
  const navigate = useNavigate();
  const { servicesMap, company } = useContext(CheckoutContext) as CheckoutContextProps;
  const service = servicesMap?.get(membership.serviceId);

  const handleClick = () => {
    navigate(`/membershipPurchase?companyId=${company?.id}&membershipId=${membership.id}`);
  };

  return (
    <Box sx={{ mb: 3, width: '100%' }}>
      <Card
        sx={{
          width: '100%',
          padding: '16px 20px',
          textAlign: 'center',
          mb: 2,
          boxShadow: '0 6px 40px rgba(208, 208, 208, 0.2)',
          borderRadius: '16px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <MembershipDetails
          membershipPrice={membership.price}
          membershipServiceName={service?.name || ''}
          membershipDuration={membership.days}
          membershipMaxUsage={membership.maxUsage}
          title={membership.name}
        />

        <PurchasePackageButton handleClick={handleClick} label={'Purchase'} />
      </Card>
    </Box>
  );
};
export default MembershipPurchaseDetails;
