import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { CheckoutContext, CheckoutContextProps } from '../../context/CheckoutContext';
import { formatDurationTime, getCurrency, getFormattedTime, getTimezone } from '../../Utils/format';
import BookingDetails from '../shared/bookingDetails';
import { getPrice } from '../../Utils/extract';

const CheckoutBookingDetails: React.FC = () => {
  const {
    company,
    selectedSlot,
    selectedDuration,
    selectedBranch,
    selectedResource,
    selectedService,
    servicesMap,
    resourcesMap,
    branchesMap,
    quantity,
    shouldShowServiceDropdown,
  } = useContext(CheckoutContext) as CheckoutContextProps;

  const companyName = company?.name;
  const locationName = branchesMap?.get(selectedBranch)?.name;
  const serviceName = shouldShowServiceDropdown
    ? servicesMap?.get(selectedService)?.name
    : resourcesMap?.get(selectedResource)?.services[0].name;
  const date = dayjs(selectedSlot?.fullDate).format('dddd, MMMM DD');
  const startTime = getFormattedTime(
    selectedSlot?.fullDate!,
    'h:mm A',
    false,
    getTimezone(company?.timezone)
  );
  const duration = formatDurationTime(selectedDuration?.durationTime!, company!, selectedResource);
  const price = selectedDuration?.price.toString() + ` ${getCurrency(company?.currency)}`;

  const service = servicesMap?.get(selectedService);

  const priceRow =
    service?.maxQuantity && service.maxQuantity > 1
      ? [
          {
            label: 'Price',
            value: `${getPrice(price) * quantity} ${getCurrency(company?.currency)}`,
          },
          { label: 'quantity', value: `${quantity}` },
        ]
      : [
          {
            label: 'Price',
            value: `${getPrice(price) * quantity} ${getCurrency(company?.currency)}`,
          },
        ];

  return (
    <BookingDetails
      details={[
        {
          items: [
            { label: 'Company', value: companyName! },
            { label: 'Location', value: locationName! },
          ],
        },
        {
          items: [
            { label: 'Date', value: date },
            { label: 'Service', value: serviceName! },
          ],
        },
        {
          items: [
            { label: 'Start Time', value: startTime },
            { label: 'Duration', value: duration },
          ],
        },
        {
          items: priceRow,
        },
      ]}
    />
  );
};
export default CheckoutBookingDetails;
