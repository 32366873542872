import { Typography } from '@mui/material';
import React from 'react';
import { StatusHeaderWrapper } from './style';

interface IProps {
  title: string;
  header: string;
}
const StatusHeader: React.FC<IProps> = ({ header, title }) => {
  return (
    <StatusHeaderWrapper>
      <Typography className="header">{header}</Typography>
      <Typography className="title"> {title}</Typography>
    </StatusHeaderWrapper>
  );
};

export default StatusHeader;
