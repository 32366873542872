import { Box, Link, Typography, useMediaQuery } from '@mui/material';
import { Link as ReactLink } from 'react-router-dom';
import React, { useContext } from 'react';
import { FooterWrapper } from './style';
import { ReactComponent as EmailIcon } from '../../../assets/icons/email.svg';
import { ReactComponent as PhoneIcon } from '../../../assets/icons/phone.svg';
import { ReactComponent as MapIcon } from '../../../assets/icons/map.svg';
import { ReactComponent as InstagramIcon } from '../../../assets/icons/instagram.svg';
import { ReactComponent as FacebookIcon } from '../../../assets/icons/facebook.svg';
import { ReactComponent as TiktokIcon } from '../../../assets/icons/tiktok.svg';
import { ReactComponent as YoutubeIcon } from '../../../assets/icons/youtube.svg';
import { ReactComponent as WhatsappIcon } from '../../../assets/icons/whatsapp.svg';

import { CheckoutContext, CheckoutContextProps } from '../../../context/CheckoutContext';

interface FooterProps {
  socialLinks: {
    instagram: string;
    facebook: string;
    youtube: string;
    tiktok: string;
  };
  hideCancellationPolicy?: boolean;
  hideTermsAndConditions?: boolean;
  hidePrivacyPolicy?: boolean;
  hideWhatsapp?: boolean;
  hidePhoneNumber?: boolean;
  hideAboutUs?: boolean;
}

const Footer: React.FC<FooterProps> = ({
  socialLinks,
  hideCancellationPolicy,
  hideTermsAndConditions,
  hidePrivacyPolicy,
  hideWhatsapp,
  hidePhoneNumber,
  hideAboutUs,
}) => {
  const isMd = useMediaQuery('(min-width:600px)');
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;

  const goToPath = (path: string) => {
    window.open(path, '_blank');
  };

  return (
    <FooterWrapper component={'footer'}>
      <Box
        className="footer-details"
        sx={{
          color: '#fff',
          fontSize: '10px',
          ...(isMd && { flexDirection: 'row !important', maxWidth: '90%' }),
          fontFamily: 'Dubai-Bold',
        }}
      >
        <Typography sx={{ color: '#fff', fontSize: '10px', whiteSpace: 'nowrap' }}>
          © {new Date().getFullYear()} {company?.name}. All rights reserved.
        </Typography>
        <Box
          className="links"
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '12px',
            ...(isMd && { width: 'auto', marginLeft: 'auto' }),
          }}
        >
          {!hideCancellationPolicy && (
            <Link
              component={ReactLink}
              to={`${company?.footerPagesLinks?.refund}`}
              target="_blank"
              sx={{ textDecoration: 'none', color: '#fff', fontSize: '10px' }}
              onClick={e => {
                if (!company?.footerPagesLinks?.refund) {
                  e.preventDefault();
                }
              }}
            >
              Cancellation Policy
            </Link>
          )}

          {!hideTermsAndConditions && (
            <Link
              component={ReactLink}
              to={`${company?.footerPagesLinks?.terms}`}
              target="_blank"
              sx={{ textDecoration: 'none', color: '#fff', fontSize: '10px' }}
              onClick={e => {
                if (!company?.footerPagesLinks?.terms) {
                  e.preventDefault();
                }
              }}
            >
              Terms & Conditions
            </Link>
          )}

          {!hidePrivacyPolicy && (
            <Link
              component={ReactLink}
              to={`${company?.footerPagesLinks?.privacy}`}
              target="_blank"
              sx={{ textDecoration: 'none', color: '#fff', fontSize: '10px' }}
              onClick={e => {
                if (!company?.footerPagesLinks?.privacy) {
                  e.preventDefault();
                }
              }}
            >
              Privacy Policy
            </Link>
          )}

          {!hideAboutUs && (
            <Typography
              onClick={() => {
                if (company?.footerPagesLinks?.aboutUs)
                  goToPath(`${company?.footerPagesLinks?.aboutUs}`);
              }}
              sx={{ color: '#fff', fontSize: '10px' }}
            >
              About Us
            </Typography>
          )}
        </Box>

        <Box
          className="address"
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '12px',
            ...(isMd && {
              marginBottom: '0px !important',
              width: 'auto',
              marginLeft: 0,
            }),
          }}
        >
          {/* <Typography
            sx={{
              color: "#fff",
              fontSize: "10px",
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
              whiteSpace: "nowrap",
            }}
            component="a"
            target="_blank"
            rel="noreferrer"
            href={company?.mapLink}
          >
            <MapIcon />
            {company?.companyAddress}
          </Typography> */}

          {!hidePhoneNumber && (
            <Typography
              sx={{
                color: '#fff',
                fontSize: '10px',
                textDecoration: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '8px',
                whiteSpace: 'nowrap',
              }}
              component="a"
              href={`tel:${company?.companyPhoneNumber}`}
            >
              <PhoneIcon />
              {company?.companyPhoneNumber}
            </Typography>
          )}

          <Typography
            sx={{
              color: '#fff',
              fontSize: '10px',
              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '8px',
            }}
            component="a"
            href={`mailto:${company?.companyEmail}`}
          >
            <EmailIcon />
            {company?.companyEmail}
          </Typography>
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '12px',
            ...(isMd && { width: 'fit-content' }),
          }}
        >
          <Link
            component={ReactLink}
            to={socialLinks.instagram}
            target="_blank"
            sx={{ textDecoration: 'none', color: '#fff', fontSize: '10px' }}
          >
            <InstagramIcon />
          </Link>
          <Link
            component={ReactLink}
            to={socialLinks.facebook}
            target="_blank"
            sx={{ textDecoration: 'none', color: '#fff', fontSize: '10px' }}
          >
            <FacebookIcon />
          </Link>
          <Link
            component={ReactLink}
            to={socialLinks.youtube}
            target="_blank"
            sx={{ textDecoration: 'none', color: '#fff', fontSize: '10px' }}
          >
            <YoutubeIcon />
          </Link>

          {!hideWhatsapp && (
            <Link
              component={ReactLink}
              to={`https://wa.me/${company?.companyPhoneNumber}`}
              target="_blank"
              sx={{ textDecoration: 'none', color: '#fff', fontSize: '10px' }}
            >
              <WhatsappIcon />
            </Link>
          )}
        </Box>
      </Box>
    </FooterWrapper>
  );
};

export default Footer;
