import React from 'react';

const CardNumberField: React.FC = () => (
  <div>
    <label htmlFor="card-number">Card number</label>
    <div className="input-container card-number">
      <div className="icon-container">
        <img id="icon-card-number" src="images/card-icons/card-number.svg" alt="PAN" />
      </div>
      <div className="card-number-frame"></div>
      <div className="icon-container payment-method">
        <img id="logo-payment-method" alt="" />
      </div>
      <div className="icon-container">
        <img id="icon-card-number-error" src="images/card-icons/error.svg" alt="Error" />
      </div>
    </div>
  </div>
);

export default CardNumberField;
