import React from 'react';
import { MenuItem, Select } from '@mui/material';
import { usePackageSelector } from '../../usePackageSelector';
import { IPackageSelectorProps } from '../../interfaces';
import { PackageSelectorWrapper } from './style';
import { getCurrency } from '../../../../../Utils/format';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Package } from '../../../../../hooks/usePackage';
import VipIcon from '@mui/icons-material/Stars';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';

const PackageSelectorTheme2: React.FC<IPackageSelectorProps> = ({
  packages,
  selectedPackage,
  setSelectedPackage,
  setIsValidating,
  setPackageValidationOutput,
  currency,
}) => {
  const { handleChange, servicesMap } = usePackageSelector(
    selectedPackage,
    setSelectedPackage,
    setIsValidating,
    setPackageValidationOutput
  );

  const renderMenuItem = (packageDetails: Package) => {
    const serviceName = packageDetails.serviceId ? servicesMap?.get(packageDetails.serviceId)?.name : '';
    const packageName = packageDetails.name ? packageDetails.name : packageDetails.code;
    
    if (packageDetails.type === 'membership') {
      return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <VipIcon fontSize="small" style={{ color: '#FFD700' }} />
          {`${packageName} | Membership${
            packageDetails.serviceId && servicesMap
              ? ` for ${serviceName}`
              : ''
          }`}
        </div>
      );
    } else {
      const packageCredit = packageDetails.totalCredit - packageDetails.usedCredit;
      return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <AccountBalanceWalletIcon fontSize="small" style={{ color: '#2E7D32' }} />
          {`${packageName} ${
            packageCredit ? `- ${packageCredit} ${getCurrency(currency)}` : ''
          } ${
            packageDetails.serviceId && servicesMap
              ? ` | ${serviceName}`
              : ''
          }`}
        </div>
      );
    }
  }

  const renderSelectedValue = (value: string) => {
    if (value === 'promo-code') {
      return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <ConfirmationNumberIcon fontSize="small" style={{ color: '#1976D2' }} />
          Promo Code
        </div>
      );
    }
    const packageDetails = packages.find(pkg => pkg.code === value);
    if (!packageDetails) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <LocalOfferOutlinedIcon fontSize="small" />
          Select Package or Promo Code
        </div>
      );
    }
    return renderMenuItem(packageDetails);
  }

  return (
    <PackageSelectorWrapper>
      <div className="select-container">
        <div className="select-content">
          <div className="select-field-container">
            <Select
              onChange={handleChange}
              value={selectedPackage}
              displayEmpty
              className={!selectedPackage ? 'placeholder' : ''}
              renderValue={value => renderSelectedValue(value)}
            >
              {packages.map(currentPackage => (
                <MenuItem key={currentPackage.id} value={currentPackage.code}>
                  {renderMenuItem(currentPackage)}
                </MenuItem>
              ))}
              <MenuItem value="promo-code">
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <ConfirmationNumberIcon fontSize="small" style={{ color: '#1976D2' }} />
                  Promo Code
                </div>
              </MenuItem>
            </Select>
          </div>
        </div>
      </div>
    </PackageSelectorWrapper>
  );
};

export default PackageSelectorTheme2;
