import styled from '@emotion/styled';
import { Box, Dialog } from '@mui/material';

interface IProps {
  theme: any;
}

export const DeleteAccountButtonWrapper = styled(Box)<IProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 56px;
  padding: 0 16px;
  background-color: #fff;
  border: 1px solid rgba(240, 240, 240, 1);
  border-radius: 12px;

  .delete-account {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    color: rgba(12, 12, 12, 1);
    letter-spacing: -0.4px;
  }

  .delete-button {
    background-color: #fff;
    border: none;
    border-radius: 1000px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    -webkit-mask-composite: xor;
    &:hover {
      background-color: #eee;
    }
  }

  .button-text {
    color: red;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    letter-spacing: -0.4px;
    text-transform: upperCase;
  }

  @media (max-width: 868px) {
    width: 100%;
  }
`;

export const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    border-radius: 12px;
    padding: 24px;
    max-width: 400px;
    width: 100%;
  }

  .dialog-title {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    color: rgba(12, 12, 12, 1);
    letter-spacing: -0.4px;
    margin-bottom: 16px;
    padding: 0;
  }

  .dialog-content {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: rgba(36, 36, 36, 0.8);
    padding: 0;
    margin-bottom: 24px;
  }

  .dialog-actions {
    display: flex;
    gap: 12px;
    padding: 0;
    justify-content: flex-end;

    .delete-btn {
      width: 139px;
      height: 32px;
      background: red;
      color: #fff;
      border-radius: 1000px;
      text-transform: none;
      padding: 6px 8px;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      letter-spacing: -0.4px;
    }

    .cancel-btn {
      background-color: #f5f5f5;
      color: rgba(36, 36, 36, 1);
      border-radius: 1000px;
      text-transform: none;
      padding: 6px 16px;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      letter-spacing: -0.4px;

      &:hover {
        background-color: #eee;
      }
    }
  }
`;
