import React from 'react';
import { DaysContainer, DayChipContainer, DayName, DayNumber } from './style';
import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

interface DaysListProps {
  selectedDay: string;
  setSelectedDay: (date: string) => void;
  dayHasAvailableSlots: Map<string, boolean>;
  onlySevenDaysInAdvance?: boolean;
  daysList: Dayjs[];
}

const DaysList: React.FC<DaysListProps> = ({
  selectedDay,
  setSelectedDay,
  dayHasAvailableSlots,
  daysList,
}) => {
  const handleClick = (day: Dayjs) => {
    setSelectedDay(day.format('DD/MM/YYYY'));
  };

  const isToday = (day: Dayjs) => {
    const now = dayjs();
    return day.format('YYYY-MM-DD') === now.format('YYYY-MM-DD');
  };

  return (
    <DaysContainer>
      {daysList.map(day => {
        const dateString = day.format('YYYYMMDD');
        const isAvailable = dayHasAvailableSlots.get(dateString) ?? false;
        const isSelected = day.format('DD/MM/YYYY') === selectedDay;
        const dayIsToday = isToday(day);

        return (
          <DayChipContainer
            key={dateString}
            isSelected={isSelected}
            isAvailable={isAvailable}
            onClick={() => handleClick(day)}
          >
            <DayName isSelected={isSelected}>{dayIsToday ? 'Today' : day.format('ddd')}</DayName>
            <DayNumber isSelected={isSelected}>{dayIsToday ? '' : day.format('D')}</DayNumber>
          </DayChipContainer>
        );
      })}
    </DaysContainer>
  );
};

export default DaysList;
