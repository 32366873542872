import { useDaySelector } from '../../hooks/useDaySelector';
import DaysList from './DaysList';
import { IDaySelectorProps } from '../../interfaces';
import DaySelectorHeader from './DaySelectorHeader';
import { DaySelectorWrapper } from './style';
import { useTheme } from '@mui/material';

const DaySelectorTheme1: React.FC<IDaySelectorProps> = ({
  onlySevenDaysInAdvance,
  dayHasAvailableSlots,
  selectedWeekStartDate,
  setSelectedWeekStartDate,
}) => {
  const {
    selectedDay,
    setSelectedDay,
    daysList,
    handlePrevWeek,
    handleNextWeek,
    isBackDisabled,
    isNextDisabled,
    getMonths,
  } = useDaySelector(selectedWeekStartDate, setSelectedWeekStartDate);

  console.log('[DaySelectorTheme1] daysHasAvailableSlots', dayHasAvailableSlots);
  const theme = useTheme();
  return (
    <DaySelectorWrapper className="days-wrapper" theme={theme}>
      <DaySelectorHeader
        selectedWeekStartDate={selectedWeekStartDate}
        setSelectedWeekStartDate={setSelectedWeekStartDate}
        setSelectedDay={setSelectedDay}
        handlePrevWeek={handlePrevWeek}
        handleNextWeek={handleNextWeek}
        isBackDisabled={isBackDisabled}
        isNextDisabled={isNextDisabled}
        onlySevenDaysInAdvance={onlySevenDaysInAdvance}
        getMonths={getMonths}
      />
      <DaysList
        selectedDay={selectedDay}
        setSelectedDay={setSelectedDay}
        daysList={daysList}
        dayHasAvailableSlots={dayHasAvailableSlots}
      />
    </DaySelectorWrapper>
  );
};

export default DaySelectorTheme1;
