import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import FormInput from '../../../../FormCollection/FormInput';
import { UseFormReturn } from 'react-hook-form';
import { IFormTypes } from '../../Types';
import { FormContainer } from './style';
import PrimaryBtn from '../../../../FormCollection/PrimaryBtn';

interface ISignupFormProps {
  form: UseFormReturn<IFormTypes, any>;
  onSubmit: (data: IFormTypes) => void;
  isLoading: boolean;
}

const SignupForm: React.FC<ISignupFormProps> = ({ form, onSubmit, isLoading }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = form;

  const theme = useTheme();
  return (
    <FormContainer
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <FormInput
        error={errors.email}
        placeholder={'Email Address*'}
        register={register('email')}
        id="email"
        type="email"
        dataTestId="email-input"
      />

      <PrimaryBtn
        isLoading={isLoading}
        type="submit"
        text="Continue"
        dataTestId="submit-button"
        disabled={isLoading}
      />
    </FormContainer>
  );
};

export default SignupForm;
