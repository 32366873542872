import React from 'react';

import { Badge, Popover, Typography, useTheme } from '@mui/material';

import { PopoverWrapper, SlotChipWrapper, StyledBadge } from './style';

import { getFormattedTime, getTimezone } from '../../../../../Utils/format';

import SlotConfirmation from './SlotConfirmation';
import { ISlotChipProps } from '../../interfaces';

const SlotChip: React.FC<ISlotChipProps> = ({
  slot,
  handleAddToCart,
  areAllOffPeakSlots,
  selectedSlot,
  setSelectedSlot,
  timezone,
  isSelected,
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<any>(null);

  const handleClick = (event: React.MouseEvent) => {
    setSelectedSlot(slot);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setSelectedSlot(undefined);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <StyledBadge>
        <Badge badgeContent={slot.isPeak || areAllOffPeakSlots ? '' : 'off-peak'}>
          <SlotChipWrapper
            onClick={handleClick}
            aria-describedby={id}
            isSelected={isSelected}
            theme={theme}
          >
            <Typography>
              {getFormattedTime(slot.fullDate, 'hh:mm A', false, getTimezone(timezone))}
            </Typography>
          </SlotChipWrapper>
        </Badge>
      </StyledBadge>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            boxShadow: '0 6px 40px rgba(208, 208, 208, 0.4)',
            borderRadius: '8px',
            paddingY: '12px',
            paddingX: '16px',
          },
        }}
      >
        <PopoverWrapper>
          <SlotConfirmation
            handleAddToCart={handleAddToCart}
            setAnchorEl={setAnchorEl}
            selectedSlot={selectedSlot}
          />
        </PopoverWrapper>
      </Popover>
    </>
  );
};

export default SlotChip;
