import { useState, useEffect, useContext } from 'react';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import { CheckoutContextProps, CheckoutContext } from '../context/CheckoutContext';

export const useDaySelection = () => {
  const [searchParams] = useSearchParams();
  const date = searchParams.get('date');

  const [selectedWeekStartDate, setSelectedWeekStartDate] = useState<Date>(new Date());
  const { setSelectedDay } = useContext(CheckoutContext) as CheckoutContextProps;

  useEffect(() => {
    const today = dayjs().startOf('day');
    
    if (date) {
      const formattedDate = dayjs(date, 'DD/MM/YYYY');
      
      if (formattedDate.isValid()) {
        const dateToUse = formattedDate.isBefore(today) ? today : formattedDate;
        
        setSelectedDay(dateToUse.format('DD/MM/YYYY'));
        setSelectedWeekStartDate(dateToUse.toDate());
        return;
      }
    }
    
    const currentDate = dayjs(selectedWeekStartDate).startOf('day');
    setSelectedDay(currentDate.format('DD/MM/YYYY'));
  }, [date, setSelectedDay]);

  return { selectedWeekStartDate, setSelectedWeekStartDate };
};
