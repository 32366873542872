import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { TimerResult } from 'react-timer-hook';
import { CountdownContainer, TimeText, MessageText, ProgressWrapper } from './style';
import { Company } from '../../../../../hooks/useCompany';
import { getCountdownTime } from '../../../../../Utils/validate';
interface ControlledCountdownProps {
  countdownApi: TimerResult;
  company?: Company;
}

const ControlledCountdown: React.FC<ControlledCountdownProps> = ({ countdownApi, company }) => {
  const theme = useTheme();
  const { minutes, seconds, isRunning } = countdownApi;
  const [progress, setProgress] = useState(100);

  useEffect(() => {
    if (isRunning) {
      const totalSeconds = getCountdownTime(company);
      const currentSeconds = minutes * 60 + seconds;
      const progressValue = (currentSeconds / totalSeconds) * 100;
      setProgress(progressValue);
    }
  }, [minutes, seconds, isRunning]);

  if (!isRunning) return null;

  return (
    <CountdownContainer>
      <ProgressWrapper>
        <CircularProgress
          variant="determinate"
          value={-progress}
          size={56}
          thickness={4}
          sx={{
            color: theme.palette.primary.main,
            position: 'absolute',
            zIndex: 1,
            transform: 'rotate(-90deg)',
          }}
        />
        <CircularProgress
          variant="determinate"
          value={-100}
          size={56}
          thickness={4}
          sx={{
            color: theme.palette.grey[200],
            position: 'absolute',
            transform: 'rotate(-90deg)',
          }}
        />
        <TimeText theme={theme}>
          {`${minutes.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}:${seconds.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}`}
        </TimeText>
      </ProgressWrapper>
      <MessageText theme={theme}>Cart reservation time</MessageText>
    </CountdownContainer>
  );
};

export default ControlledCountdown;
