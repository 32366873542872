import React, { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DUBAI_FITNESS_CHALLENGE_COMPANY_ID } from '../../Utils/constants';
import Typography from '@mui/material/Typography'; // Add this import

import DaySelector from '../../components/Calendar/DaySelector';
import { CheckoutContext, CheckoutContextProps } from '../../context/CheckoutContext';

import useTitle from '../../hooks/useTitle';

import ClassSessionSelector from '../../components/Calendar/ClassSessionSelector';
import useClassSessions from '../../hooks/useClassSessions';
import dayjs from 'dayjs';
import { ClassCalendarWrapper } from './style';
import CustomFooter from '../../components/shared/footer/CustomFooter';
import { useDaySelection } from '../../hooks/useDaySelection';
import DFCFooter from '../../components/shared/footer/DFCFooter';
import ServiceSelector from '../../components/Calendar/ServiceSelector';

interface IProps {
  title: string;
}

const ClassCalendar: React.FC<IProps> = ({ title }) => {
  useTitle(title);

  const [searchParams] = useSearchParams();
  const serviceId = searchParams.get('serviceId');
  const branchId = searchParams.get('branchId');
  const resourceId = searchParams.get('resourceId');

  const {
    selectedService,
    company,
    selectedDay,
    setSelectedDay,
    selectedResource,
    marketplaceCompany,
  } = useContext(CheckoutContext) as CheckoutContextProps;

  const { isLoading: isLoadingSessions, sessions } = useClassSessions(
    company?.id || '',
    selectedService,
    selectedResource,
    dayjs(selectedDay, 'DD/MM/YYYY').format('YYYYMMDD')
  );

  const { selectedWeekStartDate, setSelectedWeekStartDate } = useDaySelection();

  return (
    <ClassCalendarWrapper>
      <ServiceSelector
        type="class"
        subType="fitness"
        serviceId={serviceId || ''}
        branchId={branchId || ''}
        resourceId={resourceId || ''}
      />

      <DaySelector
        onlySevenDaysInAdvance={company?.id === DUBAI_FITNESS_CHALLENGE_COMPANY_ID}
        dayHasAvailableSlots={new Map()}
        selectedWeekStartDate={selectedWeekStartDate}
        setSelectedWeekStartDate={setSelectedWeekStartDate}
      />

      {company?.id === DUBAI_FITNESS_CHALLENGE_COMPANY_ID && (
        <Typography
          variant="body2"
          sx={{
            marginBottom: 2,
            fontStyle: 'italic',
            color: 'darkred',
            textAlign: 'center',
          }}
        >
          Class timetable is updated daily and subject to change. <br />
          Classes are not bookable they are filled on a First come – first served basis.
        </Typography>
      )}

      <ClassSessionSelector sessions={sessions} isLoading={isLoadingSessions} type="fitness" />

      {company?.hasFooter && <CustomFooter company={marketplaceCompany || company} />}

      {company?.id === DUBAI_FITNESS_CHALLENGE_COMPANY_ID && <DFCFooter />}
    </ClassCalendarWrapper>
  );
};

export default ClassCalendar;
