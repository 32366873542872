import styled from '@emotion/styled';
import { AppBar, Box, Button, Badge, Typography } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { styled as muiStyled } from '@mui/material/styles';

interface HeaderProps {
  theme: Theme;
  isHome?: boolean;
}

export const ModernAppBar = styled(AppBar)<HeaderProps>`
  background: rgba(255, 255, 255, 0.98);
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  width: ${({ isHome }) => (isHome ? 'calc(100% - 48px)' : '100%')};
  border-radius: ${({ isHome }) => (isHome ? '0 0 24px 24px' : '0')};
  padding: 16px 24px;
  transition: all 0.3s ease;
  position: sticky;
  top: 0;
  z-index: 1100;
`;

export const HeaderContent = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 16px;
`;

export const ActionGroup = styled(Box)`
  display: flex;
  align-items: center;
  gap: 16px;
  min-width: 48px;
  justify-content: flex-end;
`;

export const ModernCartButton = styled(Button)`
  min-width: 40px;
  width: 40px;
  height: 40px;
  border-radius: 1000px;
  padding: 0;
  background: transparent;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(0, 0, 0, 0.04);
    transform: translateY(-1px);
  }

  svg {
    width: 20px;
    height: 20px;
    color: #24203a;
  }
`;

export const StyledBadge = muiStyled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    background: theme.palette.primary.main,
    color: 'white',
    minWidth: '20px',
    height: '20px',
    padding: '0 6px',
    borderRadius: '1000px',
    fontSize: '12px',
    fontWeight: 500,
  },
}));

export const ModernToggleButton = styled(Button)`
  min-width: 40px;
  width: 40px;
  height: 40px;
  border-radius: 1000px;
  padding: 0;
  background: transparent;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(0, 0, 0, 0.04);
    transform: translateY(-1px);
  }

  svg {
    width: 20px;
    height: 20px;
    color: #24203a;
  }
`;

export const CountdownWrapper = styled(Box)`
  animation: slideDown 0.3s ease;

  @keyframes slideDown {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const CountdownContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 12px;
  background: rgba(255, 255, 255, 0.98);
  backdrop-filter: blur(10px);
  animation: slideDown 0.3s ease;
`;

export const ProgressWrapper = styled(Box)`
  position: relative;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: pulse 2s infinite;

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
`;

export const TimeText = styled(Box)`
  font-size: 16px;
  font-weight: 600;
  color: ${(props: { theme: Theme }) => props.theme.palette.primary.main};
  z-index: 2;
`;

export const MessageText = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  color: ${(props: { theme: Theme }) => props.theme.palette.text.secondary};
  letter-spacing: -0.3px;
`;

export const LogoContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  cursor: pointer;
  transition: opacity 0.2s ease;
  // max-width: 400px;
  
  &:hover {
    opacity: 0.9;
  }
`;

