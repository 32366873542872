import React from 'react';
import QRCode from 'react-qr-code';
import { UserInformationContainer, ProfileInformationWrapper, QRCodeWrapper } from './style';
import { useUserInformationSection } from '../../useUserInformationSection';
import { IUserInformationSectionProps } from '../../interfaces';

export const UserInformationSectionTheme2: React.FC<IUserInformationSectionProps> = ({
  user,
  authToken,
}) => {
  const { qrCodeValue, theme } = useUserInformationSection(user!, authToken);
  const { firstName, lastName, email } = user || {};

  return (
    <UserInformationContainer theme={theme}>
      <ProfileInformationWrapper theme={theme}>
        <div className="profile-information-container">
          <h2 className="title">Profile Information</h2>
          <div className="info-container">
            <div className="name-container">
              <label className="label">First name</label>
              <span className="value">{firstName}</span>
            </div>
            <div className="name-container">
              <label className="label">Last name</label>
              <span className="value">{lastName}</span>
            </div>
            <div className="name-container">
              <label className="label">Email</label>
              <span className="value">{email}</span>
            </div>
          </div>
        </div>
      </ProfileInformationWrapper>

      <QRCodeWrapper theme={theme}>
        <div className="qr-code-container">
          <h2 className="qr-code-title">Scan QR Code for quick Check-in</h2>
          <QRCode value={qrCodeValue} size={170} className="qr-code-image" level="H" />
        </div>
      </QRCodeWrapper>
    </UserInformationContainer>
  );
};

export default UserInformationSectionTheme2;
