import React from 'react';
import { Card, CardContent, CardMedia, Typography, Box, Chip, Button, CardActions } from '@mui/material';
import { Service } from '../../../hooks/useCompany';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// Import icons for different service types
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter'; // For gym
import ChildCareIcon from '@mui/icons-material/ChildCare'; // For kids
import SportsIcon from '@mui/icons-material/Sports'; // Default and sports
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball'; // For class
import PersonIcon from '@mui/icons-material/Person'; // For coach
import SportsEsportsIcon from '@mui/icons-material/SportsEsports'; // For fun
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun'; // For fitness
import ViewInArIcon from '@mui/icons-material/ViewInAr'; // For pods

interface ServiceCardProps {
  service: Service;
  providerId: string;
  onClick: (providerId: string, serviceId: string) => void;
}

const ServiceCard: React.FC<ServiceCardProps> = ({ service, providerId, onClick }) => {
  // Get service type label
  const getServiceTypeLabel = (type?: string, subType?: string) => {
    if (subType) {
      return subType.charAt(0).toUpperCase() + subType.slice(1);
    }
    if (type) {
      return type.charAt(0).toUpperCase() + type.slice(1);
    }
    return 'Standard';
  };

  // Get appropriate icon based on service type and subtype
  const getServiceIcon = (type?: string, subType?: string) => {
    // First check subType
    if (subType) {
      switch (subType) {
        case 'gym':
          return <FitnessCenterIcon />;
        case 'kids':
          return <ChildCareIcon />;
        case 'fun':
          return <SportsEsportsIcon />;
        case 'fitness':
          return <DirectionsRunIcon />;
        case 'pods':
          return <ViewInArIcon />;
      }
    }

    // Then check type
    if (type) {
      switch (type) {
        case 'class':
          return <SportsBasketballIcon />;
        case 'coach':
          return <PersonIcon />;
        case 'standard':
          return <SportsIcon />;
      }
    }

    // Default icon
    return <SportsIcon />;
  };

  return (
    <Card 
      sx={{ 
        width: 280, 
        minWidth: 280, 
        borderRadius: '12px',
        boxShadow: '0 6px 40px rgba(208, 208, 208, 0.2)',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <CardMedia
        component="div"
        sx={{
          height: 100,
          background: service.image
            ? `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${service.image})`
            : 'linear-gradient(45deg, #E2ECff 10%, #FCCCB8 100%)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          alignItems: 'flex-end',
          padding: 1
        }}
      >
      </CardMedia>
      <CardContent sx={{ flexGrow: 1, p: 1.5, pb: 0.5 }}>
        <Typography variant="subtitle1" component="div" sx={{ fontWeight: 'bold', mb: 0.5, lineHeight: 1.2 }}>
          {service.name}
        </Typography>
        <Typography 
          variant="body2" 
          color="text.secondary"
          sx={{ 
            mb: 1,
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            height: '2.4em',
            fontSize: '0.8rem',
            lineHeight: 1.2
          }}
        >
          {service.description}
        </Typography>
        
      </CardContent>
      <CardActions sx={{ justifyContent: 'center', p: '0 8px 8px 8px' }}>
        <Button 
          variant="contained" 
          disableElevation
          onClick={() => onClick(providerId, service.id)}
          endIcon={
            <Box 
              sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                width: '18px',
                height: '18px',
                borderRadius: '50%',
                backgroundColor: 'rgba(255, 255, 255, 0.25)',
                ml: 0.5
              }}
            >
              <ArrowForwardIcon sx={{ fontSize: '0.8rem' }} />
            </Box>
          }
          fullWidth
          sx={{ 
            borderRadius: '6px',
            textTransform: 'none',
            fontWeight: 'bold',
            py: 0.75,
            fontSize: '0.8rem',
            background: theme => `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
            border: 'none',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
          }}
        >
          View Memberships
        </Button>
      </CardActions>
    </Card>
  );
};

export default ServiceCard; 