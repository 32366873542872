import React, { useContext, useEffect, useState } from 'react';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import { ThemeProvider, Box, Typography } from '@mui/material';
import { CheckoutContext, CheckoutContextProps } from './context/CheckoutContext';
import { BaseUrlToCompany, RegistrationStep } from './Utils/constants';
import { COLORS } from './Utils/colors';
import { createCustomTheme } from './theme';
import { AppWrapper } from './Style';
import useCompany from './hooks/useCompany';
import useCart from './hooks/useCart';
import Layout from './components/Layout';
import ContentLoader from './components/shared/ContentLoader';
import 'react-toastify/dist/ReactToastify.css';

import {
  Calendar,
  MarketplaceCalendar,
  ClassCalendar,
  CoachCalendar,
  GymCalendar,
  KidsAreaCalendar,
  KidsBooking,
  Checkout,
  Confirmation,
  Cancellation,
  Status,
  PackageConfirmation,
  PackagePurchase,
  MembershipPurchase,
  PackageCancellation,
  Cart,
  Profile,
  CartCheckout,
  AddOns,
  Packages,
  Register,
  Home,
  ResetPassword,
  FunActivities,
  ConsentForm,
  BirthdayCelebrationForm,
  EmailVerification,
  InValidEmailVerification,
  EmailVerificationConfirmation,
  MemberShips,
  Privacy,
  CancellationPolicy,
  Terms,
  CheckIn,
  Error,
  KidsActivitiesCalendar,
  GroupedMemberships,
  GroupBookingConfirmation,
} from './pages/index';
import HTMLHeadManipulator from './HTMLHeadManipulator';
import PodsClasses from './pages/PodsClasses';
import PodsBooking from './pages/PodsClasses/PodsBooking';
import useMarketplace from './hooks/useMarketplace';

const App: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [theme, setTheme] = useState<any>(null);
  const [searchParams] = useSearchParams();

  const companyId =
    searchParams.get('companyId') || BaseUrlToCompany.get(window.location.origin) || '';
  const { loading: isLoadingCompany } = useCompany(companyId);
  const { loading: isLoadingMarketplace } = useMarketplace();
  const { company, marketplaceCompany } = useContext(CheckoutContext) as CheckoutContextProps;

  const toggleDrawer = (open: boolean) => setIsOpen(open);

  useEffect(() => {
    if (!isLoadingCompany && !isLoadingMarketplace) {
      if (marketplaceCompany) {
        setTheme(createCustomTheme(marketplaceCompany?.primaryColor || COLORS.blue));
      } else {
        setTheme(createCustomTheme(company?.primaryColor || COLORS.blue));
      }
    }
  }, [isLoadingCompany, isLoadingMarketplace]);

  useCart(true);

  if (isLoadingCompany || isLoadingMarketplace || !theme) {
    return (
      <AppWrapper>
        <ContentLoader isLoading={true}>
          <div />
        </ContentLoader>
      </AppWrapper>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <HTMLHeadManipulator />
      {company?.isBetaVersion && (
        <Box sx={{ backgroundColor: 'yellow', padding: '10px', textAlign: 'center' }}>
          <Typography variant="body1" sx={{ fontFamily: 'Dubai-Bold' }}>
            This is a test website. All bookings are test bookings, and will be deleted.
          </Typography>
        </Box>
      )}
      <AppWrapper>
        <Layout isOpen={isOpen} toggleDrawer={toggleDrawer}>
          <Routes>
            <Route path="/calendar" element={<Calendar title="Calendar" />} />
            <Route
              path="/MarketplaceCalendar"
              element={<MarketplaceCalendar title="MarketplaceCalendar" />}
            />
            <Route path="/Class" element={<ClassCalendar title="Class" />} />
            <Route path="/Coach" element={<CoachCalendar title="Coach" />} />
            <Route path="/Gym" element={<GymCalendar title="Gym" />} />
            <Route path="/KidsArea" element={<KidsAreaCalendar title="KidsArea" />} />
            <Route
              path="/KidsActivities"
              element={<KidsActivitiesCalendar title="KidsActivities" />}
            />
            <Route path="/PodsClasses" element={<PodsClasses title="PodsClasses" />} />
            <Route path="/KidsBooking" element={<KidsBooking title="KidsBooking" />} />
            <Route path="/PodsBooking" element={<PodsBooking title="PodsBooking" />} />
            <Route path="/Checkout" element={<Checkout title="Booking Details" />} />
            <Route path="/Confirmation" element={<Confirmation title="Confirmation" />} />
            <Route path="/Cancellation" element={<Cancellation title="Cancellation" />} />
            <Route path="/Status" element={<Status title="Status" />} />
            <Route
              path="/PackageConfirmation"
              element={<PackageConfirmation title="PackageConfirmation" />}
            />
            <Route path="/PackagePurchase" element={<PackagePurchase title="PackagePurchase" />} />
            <Route
              path="/MembershipPurchase"
              element={<MembershipPurchase title="MembershipPurchase" />}
            />
            <Route
              path="/PackageCancellation"
              element={<PackageCancellation title="PackageCancellation" />}
            />
            <Route path="/Cart" element={<Cart title="Cart" />} />
            <Route path="/My-profile" element={<Profile title="My-profile" />} />
            <Route path="/CartCheckout" element={<CartCheckout title="Checkout" />} />
            <Route path="/AddOns" element={<AddOns title="Add-ons" />} />
            <Route path="/Packages" element={<Packages title="Packages" />} />
            <Route
              path="/Register"
              element={
                <Register
                  registrationStep={RegistrationStep.SignUp}
                  title="Register"
                  redirectToCalendar={true}
                />
              }
            />
            <Route path="/" element={<Home title="Home" />} />
            <Route path="/ResetPassword" element={<ResetPassword title="ResetPassword" />} />
            <Route path="/Fun-activities" element={<FunActivities title="Fun-activities" />} />
            <Route path="/Consent-form" element={<ConsentForm title="Consent-form" />} />
            <Route
              path="/birthday-celebration"
              element={<BirthdayCelebrationForm title="birthday-celebration" />}
            />
            <Route path="/EmailVerification" element={<EmailVerification />} />
            <Route
              path="/InValidEmailVerification"
              element={<InValidEmailVerification title="InValidEmailVerification" />}
            />
            <Route
              path="/EmailVerificationConfirmation"
              element={<EmailVerificationConfirmation title="EmailVerificationConfirmation" />}
            />
            <Route path="/memberships" element={<MemberShips title="memberships" />} />
            <Route
              path="/groupedMemberships"
              element={<GroupedMemberships title="grouped-memberships" />}
            />
            <Route path="/Privacy" element={<Privacy title="Privacy" />} />
            <Route
              path="/Cancellation-policy"
              element={<CancellationPolicy title="Cancellation-policy" />}
            />
            <Route path="/Terms" element={<Terms title="Terms" />} />
            <Route path="/CheckIn" element={<CheckIn title="CheckIn" />} />
            <Route path="/GroupBookingConfirmation" element={<GroupBookingConfirmation />} />
            <Route path="*" element={<Error />} />
          </Routes>
        </Layout>
      </AppWrapper>
    </ThemeProvider>
  );
};

export default App;
