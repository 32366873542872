import React, { useContext, useState } from 'react';

import dayjs from 'dayjs';
import { CheckoutContext, CheckoutContextProps } from '../../context/CheckoutContext';
import useTitle from '../../hooks/useTitle';
import useAvailableStartingTimes from '../../hooks/useAvailableStartingTimes';
import useCart from '../../hooks/useCart';
import { KidsCalendarWrapper } from './style';
import DaySelector from '../../components/Calendar/DaySelector';
import TimeSelector from '../../components/Calendar/TimeSelector';
import QuantitySelector from '../../components/Calendar/QuantitySelector';
import { useDaySelection } from '../../hooks/useDaySelection';
import DFCFooter from '../../components/shared/footer/DFCFooter';
import { DUBAI_FITNESS_CHALLENGE_COMPANY_ID } from '../../Utils/constants';
import ServiceSelector from '../../components/Calendar/ServiceSelector';
interface KidsAreaCalendarProps {
  title: string;
}

const KidsAreaCalendar: React.FC<KidsAreaCalendarProps> = ({ title }) => {
  useTitle(title);
  const { addToCart } = useCart();
  const checkoutContext = useContext(CheckoutContext) as CheckoutContextProps;
  const { selectedWeekStartDate, setSelectedWeekStartDate } = useDaySelection();

  const { loading: isLoadingAvailabilitySlots } = useAvailableStartingTimes({
    companyId: checkoutContext.company?.id,
    branchId: checkoutContext.selectedBranch,
    serviceId: checkoutContext.selectedService,
    selectedDay: checkoutContext.selectedDay,
    selectedResource: checkoutContext.selectedResource,
    isAllResourcesOptionEnabled: checkoutContext.isAllResourcesOptionEnabled,
    bookingCart: checkoutContext.bookingCart,
    selectedWeekStartDate,
  });

  const service = checkoutContext.servicesMap?.get(checkoutContext.selectedService);

  return (
    <KidsCalendarWrapper>
      <ServiceSelector type="standard" subType="kids" />
      <DaySelector
        onlySevenDaysInAdvance={false}
        dayHasAvailableSlots={new Map()}
        selectedWeekStartDate={selectedWeekStartDate}
        setSelectedWeekStartDate={setSelectedWeekStartDate}
      />
      {service?.maxQuantity && service.maxQuantity > 1 && <QuantitySelector />}
      <TimeSelector
        availableStartingTimes={checkoutContext.availableStartingTimesResource!}
        isLoadingAvailabilitySlots={isLoadingAvailabilitySlots || checkoutContext.isCartLoading}
        showDurationFilter={true}
        timeUnit="Hour"
        startTime={service?.workingHours?.start}
        endTime={service?.workingHours?.end}
      />

      {checkoutContext.company?.id === DUBAI_FITNESS_CHALLENGE_COMPANY_ID && <DFCFooter />}
    </KidsCalendarWrapper>
  );
};

export default KidsAreaCalendar;
