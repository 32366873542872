import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const PackagesWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // width: 90%;
  margin-top: 10px;

  width: 100%;
`;
