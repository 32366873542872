import { Typography, useTheme } from '@mui/material';
import React from 'react';

import { DurationChipWrapper } from './style';

interface IProps {
  formattedDuration: string;
  isSelected: boolean;
  onDurationClick: () => void;
}
const DurationChipTheme2: React.FC<IProps> = ({
  formattedDuration,
  isSelected,
  onDurationClick,
}) => {
  const theme = useTheme();

  return (
    <DurationChipWrapper onClick={onDurationClick} isSelected={isSelected} theme={theme}>
      <Typography
        className="duration"
        sx={{
          fontFamily: 'Inter',
          fontSize: '14px !important',
          fontWeight: 500,
          lineHeight: '1',
          whiteSpace: 'nowrap',
          letterSpacing: '0.1px',
          color: isSelected ? '#FFFFFF' : '#1A1A1A',
        }}
      >
        {formattedDuration}
      </Typography>
    </DurationChipWrapper>
  );
};

export default DurationChipTheme2;
