import React from 'react';
import QRCode from 'react-qr-code';
import { InfoBox, UserInformationWrapper, QRCodeWrapper } from './style';
import { Typography } from '@mui/material';
import LandingPageTitle from '../../../../shared/LandingPageTitle';
import { useUserInformationSection } from '../../useUserInformationSection';
import { IUserInformationSectionProps } from '../../interfaces';

const UserInformationSectionTheme1: React.FC<IUserInformationSectionProps> = ({
  user,
  authToken,
}) => {
  const { firstName, lastName, email } = user || {};
  const { theme, qrCodeValue } = useUserInformationSection(user!, authToken);

  return (
    <>
      <LandingPageTitle title="Profile Information" />
      <UserInformationWrapper theme={theme}>
        <InfoBox theme={theme}>
          <span className="label">First Name</span>
          <span className="value">{firstName || '-'}</span>
        </InfoBox>
        <InfoBox theme={theme}>
          <span className="label">Last Name</span>
          <span className="value">{lastName || '-'}</span>
        </InfoBox>
        <InfoBox theme={theme}>
          <span className="label">Email</span>
          <span className="value">{email || '-'}</span>
        </InfoBox>

        <QRCodeWrapper theme={theme}>
          <Typography className="qr-label">Scan QR Code for Quick Check-in</Typography>
          <div className="qr-code">
            <QRCode value={qrCodeValue} size={200} />
          </div>
        </QRCodeWrapper>
      </UserInformationWrapper>
    </>
  );
};

export default UserInformationSectionTheme1;
