import styled from '@emotion/styled';
import { Box, Button, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';

interface IProps {
  theme: Theme;
}

export const SlotsSectionWrapper = styled(Box)<IProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  padding: 20px;
  margin: 8px 0;
  transition: all 0.3s ease;
  animation: fadeIn 0.4s ease-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 12px 48px rgba(0, 0, 0, 0.12);
  }

  .label {
    color: #1a1a2f;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.3px;
    margin-bottom: 12px;
    text-transform: uppercase;
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
      font-size: 20px;
      color: ${props => props.theme.palette.primary.main};
      animation: iconPop 0.3s ease-out;
    }

    @keyframes iconPop {
      0% {
        transform: scale(0.8);
        opacity: 0;
      }
      100% {
        transform: scale(1);
        opacity: 1;
      }
    }

    @keyframes lineGrow {
      from {
        width: 0;
      }
      to {
        width: 32px;
      }
    }
  }
`;

export const SlotsListWrapper = styled(Box)<IProps>`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
  gap: 12px;
  width: 100%;
  padding: 4px;
`;

interface SlotChipWrapperProps {
  isSelected: boolean;
  theme: Theme;
}

export const SlotChipWrapper = styled(Box)<SlotChipWrapperProps>`
  display: flex;
  align-items: center;
  gap: 2px;
  height: 36px;
  padding: 0 10px;
  border-radius: 100px;
  border: 1px solid #f0f0f0;
  background: #ffffff;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: translateY(-1px);
    border-color: #e0e0e0;
  }

  ${({ isSelected, theme }) =>
    isSelected &&
    `
    background-color: ${theme.palette.primary.main};
    border-color: ${theme.palette.primary.main};
    .MuiTypography-root {
      color: #FFFFFF;
    }
  `}

  .dot-indicator {
    width: 3px;
    height: 3px;
    background: ${props => (props.isSelected ? '#fff' : '#3D71CA')};
    border-radius: 50%;
  }
`;

export const SlotConfirmationWrapper = styled(Box)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  background: #ffffff;
  box-shadow:
    0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  z-index: 1000;
`;

export const ConfirmationTitle = styled(Typography)<IProps>`
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #33334f;
  margin-bottom: 4px;
  text-align: center;
  width: 100%;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: 2px;
    background: ${props => props.theme.palette.primary.main};
    border-radius: 2px;
  }
`;

export const ConfirmationLabel = styled(Typography)`
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: 600;
  color: #33334f;
  margin-bottom: 2px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
`;

export const ConfirmationValue = styled(Typography)`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #808080;
  margin-bottom: 0;
`;

export const AddToCartButton = styled(Button)<IProps & { disabled?: boolean }>`
  background: ${props => (props.disabled ? '#E0E0E0' : '#FF0000')};
  color: ${props => (props.disabled ? '#9E9E9E' : '#ffffff')};
  border-radius: 1000px;
  padding: 12px 24px;
  height: 50px;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-transform: none;
  width: 100%;
  max-width: 343px;
  transition: all 0.2s ease-in-out;
  box-shadow: ${props =>
    props.disabled
      ? 'none'
      : '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)'};

  &:hover {
    background: ${props => (props.disabled ? '#E0E0E0' : props.theme.palette.primary.dark)};
    transform: ${props => (props.disabled ? 'none' : 'translateY(-1px)')};
    box-shadow: ${props =>
      props.disabled
        ? 'none'
        : '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)'};
  }

  &:active {
    transform: translateY(0);
  }

  .MuiButton-startIcon {
    margin-right: 8px;

    svg {
      font-size: 20px;
    }
  }
`;

interface SlotTimeTypographyProps {
  isOffPeak: boolean;
}

export const SlotTimeTypography = styled(Typography)<SlotTimeTypographyProps>`
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  color: ${props => (props.isOffPeak ? '#3D71CA' : 'black')};
  flex-grow: 1;
  text-align: center;
`;

export const OffPeakIndicator = styled(Box)`
  width: 24px;
  height: 24px;
  border-radius: 100px;
  background: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;

  .indicator-text {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    color: #ffffff;
  }
`;

export const ShowMoreButton = styled(Button)<IProps>`
  margin-top: 16px;
  color: ${props => props.theme.palette.text.secondary};
  text-transform: none;
  font-weight: 500;
  padding: 6px 16px;
  border-radius: 20px;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(0, 0, 0, 0.04);
    transform: translateY(-1px);
  }

  .MuiButton-startIcon {
    margin-right: 4px;
  }
`;

export const ActionButtonsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
  width: 100%;
  color: #808080;
`;

export const ActionButton = styled(Button)`
  text-transform: none;
`;
