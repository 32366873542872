import React from 'react';
import { Typography } from '@mui/material';
import ContentLoader from '../../../../shared/ContentLoader';
import { ISelectedPackageDetails } from '../../interface';
import { useSelectedPackageDetails } from '../../hooks/useSelectedPackageDetails';
import PackageValidationDetails from './PackageValidationDetails';

const SelectedPackageDetailsTheme2: React.FC<ISelectedPackageDetails> = ({
  packageValidationOutput,
  selectedPackage,
  isValidating,
  setSelectedPackage,
  setPackageValidationOutput
}) => {
  const { currentPackageAmount, amountRedeemed, newBalance, isValid } = useSelectedPackageDetails(
    selectedPackage,
    packageValidationOutput
  );

  return (
    <ContentLoader isLoading={isValidating}>
      <>
        {isValid ? (
          <PackageValidationDetails
            currentPackageAmount={currentPackageAmount()!}
            amountRedeemed={amountRedeemed()!}
            newBalance={newBalance()!}
            setSelectedPackage={setSelectedPackage}
            setPackageValidationOutput={setPackageValidationOutput}
          />
        ) : (
          <Typography 
            className="error-message"
            sx={{
              color: 'error.main',
              fontSize: '14px',
              fontWeight: 500,
              textAlign: 'center',
              margin: '16px',
              padding: '12px',
              borderRadius: '8px',
              backgroundColor: 'error.lighter',
              border: '1px solid',
              borderColor: 'error.light'
            }}
          >
            {packageValidationOutput?.type === 'credit'
              ? `Not enough credits, remaining credits: ${currentPackageAmount}`
              : packageValidationOutput?.type === 'duration'
              ? `Not enough duration, remaining duration: ${currentPackageAmount}`
              : packageValidationOutput?.type === 'membership'
              ? `Not enough sessions, remaining sessions: ${currentPackageAmount}`
              : 'Voucher is not valid for this booking'}
          </Typography>
        )}
      </>
    </ContentLoader>
  );
};

export default SelectedPackageDetailsTheme2; 