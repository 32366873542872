import React, { useContext } from 'react';
import {
  Stack,
  FormControlLabel,
  Typography,
  Tooltip,
  Box,
  tooltipClasses,
  TooltipProps,
  styled,
  Checkbox,
  Select,
  MenuItem,
  SelectChangeEvent,
  Card,
} from '@mui/material';
import { HelpOutline } from '@mui/icons-material';
import { CheckoutContext, CheckoutContextProps } from '../../../context/CheckoutContext';
import { calculateServiceFees } from '../../../Utils/format';

interface PaymentOptionsProps {
  totalAmount: number;
}

// Custom styled tooltip
const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    padding: '12px 16px',
    fontSize: 14,
    borderRadius: 8,
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    maxWidth: 300,
    border: '1px solid rgba(0, 0, 0, 0.08)',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.background.paper,
    '&:before': {
      border: '1px solid rgba(0, 0, 0, 0.08)',
    },
  },
}));

export const PaymentOptionsWrapper = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  width: '100%',
  boxShadow: '0 6px 40px rgba(208, 208, 208, 0.2)',
  borderRadius: '6px',
  padding: '24px',
  marginTop: '24px',
  '&:hover': {
    boxShadow: '0 8px 45px rgba(0, 0, 0, 0.07)',
  },
}));

const PaymentOptions: React.FC<PaymentOptionsProps> = ({ totalAmount }: PaymentOptionsProps) => {
  const { splitPayment, numOfParticipants, setSplitPayment, setNumOfParticipants , company } = useContext(
    CheckoutContext
  ) as CheckoutContextProps;


  const handlePaymentTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSplitPayment(event.target.checked);
  };

  const handleParticipantsChange = (e: SelectChangeEvent<number>) => {
    const value = parseInt(e.target.value as string);
    setNumOfParticipants(value);
  };


  return (
    <PaymentOptionsWrapper>
      <Stack spacing={2}>
        <FormControlLabel
          control={<Checkbox checked={splitPayment} onChange={handlePaymentTypeChange} />}
          label="Split payment with others"
        />

        {splitPayment && (
          <div>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography variant="body1">Number of Participants</Typography>
              <CustomTooltip
                title="Enter the total number of participants to split the payment between (minimum 2, maximum 6)"
                placement="right"
                arrow
              >
                <HelpOutline sx={{ fontSize: 18, ml: 1, color: 'text.secondary' }} />
              </CustomTooltip>
            </Box>
            <Select
              value={numOfParticipants}
              onChange={handleParticipantsChange}
              fullWidth
              size="small"
              sx={{
                backgroundColor: 'white',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(0, 0, 0, 0.23)',
                },
              }}
            >
              {[2, 3, 4, 5, 6].map(num => (
                <MenuItem key={num} value={num}>
                  {num}
                </MenuItem>
              ))}
            </Select>

            <Typography variant="body2" sx={{ mt: 2, color: 'text.secondary' }}>
              Your share will be: AED {(totalAmount) / numOfParticipants}
            </Typography>

            <Typography variant="body2" sx={{ mt: 1, color: 'warning.main' }}>
              Note: The booking will only be confirmed after all participants have completed their
              payments. The total amount will be held until everyone has paid their share.
            </Typography>
          </div>
        )}

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {splitPayment
            ? `Total booking amount: ${company?.currency} ${(totalAmount).toFixed(2)}`
            : `You will pay: ${company?.currency} ${(totalAmount).toFixed(2)}`}
        </Typography>
      </Stack>
    </PaymentOptionsWrapper>
  );
};

export default PaymentOptions;
