import { useContext, useEffect } from 'react';
import { CheckoutContext, CheckoutContextProps } from '../context/CheckoutContext';

const useTitle = (title?: string) => {
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (company?.name) {
      document.title = title ? `${company.name} | ${title}` : company.name;
    }
  }, [title, company?.name]);
};

export default useTitle;
