import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { RegisterWrapper } from './style';
import { RegistrationStep } from '../../Utils/constants';
import CompleteProfile from '../../components/register/completeProfile';
import ContentLoader from '../../components/shared/ContentLoader';
import AuthenticationForm from '../../components/register/authenticationForm';
import useTitle from '../../hooks/useTitle';
interface IProps {
  title?: string;
  redirectToCalendar?: boolean;
  registrationStep: RegistrationStep;
}

const Register: React.FC<IProps> = ({ title, redirectToCalendar = true, registrationStep }) => {
  useTitle(title);
  const { state } = useLocation();
  const [currentStep, setCurrentStep] = useState<RegistrationStep>(registrationStep);
  const { isAuthLoading } = useAuth();
  const [isSocialLogin, setIsSocialLogin] = useState<boolean>(true);
  const [email, setEmail] = useState<string>('');


  useEffect(() => {
    if (state) {
      setCurrentStep((state as any).registrationStep as RegistrationStep);
    }
  }, [state]);

  const redirectToCompleteProfile = (socialRegister: boolean, userEmail?: string) => {
    setIsSocialLogin(socialRegister);
    setCurrentStep(RegistrationStep.CompleteProfile);
    if (!socialRegister) setEmail(userEmail!);
  };

  const redirectToLogin = () => {
    setCurrentStep(RegistrationStep.Login);
  };

  return (
    <ContentLoader isLoading={isAuthLoading}>
      <RegisterWrapper data-testid="register-wrapper">
        {currentStep === RegistrationStep.CompleteProfile ? (
          <CompleteProfile
            redirectToCalendar={redirectToCalendar}
            isSocialLogin={isSocialLogin}
            email={email}
            redirectToLogin={redirectToLogin}
            data-testid="complete-profile-component"
          />
        ) : (
          <AuthenticationForm
            redirectToCalendar={redirectToCalendar}
            redirectToCompleteProfile={redirectToCompleteProfile}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            data-testid="signup-component"
          />
        )}
      </RegisterWrapper>
    </ContentLoader>
  );
};

export default Register;
