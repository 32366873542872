import React, { useContext, useMemo, useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  InputLabel,
  useTheme,
  IconButton,
  Stack,
  Switch,
  Box,
} from '@mui/material';
import { Link } from 'react-router-dom';
import countryList from 'react-select-country-list';
import PrimaryBtn from '../../FormCollection/PrimaryBtn';
import Heading from '../../shared/Heading';
import FormSelect from '../../FormCollection/FormSelect';
import FormInput from '../../FormCollection/FormInput';
import PhoneFormInput from './PhoneFormInput';
import { IFormTypes, IFormUIProps } from './Types';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Genders, getSportsList, whereDidYouHearAboutUs, dubaiAreas } from './data';
import { Controller, useFieldArray } from 'react-hook-form';
import { CheckoutContext, CheckoutContextProps } from '../../../context/CheckoutContext';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ChildInput from '../../KidsBooking/ChildInput';
import AddIcon from '@mui/icons-material/Add';
import { FormAutocomplete } from '../../FormCollection/FormAutocomplete';

const FormUI: React.FC<IFormUIProps> = ({
  onSubmit,
  form,
  isSignupWithEmailAndPassword,
  isLoading,
  hasChildren,
  setHasChildren,
  hasPods,
  setHasPods,
}) => {
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;
  const sportsList = useMemo(() => getSportsList(company?.id || ''), [company?.id]);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const options = useMemo(() => countryList().getData(), []);
  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    watch,
  } = form;

  const {
    fields: childrenFields,
    append: appendChild,
    remove: removeChild,
  } = useFieldArray<IFormTypes>({
    control,
    name: 'children',
  });

  const {
    fields: podsFields,
    append: appendPod,
    remove: removePod,
  } = useFieldArray<IFormTypes>({
    control,
    name: 'pods',
  });

  const { createAccountFields } = company || {};

  return (
    <Box sx={{ width: '100%', padding: '40px 20px' }}>
      <Heading
        heading={isSignupWithEmailAndPassword ? 'Create a Profile' : 'Complete Your Profile'}
        sx={{ marginTop: '20px' }}
      />

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          gap: '20px',
          width: '100%',
          marginBottom: '40px',
        }}
        autoComplete="off"
        data-testid="profile-form"
      >
        {/* Hidden fields to prevent autofill */}
        {/* <input type="text" name="fake-field" style={{ display: 'none' }} autoComplete="off" />
        <input type="password" name="fake-password" style={{ display: 'none' }} autoComplete="new-password" /> */}

        {createAccountFields?.firstName && (
          <FormInput
            label="First Name*"
            error={errors.firstName}
            placeholder={'Enter First Name'}
            register={register('firstName')}
            id="firstName"
            dataTestId="first-name-input"
          />
        )}

        {createAccountFields?.lastName && (
          <FormInput
            label="Last Name*"
            error={errors.lastName}
            placeholder={'Enter Last Name'}
            register={register('lastName')}
            id="lastName"
            dataTestId="last-name-input"
          />
        )}

        {createAccountFields?.employeeId && (
          <FormInput
            label="Employee Id*"
            error={errors.employeeId}
            placeholder={'Enter Employee ID'}
            register={register('employeeId')}
            id="employeeId"
            dataTestId="employee-id-input"
          />
        )}

        {createAccountFields?.phoneNumber && (
          <PhoneFormInput
            control={control}
            error={errors['phoneNumber']}
            name="phoneNumber"
            label="Phone Number *"
            dataTestId="phone-number-input"
          />
        )}

        {createAccountFields?.gender && (
          <FormSelect
            label="Gender*"
            placeholder={'gender'}
            id="gender"
            sx={{
              height: '70px',
              width: '100%',
              borderRadius: '16px',
              background: '#fff',
              boxShadow: '0 6px 40px rgba(208, 208, 208, 0.4)',
              fontSize: '16px',
            }}
            options={Genders}
            register={register('gender')}
            error={errors.gender}
            data-testid="gender-input"
          />
        )}

        {createAccountFields?.dateOfBirth && (
          <>
            <InputLabel
              sx={{
                fontSize: '18px',
                fontWeight: 500,
                alignSelf: 'flex-start',
                color: '#171717',
              }}
            >
              Date of Birth*
            </InputLabel>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                control={control}
                name="dateOfBirth"
                render={({ field: { ...field } }) => (
                  <DatePicker
                    {...field}
                    views={['year', 'month', 'day']}
                    sx={{
                      border: 0,
                      height: '70px',
                      boxShadow: '0 6px 40px rgba(208, 208, 208, 0.4)',
                      borderRadius: '16px',
                      pl: '16px',
                      width: '100%',
                      '&::before': {
                        content: 'none',
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 0,
                      },
                      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: 0,
                      },
                      '& .MuiOutlinedInput-input': {
                        height: '70px',
                        boxSizing: 'border-box',
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>

            <Typography
              sx={{
                fontSize: '12px',
                color: 'red',
              }}
            >
              {errors.dateOfBirth?.message as string}
            </Typography>
          </>
        )}

        {createAccountFields?.whereDidYouHearAboutUs && (
          <FormSelect
            label="How did you hear about us?*"
            placeholder={'whereDidYouHearAboutUs'}
            id="whereDidYouHearAboutUs"
            sx={{
              height: '70px',
              width: '100%',
              borderRadius: '16px',
              background: '#fff',
              boxShadow: '0 6px 40px rgba(208, 208, 208, 0.4)',
              fontSize: '16px',
            }}
            options={whereDidYouHearAboutUs}
            register={register('whereDidYouHearAboutUs')}
            error={errors.whereDidYouHearAboutUs}
          />
        )}

        {createAccountFields?.favoriteSports && (
          <FormSelect
            label="Favorite Sports*"
            placeholder={'Favorite Sports'}
            id="favoriteSports"
            sx={{
              height: '70px',
              width: '100%',
              borderRadius: '16px',
              background: '#fff',
              boxShadow: '0 6px 40px rgba(208, 208, 208, 0.4)',
              fontSize: '16px',
            }}
            options={sportsList}
            register={register('favoriteSports')}
            error={errors?.favoriteSports}
            multi
            value={watch('favoriteSports')}
          />
        )}

        {createAccountFields?.nationality && (
          <FormAutocomplete
            label="Nationality*"
            placeholder={'Nationality'}
            id="nationality"
            options={[{ value: '', label: 'Select your option' }, ...options]}
            error={errors.nationality}
            name="nationality"
            control={control}
            data-testid="nationality-input"
          />
        )}

        {createAccountFields?.residenceArea && (
          <FormSelect
            label="Where do you live?*"
            placeholder={'Where do you live?'}
            id="residenceArea"
            sx={{
              height: '70px',
              width: '100%',
              borderRadius: '16px',
              background: '#fff',
              boxShadow: '0 6px 40px rgba(208, 208, 208, 0.4)',
              fontSize: '16px',
            }}
            options={dubaiAreas}
            register={register('residenceArea')}
            error={errors.residenceArea}
            data-testid="residence-area-input"
          />
        )}

        {createAccountFields?.emergencyContactName && (
          <FormInput
            label="Emergency Contact Name*"
            error={errors.emergencyContactName}
            placeholder={'Enter Emergency Contact Name'}
            register={register('emergencyContactName')}
            id="emergencyContactName"
            dataTestId="emergency-contact-name-input"
          />
        )}

        {createAccountFields?.emergencyContactMobile && (
          <PhoneFormInput
            control={control}
            error={errors.emergencyContactMobile}
            name="emergencyContactMobile"
            label="Emergency Contact Mobile Number*"
            data-testid="emergency-contact-mobile-input"
          />
        )}

        {createAccountFields?.groupCode && (
          <FormInput
            label="Group Code (optional)"
            error={errors.groupCode}
            placeholder={'Enter Group Code'}
            register={register('groupCode')}
            id="groupCode"
            autoComplete="off"
            type="text"
            dataTestId="group-code-input"
          />
        )}

        {createAccountFields?.childrenInformation && (
          <>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography>
                Register your child (All children under 16 must be registered by a guardian to enter
                Kite Beach village){' '}
              </Typography>
              <Switch
                checked={hasChildren}
                onChange={e => {
                  setHasChildren(e.target.checked);
                  if (e.target.checked && childrenFields.length === 0) {
                    appendChild({ name: '', age: 0 });
                  } else if (!e.target.checked) {
                    // Remove all children fields when toggled off
                    childrenFields.forEach((_, index) => removeChild(index));
                  }
                }}
                data-testid="children-switch"
              />
            </Stack>
            {hasChildren && (
              <Stack spacing={2}>
                {childrenFields.map((field, index) => (
                  <Stack
                    key={field.id}
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    justifyContent={'center'}
                  >
                    <Box flexGrow={1}>
                      <ChildInput
                        errors={errors}
                        register={register}
                        field={field}
                        index={index}
                        fieldName="children"
                      />
                    </Box>
                    {index === childrenFields.length - 1 && (
                      <IconButton
                        onClick={() => appendChild({ name: '', age: 0 })}
                        sx={{ marginRight: '10px' }}
                      >
                        <AddIcon />
                      </IconButton>
                    )}
                  </Stack>
                ))}
              </Stack>
            )}
          </>
        )}

        {/* {createAccountFields?.podsInformation && (
          <>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography>Are you signing up for any People of Determination?</Typography>
              <Switch
                checked={hasPods}
                onChange={(e) => {
                  setHasPods(e.target.checked);
                  if (e.target.checked && podsFields.length === 0) {
                    appendPod({ name: "", age: 0 });
                  } else if (!e.target.checked) {
                    // Remove all pods fields when toggled off
                    podsFields.forEach((_, index) => removePod(index));
                  }
                }}
              />
            </Stack>
            {hasPods && (
              <Stack spacing={2}>
                {podsFields.map((field, index) => (
                  <Stack key={field.id} direction="row" alignItems="center" spacing={2}>
                    <Box flexGrow={1}>
                      <ChildInput
                        errors={errors}
                        register={register}
                        field={field}
                        index={index}
                        fieldName="pods"
                      />
                    </Box>
                    {index === podsFields.length - 1 && (
                      <IconButton onClick={() => appendPod({ name: "", age: 0 })}>
                        <AddIcon />
                      </IconButton>
                    )}
                  </Stack>
                ))}
              </Stack>
            )}
          </>
        )} */}

        {isSignupWithEmailAndPassword && (
          <>
            <FormInput
              label="Password*"
              error={errors.password}
              id="password"
              placeholder="Password"
              register={register('password')}
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              }
              dataTestId="password-input"
            />

            <FormInput
              label="Re-enter Password*"
              error={errors.passwordConfirm}
              id="passwordConfirm"
              placeholder="Re-enter Password"
              register={register('passwordConfirm')}
              type={showConfirmPassword ? 'text' : 'password'}
              endAdornment={
                <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                  {showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              }
              dataTestId="password-confirm-input"
            />
          </>
        )}

        <FormGroup sx={{ marginTop: '20px' }}>
          <FormControlLabel
            control={<Checkbox {...register('privacy')} data-testid="privacy-checkbox" />}
            label={
              <Typography
                sx={{
                  color: '#808080',
                  fontWeight: 100,
                  a: {
                    color: '#808080',
                    textDecoration: 'none',
                    fontWeight: 700,
                  },
                }}
              >
                I agree to the{' '}
                <Link
                  to={`${company?.footerPagesLinks?.privacy}`}
                  target="_blank"
                  onClick={e => {
                    if (!company?.footerPagesLinks?.privacy) {
                      e.preventDefault();
                    }
                  }}
                >
                  Privacy Policy
                </Link>
                , and the
                <Link
                  to={`${company?.footerPagesLinks?.terms}`}
                  target="_blank"
                  onClick={e => {
                    if (!company?.footerPagesLinks?.terms) {
                      e.preventDefault();
                    }
                  }}
                >
                  {' '}
                  Terms & Conditions.
                </Link>
              </Typography>
            }
          />
          {errors.privacy && (
            <Typography
              sx={{
                fontSize: '12px',
                color: 'red',
                marginTop: '8px',
              }}
              data-testid="privacy-checkbox-error"
            >
              {errors.privacy.message}
            </Typography>
          )}
        </FormGroup>

        {createAccountFields?.marketingConsent && (
          <FormGroup sx={{ marginTop: '20px' }}>
            <FormControlLabel
              control={
                <Checkbox
                  {...register('marketingConsent')}
                  data-testid="marketing-consent-checkbox"
                />
              }
              label={
                <Typography
                  sx={{
                    color: '#808080',
                    fontWeight: 100,
                    a: {
                      color: '#808080',
                      textDecoration: 'none',
                      fontWeight: 700,
                    },
                  }}
                >
                  I consent to
                  <Link
                    to={`${company?.footerPagesLinks?.marketingConsent}`}
                    target="_blank"
                    onClick={e => {
                      if (!company?.footerPagesLinks?.marketingConsent) {
                        e.preventDefault();
                      }
                    }}
                  >
                    {' '}
                    Dubai Fitness Challenge waiver.
                  </Link>
                </Typography>
              }
            />
            {errors.marketingConsent && (
              <Typography
                sx={{
                  fontSize: '12px',
                  color: 'red',
                  marginTop: '8px',
                }}
              >
                {errors.marketingConsent.message}
              </Typography>
            )}
          </FormGroup>
        )}

        {hasChildren && (
          <FormGroup sx={{ marginTop: '20px' }}>
            <FormControlLabel
              control={
                <Checkbox {...register('parentalWaiver')} data-testid="parental-waiver-checkbox" />
              }
              label={
                <Typography
                  sx={{
                    color: '#808080',
                    fontWeight: 100,
                  }}
                >
                  I agree to the Parental Waiver.
                </Typography>
              }
            />
            {errors.parentalWaiver && (
              <Typography
                sx={{
                  fontSize: '12px',
                  color: 'red',
                  marginTop: '8px',
                }}
              >
                {errors.parentalWaiver.message}
              </Typography>
            )}
          </FormGroup>
        )}

        {Object.keys(errors).length > 0 && (
          <Typography color={'red'} textAlign={'center'} data-testid="error-message">
            Please fill in all required fields before submitting.
          </Typography>
        )}

        <div style={{ width: '100%', margin: '0 auto' }}>
          <PrimaryBtn
            isLoading={isLoading}
            type="submit"
            text={'Create Profile'}
            data-testid="create-profile-button"
          />
        </div>
      </form>
    </Box>
  );
};

export default FormUI;
