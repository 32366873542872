import { Box, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { FooterWrapper } from './style';
import MasterCard from '../../../../src/assets/logos/MasterCard.svg';
import VisaCard from '../../../../src/assets/logos/VisaCard.svg';

import { CheckoutContext, CheckoutContextProps } from '../../../context/CheckoutContext';
import { Company } from '../../../hooks/useCompany';

const CustomFooter: React.FC<{ company: Company | undefined }> = ({ company }) => {
  const goToPath = (path: string) => {
    window.open(path, '_blank');
  };

  const formatContactDetails = () => {
    if (company?.companyPhoneNumber && company?.companyEmail) {
      return `For any questions please reach out to: ${company?.companyPhoneNumber} or
      ${company?.companyEmail}`;
    } else if (company?.companyPhoneNumber) {
      return `For any questions please reach out to: ${company?.companyPhoneNumber} `;
    } else if (company?.companyEmail) {
      return `For any questions please reach out to: ${company?.companyEmail} `;
    }
  };
  return (
    <>
      {company?.showSKMDLogo && (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '30px',
          }}
        >
          <Typography> Powered by </Typography>
          <Box>
            <a href="http://skmd.app/" target="_blank">
              <img
                src={`${process.env.PUBLIC_URL}/skmd_logo2.svg`}
                alt="CompanyLogo"
                style={{ width: '70px', height: '45px', marginLeft: '10px', marginTop: '5px' }}
              />
            </a>
          </Box>
        </Box>
      )}
      <FooterWrapper style={{ marginTop: 15 }}>
        <Box className="footer-details">
          <Box className="links">
            <Typography
              className="links-item links-item-separator"
              onClick={() => {
                if (company?.footerPagesLinks?.aboutUs)
                  goToPath(company?.footerPagesLinks?.aboutUs!);
              }}
            >
              About Us
            </Typography>
            <Typography
              className="links-item"
              onClick={() => {
                if (company?.footerPagesLinks?.refund) goToPath(company?.footerPagesLinks?.refund!);
              }}
            >
              Refund Policy
            </Typography>
            <Typography
              className="links-item links-item-separator"
              onClick={() => {
                if (company?.footerPagesLinks?.privacy)
                  goToPath(company?.footerPagesLinks?.privacy!);
              }}
            >
              Privacy Policy
            </Typography>
            <Typography
              className="links-item"
              onClick={() => {
                if (company?.footerPagesLinks?.terms) goToPath(company?.footerPagesLinks?.terms!);
              }}
            >
              Terms & Conditions
            </Typography>
          </Box>

          <Box className="address">
            <Typography>
              {company?.companyAddress}{' '}
              <a href={company?.mapLink} className="map-link">
                (Map)
              </a>
            </Typography>
          </Box>

          <Box className="question">
            {(company?.companyPhoneNumber || company?.companyEmail) &&
              company?.showContactDetails && <Typography>{formatContactDetails()}</Typography>}
          </Box>

          <Box className="payment-methods">
            <img src={MasterCard} alt="MasterCard" className="payment-methods-item" />

            <img src={VisaCard} alt="VisaCard" className="payment-methods-item" />
          </Box>
        </Box>
      </FooterWrapper>
    </>
  );
};

export default CustomFooter;
