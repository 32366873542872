import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles';

interface IProps {
  theme: Theme;
}

export const CalendarPriceCardWrapper = styled(Box)<IProps>`
  background: ${props => props.theme.palette.background.paper};
  border-radius: 8px;
  width: 100%;
  margin: 8px 0;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  box-shadow: none;
  border: 1px solid #f0f0f0;
  padding: 0;

  .price-section {
    padding: 10px;
    padding-left: 12px;
    flex: 1;

    .price-label {
      color: #6b7280;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 4px;
      margin-top: 5px;
    }

    .price-amount {
      color: #111827;
      font-size: 24px;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 5px;

      .session-suffix {
        color: #6b7280;
        font-size: 14px;
        font-weight: 400;
        display: flex;
        align-items: center;
        gap: 4px;

        .time-icon {
          font-size: 16px;
        }
      }
    }

    &.off-peak {
      .price-amount {
        color: #2563eb;
      }
    }
  }

  .off-peak-section {
    background: #f2f7ff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 180px;
    @media (max-width: 768px) {
      width: 150px;
    }
    @media (max-width: 480px) {
      width: 140px;
    }

    .off-peak-content {
      padding: 10.5px 24px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .off-peak-label {
      color: #626262;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .off-peak-amount {
      margin-left: 6px;
      color: #3d71ca;
      @media (max-width: 768px) {
        font-size: 16px;
      }
      @media (max-width: 480px) {
        font-size: 14px;
      }
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 8px;
      margin-top: 0;

      .dot-indicator {
        width: 4px;
        height: 4px;
        background: #3d71ca;
        border-radius: 50%;
      }

      .session-suffix {
        color: #6b7280;
        font-size: 14px;
        font-weight: 400;
        display: flex;
        align-items: center;
        gap: 4px;

        .time-icon {
          font-size: 16px;
        }
      }
    }
  }
`;
