import { Typography, Box, useTheme } from '@mui/material';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { CalendarPriceCardWrapper } from './style';
import { PricingCardProps } from '../../interfaces';
import { usePricingCard } from '../../hooks/usePricingCard';

const PricingCardTheme2: React.FC<PricingCardProps> = ({ slots, timeUnit }) => {
  const { peakPrice, offPeakPrice, currency, sessionSuffix, areAllPeakSlots, areAllOffPeakSlots } =
    usePricingCard(slots, timeUnit);
  const theme = useTheme();

  if (peakPrice === 0 && offPeakPrice === 0) {
    return null;
  }

  const renderPriceContent = () => {
    if (areAllPeakSlots || peakPrice === offPeakPrice) {
      return (
        <Box className="price-section">
          <Typography variant="h6" className="price-label">
            Price
          </Typography>
          <Typography className="price-amount">
            {currency} {peakPrice}
            {sessionSuffix && (
              <span className="session-suffix">
                <AccessTimeOutlinedIcon className="time-icon" />
                {sessionSuffix}
              </span>
            )}
          </Typography>
        </Box>
      );
    } else if (areAllOffPeakSlots) {
      return (
        <Box className="price-section">
          <Typography variant="h6" className="price-label">
            Price
          </Typography>
          <Typography className="price-amount">
            {currency} {offPeakPrice}
            {sessionSuffix && (
              <span className="session-suffix">
                <AccessTimeOutlinedIcon className="time-icon" />
                {sessionSuffix}
              </span>
            )}
          </Typography>
        </Box>
      );
    } else {
      return (
        <>
          <Box className="price-section">
            <Typography variant="h6" className="price-label">
              Price
            </Typography>
            <Typography className="price-amount">
              {currency} {peakPrice}
              {sessionSuffix && (
                <span className="session-suffix">
                  <AccessTimeOutlinedIcon className="time-icon" />
                  {sessionSuffix}
                </span>
              )}
            </Typography>
          </Box>
          <Box className="off-peak-section">
            <Box className="off-peak-content">
              <Typography className="off-peak-label">Off-Peak Price</Typography>
              <Typography className="off-peak-amount">
                <span className="dot-indicator" />
                {currency} {offPeakPrice}
                {sessionSuffix && (
                  <span className="session-suffix">
                    <AccessTimeOutlinedIcon className="time-icon" />
                    {sessionSuffix}
                  </span>
                )}
              </Typography>
            </Box>
          </Box>
        </>
      );
    }
  };

  return <CalendarPriceCardWrapper theme={theme}>{renderPriceContent()}</CalendarPriceCardWrapper>;
};

export default PricingCardTheme2;
