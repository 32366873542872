import React, { useContext, useEffect } from 'react';
import Heading from '../../../components/shared/Heading';
import { PackagesWrapper } from './style';
import { useSearchParams } from 'react-router-dom';
import { CheckoutContext, CheckoutContextProps } from '../../../context/CheckoutContext';
import useCompany, { Discount, Voucher } from '../../../hooks/useCompany';
import PackagePurchaseDetails from '../../../components/discount/package/PackagePurchaseDetails';
import ContentLoader from '../../../components/shared/ContentLoader';
import { Typography } from '@mui/material';
import useTitle from '../../../hooks/useTitle';

interface IProps {
  title: string;
}
const Packages: React.FC<IProps> = ({ title }) => {
  useTitle(title);
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;

  return (
    <>
      <Heading heading="Packages" sx={{ mt: 2 }} />
      <Typography sx={{ textAlign: 'center', mb: 3 }}>{company?.packageDisclaimer}</Typography>
      <PackagesWrapper sx={{ gap: '32px' }}>
        {company?.vouchers
          .filter(discount => ['credit', 'duration'].includes(discount.type))
          .sort((a, b) => a.price - b.price)
          .map((discount: Discount, i: number) => (
            <PackagePurchaseDetails voucher={discount} key={i} />
          ))}
      </PackagesWrapper>
    </>
  );
};

export default Packages;
