import styled from '@emotion/styled';
import { Box } from '@mui/material';

interface IProps {
  theme: any;
}

export const ProfileTabsSwitcherWrapper = styled(Box)<IProps>`
  width: 100%;
  margin-bottom: 24px;
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-radius: 12px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  padding: 8px;

  .MuiTabs-root {
    min-height: 48px;

    .MuiTabs-scroller {
      .MuiTabs-flexContainer {
        gap: 8px;
      }

      .MuiTabs-indicator {
        height: 3px;
        border-radius: 1.5px;
        background-color: ${({ theme }) => theme.palette.primary.main};
      }
    }
  }

  .MuiTab-root {
    min-height: 40px;
    padding: 8px 16px;
    text-transform: none;
    font-weight: 500;
    font-size: 14px;
    color: ${({ theme }) => theme.palette.text.secondary};
    border-radius: 8px;
    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: ${({ theme }) => theme.palette.action.hover};
      color: ${({ theme }) => theme.palette.text.primary};
    }

    &.Mui-selected {
      color: ${({ theme }) => theme.palette.primary.main};
      background-color: ${({ theme }) => theme.palette.primary.light}20;
      font-weight: 600;
    }
  }
`;
