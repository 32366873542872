import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const DaySelectorSkeletonWrapper = styled(Box)`
  width: 100%;
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeaderSkeletonContainer = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 16px;
`;

export const DaysListSkeletonContainer = styled(Box)`
  display: flex;
  gap: 0;
  overflow-x: hidden;
  padding: 8px 16px;
  width: 100%;

  @media (max-width: 600px) {
    justify-content: flex-start;
    padding-left: 0;
  }
`;

export const DayChipSkeletonWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 8px 12px;
  flex: 1;
  min-width: 105px;

  @media (max-width: 600px) {
    padding: 8px 8px;
    min-width: 90px;
  }
`;
