import React from 'react';
import { Box } from '@mui/system';
import { ServiceSelectorWrapper } from './style';
import { IServiceSelectorProps } from '../../interfaces';
import { useServiceSelector } from '../../hooks/useServiceSelector';
import Heading from '../../../../shared/Heading';
import ScrollableTabs from './ScrollableTabs';

const ServiceSelectorTheme1: React.FC<IServiceSelectorProps> = ({
  type,
  subType,
  serviceId,
  branchId,
  resourceId,
  sportId,
  uponSelectCallback,
}) => {
  const {
    sportsOptions,
    branchOptions,
    servicesOptions,
    resourceOptions,
    onServiceChange,
    OnBranchChange,
    onResourceChange,
    onSportChange,
    selectedSport,
    selectedBranch,
    selectedService,
    selectedResource,
    showResourcesSelector,
  } = useServiceSelector(
    type,
    subType,
    serviceId,
    branchId,
    resourceId,
    sportId,
    uponSelectCallback
  );

  return (
    <ServiceSelectorWrapper>
      {sportsOptions.length > 1 && <Heading heading="Sport" />}

      {sportsOptions.length > 1 && (
        <Box style={{ width: '100%' }}>
          <ScrollableTabs onChange={onSportChange} value={selectedSport} data={sportsOptions} />
        </Box>
      )}

      {branchId && <Heading heading="Location" />}

      {branchId && branchOptions.length > 0 && (
        <Box
          style={{
            width: '100%',
            marginBottom: 16,
          }}
        >
          <ScrollableTabs onChange={OnBranchChange} value={selectedBranch} data={branchOptions} />
        </Box>
      )}

      {(servicesOptions.length > 1 ||
        (servicesOptions.length > 0 && sportsOptions.length == 0)) && (
        <>
          <Heading heading="Service" />
          <ScrollableTabs
            onChange={onServiceChange}
            value={selectedService}
            data={servicesOptions}
          />
        </>
      )}

      {showResourcesSelector && <Heading heading="Choose a Resource" />}

      {showResourcesSelector && (
        <Box style={{ width: '100%' }}>
          <ScrollableTabs
            onChange={onResourceChange}
            value={selectedResource}
            data={resourceOptions}
          />
        </Box>
      )}
    </ServiceSelectorWrapper>
  );
};

export default ServiceSelectorTheme1;
