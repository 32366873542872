import { Typography } from '@mui/material';
import React from 'react';
import { ConfirmationHeaderWrapper } from './style';
import Heading from '../shared/Heading';

interface IProps {
  searchParams: URLSearchParams;
}
const ConfirmationHeader: React.FC<IProps> = ({ searchParams }) => {
  const customerEmail = searchParams.get('customerEmail');
  return (
    <ConfirmationHeaderWrapper>
      <Heading heading="Booking Confirmation" />

      <Typography sx={{ mt: 4, mb: 3 }} className="email-label">
        Your booking is confirmed.
        <Typography component={'span'} sx={{ display: 'block' }} className="email-label">
          We've sent all the details to your email at :
        </Typography>
      </Typography>
      <Typography className="email-info">{customerEmail}</Typography>
    </ConfirmationHeaderWrapper>
  );
};

export default ConfirmationHeader;
