import styled from '@emotion/styled';
import { Badge, Box } from '@mui/material';
import { down } from 'styled-breakpoints';

interface IProps {
  theme: any;
}
export const DaySelectorWrapper = styled.div<IProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  // margin-top: 24px;
  margin-bottom: 24px;

  .day-selector-label {
    color: #33334f;
    width: 100%;
    font-size: 24px;
    font-style: normal;
    text-align: center;
    font-weight: 500;
    margin-bottom: 12px;
  }

  &.days-wrapper {
    width: 100%;
    .days-list {
      margin: 24px 0;
      overflow: auto;
      width: 100%;
      -ms-overflow-style: none;
      scrollbar-width: none;

      margin-bottom: 0;
      padding-bottom: 0;

      &::-webkit-scrollbar {
        display: none;
      }

      .day-chip {
        margin-top: 0;
        margin-bottom: 0;
        flex-basis: 80px;
        height: 100px;
        padding: 18px 24px;
        font-size: 18px;
        color: #808080;
        font-weight: 600;
        // box-shadow: 0 6px 40px rgba(208, 208, 208, 0.2);
        transition: all 0.3s ease-in-out;
        flex-grow: 0;
        flex-shrink: 0;
        max-width: 80px;

        &--selected {
          background-color: ${props => props.theme.palette.primary.main};
          color: #fff;
        }
      }
    }
  }
`;

export const DaysListWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: space-between;
  width: 80%;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  ${down('sm')} {
    overflow: auto;
    justify-content: flex-start;
    width: 100%;
  }
`;

interface DayChipWrapperProps {
  isSelected: boolean;
}
export const DayChipWrapper = styled(Box)<DayChipWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
  font-size: 14px;
  text-align: center;
  font-family: Roboto-thin;
  font-weight: normal;
  line-height: 140%;
  background-color: ${({ isSelected }) => (isSelected ? '#5182FF' : '#ffffff')};
  color: ${({ isSelected }) => (isSelected ? '#ffffff' : '#000000')};
  padding: 8px 18px;
  // box-shadow: 0px 6px 40px 0px rgba(208, 208, 208, 0.2);
  border-radius: 12px;
  margin-bottom: 15px;
  margin-top: 15px;
  margin-right: 15px;
`;

export const DaySelectorHeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${down('md')} {
    justify-content: center;
  }

  .arrow-icon {
    font-size: 35px;
  }

  .day-selector-calendar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .day-selector-calendar-label {
      color: #33334f;
      margin: 0px 10px;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;

      &.date-header {
        font-size: 24px;
        margin: 0 auto;
        padding-left: 50px;
      }
    }

    .month-label {
      color: #33334f;
      margin: 0px 10px;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      padding-left: 50px;
    }
  }
`;
