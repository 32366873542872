import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { CountryCode } from 'libphonenumber-js/min';
import { getCountryCallingCode } from 'react-phone-number-input';
import { AvailabilityDuration, AvailabilitySlot } from '../hooks/useAvailableStartingTimes';
import { Company, Service } from '../hooks/useCompany';
import { DEFAULT_DURATION_MULTIPLES, SESSION_DURATION, TimeUnits } from './constants';
import { getTimeUnit } from './extract';
import { Typography } from '@mui/material';
import { Session } from '../hooks/useClassSessions';

dayjs.extend(utc);
dayjs.extend(timezone);

export const getDateInTimezone = (date: string, givenFormat?: boolean, timezone?: string) => {
  if (givenFormat) return dayjs(date, 'MMMM Do YYYY').tz(timezone);
  return dayjs(date).tz(timezone);
};

export const getFormattedTime = (
  date: string,
  format: string,
  givenFormat?: boolean,
  timezone?: string
) => {
  const dateTime = getDateInTimezone(date, givenFormat, timezone);
  return dateTime.format(format);
};

export const convertMinutesToHours = (minutes: number) => {
  if (minutes < 60) return `${minutes} mins`;
  else return `${minutes / 60} Hour${minutes > 60 ? 's' : ''}`;
};

export const convertMinutesToSessions = (minutes: number) => {
  return `${minutes / SESSION_DURATION} Session${minutes > 15 ? 's' : ''}`;
};

export const formatDurationTime = (
  durationInMinutes: number,
  company: Company,
  selectedResource: string
) => {
  const timeUnit = getTimeUnit(company, selectedResource);
  if (timeUnit === TimeUnits.HOUR) return convertMinutesToHours(durationInMinutes);
  if (timeUnit === TimeUnits.SESSION) return convertMinutesToSessions(durationInMinutes);
  return `${durationInMinutes} mins`;
};

export const formatPhoneNumber = (phoneNumber: string, country: string) => {
  return `+${getCountryCallingCode(country as CountryCode)}${phoneNumber}`;
};

export const formatPackageCode = (packageCode: string) => {
  let formattedPackageCode = '';
  for (let i = 0; i < packageCode.length; i++) {
    if (i % 3 === 0 && i !== 0) {
      formattedPackageCode += '-';
    }
    formattedPackageCode += packageCode[i];
  }
  return formattedPackageCode;
};

export const diffInMinutes = (startTime: string, endTime: string) => {
  const start = dayjs(startTime, 'HHmm');
  const end = dayjs(endTime, 'HHmm');
  return end.diff(start, 'minutes');
};

export const formatConfirmationSearchParams = (bookingResponse: any) => {
  return (
    `confirmation?companyName=${bookingResponse.company.companyName}` +
    `&location=${bookingResponse.branch.branchName}` +
    `&date=${bookingResponse.date}` +
    `&startTime=${bookingResponse.startTime}` +
    `&serviceName=${bookingResponse.service.serviceName}` +
    `&durationInMinutes=${diffInMinutes(bookingResponse.startTime, bookingResponse.endTime)}` +
    `&price=${bookingResponse.service.servicePrice}` +
    `&customerEmail=${bookingResponse.customer.email}` +
    `&bookingId=${bookingResponse.id}` +
    `&timezone=${bookingResponse.timezone}` +
    `&orderStatusName=Paid`
  );
};

export const isAfterCutOff = (
  cutoff: number | undefined,
  slotFullDate: string,
  timezone: string | undefined
) => {
  if (!cutoff) return true;
  const cutOffTime = dayjs().tz(timezone).add(cutoff, 'hour');

  const slotTime = dayjs(slotFullDate);

  return slotTime.isAfter(cutOffTime);
};

export const withinWorkingHours = (time: string, startTime?: string, endTime?: string) => {
  if (!startTime || !endTime) return true;

  return time >= startTime && time <= endTime;
};

export const isWithinAdvanceBookingDays = (
  slotFullDate: string,
  advanceBookingDays: number | undefined,
  timezone: string | undefined
) => {
  if (!advanceBookingDays) return true;

  const maxBookingDate = dayjs().tz(timezone).add(advanceBookingDays, 'days').endOf('day');

  const slotTime = dayjs(slotFullDate);

  return slotTime.isBefore(maxBookingDate) || slotTime.isSame(maxBookingDate, 'day');
};

// export const getTotalPrice = (
//   slotsMap: Map<string, BookingSlot>,
//   selectedSlot: AvailabilitySlot | undefined,
//   selectedDuration: AvailabilityDuration | undefined,
//   company: Company | undefined,
//   quantity: number
// ) => {
//   if (!selectedSlot || !selectedDuration || !company) {
//     return 0;
//   }
//   let price = 0;
//   let currentTime = getStartTime(selectedSlot);
//   const endTime = getEndTime(selectedSlot, selectedDuration);
//   while (currentTime.isBefore(endTime)) {
//     const currentSlot = slotsMap.get(currentTime.format("HHmm"))!;
//     price += currentSlot.isPeak
//       ? selectedDuration.peakPrice
//       : selectedDuration.offPeakPrice;
//     currentTime = getDateInTimezone(
//       currentSlot.fullDate,
//       false,
//       "Asia/Dubai"
//     ).add(company.serviceDurationMultiples, "minutes");
//   }
//   return price * quantity;
// };

export const getStartTime = (slot: AvailabilitySlot, company: Company) => {
  return getDateInTimezone(slot.fullDate, false, getTimezone(company?.timezone));
};

export const getEndTime = (
  slot: AvailabilitySlot,
  selectedDuration: AvailabilityDuration,
  company: Company
) => {
  return getDateInTimezone(slot.fullDate, false, getTimezone(company?.timezone)).add(
    selectedDuration.durationTime,
    'minutes'
  );
};

export const formatStringTime = (time: string) => {
  return dayjs(time, 'HHmm').format('h:mm A');
};

export const formatPrice = (price: number, company?: Company) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: getCurrency(company?.currency),
    minimumFractionDigits: 0,
  });

  return formatter.format(price);
};

export const formatDuration = (duration: number, slotDuration: number) => {
  return `${duration / slotDuration} Sessions`;
};

export const getCurrency = (currency?: string) => {
  return currency || 'AED';
};

export const getTimezone = (timezone?: string) => {
  return timezone || 'Asia/Dubai';
};

const getPrice = (
  timeUnit: 'Hour' | 'Session',
  price: number,
  duration: number,
  serviceDurationMultiples: number,
  quantity: number
) => {
  if (timeUnit === 'Hour') return (duration / (serviceDurationMultiples || 15)) * price * quantity;
  return price * quantity;
};

export const areAllOffPeakSlots = (slots: AvailabilitySlot[]) => {
  let allOffPeakSlots = true;
  slots.forEach(slot => {
    if (slot.isPeak) {
      allOffPeakSlots = false;
    }
  });
  return allOffPeakSlots;
};

export const areAllPeakSlots = (slots: AvailabilitySlot[]) => {
  let allPeakSlots = true;
  slots.forEach(slot => {
    if (!slot.isPeak) {
      allPeakSlots = false;
    }
  });
  return allPeakSlots;
};

export const getPriceCard = (
  slots: AvailabilitySlot[],
  selectedDuration: AvailabilityDuration,
  timeUnit: 'Hour' | 'Session',
  serviceDurationMultiples: number,
  quantity: number,
  currency?: string
) => {
  if (areAllOffPeakSlots(slots)) {
    return (
      <Typography className="label">
        {`${getPrice(
          timeUnit,
          selectedDuration?.offPeakPricePerDurationMultiple!,
          selectedDuration?.durationTime!,
          serviceDurationMultiples,
          quantity
        )} ${getCurrency(currency)} ${timeUnit === 'Session' ? '/Session' : ''}`}
      </Typography>
    );
  } else if (areAllPeakSlots(slots)) {
    return (
      <Typography className="label">
        {`${getPrice(
          timeUnit,
          selectedDuration?.peakPricePerDurationMultiple!,
          selectedDuration?.durationTime!,
          serviceDurationMultiples,
          quantity
        )} ${getCurrency(currency)} ${timeUnit === 'Session' ? '/Session' : ''}`}
      </Typography>
    );
  } else if (
    selectedDuration?.peakPricePerDurationMultiple ===
    selectedDuration?.offPeakPricePerDurationMultiple
  ) {
    return (
      <Typography className="label">
        {`${getPrice(
          timeUnit,
          selectedDuration?.peakPricePerDurationMultiple!,
          selectedDuration?.durationTime!,
          serviceDurationMultiples,
          quantity
        )} ${getCurrency(currency)} ${timeUnit === 'Session' ? '/Session' : ''}`}
      </Typography>
    );
  } else if (
    selectedDuration?.peakPricePerDurationMultiple !==
    selectedDuration?.offPeakPricePerDurationMultiple
  ) {
    return (
      <>
        <Typography className="label">
          {`${getPrice(
            timeUnit,
            selectedDuration?.peakPricePerDurationMultiple!,
            selectedDuration?.durationTime!,
            serviceDurationMultiples,
            quantity
          )} ${getCurrency(currency)} ${timeUnit === 'Session' ? '/Session' : ''}`}
        </Typography>
        <Typography className="label">{`Off-Peak: ${getPrice(
          timeUnit,
          selectedDuration?.offPeakPricePerDurationMultiple!,
          selectedDuration?.durationTime!,
          serviceDurationMultiples,
          quantity
        )} ${getCurrency(currency)} ${timeUnit === 'Session' ? '/Session' : ''}`}</Typography>
      </>
    );
  }
};

export const getClassFullDate = (session: Session) => {
  return dayjs(`${session.date} ${session.startTime}`, 'YYYYMMDD HHmm');
};

export const calculatePrice = (
  price: number | undefined,
  duration: number,
  timeUnit: 'Hour' | 'Session',
  company: Company,
  service: Service,
  quantity: number
) => {
  const safePrice = price ?? 0;
  if (timeUnit === 'Hour') {
    const durationMultiples =
      service?.serviceDurationMultiples ||
      company?.serviceDurationMultiples ||
      DEFAULT_DURATION_MULTIPLES;
    return (duration / durationMultiples) * safePrice * quantity;
  }
  return safePrice * quantity;
};

export const formatPriceValue = (price: number, currency?: string) => {
  return price === 0 ? 'Free' : `${price} ${getCurrency(currency)}`;
};

export const parseDateTimeString = (date: string, startTime: string) => {
  const year = date.slice(0, 4);
  const month = date.slice(4, 6);
  const day = date.slice(6);
  const hour = startTime.slice(0, 2);
  const min = startTime.slice(2);
  return dayjs(`${year}/${month}/${day} ${hour}:${min}`);
};


export const calculateServiceFees = (
  price: number,
  company?: Company,
) => {
  if (!company || !company.serviceFeesType || !company.serviceFees) return 0;
  if (company.serviceFeesType === 'none') return 0;
  if (company.serviceFeesType === 'fixed') return company.serviceFees;
  if (company.serviceFeesType === 'percentage') return (price * company.serviceFees) / 100;
  return 0;
};
