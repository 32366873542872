import React, { useContext } from 'react';
import { IPackageSelectorProps } from './interfaces';
import { CheckoutContext, CheckoutContextProps } from '../../../context/CheckoutContext';
import { ThemeVariant } from '../../../hooks/useCompany';
import PackageSelectorTheme1 from './variants/theme1';
import PackageSelectorTheme2 from './variants/theme2';

const variantComponents: Record<ThemeVariant, React.ComponentType<IPackageSelectorProps>> = {
  default: PackageSelectorTheme1,
  theme1: PackageSelectorTheme1,
  theme2: PackageSelectorTheme2,
};

const PackageSelector: React.FC<IPackageSelectorProps> = props => {
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;
  const companyTheme = company?.theme;
  const Component = variantComponents[(companyTheme as ThemeVariant) || 'default'];

  return <Component currency={company?.currency} {...props} />;
};

export default PackageSelector;
