import styled from '@emotion/styled';
import { Box, Button, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';

interface IProps {
  theme: Theme;
}

export const TimeSelectorWrapper = styled(Box)<IProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 6px 40px rgb(208 208 208 / 20%);
  border-radius: 16px;
  margin-top: 20px;

  .availability-label {
    color: #33334f;
    width: 100%;
    font-size: 1.25rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 12px;
    margin-top: 20px;
  }

  .slots-container {
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    padding: 0 20px;
  }

  .no-slots-label {
    color: #000000;
    padding: 30px 20px;
    font-size: 18px;
    text-align: center;
    font-weight: normal;
    line-height: 140%;
  }

  .resource-disclaimer {
    color: #000000;
    font-size: 18px;
    text-align: center;
    font-weight: normal;
    line-height: 140%;
  }
`;
