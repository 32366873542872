import React, { useEffect, useState } from 'react';
import LandingPageTitle from '../../shared/LandingPageTitle';
import { Box } from '@mui/material';
import Pagination from '../../Pagination';
import { Booking } from '../../../hooks/useCart';
import CartBookingCard from '../../cart/BookingCard';

interface PaginatedBookingsProps {
  title: string;
  bookings: Booking[];
  showCancelOption?: boolean;
  isCancelAddon?: boolean;
  onCancelBookingHandler?: (booking: Booking) => void;
}

const PaginatedBookings: React.FC<PaginatedBookingsProps> = ({
  title,
  bookings,
  isCancelAddon = true,
  showCancelOption = false,
  onCancelBookingHandler,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pagedBookings, setPagedBookings] = useState<Booking[]>([]);

  const onNextPageHandler = () => setCurrentPage(currentPage + 1);
  const onPrevPageHandler = () => setCurrentPage(currentPage - 1);

  useEffect(() => {
    setPagedBookings(bookings.slice((currentPage - 1) * 5, currentPage * 5));
  }, [currentPage, bookings]);

  return (
    <>
      <LandingPageTitle title={title} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '30px',
          margin: '40px',
          width: '100%',
        }}
      >
        {pagedBookings.map((item: Booking) => (
          <CartBookingCard
            booking={item}
            key={item.id}
            isCancelAddon={isCancelAddon}
            showCancelOption={showCancelOption}
            onCancelBookingHandler={onCancelBookingHandler}
          />
        ))}
        {bookings.length > 0 && (
          <Pagination
            current={currentPage}
            total={bookings.length}
            onNext={onNextPageHandler}
            onPrev={onPrevPageHandler}
          />
        )}
      </Box>
    </>
  );
};

export default PaginatedBookings;
