import React, { useContext } from 'react';
import { Discount, Membership, Voucher } from '../../../hooks/useCompany';
import PackageDetails from './PackageDetails';
import PurchasePackageButton from './PurchasePackageButton';
import { useNavigate } from 'react-router-dom';
import { Box, Card } from '@mui/material';
import { CheckoutContext, CheckoutContextProps } from '../../../context/CheckoutContext';

interface IProps {
  voucher: Discount;
}

const PackagePurchaseDetails: React.FC<IProps> = ({ voucher }) => {
  const navigate = useNavigate();
  const { company, marketplaceName } = useContext(CheckoutContext) as CheckoutContextProps;

  const getPackageValue = () => {
    if (voucher.type === 'credit') {
      return (voucher as Voucher).credit;
    }

    if (voucher.type === 'duration') {
      return (voucher as Voucher).duration;
    }
  };

  const handleClick = () => {
    const baseUrl = `/packagePurchase?companyId=${company?.id}`;
    const packageParams = `&packageValue=${getPackageValue()}&packagePrice=${voucher.price}&packageName=${voucher.name || ''}&packageId=${voucher.id}&packageType=${voucher.type}`;

    if (marketplaceName) {
      navigate(`${baseUrl}&marketplaceName=${marketplaceName}${packageParams}`);
    } else {
      navigate(`${baseUrl}${packageParams}`);
    }
  };

  return (
    <Box sx={{ mb: 3, width: '100%' }} data-testid="package-purchase-details">
      <Card
        sx={{
          width: '100%',
          padding: '16px 20px',
          textAlign: 'center',
          mb: 2,
          boxShadow: '0 6px 40px rgba(208, 208, 208, 0.2)',
          borderRadius: '16px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <PackageDetails
          packagePrice={voucher.price}
          packageValue={getPackageValue()!}
          packageName={voucher?.name}
          type={voucher.type}
          packageDuration={0}
        />

        <PurchasePackageButton handleClick={handleClick} label="Purchase" />
      </Card>
    </Box>
  );
};

export default PackagePurchaseDetails;
