import { duration, Typography, useTheme } from '@mui/material';
import React, { useContext } from 'react';

import { DurationChipWrapper } from './style';
import { CheckoutContext } from '../../../../../context/CheckoutContext';
import { CheckoutContextProps } from '../../../../../context/CheckoutContext';
import { AvailabilityDuration } from '../../../../../hooks/useAvailableStartingTimes';
import { formatDurationTime } from '../../../../../Utils/format';

interface IProps {
  formattedDuration: string;
  isSelected: boolean;
  onDurationClick: () => void;
}
const DurationChipTheme1: React.FC<IProps> = ({
  formattedDuration,
  isSelected,
  onDurationClick,
}) => {
  const theme = useTheme();

  return (
    <DurationChipWrapper onClick={onDurationClick} isSelected={isSelected} theme={theme}>
      <Typography
        className="duration"
        sx={{
          fontFamily: 'Inter',
          fontSize: '16px !important',
          fontWeight: 400,
          lineHeight: '22.4px',
          whiteSpace: 'nowrap',
        }}
      >
        {formattedDuration}
      </Typography>
    </DurationChipWrapper>
  );
};

export default DurationChipTheme1;
