import { styled } from '@mui/material/styles';
import { Box, MenuItem, Select, Card, CardContent } from '@mui/material';

interface ServiceSelectorWrapperProps {
  backgroundImage?: string;
}

export const ServiceSelectorTheme2Wrapper = styled(Box, {
  shouldForwardProp: prop => prop !== 'backgroundImage',
})<ServiceSelectorWrapperProps>(({ theme, backgroundImage }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  width: '100%',
  background: backgroundImage
    ? `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${backgroundImage})`
    : 'linear-gradient(45deg, #E2ECff 10%, #FCCCB8 100%)',
  backgroundSize: backgroundImage ? 'cover' : 'auto',
  backgroundPosition: 'center',
  height: '200px',
  position: 'relative',
  padding: theme.spacing(2),
  borderBottomLeftRadius: '24px',
  borderBottomRightRadius: '24px',
}));

export const StyledSelect = styled(Select<string>)(({ theme }) => ({
  backgroundColor: '#fff',
  borderRadius: '1000px',
  height: '56px',
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',

  '& .MuiSelect-select': {
    padding: '0 16px',
    display: 'flex',
    alignItems: 'center',
    height: '100% !important',
  },

  '& .MuiSvgIcon-root': {
    color: '#24203A',
  },

  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#fff',
  },

  '&:hover': {
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#fff',
    },
  },

  '&.Mui-focused': {
    borderRadius: '1000px',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#fff',
    },
  },
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: theme.spacing(2),
  fontSize: '16px',
  color: '#24203A',
  transition: 'all 0.2s ease',
  minHeight: '48px',

  '&:hover': {
    backgroundColor: 'rgba(36, 32, 58, 0.04)',
  },

  '&.Mui-selected': {
    backgroundColor: 'rgba(36, 32, 58, 0.08)',

    '&:hover': {
      backgroundColor: 'rgba(36, 32, 58, 0.12)',
    },
  },
}));

export const ResourcesListWrapper = styled(Box)`
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 16px 20px;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  display: flex;
  align-items: center;

  /* Content container */
  & > div {
    display: flex;
    gap: 12px;
    padding: 0 5px;
    margin: 0 auto;
    min-width: max-content;
    justify-content: flex-start;
  }

  /* Hide scrollbar */
  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 600px) {
    padding: 12px 20px;
    & > div {
      gap: 10px;
    }
  }
`;

export const StyledResourceCard = styled(Card)<{ selected: boolean }>(({ theme, selected }) => ({
  cursor: 'pointer',
  height: '100%',
  minHeight: '48px',
  transition: 'all 0.2s ease-in-out',
  transform: selected ? 'translateY(-2px)' : 'none',
  backgroundColor: selected ? theme.palette.primary.main : theme.palette.background.paper,
  borderRadius: '8px',
  border: `1px solid ${selected ? theme.palette.primary.main : 'rgba(81, 130, 255, 0.1)'}`,
  position: 'relative',
  overflow: 'visible',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  WebkitTapHighlightColor: 'transparent',
  touchAction: 'manipulation',
  padding: '0 14px',
  whiteSpace: 'nowrap',
  boxShadow: 'none',

  '&:hover': {
    transform: 'translateY(-2px)',
    backgroundColor: selected ? theme.palette.primary.main : 'rgba(81, 130, 255, 0.03)',
    boxShadow: 'none',
  },

  '&:active': {
    transform: 'translateY(0)',
    transition: 'all 0.1s ease-in-out',
  },
}));

export const StyledResourceCardContent = styled(CardContent)<{ selected: boolean }>(
  ({ theme, selected }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0.75),
    flex: 1,
    width: 'auto',
    minWidth: 'fit-content',
    '&:last-child': {
      paddingBottom: theme.spacing(0.75),
    },
    '& .MuiTypography-root': {
      color: selected ? theme.palette.primary.contrastText : theme.palette.text.primary,
      fontWeight: selected ? 600 : 500,
      textAlign: 'center',
      fontSize: '0.75rem',
      lineHeight: 1.2,
      letterSpacing: 0,
      transition: 'all 0.2s ease',
      whiteSpace: 'nowrap',
      overflow: 'visible',
      margin: 0,
      width: 'auto',
      minWidth: 'fit-content',
      userSelect: 'none',
    },
  })
);
