import React from 'react';
import { Box } from '@mui/material';
import { Company } from '../../../../../hooks/useCompany';

interface LogoSectionProps {
  company?: Company;
  onClick: () => void;
}

const LogoSection: React.FC<LogoSectionProps> = ({ company, onClick }) => {
  const width = company?.logoDimensions?.width 
    ? Math.round(Number(company.logoDimensions.width) * 0.9) 
    : 220;
  
  const height = company?.logoDimensions?.height 
    ? Math.round(Number(company.logoDimensions.height) * 0.9) 
    : 100;

  return (
    <Box 
      onClick={onClick}
      sx={{
        width: width,
        height: height,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <img
        src={company?.logo}
        alt={`${company?.name} Logo`}
        style={{
          objectFit: 'cover',
          objectPosition: 'center',
          width: '100%',
          height: '100%',
        }}
      />
    </Box>
  );
};

export default LogoSection; 