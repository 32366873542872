import React, { useContext } from 'react';
import { CheckoutContext, CheckoutContextProps } from '../../../context/CheckoutContext';
import { ThemeVariant } from '../../../hooks/useCompany';
import UserMembershipsSectionTheme2 from './variants/theme2/UserMembershipsSectionTheme2';
import { UserVoucherSectionProps } from './interfaces';
import UserMembershipsSectionTheme1 from './variants/theme1/UserMembershipsSectionTheme1';

const variantComponents: Record<ThemeVariant, React.ComponentType<UserVoucherSectionProps>> = {
  default: UserMembershipsSectionTheme1,
  theme1: UserMembershipsSectionTheme1,
  theme2: UserMembershipsSectionTheme2,
};

const UserMembershipsSection: React.FC<UserVoucherSectionProps> = props => {
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;
  const companyTheme = company?.theme;
  const Component = variantComponents[(companyTheme as ThemeVariant) || 'default'];

  return <Component {...props} />;
};

export default UserMembershipsSection;
