import React, { useContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import useCompany from '../../hooks/useCompany';

import ContentLoader from '../../components/shared/ContentLoader';
import DaySelector from '../../components/Calendar/DaySelector';
import { CheckoutContext, CheckoutContextProps } from '../../context/CheckoutContext';

import useTitle from '../../hooks/useTitle';

import useClassSessions from '../../hooks/useClassSessions';
import dayjs from 'dayjs';
import { GymCalendarWrapper } from './style';
import Heading from '../../components/shared/Heading';
import TimeSelector from '../../components/Calendar/TimeSelector';
import useAvailableStartingTimes from '../../hooks/useAvailableStartingTimes';
import useCart from '../../hooks/useCart';
import { Typography } from '@mui/material';
import { useDaySelection } from '../../hooks/useDaySelection';
import ServiceSelector from '../../components/Calendar/ServiceSelector';

interface IProps {
  title: string;
}

const GymCalendar: React.FC<IProps> = ({ title }) => {
  useTitle(title);

  const { selectedWeekStartDate, setSelectedWeekStartDate } = useDaySelection();

  const {
    selectedService,
    company,
    selectedDay,
    setSelectedDay,
    selectedBranch,
    selectedResource,
    isAllResourcesOptionEnabled,
    availableStartingTimesResource,
    bookingCart,
    setQuantity,
    isCartLoading,
  } = useContext(CheckoutContext) as CheckoutContextProps;

  const { addToCart } = useCart();

  const { loading: isLoadingAvailabilitySlots } = useAvailableStartingTimes({
    companyId: company?.id,
    branchId: selectedBranch,
    serviceId: selectedService,
    selectedDay,
    selectedResource,
    isAllResourcesOptionEnabled,
    bookingCart,
    selectedWeekStartDate,
  });

  setQuantity(1);
  return (
    <GymCalendarWrapper>
      <Heading heading="Book a Gym Session" />
      <ServiceSelector type="standard" subType="gym" />

      <DaySelector
        onlySevenDaysInAdvance={false}
        dayHasAvailableSlots={new Map()}
        selectedWeekStartDate={selectedWeekStartDate}
        setSelectedWeekStartDate={setSelectedWeekStartDate}
      />

      <Typography className="kids-label">
        Gym access lasts for up to 2 hours per session.
      </Typography>

      <TimeSelector
        availableStartingTimes={availableStartingTimesResource!}
        isLoadingAvailabilitySlots={isLoadingAvailabilitySlots || isCartLoading}
        showDurationFilter={true}
        timeUnit="Session"
      />
    </GymCalendarWrapper>
  );
};

export default GymCalendar;
