import React from 'react';
import { useNavigate } from 'react-router';
import useCancelPackage from '../../hooks/useCancelPackage';
import { RequestStatus } from '../../Utils/constants';
import CancelButton from '../shared/cancelButton';

interface IProps {
  companyId: string;
  packageCode: string;
}

const CancelPackageButton: React.FC<IProps> = ({ companyId, packageCode }) => {
  const navigate = useNavigate();
  const { isLoading, cancelPackage } = useCancelPackage();

  const onFailedCancellation = () => {
    navigate(`/status?requestStatus=${RequestStatus.FailedPackageCancellation}`);
  };
  const onSuccessfulCancellation = () => {
    navigate(`/status?requestStatus=${RequestStatus.SuccessfulPackageCancellation}`);
  };

  const handleClick = () => {
    cancelPackage({
      companyId,
      packageCode,
      onFailedCancellation,
      onSuccessfulCancellation,
    });
  };
  return <CancelButton onClick={handleClick} buttonLabel="Cancel Package" isLoading={isLoading} />;
};

export default CancelPackageButton;
