import React from 'react';
import { useTheme } from '@mui/material';
import { DurationListWrapper } from './style';
import { AvailabilityDuration } from '../../../../../hooks/useAvailableStartingTimes';
import DurationChipTheme1 from './DurationChip';

interface IProps {
  formattedDurationsMap: Map<string, string>;
  durations: AvailabilityDuration[];
  selectedDuration: AvailabilityDuration | undefined;
  setSelectedDuration: React.Dispatch<React.SetStateAction<AvailabilityDuration | undefined>>;
}
const DurationsListTheme1: React.FC<IProps> = ({
  formattedDurationsMap,
  durations,
  selectedDuration,
  setSelectedDuration,
}) => {
  const theme = useTheme();
  const handleDurationClick = (duration: AvailabilityDuration) => {
    setSelectedDuration(duration);
  };
  return (
    <DurationListWrapper
      theme={theme}
      sx={{
        ...(durations?.length > 2 && {
          display: 'grid !important',
          gridTemplateColumns: 'repeat(3, 1fr)',
        }),
      }}
    >
      {durations &&
        durations.map((duration, index) => (
          <DurationChipTheme1
            key={index}
            formattedDuration={formattedDurationsMap.get(duration.id) || ''}
            isSelected={selectedDuration?.id === duration.id}
            onDurationClick={() => handleDurationClick(duration)}
          />
        ))}
    </DurationListWrapper>
  );
};

export default DurationsListTheme1;
