import { Box } from '@mui/material';
import { Company } from '../../../../../hooks/useCompany';

interface LogoSectionProps {
  company?: Company;
  onClick: () => void;
}

const LogoSection: React.FC<LogoSectionProps> = ({ company, onClick }) => (
  <div
    style={{
      textAlign: 'center',
      width: '300px',
      cursor: 'pointer',
    }}
    onClick={onClick}
  >
    <Box>
      <img
        src={company?.logo}
        alt={`${company?.name} Logo`}
        width={company?.logoDimensions?.width || 120}
        height={company?.logoDimensions?.height || 90}
      />
    </Box>
  </div>
);

export default LogoSection;
