import React from 'react';
import { MenuItem, Select } from '@mui/material';
import { usePackageSelector } from '../../usePackageSelector';
import { IPackageSelectorProps } from '../../interfaces';
import { PackageSelectorWrapper } from './style';
import { getCurrency } from '../../../../../Utils/format';

const PackageSelectorTheme1: React.FC<IPackageSelectorProps> = ({
  packages,
  selectedPackage,
  setSelectedPackage,
  setIsValidating,
  setPackageValidationOutput,
  currency,
}) => {
  const { handleChange } = usePackageSelector(
    selectedPackage,
    setSelectedPackage,
    setIsValidating,
    setPackageValidationOutput
  );

  return (
    <PackageSelectorWrapper>
      <Select
        onChange={handleChange}
        value={selectedPackage}
        displayEmpty={true}
        renderValue={value => (value !== '' ? value : 'Packages or Promo Code')}
        className="select"
      >
        {packages.map(currentPackage => (
          <MenuItem key={currentPackage.id} value={currentPackage.code}>{`${
            currentPackage.code
          } - ${
            currentPackage.totalCredit - currentPackage.usedCredit
          } ${getCurrency(currency)}`}</MenuItem>
        ))}

        <MenuItem value="promo-code">Promo Code</MenuItem>
      </Select>
    </PackageSelectorWrapper>
  );
};

export default PackageSelectorTheme1;
