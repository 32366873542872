import React from 'react';
import { Box } from '@mui/material';
import taglineSvg from '../../../assets/images/tagline.svg';
import hashtagPng from '../../../assets/images/hashtag.png';

const DFCFooter: React.FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '30px',
      }}
    >
      <Box
        component="img"
        src={taglineSvg}
        alt="Share Your 30"
        sx={{
          height: 'auto',
          marginBottom: '10px',
          maxWidth: '300px',
          maxHeight: '300px',
        }}
      />
      <Box
        component="img"
        src={hashtagPng}
        alt="Dubai Fitness Challenge Hashtag"
        sx={{
          height: 'auto',
          marginBottom: '10px',
          maxHeight: '300px',
          maxWidth: '300px',
        }}
      />
    </Box>
  );
};

export default DFCFooter;
