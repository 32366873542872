import { useContext, useState } from 'react';
import { CheckoutContext, CheckoutContextProps } from '../../../context/CheckoutContext';
import { ValidationOutput } from '../../../hooks/usePackage';

export const useTermsAgreementCard = (
  totalPrice: number,
  packageValidationOutput: ValidationOutput
) => {
  const [userAgreed, setUserAgreed] = useState(false);
  const checkBoxHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserAgreed(event.target.checked);
  };

  const { company } = useContext(CheckoutContext) as CheckoutContextProps;

  const getPaymentText = () => {
    if (totalPrice === 0 && !packageValidationOutput) {
      return 'Book Now';
    }

    if (
      !packageValidationOutput ||
      packageValidationOutput?.type === 'promocode' ||
      packageValidationOutput?.cartTotalAfterApplyingVoucher !== 0
    ) {
      return 'Proceed to Payment';
    }
    return 'Book with Package';
  };
  return {
    userAgreed,
    setUserAgreed,
    checkBoxHandler,
    company,
    getPaymentText,
  };
};
