import { Stack, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import { PackageDetailsItemWrapper } from '../style';

interface IProps {
  label: string;
  value: string;
  valueColor?: string;
  icon?: ReactElement;
  highlight?: boolean;
}

const PackageDetailsItem: React.FC<IProps> = ({ label, value, valueColor, icon, highlight }) => {
  return (
    <PackageDetailsItemWrapper
      direction="row"
      spacing={3}
      justifyContent="space-between"
      alignItems="center"
      valuecolor={valueColor || '#33334f'}
      data-testid="package-details-item"
      className={highlight ? 'highlighted' : ''}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        {icon && <span className="icon-wrapper">{icon}</span>}
        <Typography data-testid="package-details-item-label" className="package-details-item-label">
          {label}
        </Typography>
      </Stack>
      <Typography
        data-testid="package-details-item-value"
        className="package-details-item-value"
        sx={
          highlight
            ? {
                fontWeight: 600,
                fontSize: '20px',
                background: 'linear-gradient(45deg, #33334f 30%, #5B5B8D 90%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }
            : {}
        }
      >
        {value}
      </Typography>
    </PackageDetailsItemWrapper>
  );
};

export default PackageDetailsItem;
