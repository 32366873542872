import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CheckoutContext } from '../../../../context/CheckoutContext';
import { CheckoutContextProps } from '../../../../context/CheckoutContext';
import { Service } from '../../../../hooks/useCompany';

export const useServiceSelector = (
  type: string,
  subType?: string,
  serviceId?: string,
  branchId?: string,
  resourceId?: string,
  sportId?: string,
  uponSelectCallback?: (value: string) => void
) => {
  const [isInitialSelection, setIsInitialSelection] = useState(true);

  const {
    branchesMap,
    servicesMap,
    selectedBranch,
    resourcesMap,
    selectedService,
    selectedResource,
    setSelectedBranch,
    setSelectedService,
    setSelectedResource,
    company,
    selectedSport,
    setSelectedSport,
  } = useContext(CheckoutContext) as CheckoutContextProps;

  const sportsOptions = useMemo(() => {
    if (!company?.sports) return [];
    return [
      {
        value: '',
        label: 'All Sports',
        image: '',
      },
      ...company.sports.map(sport => ({
        value: sport.id,
        label: sport.name,
        image: sport.image,
      })),
    ];
  }, [company?.sports]);

  const branchOptions = useMemo(() => {
    if (!branchesMap || branchesMap.size === 0) return [];
    return Array.from(branchesMap.values())
      .filter(branch => {
        return Array.from(servicesMap?.values() || []).some(service => {
          const matchesType = shouldConsiderThisService(service, type, subType);
          const matchesSport = !selectedSport || service.sportId === selectedSport;
          return service.branchId === branch.id && matchesType && matchesSport;
        });
      })
      .map(branch => ({
        value: branch.id,
        label: branch.name,
      }));
  }, [branchesMap, servicesMap, type, subType, selectedSport]);

  const servicesOptions = useMemo(() => {
    if (!servicesMap || servicesMap.size === 0) return [];

    return Array.from(servicesMap.values())
      .filter(service => {
        const matchesType = shouldConsiderThisService(service, type, subType);
        const matchesSport = !selectedSport || service.sportId === selectedSport;
        return matchesType && matchesSport;
      })
      .filter(service => service.branchId === selectedBranch)
      .map(service => ({
        value: service.id,
        label: service.name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [servicesMap, type, subType, selectedBranch, selectedSport]);

  const resourceOptions = useMemo(() => {
    const options: any[] = [
      {
        value: '',
        label: 'All Courts',
      },
    ];
    if (!resourcesMap || resourcesMap.size === 0) return options;

    const selectedServiceData = servicesMap?.get(selectedService);

    selectedServiceData?.resources.forEach(resource => {
      options.push({
        value: resource.id,
        label: resource.name,
      });
    });

    options.sort((a, b) => {
      if (a.value === '') return -1;
      if (b.value === '') return 1;
      return a.label.localeCompare(b.label);
    });

    return options;
  }, [resourcesMap, selectedService, servicesMap, type, subType, selectedBranch, selectedSport]);

  useEffect(() => {
    if (sportsOptions.length === 0) {
      setSelectedSport('');
      return;
    }

    let newSelectedSport = '';

    if (sportId && isInitialSelection && company?.sports?.some(sport => sport.id === sportId)) {
      newSelectedSport = sportId;
    }

    if (newSelectedSport) {
      setSelectedSport(newSelectedSport);
    }
  }, [sportId, company?.sports, sportsOptions, setSelectedSport]);

  useEffect(() => {
    if (branchOptions.length === 0) {
      setSelectedBranch('');
      return;
    }

    let newSelectedLocation = branchOptions[0]?.value;

    if (branchId && isInitialSelection && branchesMap?.has(branchId)) {
      newSelectedLocation = branchId;
    }

    if (serviceId && isInitialSelection && servicesMap?.has(serviceId)) {
      const service = servicesMap.get(serviceId)!;
      newSelectedLocation = service?.branchId ?? '';
    }

    if (newSelectedLocation) {
      setSelectedBranch(newSelectedLocation);
    }
  }, [branchId, serviceId, branchOptions, setSelectedBranch, servicesMap, branchesMap]);

  useEffect(() => {
    if (servicesOptions.length === 0) {
      setSelectedService('');
      return;
    }

    let newSelectedService = servicesOptions[0]?.value;

    if (serviceId && servicesMap?.has(serviceId) && isInitialSelection) {
      newSelectedService = servicesMap.get(serviceId)?.id ?? '';
    }

    if (newSelectedService) {
      setSelectedService(newSelectedService);
    }
  }, [serviceId, servicesMap, servicesOptions, setSelectedService]);

  useEffect(() => {
    if (resourceOptions.length === 0) {
      setSelectedResource('');
      return;
    }

    let newSelectedResource = resourceOptions[0]?.value;

    if (resourceId && resourcesMap?.has(resourceId) && isInitialSelection) {
      newSelectedResource = resourceId;
    }

    setSelectedResource(newSelectedResource);
  }, [resourceId, resourcesMap, resourceOptions, setSelectedResource]);

  const onServiceChange = useCallback(
    (_: React.SyntheticEvent<Element, Event>, value: string) => {
      setIsInitialSelection(false);
      setSelectedService(value);
      uponSelectCallback?.(value);
    },
    [setSelectedService, uponSelectCallback, setIsInitialSelection]
  );

  const OnBranchChange = useCallback(
    (_: React.SyntheticEvent<Element, Event>, value: string) => {
      setIsInitialSelection(false);
      setSelectedBranch(value);
    },
    [setIsInitialSelection, setSelectedBranch]
  );

  const onResourceChange = useCallback(
    (_: React.SyntheticEvent<Element, Event>, value: string) => {
      setIsInitialSelection(false);
      setSelectedResource(value);
    },
    [setIsInitialSelection, setSelectedResource]
  );

  const onSportChange = useCallback(
    (_: React.SyntheticEvent<Element, Event>, value: string) => {
      setIsInitialSelection(false);
      setSelectedSport(value);
    },
    [
      setIsInitialSelection,
      setSelectedSport,
      setSelectedBranch,
      setSelectedService,
      setSelectedResource,
    ]
  );

  const service = servicesMap?.get(selectedService);

  return {
    company,
    selectedBranch,
    selectedService,
    selectedResource,
    selectedSport,
    branchOptions,
    servicesOptions,
    resourceOptions,
    sportsOptions,
    onServiceChange,
    OnBranchChange,
    onResourceChange,
    onSportChange,
    showResourcesSelector: service?.showResourcesSelector,
    selectedServiceData: service,
  };
};

const shouldConsiderThisService = (service: Service, type: string, subType?: string): boolean => {
  if (!service.type) return false;
  else if (type && subType) return service.type === type && service.subType === subType;
  else if (type) return service.type === type && !service.subType;
  return false;
};
