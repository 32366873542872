import { Box, IconButton, Typography } from '@mui/material';
import { confirmPasswordReset } from 'firebase/auth';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import FormInput from '../../components/FormCollection/FormInput';
import { useForm } from 'react-hook-form';
import Heading from '../../components/shared/Heading';
import { yupResolver } from '@hookform/resolvers/yup';
import { getResetPasswordSchema } from '../../Utils/validate';
import PrimaryBtn from '../../components/FormCollection/PrimaryBtn';
import { useContext, useState } from 'react';
import { CheckoutContext, CheckoutContextProps } from '../../context/CheckoutContext';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import useTitle from '../../hooks/useTitle';

const ResetPassword: React.FC<{ title?: string }> = ({ title }) => {
  useTitle(title);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [error, setError] = useState<string>('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<{
    password: string;
    passwordConfirm: string;
  }>({
    resolver: yupResolver(getResetPasswordSchema()),
  });
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;

  const { auth } = useAuth();
  const changePassword = async (data: any) => {
    setError('');
    try {
      await confirmPasswordReset(auth, searchParams.get('oobCode')!, data.password);

      navigate(`/?companyId=${company?.id}`);
    } catch (error: any) {
      console.log('[TEST] error', error);
      setError('Something went wrong. Please try resetting your password again.');
    }
  };

  return (
    <Box width={'90%'}>
      <Heading heading="Reset Password" />
      <FormInput
        label="Password*"
        error={errors.password}
        placeholder={'Password'}
        register={register('password')}
        id="password"
        type={showPassword ? 'text' : 'password'}
        endAdornment={
          <IconButton onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        }
        dataTestId="password-input"
      />
      <FormInput
        label="Re-enter Password*"
        error={errors.passwordConfirm}
        id="passwordConfirm"
        placeholder="Re-enter Password"
        register={register('passwordConfirm')}
        type={showConfirmPassword ? 'text' : 'password'}
        endAdornment={
          <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
            {showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        }
        dataTestId="password-confirm-input"
      />

      <PrimaryBtn onClick={handleSubmit(changePassword)} text="Submit" />

      <Typography color={'error'} sx={{ mt: '10px' }}>
        {error}
      </Typography>
    </Box>
  );
};

export default ResetPassword;
