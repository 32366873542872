import React from 'react';
import { Box, Typography } from '@mui/material';
import BookingDetailsItem from './BookingDetailsItem';
import { BookingDetailsWrapper } from './style';

interface Detail {
  label: string;
  value?: string;
}
interface RowOfDetails {
  items: Detail[];
}
interface IProps {
  details: RowOfDetails[];
}
const BookingDetails: React.FC<IProps> = ({ details }) => {
  return (
    <BookingDetailsWrapper>
      <Typography variant="h6" className="booking-derails-label">
        Booking Details
      </Typography>
      <Box className="booking-details">
        {details.map((row, row_index) => (
          <Box className="booking-details-row" key={row_index}>
            {row.items.map((item, item_index) => (
              <BookingDetailsItem key={item_index} label={item.label} value={item.value} />
            ))}
          </Box>
        ))}
      </Box>
    </BookingDetailsWrapper>
  );
};
export default BookingDetails;
