import React from 'react';
import HeaderTheme1 from './variants/theme1';
import HeaderTheme2 from './variants/theme2';
import { ThemeVariant } from '../../../hooks/useCompany';
import { HeaderProps } from './interfaces';

const variantComponents: Record<ThemeVariant, React.ComponentType<any>> = {
  default: HeaderTheme1,
  theme1: HeaderTheme1,
  theme2: HeaderTheme2,
};

const Header: React.FC<HeaderProps> = ({ company, isSidebarOpen, toggleDrawer }) => {
  const companyTheme = company?.theme;
  const Component = variantComponents[(companyTheme as ThemeVariant) || 'default'];

  return <Component company={company} isSidebarOpen={isSidebarOpen} toggleDrawer={toggleDrawer} />;
};

export default Header;
