import React, { useEffect, useState } from 'react';
import './CheckoutFramesStyle.css';
import CardNumberField from './CardNumberField';
import ExpiryDateField from './ExpiryDateField';
import CvvField from './CvvField';
import ErrorMessage from './ErrorMessage';
import { useAuth } from '../../../context/AuthContext';
import { CircularProgress } from '@mui/material';
import PrimaryBtn from '../../FormCollection/PrimaryBtn';

type Props = {
  handleCheckout: any;
  totalPrice: number | undefined;
  userAgreed: boolean;
};

const CheckoutFrames: React.FC<Props> = ({ handleCheckout, totalPrice, userAgreed }: Props) => {
  const [isCardValid, setIsCardValid] = useState(false);
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    Frames.init(process.env.REACT_APP_CHECKOUT_FRAMES_KEY!);

    Frames.addEventHandler(Frames.Events.FRAME_VALIDATION_CHANGED, onValidationChanged);
    Frames.addEventHandler(Frames.Events.CARD_VALIDATION_CHANGED, onCardValidationChanged);
    Frames.addEventHandler(Frames.Events.CARD_TOKENIZATION_FAILED, onCardTokenizationFailed);
    Frames.addEventHandler(Frames.Events.PAYMENT_METHOD_CHANGED, onPaymentMethodChanged);
  }, []);

  const onValidationChanged = (event: any) => {
    const element = event.element;

    if (event.isValid || event.isEmpty) {
      if (element === 'card-number' && !event.isEmpty) {
        showPaymentMethodIcon(null);
      }
      setDefaultIcon(element);
      clearErrorIcon(element);
      clearErrorMessage(element);
    } else {
      if (element === 'card-number') {
        clearPaymentMethodIcon(null);
      }
      setDefaultErrorIcon(element);
      setErrorIcon(element);
      setErrorMessage(element);
    }
  };

  const onCardValidationChanged = () => {
    setIsCardValid(Frames.isCardValid());
  };

  const onCardTokenizationFailed = (error: any) => {
    console.error('CARD_TOKENIZATION_FAILED:', error);
  };

  const onPaymentMethodChanged = (event: any) => {
    const paymentMethod = event.paymentMethod;
    const container = document.querySelector('.icon-container.payment-method');

    if (!paymentMethod) {
      clearPaymentMethodIcon(container);
    } else {
      clearErrorIcon('card-number');
      showPaymentMethodIcon(container, paymentMethod);
    }
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setCheckoutLoading(true);
    Frames.submitCard()
      .then(async (data: any) => {
        await handleCheckout({
          firstName: user?.firstName,
          lastName: user?.lastName,
          email: user?.email,
          phone: user?.phoneNumber,
          token: data.token,
          issuer: data.issuer,
        });
        setCheckoutLoading(false);
        Frames.enableSubmitForm();
      })
      .catch(function (error: any) {
        setCheckoutLoading(false);
        Frames.enableSubmitForm();
      });
  };

  const setDefaultIcon = (element: string) => {
    const icon = document.getElementById(`icon-${element}`);
    icon?.setAttribute('src', `images/card-icons/${element}.svg`);
    icon?.setAttribute('alt', `${element} logo`);
  };

  const setDefaultErrorIcon = (element: string) => {
    const icon = document.getElementById(`icon-${element}`);
    icon?.setAttribute('src', `images/card-icons/${element}-error.svg`);
    icon?.setAttribute('alt', `${element} error logo`);
  };

  const setErrorIcon = (element: string) => {
    const icon = document.getElementById(`icon-${element}-error`);
    icon?.style.setProperty('display', 'block');
  };

  const clearErrorIcon = (element: string) => {
    const icon = document.getElementById(`icon-${element}-error`);
    icon?.style.removeProperty('display');
  };

  const clearErrorMessage = (element: string) => {
    const message = document.querySelector(`.error-message__${element}`);
    if (message) {
      message.textContent = '';
    }
  };

  const setErrorMessage = (element: string) => {
    const message = document.querySelector(`.error-message__${element}`);
    if (message) {
      message.textContent = `Please enter a valid ${element}`;
    }
  };

  const showPaymentMethodIcon = (container: Element | null, method: string = '') => {
    if (container) {
      container.classList.add('show');
    }
    const icon = document.getElementById('logo-payment-method');
    if (icon && method) {
      icon.setAttribute('src', `images/card-icons/${method.toLowerCase()}.svg`);
      icon.setAttribute('alt', method || 'payment method');
      icon.style.removeProperty('display');
    }
  };

  const clearPaymentMethodIcon = (container: Element | null) => {
    if (container) {
      container.classList.remove('show');
    }
    const icon = document.getElementById('logo-payment-method');
    if (icon) {
      icon.style.setProperty('display', 'none');
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <CardNumberField />
      <div className="date-and-code">
        <ExpiryDateField />
        <CvvField />
      </div>
      <PrimaryBtn
        fullWidth
        disabled={!isCardValid || !userAgreed || checkoutLoading}
        color="error"
        variant="contained"
        id="pay-button"
        // onClick={() => onClick()}
        isLoading={checkoutLoading}
        type="submit"
        text={`PAY AED ${totalPrice}`}
      />
      <ErrorMessage />
      {/* {successMessage && (
        <p className="success-payment-message">{successMessage}</p>
      )} */}
    </form>
  );
};

export default CheckoutFrames;
