import { useContext, useEffect, useMemo } from 'react';
import ContentLoader from '../../components/shared/ContentLoader';
import { useSearchParams, useNavigate } from 'react-router-dom';
import useCompany, { Membership } from '../../hooks/useCompany';
import { CheckoutContext, CheckoutContextProps } from '../../context/CheckoutContext';
import Heading from '../../components/shared/Heading';
import { Typography, Box, Paper } from '@mui/material';
import { PackagesWrapper } from '../package/packages/style';
import MembershipPurchaseDetails from '../../components/discount/membership/MembershipPurchaseDetails';
import useTitle from '../../hooks/useTitle';
import SearchOffIcon from '@mui/icons-material/SearchOff';

interface props {
  title?: string;
}
const MemberShips: React.FC<props> = ({ title }) => {
  useTitle(title);
  const navigate = useNavigate();
  const { company, servicesMap } = useContext(CheckoutContext) as CheckoutContextProps;
  const [searchParams] = useSearchParams();
  const providerId = searchParams.get('providerId');
  const serviceId = searchParams.get('serviceId');

  const filteredMemberships = useMemo(() => {
    if (!company?.vouchers) return [];
    
    let memberships = company.vouchers.filter(
      (discount): discount is Membership => discount.type === 'membership'
    );

    // Filter by providerId if present
    if (providerId) {
      memberships = memberships.filter(membership => membership.providerId === providerId);
    }

    // Filter by serviceId if present
    if (serviceId) {
      memberships = memberships.filter(membership => membership.serviceId === serviceId);
    }

    return memberships;
  }, [company?.vouchers, providerId, serviceId]);

  // Get service name for the heading if serviceId is provided
  const serviceName = useMemo(() => {
    if (serviceId && servicesMap) {
      const service = servicesMap.get(serviceId);
      return service?.name;
    }
    return null;
  }, [serviceId, servicesMap]);

  // Get provider name for the heading if providerId is provided
  const providerName = useMemo(() => {
    if (providerId && company?.providers) {
      const provider = company.providers.find(p => p.id === providerId);
      return provider?.name;
    }
    return null;
  }, [providerId, company?.providers]);

  // Create a custom heading based on the filters
  const customHeading = useMemo(() => {
    if (serviceName && providerName) {
      return `${serviceName} Memberships by ${providerName}`;
    } else if (serviceName) {
      return `${serviceName} Memberships`;
    } else if (providerName) {
      return `${providerName} Memberships`;
    }
    return company?.membershipHeader || 'Memberships';
  }, [serviceName, providerName, company?.membershipHeader]);

  return (
    <>
      <Heading heading={customHeading} sx={{ mt: 2 }} />
      <Typography sx={{ textAlign: 'center', mb: 3 }}>{company?.membershipDisclaimer}</Typography>
      
      {filteredMemberships.length > 0 ? (
        <PackagesWrapper sx={{ gap: '32px' }}>
          {filteredMemberships.sort((a, b) => a.price - b.price).map((membership, i: number)=> (
            <MembershipPurchaseDetails membership={membership} key={i} />
          ))}
        </PackagesWrapper>
      ) : (
        <Paper 
          elevation={0} 
          sx={{ 
            p: 4, 
            textAlign: 'center', 
            borderRadius: '16px',
            border: '1px dashed rgba(0, 0, 0, 0.12)',
            backgroundColor: 'rgba(0, 0, 0, 0.02)',
            maxWidth: '600px',
            mx: 'auto',
            mb: 4
          }}
        >
          <Typography variant="h6" gutterBottom>There are no memberships currently available. Please check back later.</Typography>
        </Paper>
      )}
    </>
  );
};

export default MemberShips;
