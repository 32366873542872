import { useContext, useEffect } from 'react';
import ClassSessionSelector from '../../components/Calendar/ClassSessionSelector';

import { useSearchParams } from 'react-router-dom';
import useClassSessions from '../../hooks/useClassSessions';
import { CheckoutContext, CheckoutContextProps } from '../../context/CheckoutContext';

import { FunActivityCalendarWrapper } from './style';
import useTitle from '../../hooks/useTitle';
import ServiceSelector from '../../components/Calendar/ServiceSelector';

interface props {
  title?: string;
}
const FunActivities: React.FC<props> = ({ title }) => {
  useTitle(title);
  const { selectedService, company, setQuantity, setMarketplaceName } = useContext(
    CheckoutContext
  ) as CheckoutContextProps;

  const [searchParams] = useSearchParams();
  const serviceId = searchParams.get('serviceId');

  const { isLoading: isLoadingSessions, sessions } = useClassSessions(
    company?.id || '',
    selectedService
  );

  setQuantity(1);

  return (
    <FunActivityCalendarWrapper>
      <ServiceSelector type="class" subType="fun" serviceId={serviceId || ''} />

      <ClassSessionSelector sessions={sessions} isLoading={isLoadingSessions} type="fun" />
    </FunActivityCalendarWrapper>
  );
};

export default FunActivities;
