import { Button, OutlinedInput, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { PromocodeInputWrapper } from './style';
import { usePromoCode } from '../../hooks/usePromoCode';
import { IPromocodeRedemptionProps } from '../../interfaces';

const PromocodeRedemptionTheme1: React.FC<IPromocodeRedemptionProps> = ({
  promocode,
  setPromocode,
  validationOutput,
  setPackageValidationOutput,
  type,
}: IPromocodeRedemptionProps) => {
  const { isValidating, isError, errorMessage, onClickApply, onClickCancel } = usePromoCode(
    promocode,
    setPromocode,
    setPackageValidationOutput
  );

  return (
    <PromocodeInputWrapper>
      <OutlinedInput
        placeholder="Enter Promo Code"
        value={promocode}
        onChange={e => setPromocode(e.target.value)}
        className="package-code-input"
        endAdornment={
          validationOutput?.valid ? (
            <Button onClick={onClickCancel} color={'error'} className="package-code-button">
              Cancel
            </Button>
          ) : (
            <LoadingButton
              onClick={onClickApply}
              color={'primary'}
              loading={isValidating}
              className="package-code-button"
              disabled={!(promocode.length > 0)}
            >
              Apply
            </LoadingButton>
          )
        }
      />

      {isError && <Typography color={'error'}>{errorMessage}</Typography>}
    </PromocodeInputWrapper>
  );
};

export default PromocodeRedemptionTheme1;
