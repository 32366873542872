import React, { useMemo } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DaysListWrapper } from './style';
import DayChip from './DayChip';

interface IProps {
  selectedDay: string;
  setSelectedDay: (day: string) => void;
  daysList: Dayjs[];
  dayHasAvailableSlots: Map<string, boolean>;
}

const DaysListTheme1: React.FC<IProps> = ({
  selectedDay,
  setSelectedDay,
  daysList,
  dayHasAvailableSlots,
}) => {
  const handleClick = (day: Dayjs) => {
    setSelectedDay(day.format('DD/MM/YYYY'));
  };

  return (
    <DaysListWrapper className="days-list">
      {daysList.map(day => (
        <DayChip
          key={day.toISOString()}
          day={day}
          isSelected={day.format('DD/MM/YYYY') === selectedDay}
          hasAvailableSlots={dayHasAvailableSlots.get(day.format('YYYYMMDD')) || false}
          onDayClick={() => handleClick(day)}
        />
      ))}
    </DaysListWrapper>
  );
};

export default DaysListTheme1;
