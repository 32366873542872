import { useContext, useRef, useState, useMemo } from 'react';
import { Typography, Box } from '@mui/material';
import { CheckoutContext, CheckoutContextProps } from '../../context/CheckoutContext';
import { Service, Membership } from '../../hooks/useCompany';
import Heading from '../../components/shared/Heading';
import useTitle from '../../hooks/useTitle';
import { useNavigate } from 'react-router-dom';
import ServiceCard from '../../components/groupedMemberships/service/ServiceCard';
import {
  GroupWrapper,
  ProviderTitle,
  ProviderDescription,
  ScrollableWrapper,
  ScrollIndicator,
  Dot
} from './style';

interface Props {
  title?: string;
}

const GroupedMemberships: React.FC<Props> = ({ title }) => {
  useTitle(title);
  const navigate = useNavigate();
  const { company, servicesMap } = useContext(CheckoutContext) as CheckoutContextProps;
  const [activeIndex, setActiveIndex] = useState<Record<string, number>>({});
  const scrollContainers = useRef<Record<string, HTMLDivElement>>({});

  // Get all memberships from vouchers
  const memberships = useMemo(() => {
    if (!company?.vouchers) return [];
    return company.vouchers.filter(
      (discount): discount is Membership => discount.type === 'membership'
    );
  }, [company?.vouchers]);

  // Create a set of service IDs that have memberships
  const serviceIdsWithMemberships = useMemo(() => {
    return new Set(memberships.map(membership => membership.serviceId));
  }, [memberships]);

  const providersMap = useMemo(() => {
    if (!company?.providers) return {};
    return company?.providers.reduce(
      (acc, provider) => {
        acc[provider.id] = { name: provider.name, description: provider.description };
        return acc;
      },
      {} as Record<string, { name: string; description: string }>
    );
  }, [company?.providers]);

  // Group services by provider, but only include services that have memberships
  const groupedServices = useMemo(() => {
    if (!servicesMap) return {};
    
    const services = Array.from(servicesMap.values())
      .filter(service => serviceIdsWithMemberships.has(service.id));
    
    return services.reduce(
      (groups, service) => {
        const provider = service.providerId;
        if (!provider) return groups;
        
        if (!groups[provider]) {
          groups[provider] = [];
        }
        groups[provider].push(service);
        return groups;
      },
      {} as Record<string, Service[]>
    );
  }, [servicesMap, serviceIdsWithMemberships]);

  const handleScroll = (providerId: string) => (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.currentTarget;
    const scrollPosition = target.scrollLeft;
    const cardWidth = 300 + 24; // card width + gap
    const newIndex = Math.round(scrollPosition / cardWidth);
    setActiveIndex(prev => ({ ...prev, [providerId]: newIndex }));
  };

  const scrollTo = (providerId: string, index: number) => {
    const container = scrollContainers.current[providerId];
    if (container) {
      const cardWidth = 300 + 24; // card width + gap
      container.scrollTo({
        left: index * cardWidth,
        behavior: 'smooth',
      });
      setActiveIndex(prev => ({ ...prev, [providerId]: index }));
    }
  };

  const handleServiceClick = (providerId: string, serviceId: string) => {
    navigate(`/memberships?companyId=${company?.id}&providerId=${providerId}&serviceId=${serviceId}`);
  };

  // Check if there are any services with memberships
  const hasServicesWithMemberships = Object.keys(groupedServices).length > 0;

  return (
    <>
      <Heading heading={company?.membershipHeader || ''} sx={{ mt: 2 }} />
      <Typography sx={{ textAlign: 'center', mb: 3 }}>{company?.membershipDisclaimer}</Typography>

      {hasServicesWithMemberships ? (
        Object.entries(groupedServices).map(([providerId, services]) => (
          <GroupWrapper key={providerId}>
            <ProviderTitle>{providersMap[providerId]?.name || 'Others'}</ProviderTitle>
            <ProviderDescription>{providersMap[providerId]?.description}</ProviderDescription>
            <ScrollableWrapper
              ref={(el: HTMLDivElement | null) => {
                if (el) scrollContainers.current[providerId] = el;
              }}
              onScroll={handleScroll(providerId)}
              className="scroll-container"
            >
              {services.map((service, i) => (
                <ServiceCard 
                  key={i}
                  service={service}
                  providerId={providerId}
                  onClick={handleServiceClick}
                />
              ))}
            </ScrollableWrapper>
            <ScrollIndicator>
              {Array.from({ length: Math.min(3, Math.ceil(services.length / 3)) }, (_, i) => (
                <Dot
                  key={i}
                  active={activeIndex[providerId] === i}
                  onClick={() => scrollTo(providerId, i)}
                />
              ))}
            </ScrollIndicator>
          </GroupWrapper>
        ))
      ) : (
        <Box sx={{ textAlign: 'center', mt: 4, color: 'text.secondary' }}>
          <Typography variant="h6">No services with memberships available</Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
            There are currently no services that offer membership options.
          </Typography>
        </Box>
      )}
    </>
  );
};

export default GroupedMemberships;
