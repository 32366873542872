import React, { useContext } from 'react';
import { ITimeSelectorProps } from './interfaces';
import { CheckoutContext } from '../../../context/CheckoutContext';
import { CheckoutContextProps } from '../../../context/CheckoutContext';
import { ThemeVariant } from '../../../hooks/useCompany';
import TimeSelectorTheme2 from './variants/theme2';
import TimeSelectorTheme1 from './variants/theme1';

const variantComponents: Record<ThemeVariant, React.ComponentType<any>> = {
  default: TimeSelectorTheme1,
  theme1: TimeSelectorTheme1,
  theme2: TimeSelectorTheme2,
};

const TimeSelector: React.FC<ITimeSelectorProps> = ({
  isLoadingAvailabilitySlots,
  showDurationFilter,
  timeUnit,
  startTime,
  endTime,
  availableStartingTimes,
}) => {
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;
  const companyTheme = company?.theme;
  const Component = variantComponents[(companyTheme as ThemeVariant) || 'default'];
  return (
    <Component
      isLoadingAvailabilitySlots={isLoadingAvailabilitySlots}
      showDurationFilter={showDurationFilter}
      timeUnit={timeUnit}
      startTime={startTime}
      endTime={endTime}
      availableStartingTimes={availableStartingTimes}
    />
  );
};

export default TimeSelector;
