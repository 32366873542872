import React from 'react';
import { ListItemButton, ListItem, ListItemText } from '@mui/material';
import { IProps } from './Type';
import { useNavigate } from 'react-router-dom';

const SidebarLink: React.FC<IProps> = ({ name, path }) => {
  const navigate = useNavigate();
  return (
    <ListItem disablePadding>
      <ListItemButton onClick={() => navigate(path)} sx={{ padding: 0 }}>
        <ListItemText primary={name} />
      </ListItemButton>
    </ListItem>
  );
};

export default SidebarLink;
