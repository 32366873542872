import React, { useContext } from 'react';
import { IBookingCardProps } from './interfaces';
import { CheckoutContext, CheckoutContextProps } from '../../../context/CheckoutContext';
import BookingCardTheme1 from './variants/theme1';
import BookingCardTheme2 from './variants/theme2';
import { ThemeVariant } from '../../../hooks/useCompany';

const variantComponents: Record<ThemeVariant, React.ComponentType<IBookingCardProps>> = {
  default: BookingCardTheme1,
  theme1: BookingCardTheme1,
  theme2: BookingCardTheme2,
};

const BookingCard: React.FC<IBookingCardProps> = props => {
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;
  const companyTheme = company?.theme;
  const Component = variantComponents[(companyTheme as ThemeVariant) || 'default'];

  return <Component {...props} />;
};

export default BookingCard;
