import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { DeleteAccountButtonWrapper } from './style';
import { useDeleteAccountButton } from '../../useDeleteAccountButton';
import PrimaryBtn from '../../../../FormCollection/PrimaryBtn';

const DeleteAccountButtonTheme1 = () => {
  const { showDialog, handleOpenDialog, handleCloseDialog, handleDeleteAccount } =
    useDeleteAccountButton();

  return (
    <DeleteAccountButtonWrapper>
      <PrimaryBtn text="Delete Account" onClick={handleOpenDialog} className="delete-account-btn" />
      <Dialog open={showDialog} onClose={handleCloseDialog} className="delete-account-dialog">
        <DialogTitle>Are you sure you would like to delete your account?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This action will permanently delete your account and all associated data, including any
            existing bookings.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleDeleteAccount}
            autoFocus
            className="delete-btn"
          >
            Delete Account
          </Button>
          <Button variant="contained" onClick={handleCloseDialog} className="cancel-btn">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </DeleteAccountButtonWrapper>
  );
};

export default DeleteAccountButtonTheme1;
