import { CheckoutContextProps } from '../../../../context/CheckoutContext';

import { CheckoutContext } from '../../../../context/CheckoutContext';

import { useContext, useEffect } from 'react';
import { AvailabilitySlot } from '../../../../hooks/useAvailableStartingTimes';
import { areAllSlotsOfType } from '../../../../Utils/validate';

export const useSlotSelector = (slots: AvailabilitySlot[]) => {
  const { company, setSelectedSlot } = useContext(CheckoutContext) as CheckoutContextProps;

  const areAllOffPeakSlots = areAllSlotsOfType(slots, false);

  useEffect(() => {
    setSelectedSlot(undefined);
  }, [JSON.stringify(slots)]);

  return {
    areAllOffPeakSlots,
    timezone: company?.timezone || '',
  };
};
