import React, { useEffect, useState, useRef, useContext, useMemo } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import {
  Typography,
  Paper,
  CircularProgress,
  Alert,
  Button,
  Box,
  Divider,
  Stack,
  LinearProgress,
  Snackbar,
  AlertTitle,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CartCheckoutDetails from '../../components/cart/CartCheckoutDetails';
import useTitle from '../../hooks/useTitle';
import useCart, { Booking } from '../../hooks/useCart';
import { CheckoutContext, CheckoutContextProps } from '../../context/CheckoutContext';
import { calculateTotals, mapBookingsWithAddons } from '../../Utils/cartUtils';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PersonIcon from '@mui/icons-material/Person';
import PaymentIcon from '@mui/icons-material/Payment';
import GroupsIcon from '@mui/icons-material/Groups';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ShareIcon from '@mui/icons-material/Share';
import axiosInstance from '../../services/axiosInstance';
import { isValidUser } from '../../Utils/validate';
import Register from '../register';
import { DSW_COMPANY_ID, RegistrationStep } from '../../Utils/constants';
import { useAuth } from '../../context/AuthContext';
import CheckoutFrames from '../../components/Checkout/CheckoutFrames/CheckoutFrames';
import { calculateServiceFees } from '../../Utils/format';

const GroupBookingWrapper = styled('div')(({ theme }) => ({
  maxWidth: '800px',
  margin: '0 auto',
  padding: theme.spacing(3),
  '.action-section': {
    marginBottom: theme.spacing(4),
    padding: theme.spacing(3),
    backgroundColor: theme.palette.primary.light,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.primary.contrastText,
  },
  '.payment-cta': {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2),
  },
  '.per-person-amount': {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  '.summary-item': {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  '.booking-status': {
    marginBottom: theme.spacing(4),
    padding: theme.spacing(3),
  },
  '.organizer-section': {
    marginBottom: theme.spacing(4),
    padding: theme.spacing(3),
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
  },
  '.organizer-header': {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    '& .MuiSvgIcon-root': {
      fontSize: '1.5rem',
    },
  },
  '.payment-link-container': {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(1.5),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(2),
    wordBreak: 'break-all',
  },
  '.copy-success': {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 1000,
  },
  '.initiator-section': {
    marginBottom: theme.spacing(4),
    padding: theme.spacing(3),
    backgroundColor: theme.palette.grey[50],
    borderLeft: `4px solid ${theme.palette.primary.main}`,
  },
  '.initiator-label': {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  },
  '.initiator-info': {
    fontWeight: 500,
  },
  '.participants-section': {
    marginTop: theme.spacing(4),
  },
  '.participant-card': {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  '.amount': {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  '.pending': {
    color: theme.palette.warning.main,
  },
  '.completed': {
    color: theme.palette.success.main,
  },
  '.share-section': {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
  },
  '.share-title': {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  '.payment-link-field': {
    backgroundColor: theme.palette.grey[50],
    marginTop: theme.spacing(2),
  },
  '.share-buttons': {
    display: 'flex',
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
}));

const GroupBookingConfirmation: React.FC = () => {
  useTitle('Group Booking Details');
  const { user } = useAuth();
  const [searchParams] = useSearchParams();
  const cartId = searchParams.get('cartId') || '';

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [cart, setCart] = useState<any>(null);
  const [total, setTotal] = useState<number>(0);
  const [copySuccess, setCopySuccess] = useState(false);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [paymentError, setPaymentError] = useState<string | null>(null);

  const hasCalledAPI = useRef(false);
  const { getCart } = useCart();
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;
  const location = useLocation();
  const fetchData = async () => {
    if (hasCalledAPI.current) return;
    hasCalledAPI.current = true;

    try {
      setLoading(true);
      const { cart: cartData } = await getCart(cartId);
      setCart(cartData);

      const { total } = calculateTotals(cartData);
      setTotal(total + (calculateServiceFees(total, company)));
    } catch (err) {
      setError('Failed to fetch cart details');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (cartId) {
      fetchData();
    }
  }, [cartId]);

  const isAlreadyPaid = () => {
    return isValidUser(user)
      ? cart.paidParticipants?.map((p: any) => p.email).includes(user?.email) ||
          cart?.customer?.email == user?.email
      : searchParams.get('isCustomerPaid') == 'true' || false;
  };
  const organizer = cart?.customer;

  const paymentLink = useMemo(() => {
    if (!cartId || !company?.id) return '';

    // Get the base URL (protocol + host)
    const baseUrl = window.location.origin;

    // Get the current pathname
    const pathname = location.pathname;

    // Construct the URL with query parameters
    const url = new URL(pathname, baseUrl);
    url.searchParams.set('cartId', cartId);
    url.searchParams.set('companyId', company.id);

    return url.toString();
  }, [cartId, company?.id, location.pathname]);

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(paymentLink);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Group Booking Payment',
          text: `Join me in completing our group booking payment. Click the link to pay your share:`,
          url: paymentLink,
        });
      } catch (err) {
        console.error('Share failed:', err);
      }
    }
    handleCopyLink();
  };

  const handlePaymentClick = async () => {
    setIsPaymentLoading(true);
    setPaymentError(null);

    try {
      const response = await axiosInstance.get(`/cart/split-payment/${cartId}`);
      console.log(response.data);
      const paymentUrl = response.data?.data?.paymentUrl;

      if (paymentUrl) {
        window.location.href = paymentUrl;
      } else {
        throw new Error('Payment URL not received');
      }
    } catch (error: any) {
      console.error('Payment link generation failed:', error);
      setPaymentError(
        error.response?.data?.message || 'Failed to generate payment link. Please try again later.'
      );
      setIsPaymentLoading(false);
    }
  };

  const handleCheckoutGroupBookingPayment = async (data: any) => {
    setIsPaymentLoading(true);
    setPaymentError(null);
    try {
      const response = await axiosInstance.post('/cart/checkout-group-booking', {
        cartId,
        token: data.token,
      });
      hasCalledAPI.current = false;
      await fetchData();
    } catch (error: any) {
      console.error('Payment link generation failed:', error);
      setPaymentError(
        error.response?.data?.message || 'Failed to generate payment link. Please try again later.'
      );
      setIsPaymentLoading(false);
    }
  };

  const handleCloseError = () => {
    setPaymentError(null);
  };

  if (loading) {
    return (
      <GroupBookingWrapper>
        <CircularProgress />
      </GroupBookingWrapper>
    );
  }

  if (error) {
    return (
      <GroupBookingWrapper>
        <Alert severity="error">{error}</Alert>
      </GroupBookingWrapper>
    );
  }
  if (company?.hasAuthentication && !isValidUser(user)) {
    return (
      <>
        <Typography>Please Login to checkout your share</Typography>
        <Register registrationStep={RegistrationStep.Login} redirectToCalendar={false} />
      </>
    );
  }
  const totalParticipants = cart?.numOfParticipants || 0;
  const paidParticipants = cart?.paidParticipants || [];
  const remainingParticipants = totalParticipants - 1 - paidParticipants.length;
  const paidAmount = paidParticipants.reduce((sum: number, p: any) => sum + p.amount, 0);

  const perPersonAmount = total / totalParticipants;

  const getDisplayName = (participant: any) => {
    if (participant.name) return participant.name;
    return participant.email.split('@')[0]; // Get the part before @
  };

  // Combine organizer and paid participants
  const getAllPaidParticipants = () => {
    const paidList = [...paidParticipants].reverse();

    // Check if organizer isn't in the list
    const organizerInList = paidList.find((p: any) => p.email === organizer?.email);

    if (!organizerInList) {
      paidList.unshift({
        email: organizer.email,
        name: getDisplayName(organizer),
        amount: perPersonAmount,
        isOrganizer: true, // Add this flag to identify the organizer
      });
    } else {
      organizerInList.isOrganizer = true;
    }

    return paidList;
  };

  const allPaidParticipants = getAllPaidParticipants();

  return (
    <GroupBookingWrapper>
      <Paper className="action-section">
        <Typography variant="h5" gutterBottom>
          Group Booking Payment
        </Typography>

        <Stack spacing={2}>
          <div className="summary-item">
            <GroupsIcon />
            <Typography>
              {totalParticipants} participants in total ({allPaidParticipants.length} paid)
            </Typography>
          </div>
          <div className="summary-item">
            <AccountBalanceWalletIcon />
            <Typography>
              Total booking amount: {company?.currency}
              {total.toFixed(2)}
            </Typography>
          </div>
        </Stack>

        <Box className="payment-cta">
          {isAlreadyPaid() ? (
            <>
              <Alert severity="success" sx={{ mb: 2 }}>
                You have already paid your share. Thank you!
              </Alert>

              {remainingParticipants > 0 && !cart.paidByOrganizer && (
                <div className="share-section">
                  <Typography variant="h6" gutterBottom className="share-title">
                    Share with Other Participants
                  </Typography>

                  <Typography variant="body2" color="text.primary" gutterBottom sx={{ mb: 2 }}>
                    Help complete the booking by sharing the payment link with the remaining{' '}
                    {remainingParticipants}{' '}
                    {remainingParticipants === 1 ? 'participant' : 'participants'}.
                  </Typography>

                  <div className="share-buttons">
                    <Button
                      variant="contained"
                      startIcon={<ShareIcon />}
                      endIcon={<ContentCopyIcon />}
                      onClick={handleShare}
                      fullWidth
                      sx={{ mt: 2 }}
                    >
                      Share Payment Link
                    </Button>
                  </div>

                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      mt: 2,
                      p: 1,
                      bgcolor: 'grey.50',
                      borderRadius: 1,
                      textAlign: 'center',
                    }}
                  >
                    Each participant will need to pay {company?.currency}
                    {perPersonAmount.toFixed(2)}
                  </Typography>
                </div>
              )}
            </>
          ) : remainingParticipants > 0 && !cart.paidByOrganizer ? (
            <>
              <Typography variant="h6" gutterBottom textAlign="center" color="text.primary">
                Your Share
              </Typography>
              <Typography className="per-person-amount">
                {company?.currency}
                {perPersonAmount.toFixed(2)}
              </Typography>
              {company?.id === DSW_COMPANY_ID ? (
                <Box
                  sx={{
                    p: 2,
                    borderRadius: 1,
                    color: 'text.primary',
                  }}
                >
                  <CheckoutFrames
                    handleCheckout={handleCheckoutGroupBookingPayment}
                    totalPrice={perPersonAmount}
                    userAgreed={true}
                  />
                </Box>
              ) : (
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  startIcon={!isPaymentLoading && <PaymentIcon />}
                  onClick={handlePaymentClick}
                  disabled={isPaymentLoading}
                >
                  {isPaymentLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    'Pay Your Share Now'
                  )}
                </Button>
              )}
              <Typography
                variant="body2"
                sx={{ mt: 1, textAlign: 'center' }}
                color="text.secondary"
              >
                Secure payment powered by{' '}
                <a
                  href={
                    company?.id === DSW_COMPANY_ID
                      ? 'https://www.checkout.com'
                      : 'https://stripe.com'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: 'inherit', textDecoration: 'underline' }}
                >
                  {company?.id === DSW_COMPANY_ID ? 'Checkout' : 'Stripe'}
                </a>
              </Typography>
            </>
          ) : (
            <Alert severity="success">
              This group booking is now complete. All participants have paid.
            </Alert>
          )}
        </Box>
      </Paper>

      <Paper className="organizer-section">
        <div className="organizer-header">
          <PersonIcon />
          <Typography variant="h6">Group Booking Details</Typography>
        </div>

        <Typography variant="body1" sx={{ mb: 1 }}>
          Organized by: <strong>{organizer?.email}</strong>
        </Typography>

        <Divider sx={{ my: 2 }} />

        <Typography variant="h6" gutterBottom>
          Booking Progress
        </Typography>

        <Box sx={{ mb: 2 }}>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Payment Status
          </Typography>
          <LinearProgress
            variant="determinate"
            value={(allPaidParticipants.length / totalParticipants) * 100}
            sx={{ height: 10, borderRadius: 5 }}
          />
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            {allPaidParticipants.length} of {totalParticipants} participants paid
          </Typography>
        </Box>

        <div className="participants-section">
          <Typography variant="h6" gutterBottom>
            Paid Participants ({allPaidParticipants.length})
          </Typography>

          {allPaidParticipants.map((participant: any, index: number) => (
            <Paper
              key={index}
              className="participant-card"
              elevation={0}
              variant="outlined"
              sx={{
                borderLeft: participant.isOrganizer
                  ? theme => `4px solid ${theme.palette.primary.main}`
                  : undefined,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box>
                  <Typography variant="subtitle1">
                    {participant.name}
                    {participant.isOrganizer && (
                      <Typography
                        component="span"
                        variant="caption"
                        sx={{
                          ml: 1,
                          px: 1,
                          py: 0.5,
                          bgcolor: 'primary.light',
                          color: 'primary.contrastText',
                          borderRadius: 1,
                          fontSize: '0.75rem',
                        }}
                      >
                        Organizer
                      </Typography>
                    )}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {participant.email}
                  </Typography>
                  <Typography variant="body2">
                    Paid:{' '}
                    <span className="amount">
                      {company?.currency}
                      {participant.amount.toFixed(2)}
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Paper>
          ))}
        </div>

        {remainingParticipants > 0 && (
          <Alert severity="info" sx={{ mt: 3 }}>
            {remainingParticipants} more {remainingParticipants === 1 ? 'payment' : 'payments'}{' '}
            needed to complete this booking
          </Alert>
        )}
      </Paper>

      {/* Optional: Show detailed booking information */}
      <Paper sx={{ mt: 3, p: 3 }}>
        <Typography variant="h6" gutterBottom>
          Booking Details
        </Typography>
        <CartCheckoutDetails
          cartId={cartId}
          shouldRedirectToCancel={false}
          showCancelOption={false}
        />
      </Paper>

      {copySuccess && (
        <Alert severity="success" className="copy-success">
          Payment link copied to clipboard!
        </Alert>
      )}

      {/* Error Snackbar */}
      <Snackbar
        open={!!paymentError}
        autoHideDuration={6000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseError} severity="error" variant="filled" sx={{ width: '100%' }}>
          <AlertTitle>Payment Error</AlertTitle>
          {paymentError}
        </Alert>
      </Snackbar>
    </GroupBookingWrapper>
  );
};

export default GroupBookingConfirmation;
