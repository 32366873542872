import styled from '@emotion/styled';
import { Box, Button, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';

interface IProps {
  theme: Theme;
}

export const DurationFilterWrapper = styled(Box)<IProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  @keyframes lineGrow {
    from {
      width: 0;
    }
    to {
      width: 48px;
    }
  }
`;

export const DurationListWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 100%;
  margin: 0 auto;
  padding: 16px;
  position: relative;

  @media (max-width: 600px) {
    gap: 10px;
    padding: 12px;
  }

  animation: fadeScale 0.4s ease-out;

  @keyframes fadeScale {
    from {
      opacity: 0;
      transform: scale(0.98);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

interface DurationChipWrapperProps {
  isSelected: boolean;
  theme: Theme;
}
export const DurationChipWrapper = styled(Box)<DurationChipWrapperProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  margin: 0;
  border-radius: 10px;
  width: 100px;
  height: 45px;
  background: ${({ isSelected }) => (isSelected ? '#1A1A1A' : '#FFFFFF')};
  color: ${({ isSelected }) => (isSelected ? '#FFFFFF' : '#1A1A1A')};
  transition: all 0.2s ease-in-out;
  box-shadow: ${({ isSelected }) => (isSelected ? 'none' : '0 2px 4px rgba(0, 0, 0, 0.06)')};
  border: 1px solid ${({ isSelected }) => (isSelected ? 'transparent' : '#E5E5E5')};

  &:hover {
    cursor: pointer;
    transform: translateY(-1px);
    background: ${({ isSelected }) => (isSelected ? '#2A2A2A' : '#F5F5F5')};
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.08);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.06);
  }

  .duration {
    font-family: 'Inter', sans-serif;
    font-size: 14px !important;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.1px;
    color: ${({ isSelected }) => (isSelected ? '#FFFFFF' : '#1A1A1A')};
  }
`;
