import { Box, styled } from '@mui/material';

export const DatePickerWrapper = styled(Box)`
  .MuiInput-underline {
    border: none;
    &:before {
      display: none;
    }
  }
`;
