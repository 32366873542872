import { Box, Button, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useContext, useMemo } from 'react';
import { DaySelectorHeaderWrapper } from './style';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CustomizedDatePicker from '../../../../DatePicker';
import { CheckoutContext, CheckoutContextProps } from '../../../../../context/CheckoutContext';

interface IProps {
  selectedWeekStartDate: Date;
  setSelectedWeekStartDate: (date: Date) => void;
  setSelectedDay: (day: string) => void;
  handlePrevWeek: () => void;
  handleNextWeek: () => void;
  isBackDisabled: boolean;
  isNextDisabled: boolean;
  onlySevenDaysInAdvance?: boolean;
  getMonths: string;
}

const DaySelectorHeaderTheme1: React.FC<IProps> = ({
  selectedWeekStartDate,
  setSelectedWeekStartDate,
  setSelectedDay,
  onlySevenDaysInAdvance,
  handlePrevWeek,
  handleNextWeek,
  isBackDisabled,
  isNextDisabled,
  getMonths,
}) => {
  return (
    <>
      <DaySelectorHeaderWrapper>
        <Box className="arrow-icon">
          <Button onClick={handlePrevWeek} disabled={isBackDisabled || onlySevenDaysInAdvance}>
            <ArrowBackIcon />
          </Button>
        </Box>
        <Box className="day-selector-calendar" sx={{ width: '100%' }}>
          <Typography
            className="month-label"
            sx={{
              fontFamily: 'Inter',
              fontSize: '24px !important',
              fontWeight: 500,
              lineHeight: '28.8px',
            }}
          >
            {getMonths}
          </Typography>
        </Box>
        {!onlySevenDaysInAdvance ? (
          <CustomizedDatePicker
            selectedWeekStartDate={selectedWeekStartDate}
            setSelectedWeekStartDate={setSelectedWeekStartDate}
            setSelectedDay={setSelectedDay}
          />
        ) : (
          <Typography
            sx={{
              fontFamily: 'Inter',
              fontSize: '24px !important',
              fontWeight: 500,
              lineHeight: '28.8px',
              minWidth: '50px',
            }}
          >
            {' '}
          </Typography>
        )}
        <Box className="arrow-icon">
          <Button onClick={handleNextWeek} disabled={isNextDisabled || onlySevenDaysInAdvance}>
            <ArrowForwardIcon />
          </Button>
        </Box>
      </DaySelectorHeaderWrapper>
    </>
  );
};

export default DaySelectorHeaderTheme1;
