import { Box, List, ListItem, Typography, Link, Grid, useMediaQuery } from '@mui/material';
import Heading from '../shared/Heading';
import { sports } from './FakeData';
import { Link as ReactLink } from 'react-router-dom';

const MakeBooking = () => {
  const isMd = useMediaQuery('(min-width:600px)');
  return (
    <Box
      sx={{
        position: 'relative',
        padding: '24px',
        paddingBottom: '48px',
        width: '100%',
        maxWidth: '1386px',
        margin: '0 auto',
      }}
    >
      <Heading
        heading="SELECT YOUR SPORT"
        sx={{
          fontFamily: 'Roboto Condensed',
          fontSize: '36px',
          color: 'black',
          fontWeight: 'bold',
          textAlign: 'start',
          ...(isMd && {
            fontSize: '48px',
          }),
        }}
      />

      <Typography
        sx={{
          color: '#333333',
          fontSize: '16px',
          textAlign: 'start',
          marginBottom: '24px',
          ...(isMd && {
            fontSize: '18px',
          }),
        }}
      >
        Select the sport you are interested in and book your session today.
      </Typography>

      <List
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          rowGap: isMd ? '24px' : '12px',
          columnGap: isMd ? '12px' : '6px',
          justifyContent: 'center',
        }}
        // component={Grid}
        // container
        // rowGap={isMd ? "24px" : "12px"}
        // columnGap={isMd ? "12px" : "6px"}
      >
        {sports.map((sport, index) => (
          <ListItem
            key={sport.id}
            sx={{
              // width: "50% !important",
              height: '106px',
              // maxWidth: "180px !important",
              // maxHeight: "106px",
              backgroundColor: '#E0E0E0',
              flexBasis: isMd ? 'calc(25% - 12px)' : 'calc(50% - 6px)',
              transform: 'translate(0, 0) rotate(0) skewX(20deg) skewY(0) scaleX(1) scaleY(1)',
              ...(isMd && {
                // maxWidth: "300px !important",
                // maxHeight: "181px",
                // width: "300px !important",
                height: '181px',
              }),
            }}
            // component={Grid}
            // item
            // xs={isMd ? 3 : 6}
          >
            <Link
              component={ReactLink}
              to={sport.url}
              sx={{
                textDecoration: 'none',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                gap: '16px',
                marginLeft: 'auto',
                marginRight: 'auto',
                transform: 'translate(0, 0) rotate(0) skewX(-20deg) skewY(0) scaleX(1) scaleY(1)',
              }}
            >
              <Box
                component="span"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img
                  src={sport.imgUrl}
                  alt={`${sport.name} Icon`}
                  style={{
                    ...(isMd && {
                      width: '54px',
                      height: '54px',
                    }),
                  }}
                />
              </Box>
              <Typography
                component={'span'}
                sx={{
                  color: '#000',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  fontFamily: 'Roboto Condensed',
                  textTransform: 'uppercase',
                  // whiteSpace: "break",
                  ...(isMd && {
                    fontSize: '24px',
                  }),
                }}
              >
                {sport.name}
              </Typography>
            </Link>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default MakeBooking;
