import React from 'react';
import { Stack, useTheme } from '@mui/material';
import { PackageDetailsItemWrapper } from './style';

interface IProps {
  label: string;
  value: string;
  valueColor: string;
  icon: React.ReactNode;
  highlight?: boolean;
}

const PackageDetailsItem: React.FC<IProps> = ({
  label,
  value,
  valueColor,
  icon,
  highlight = false
}) => {
  const theme = useTheme();

  return (
    <PackageDetailsItemWrapper
      direction="column"
      alignItems="flex-start"
      className={highlight ? 'highlighted' : ''}
      valueColor={valueColor}
      theme={theme}
    >
      <div className="icon-wrapper">
        {icon}
      </div>
      <Stack spacing={0.5}>
        <span className="package-details-item-label">{label}</span>
        <span className="package-details-item-value">{value}</span>
      </Stack>
    </PackageDetailsItemWrapper>
  );
};

export default PackageDetailsItem; 