import React from 'react';
import CountrySelect from './CountrySelect';
import en from 'react-phone-number-input/locale/en.json';
import { getCountryCallingCode } from 'react-phone-number-input';
import { CountryCode } from 'libphonenumber-js/types';
import { Box, OutlinedInput, Typography } from '@mui/material';
import { PhoneNumberFieldWrapper } from './style';

interface IProps {
  phoneNumber: string;
  setPhoneNumber: (phoneNumber: string) => void;
  country: string;
  setCountry: (country: string) => void;
}

const PhoneNumberField: React.FC<IProps> = ({
  phoneNumber,
  setPhoneNumber,
  country,
  setCountry,
}) => {
  const onCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setCountry(event.target.value);
  };

  const onPhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value);
  };

  return (
    <PhoneNumberFieldWrapper>
      <Typography className="phone-number-label">
        Phone Number <span>*</span>
      </Typography>
      <Box className="input">
        <Box className="select-country-box">
          <img
            className="country-flag"
            alt="phone"
            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${country}.svg`}
          />
          <Typography className="country-code">
            {getCountryCallingCode(country as CountryCode)}
          </Typography>
          <CountrySelect labels={en} value={country} onChange={onCountryChange} />
        </Box>
        <OutlinedInput
          id="phone_number"
          value={phoneNumber}
          className="phone-number-input"
          onChange={onPhoneNumberChange}
        />
      </Box>
    </PhoneNumberFieldWrapper>
  );
};
export default PhoneNumberField;
