import { IconButton, Stack, useTheme } from '@mui/material';
import React from 'react';
import { PackageValidationDetailsWrapper } from './style';
import PackageDetailsItem from './PackageDetailsItem';
import { ValidationOutput } from '../../../../../hooks/usePackage';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import CloseIcon from '@mui/icons-material/Close';

interface IProps {
  currentPackageAmount: string;
  amountRedeemed: string;
  newBalance: string;
  setSelectedPackage: React.Dispatch<React.SetStateAction<string>>;
  setPackageValidationOutput: React.Dispatch<React.SetStateAction<ValidationOutput | undefined>>;
}

const PackageValidationDetails: React.FC<IProps> = ({
  currentPackageAmount,
  amountRedeemed,
  newBalance,
  setSelectedPackage,
  setPackageValidationOutput
}) => {
  const theme = useTheme();

  return (
    <PackageValidationDetailsWrapper theme={theme}>
      <div className="header">
        <div className="title">
          <div className="icon-wrapper">
            <LocalOfferOutlinedIcon />
          </div>
          Package Summary
        </div>
        <IconButton
          className="close-button"
          onClick={() => {
            setSelectedPackage('');
            setPackageValidationOutput(undefined);
          }}
          size="small"
        >
          <CloseIcon />
        </IconButton>
      </div>

      <div className="details-grid">
        <PackageDetailsItem
          label="Available"
          value={currentPackageAmount}
          valueColor={theme.palette.success.main}
          icon={<AccountBalanceWalletOutlinedIcon />}
        />

        <PackageDetailsItem
          label="To Use"
          value={amountRedeemed}
          valueColor={theme.palette.error.main}
          icon={<PaymentsOutlinedIcon />}
        />

        <PackageDetailsItem
          label="Remaining"
          value={newBalance}
          valueColor={theme.palette.primary.main}
          icon={<SavingsOutlinedIcon />}
          highlight
        />
      </div>
    </PackageValidationDetailsWrapper>
  );
};

export default PackageValidationDetails; 