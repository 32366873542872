import { Box, InputLabel, Typography } from '@mui/material';
import React from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

interface FormData {
  parentsFullName: string;
  childFullName: string;
  childAge: number;
  preferredSport: string;
  contactNumber: string;
  email: string;
  preferredDate: string;
  message: string;
}

interface BirthdayPhoneInputProps {
  control: Control<FormData, any>;
  error: FieldError | undefined;
  name: keyof FormData;
  label: string;
  dataTestId?: string;
}

const BirthdayPhoneInput: React.FC<BirthdayPhoneInputProps> = ({ 
  control, 
  error, 
  name, 
  label, 
  dataTestId 
}) => {
  return (
    <Box sx={{ marginBottom: '20px' }} data-testid={dataTestId}>
      <InputLabel
        sx={{
          fontSize: '18px',
          fontWeight: 500,
          marginBottom: '16px',
          alignSelf: 'flex-start',
          color: '#171717',
        }}
      >
        {label}
      </InputLabel>

      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <PhoneInput
            country={'ae'}
            value={value?.toString()}
            onChange={(phone) => onChange(phone)}
            buttonStyle={{
              position: 'static',
              border: '1px solid #D1D5DB',
              borderRadius: '16px',
              padding: '9px 18px',
              background: '#fff',
              boxShadow: '0 6px 40px rgba(208, 208, 208, 0.4)',
            }}
            containerStyle={{
              display: 'flex',
              flexDirection: 'row-reverse',
              gap: '14px',
              height: '56px',
            }}
            inputStyle={{
              flex: 1,
              padding: '10px 18px',
              borderRadius: '16px',
              border: '1px solid transparent',
              fontSize: '16px',
              height: '56px',
              width: '100%',
              backgroundColor: '#fff',
              boxShadow: '0 6px 40px rgba(208, 208, 208, 0.4)',
            }}
          />
        )}
      />
      {error && (
        <Typography sx={{ fontSize: '12px', color: 'red', marginTop: '8px' }}>
          {error.message}
        </Typography>
      )}
    </Box>
  );
};

export default BirthdayPhoneInput; 