import styled from '@emotion/styled';
import { Box, Button, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';

interface IProps {
  theme: Theme;
}

export const SlotsSectionWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 6px 40px rgb(208 208 208 / 20%);
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 20px;
  margin-top: 5px;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  box-shadow: none;
  background: transparent;

  .label {
    color: #33334f;
    font-size: 18px;
    font-style: normal;
    text-align: left;
    font-weight: normal;
  }
`;

export const SlotsListWrapper = styled(Box)<IProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  border-radius: 20px;
  margin-top: 8px;
  width: 100%;
  padding: 0;
  row-gap: 24px;
  column-gap: 16px;

  .slot-chip {
    width: 100%;
    padding: 11px 18px;
    margin: 0;
    box-shadow: none;
    border-radius: 12px;

    p {
      font-size: 14px;
      font-weight: 300;
    }

    &--active,
    &:hover {
      background: ${props => props.theme.palette.primary.main};
      color: #fff;
    }

    &--peak {
      background: #fde9f1;
      color: #808080;
    }
  }

  .MuiBadge-badge {
    color: #9b2263;
    font-size: 10px;
    font-weight: 500;
    transform: scale(1) translate(0%, -50%);
  }
`;

interface SlotChipWrapperProps {
  isSelected: boolean;
  theme: Theme;
}

export const SlotChipWrapper = styled(Box)<SlotChipWrapperProps>`
  &:hover {
    cursor: pointer;
  }
  box-shadow: 0px 6px 40px rgb(208 208 208 / 20%);
  border-radius: 5px;
  padding: 8px;
  margin: 5px;
  margin-top: 10px;
  color: #000000;
  font-size: 12px;
  text-align: center;
  font-family: Roboto-thin;
  font-weight: normal;
  font-style: normal;
  text-align: center;
  font-weight: normal;
  line-height: 25px;
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.palette.primary.main : '#fff'};
  color: ${({ theme, isSelected }) => (isSelected ? '#fff' : '#808080')};
  width: 100%;
  padding: 11px 18px;
  margin: 0;
  box-shadow: none;
  border-radius: 12px;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: #fff;
  }
`;

export const PopoverWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  box-shadow: 0px 6px 40px rgb(208 208 208 / 20%);

  .cart-button {
    width: 140px;
    height: 50px;
    &:hover {
      cursor: pointer;
      background-color: #5182ff;
      color: #fff;
    }
  }

  .popover-item {
    margin: 10px;
    color: #000000;
    font-size: 14px;
    text-align: center;
    font-family: Roboto-thin;
    line-height: 140%;
    &:hover {
      cursor: pointer;
      background-color: #5182ff;
      color: #fff;
    }
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 6px 40px rgb(208 208 208 / 50%);
  }
`;

export const SlotConfirmationWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;

  .cart-button {
    padding: 5px;
    background: red;
  }
`;

export const StyledBadge = styled(Box)`
  box-shadow: 0px 6px 40px 0px rgba(208, 208, 208, 0.2);
  border-radius: 12px;
`;

export const TimesControlWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
  width: 100%;
  color: #808080;

  .control-button {
    text-transform: none;
  }
`;
