import { CheckoutContextProps, CheckoutContext } from '../../../context/CheckoutContext';
import { useContext } from 'react';
import { ThemeVariant } from '../../../hooks/useCompany';
import { PricingCardProps } from './interfaces';
import PricingCardTheme2 from './variants/theme2';
import PricingCardTheme1 from './variants/theme1';

const variantComponents: Record<ThemeVariant, React.ComponentType<any>> = {
  default: PricingCardTheme1,
  theme1: PricingCardTheme1,
  theme2: PricingCardTheme2,
};

const PricingCard: React.FC<PricingCardProps> = ({ slots, timeUnit }) => {
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;
  const companyTheme = company?.theme;
  const Component = variantComponents[(companyTheme as ThemeVariant) || 'default'];
  return <Component slots={slots} timeUnit={timeUnit} />;
};

export default PricingCard;
