import { Tab, Tabs, useTheme } from '@mui/material';
import React from 'react';
import { ProfileTabsSwitcherWrapper } from './style';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import EventIcon from '@mui/icons-material/Event';
import HistoryIcon from '@mui/icons-material/History';

export interface IProps {
  activeTab: 'profile' | 'upcoming' | 'previous';
  setActiveTab: React.Dispatch<React.SetStateAction<'profile' | 'upcoming' | 'previous'>>;
}

const ProfileTabsSwitcher: React.FC<IProps> = ({ activeTab, setActiveTab }) => {
  const theme = useTheme();

  const onChangeTabHandler = (
    _: React.SyntheticEvent<Element, Event>,
    value: 'profile' | 'upcoming' | 'previous'
  ) => {
    setActiveTab(value);
  };

  return (
    <ProfileTabsSwitcherWrapper theme={theme}>
      <Tabs value={activeTab} onChange={onChangeTabHandler} variant="fullWidth">
        <Tab icon={<PersonOutlineIcon />} iconPosition="start" label="Profile" value="profile" />
        <Tab icon={<EventIcon />} iconPosition="start" label="Upcoming" value="upcoming" />
        <Tab icon={<HistoryIcon />} iconPosition="start" label="Previous" value="previous" />
      </Tabs>
    </ProfileTabsSwitcherWrapper>
  );
};

export default ProfileTabsSwitcher;
