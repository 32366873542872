import { Typography } from '@mui/material';
import React from 'react';
import { BookingDetailsItemWrapper } from './style';

interface IProps {
  label: string;
  value?: string;
}

const BookingDetailsItem: React.FC<IProps> = ({ label, value }) => {
  return (
    <BookingDetailsItemWrapper>
      <Typography className="booking-details-item-label">{label}</Typography>
      <Typography className="booking-details-item-value">{value ? value : '-'}</Typography>
    </BookingDetailsItemWrapper>
  );
};
export default BookingDetailsItem;
