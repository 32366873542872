import { useContext, useState } from 'react';
import { CheckoutContext, CheckoutContextProps } from '../../../../context/CheckoutContext';
import usePackage from '../../../../hooks/usePackage';

export const usePromoCode = (
  promocode: string,
  setPromocode: (promocode: string) => void,
  setPackageValidationOutput: (voucherValidation: any) => void
) => {
  const [isValidating, setIsValidating] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { checkPackageValidity } = usePackage();
  const { company, bookingCart } = useContext(CheckoutContext) as CheckoutContextProps;

  const onClickApply = async () => {
    try {
      setIsValidating(true);
      setIsError(false);
      const voucherValidation = await checkPackageValidity({
        companyId: company ? company.id : '',
        cartId: bookingCart.length > 0 ? bookingCart[0].cartId : '',
        voucherCode: promocode,
      });
      setPackageValidationOutput(voucherValidation);
    } catch (error: any) {
      setIsError(true);
      setErrorMessage(error.response.data.message);
      setPackageValidationOutput(undefined);
    }
    setIsValidating(false);
  };

  const onClickCancel = () => {
    setPromocode('');
    setPackageValidationOutput(undefined);
  };

  return {
    isValidating,
    isError,
    errorMessage,
    onClickApply,
    onClickCancel,
  };
};
