import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { down } from 'styled-breakpoints';
export const PhoneNumberFieldWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 15px 0px;
  flex: 1;

  span {
    color: #5182ff;
  }

  .phone-number-label {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    padding-left: 10px;
    margin-bottom: 10px;
  }

  .input {
    display: flex;
    flex-wrap: nowrap;
  }
  .phone-number-input {
    border-radius: 6px;
    // border: 2px solid #d1d5db;
    margin-left: 10px;
    width: 65%;
    color: #000;
    font-size: 16px;
    font-weight: bold;
    padding-left: 10px;

    letter-spacing: 1px;
  }

  .select-country-box {
    border: 2px solid #d1d5db;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    width: 25%;
    border-radius: 6px;
    justify-content: center;

    ${down('md')} {
      width: 35%;
    }
  }

  .country-flag {
    padding: 15px 10px;
    height: 25px;
    width: 25px;
  }

  .country-code {
    color: #171717;
  }
`;

export const CountrySelectWrapper = styled.select`
  width: 20px;
  margin: 10px;
  background: none;
  outline: none;
  border: none;
`;
