import { useTheme } from '@mui/material';
import React, { useContext } from 'react';
import { IDaySelectorProps } from './interfaces';
import { CheckoutContext } from '../../../context/CheckoutContext';
import { CheckoutContextProps } from '../../../context/CheckoutContext';
import DaySelectorTheme1 from './variants/theme1';
import DaySelectorTheme2 from './variants/theme2';
import { ThemeVariant } from '../../../hooks/useCompany';
import DaySelectorSkeleton from './loading-skeleton';

const variantComponents: Record<ThemeVariant, React.ComponentType<any>> = {
  default: DaySelectorTheme1,
  theme1: DaySelectorTheme1,
  theme2: DaySelectorTheme2,
};

const DaySelector: React.FC<IDaySelectorProps> = ({
  onlySevenDaysInAdvance,
  dayHasAvailableSlots,
  selectedWeekStartDate,
  setSelectedWeekStartDate,
  isLoading,
}) => {
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;
  const companyTheme = company?.theme;
  const Component = variantComponents[(companyTheme as ThemeVariant) || 'default'];

  if (isLoading) {
    return <DaySelectorSkeleton />;
  }

  return (
    <Component
      onlySevenDaysInAdvance={onlySevenDaysInAdvance}
      dayHasAvailableSlots={dayHasAvailableSlots}
      selectedWeekStartDate={selectedWeekStartDate}
      setSelectedWeekStartDate={setSelectedWeekStartDate}
    />
  );
};

export default DaySelector;
