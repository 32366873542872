import React from 'react';
import { Box, SelectChangeEvent } from '@mui/material';
import { IServiceSelectorProps } from '../../interfaces';
import { useServiceSelector } from '../../hooks/useServiceSelector';
import { ServiceSelectorTheme2Wrapper, StyledSelect, StyledMenuItem } from './style';
import SportsIcon from '@mui/icons-material/Sports';
import ResourcesList from './ResourcesList';

const ServiceSelectorTheme2: React.FC<IServiceSelectorProps> = ({
  type,
  subType,
  serviceId,
  branchId,
  resourceId,
  sportId,
  uponSelectCallback,
}) => {
  const {
    servicesOptions,
    resourceOptions,
    onServiceChange,
    onResourceChange,
    selectedResource,
    selectedService,
    selectedServiceData,
    showResourcesSelector,
  } = useServiceSelector(
    type,
    subType,
    serviceId,
    branchId,
    resourceId,
    sportId,
    uponSelectCallback
  );

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const syntheticEvent = {
      ...event,
      nativeEvent: event as unknown as Event,
      isDefaultPrevented: () => false,
      isPropagationStopped: () => false,
      persist: () => {},
    } as React.SyntheticEvent;

    onServiceChange(syntheticEvent, event.target.value);
  };

  return (
    <>
      <ServiceSelectorTheme2Wrapper backgroundImage={selectedServiceData?.image}>
        <StyledSelect
          value={selectedService || ''}
          onChange={handleSelectChange}
          displayEmpty
          renderValue={(value: string) => (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <SportsIcon />
              <span>
                {value
                  ? servicesOptions.find(opt => opt.value === value)?.label
                  : 'Select a service'}
              </span>
            </Box>
          )}
          MenuProps={{
            PaperProps: {
              sx: {
                mt: 1,
                borderRadius: '16px',
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
              },
            },
          }}
        >
          {servicesOptions.map(option => (
            <StyledMenuItem key={option.value} value={option.value}>
              {option.label}
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </ServiceSelectorTheme2Wrapper>

      {showResourcesSelector && (
        <ResourcesList
          resourceOptions={resourceOptions}
          selectedResource={selectedResource}
          onResourceChange={onResourceChange}
        />
      )}
    </>
  );
};

export default ServiceSelectorTheme2;
