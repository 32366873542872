import styled from '@emotion/styled';
import { Box, Button, Tab, Tabs, Typography, IconButton, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Theme } from '@mui/material/styles';

export const AuthenticationFormWrapper = styled(Box)`
  width: 100%;
  transition: all 0.3s ease;
`;

export const AuthenticationFormTabsWrapper = styled(Box)`
  width: 100%;
  margin-bottom: 24px;
`;

export const MainContainer = styled(Box)`
  background-color: white;
  border-radius: 16px;
  width: 100%;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
  
  &:hover {
    box-shadow: 0 12px 32px rgba(0, 0, 0, 0.12);
  }
`;

export const FormContainer = styled('form')`
  width: 100%;
  padding: 40px;
  padding-bottom: 24px;
  max-width: 570px;
  margin: 0 auto;

  @media (min-width: 600px) {
    padding: 56px;
    padding-bottom: 32px;
  }
`;

export const StyledTab = styled(Tab)<{ theme: Theme }>`
  text-transform: uppercase;
  color: #666;
  font-family: Roboto Condensed;
  font-size: 16px;
  font-weight: 600;
  padding: 16px;
  width: 50%;
  max-width: 385px;
  border-bottom: 2px solid #eee;
  transition: all 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
    background-color: rgba(0, 0, 0, 0.02);
  }

  &.Mui-selected {
    color: ${({ theme }) => `${theme.palette.primary.main} !important`};
    border-bottom: 2px solid ${({ theme }) => theme.palette.primary.main};
  }

  @media (min-width: 600px) {
    font-size: 18px;
  }
`;

export const StyledLoadingButton = styled(LoadingButton)<{ theme: Theme }>`
  width: 100%;
  height: 40px;
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-radius: 8px;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  position: relative;
  overflow: hidden;

  @media (min-width: 600px) {
    font-size: 16px;
    height: 48px;
  }

  &:hover {
    background-color: ${({ theme }) => `${theme.palette.primary.dark} !important`};
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }

  &:active {
    transform: translateY(0);
  }

  &.MuiLoadingButton-loading {
    background-color: ${({ theme }) => `${theme.palette.primary.main} !important`};
    opacity: 0.9;

    .MuiLoadingButton-loadingIndicator {
      color: rgba(255, 255, 255, 0.8);
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      transition: all 0.3s ease;
    }

    .MuiButton-startIcon,
    .MuiButton-endIcon {
      opacity: 0;
    }
  }

  .MuiLoadingButton-loadingIndicator {
    top: 50%;
    transform: translateY(-50%);

    circle {
      stroke-width: 3;
    }
  }

  .MuiCircularProgress-root {
    width: 24px !important;
    height: 24px !important;
  }

  &:disabled {
    background-color: rgba(0, 0, 0, 0.12);
    color: rgba(255, 255, 255, 0.8);
  }
`;

export const FormDescription = styled(Typography)<{ theme: Theme }>`
  font-size: 13px;
  color: #666;
  text-align: center;
  margin-bottom: 24px;
  line-height: 1.6;

  @media (min-width: 600px) {
    font-size: 15px;
  }
`;

export const ForgotPasswordButton = styled(Button)<{ theme: Theme }>`
  padding: 8px 16px;
  text-transform: none;
  color: ${props => (props.disabled ? '#999' : '#666')};
  font-size: 13px;
  margin-bottom: 24px;
  transition: all 0.3s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    color: ${({ theme }) => theme.palette.primary.main};
  }

  @media (min-width: 600px) {
    font-size: 14px;
  }
`;

export const SocialDivider = styled(Divider)<{ theme: Theme }>`
  color: #888;
  font-size: 13px;
  padding: 0 47px;
  max-width: 570px;
  margin: 32px auto;
  
  &::before, &::after {
    border-color: #eee;
  }
`;

export const SocialContainer = styled(Box)<{ theme: Theme }>`
  width: 100%;
  padding: 0 47px;
  max-width: 570px;
  margin: 24px auto;
  display: flex;
  justify-content: center;
  gap: 16px;
`;

export const SocialIconButton = styled(IconButton)<{ theme: Theme }>`
  position: relative;
  border-radius: 50%;
  margin: ${({ theme }) => theme.spacing(0.5)};
  transition: all 0.3s ease;
  width: 48px;
  height: 48px;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  @media (min-width: 600px) {
    width: 56px;
    height: 56px;
  }
`;

export const SocialIconImage = styled('img')<{ theme: Theme }>`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: all 0.3s ease;
`;

export const AppleIconOverlay = styled('span')<{ theme: Theme }>`
  display: block;
  width: 48px;
  height: 48px;
  position: absolute;
  border: 12px solid white;
  border-radius: 50%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.3s ease;

  @media (min-width: 600px) {
    width: 56px;
    height: 56px;
    border-width: 14px;
  }
`;