import React from 'react';
import { Box } from '@mui/system';
import { CircularProgress, IconButton, Typography } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { formatPriceValue, formatStringTime } from '../../../../../../Utils/format';
import { CartBookingCardRowWrapper } from './style';
import { useBookingCard } from '../../../hooks/useBookingCard';
import { IBookingCardRowProps } from '../../../interfaces';
import CancelIcon from '@mui/icons-material/Cancel';

const BookingCardRow: React.FC<IBookingCardRowProps> = ({
  booking,
  showRemoveFromCartOption,
  showCancelOption,
  onCancelBookingHandler,
}) => {
  const { handleRemoveFromCart, isCartLoading, company, handleCancel } = useBookingCard(
    booking,
    onCancelBookingHandler
  );

  return (
    <CartBookingCardRowWrapper>
      <Box className="time-duration">
        <Typography>{formatStringTime(booking.startTime)}</Typography>
        {booking.serviceDuration?.durationTime && (
          <Typography>{booking.serviceDuration.durationTime} Min</Typography>
        )}
      </Box>

      <Typography className="price" data-testid="booking-card-row-price">
        {formatPriceValue(booking.price, company?.currency)}
      </Typography>

      {showCancelOption && (
        <IconButton
          onClick={handleCancel}
          size="small"
          className="remove-button"
          data-testid="booking-card-row-remove-button"
        >
          <CancelIcon />
        </IconButton>
      )}

      {showRemoveFromCartOption && (
        <Box className="remove-button" data-testid="booking-card-row-remove-button">
          {isCartLoading ? (
            <CircularProgress />
          ) : (
            <DeleteOutlineOutlinedIcon onClick={handleRemoveFromCart} data-testid="delete-icon" />
          )}
        </Box>
      )}
    </CartBookingCardRowWrapper>
  );
};

export default BookingCardRow;
