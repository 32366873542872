import styled from '@emotion/styled';
import { down } from 'styled-breakpoints';
export const CartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  // width: 80%;
  width: 100%;
  text-align: center;

  ${down('sm')} {
    width: 100%;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    // margin: 10px;

    .details {
      // width: 90%;
      width: 100%;
    }
  }
`;

export const CartCheckoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 8px;

  .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    // width: 90%;
    width: 100%;
    margin-bottom: 15px;
  }
`;

export const AddOnsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 80%;

  ${down('sm')} {
    width: 95%;
  }

  .section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .equipment-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .equipment-header {
      color: #000000;
      font-size: 20px;
      text-align: center;
      font-weight: normal;
      line-height: 140%;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .products-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 15px;
    width: 100%;

    .products-section-header {
      color: #000000;
      font-size: 19px;
      text-align: center;
      font-weight: normal;
      line-height: 140%;
      margin-bottom: 10px;
    }

    .products-section-description {
      color: #000000;
      font-size: 17px;
      text-align: center;
      font-family: Roboto-thin;
      font-weight: normal;
    }

    .products-section-items {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(24%, 1fr));
      grid-auto-rows: 1fr;
      margin-top: 15px;
      width: 100%;

      .products-section-item {
        margin: 5px;
      }

      ${down('sm')} {
        grid-template-columns: repeat(auto-fill, minmax(49%, 1fr));
      }
    }
  }

  .addon-buttons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    margin-top: 15px;

    Button {
      margin-right: 15px;
      margin-top: 20px;
    }
  }

  &.equipment-container {
    // width: calc(100% - 48px);

    width: 100%;

    .section {
      width: 100%;
    }

    .skip-button {
      margin-left: auto;
    }

    .add-equipment {
      margin-top: 42px;
      margin-bottom: 24px;
    }

    .items-container {
      row-gap: 34px;
      column-gap: 5px;
    }
  }
`;
