import React from 'react';
import { Box, Typography } from '@mui/material';
import { formatPriceValue, getCurrency } from '../../../../../Utils/format';
import { IPricingCardRowProps } from '../../interfaces';

const PriceRow: React.FC<IPricingCardRowProps> = ({ label, value, isDiscount = false }) => {
  const formattedValue = isDiscount ? `${value} ${getCurrency()}` : formatPriceValue(value);

  return (
    <Box className="price-row">
      <Typography className="label">{label}</Typography>
      <Typography className="value">{formattedValue}</Typography>
    </Box>
  );
};

export default PriceRow;
