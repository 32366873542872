import { Company } from '../hooks/useCompany';
import { Booking } from '../hooks/useCart';
import { SESSION_DURATION } from './constants';
import { convertMinutesToHours, getFormattedTime } from './format';
import { UserInfo } from 'firebase/auth';

export const extractBookingDetails = (searchParams: URLSearchParams) => {
  const companyName = searchParams.get('companyName');
  const locationName = searchParams.get('location');
  const serviceName = searchParams.get('serviceName');
  const price = searchParams.get('price');
  const bookingId = searchParams.get('bookingId');

  const date = getFormattedTime(searchParams.get('date')!, 'dddd, MMMM DD', true);
  console.log('startTime', searchParams.get('startTime'));
  const startTime = searchParams.get('startTime')!;
  const duration = convertMinutesToHours(Number(searchParams.get('durationInMinutes')));
  const quantity = searchParams.get('quantity');

  return {
    companyName,
    locationName,
    serviceName,
    date,
    startTime,
    duration,
    price,
    bookingId,
    quantity,
  };
};

export const extractPackageDetails = (searchParams: URLSearchParams) => {
  const numOfSessions = `${Number(searchParams.get('duration')) / SESSION_DURATION}`;
  const customerEmail = searchParams.get('customerEmail');
  const packageCode = searchParams.get('voucherCode');

  const type = searchParams.get('type');
  return {
    numOfSessions,
    customerEmail,
    packageCode,
    type,
  };
};

export const extractPackageCancellationDetails = (searchParams: URLSearchParams) => {
  const numOfSessions = searchParams.get('numberOfSessions')!;
  const price = searchParams.get('price')!;
  const packageCode = searchParams.get('voucherCode')!;
  const companyId = searchParams.get('companyId')!;
  const companyName = searchParams.get('companyName')!;
  return {
    numOfSessions,
    price,
    packageCode,
    companyId,
    companyName,
  };
};

export const extractCartBookings = (searchParams: URLSearchParams) => {
  const bookings = JSON.parse(`[${searchParams.get('bookings')}]`);
  const mappedBookings: Booking[] = bookings.map((booking: any) => ({
    startTime: booking.startTime,
    endTime: booking.endTime,
    price: booking.price,
    service: {
      serviceName: booking.serviceName,
    },
    serviceDuration: {
      durationTime: booking.durationTime,
    },
    quantity: booking.quantity,
  }));

  return mappedBookings;
};

export const getResourceDisclaimer = (company: Company, selectedResource: string) => {
  const resource = company?.resourcesInformation?.find(
    resource => resource.resourceId === selectedResource
  );
  return resource?.disclaimer;
};

export const getTimeUnit = (company: Company, selectedResource: string) => {
  const resource = company?.resourcesInformation?.find(
    resource => resource.resourceId === selectedResource
  );
  return resource?.timeUnit || company?.timeUnit || 'hour';
};

export const getPrice = (price: string) => {
  return Number(price.split(' ')[0]);
};

export const extractUserInfo = (providerData: UserInfo) => {
  const displayName = providerData.displayName?.split(' ');
  const firstName = displayName && displayName?.length > 0 ? displayName[0] : '';

  const lastName = displayName && displayName?.length > 1 ? displayName[1] : '';

  const email = providerData.email || '';

  const phoneNumber = providerData.phoneNumber || '';

  return {
    firstName,
    lastName,
    email,
    phoneNumber,
  };
};
