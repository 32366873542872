import {
  Box,
  Button,
  Checkbox,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { IFormSelectProps } from './Types';
const FormSelect: React.FC<IFormSelectProps> = ({
  id,
  options,
  placeholder,
  register,
  error,
  sx,
  label,
  multi = false,
  value,
}) => {
  const theme = useTheme();

  const [open, setOpen] = useState(false);

  const getMultipleSelectText = () => {
    if (multi)
      return (
        <Typography component={'span'} sx={{ fontSize: '12px', color: '#9CA3AF', fontWeight: 100 }}>
          (Select all that apply)
        </Typography>
      );
  };

  return (
    <Box>
      {label && (
        <InputLabel
          sx={{
            fontSize: '18px',
            fontWeight: 500,
            marginBottom: '16px',
            alignSelf: 'flex-start',
            color: '#171717',
            display: 'flex',
            flexWrap: 'wrap',
            gap: '8px',
            alignItems: 'center',
            whiteSpace: 'normal',
          }}
        >
          <Typography component={'span'}>{label}</Typography>
          {getMultipleSelectText()}
        </InputLabel>
      )}
      <Select
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        placeholder={placeholder}
        error={!!error}
        id={id}
        labelId={id}
        sx={{
          border: 0,
          boxShadow: '0 6px 40px rgba(208, 208, 208, 0.2)',
          borderRadius: '8px',
          pl: 2,
          fontSize: '10px',
          fontWeight: '100',
          '&::before': {
            content: 'none',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 0,
          },
          '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 0,
          },
          ...sx,
        }}
        defaultValue={multi ? [] : ''}
        {...register}
        renderValue={selected => {
          return Array.isArray(selected)
            ? selected.filter(val => val !== undefined).join(', ')
            : options.find(option => option.value === selected)?.label;
        }}
        multiple={multi}
      >
        {options.map(option => (
          <MenuItem value={option.value} disabled={option.value === ''} key={option.value}>
            {multi && (
              <Checkbox checked={Array.isArray(value) && value.indexOf(`${option.value}`) > -1} />
            )}
            {option.label}
          </MenuItem>
        ))}

        {multi && (
          <MenuItem>
            <Button variant="contained" color="error" onClick={() => setOpen(false)}>
              Close
            </Button>
          </MenuItem>
        )}
      </Select>
      {error && (
        <Typography sx={{ fontSize: '12px', color: 'red', marginTop: '8px' }}>
          {error.message as string}
        </Typography>
      )}
    </Box>
  );
};

export default React.memo(FormSelect);
