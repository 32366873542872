import React, { useContext } from 'react';
import { CheckoutContext, CheckoutContextProps } from '../../../../../context/CheckoutContext';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { getCurrency } from '../../../../../Utils/format';
import { AvailabilitySlot } from '../../../../../hooks/useAvailableStartingTimes';
import { DUBAI_FITNESS_CHALLENGE_COMPANY_ID } from '../../../../../Utils/constants';
import { useTheme } from '@mui/material/styles';
import { SlotConfirmationWrapper, AddToCartButton } from './style';

interface IProps {
  handleAddToCart: (slot: AvailabilitySlot) => void;
  selectedSlot?: AvailabilitySlot;
}

const SlotConfirmation: React.FC<IProps> = ({ handleAddToCart, selectedSlot }) => {
  const { company, quantity, bookingCart } = useContext(CheckoutContext) as CheckoutContextProps;
  const theme = useTheme();

  const totalPrice = selectedSlot?.price! * quantity;
  const showAddToCartButton = !(
    company?.id === DUBAI_FITNESS_CHALLENGE_COMPANY_ID && bookingCart.length > 0
  );

  if (!showAddToCartButton) return null;

  const buttonText = selectedSlot
    ? `Add to Cart - ${totalPrice === 0 ? 'Free' : `${totalPrice} ${getCurrency(company?.currency)}`}`
    : 'Select a Time Slot';

  return (
    <SlotConfirmationWrapper>
      <AddToCartButton
        theme={theme}
        variant="contained"
        startIcon={<AddShoppingCartIcon />}
        onClick={() => selectedSlot && handleAddToCart(selectedSlot)}
        disabled={!selectedSlot}
      >
        {buttonText}
      </AddToCartButton>
    </SlotConfirmationWrapper>
  );
};

export default SlotConfirmation;
