import { useNavigate, useSearchParams } from 'react-router-dom';
import PrimaryBtn from '../../components/FormCollection/PrimaryBtn';
import { Box, Typography } from '@mui/material';
import { CheckoutContext, CheckoutContextProps } from '../../context/CheckoutContext';
import { useContext } from 'react';
import BackToHomeButton from '../../components/shared/BackToHomeButton';

const EmailVerification: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;

  return (
    <Box width={'90%'} marginTop={'20px'}>
      <Typography
        style={{
          fontSize: '18px',
          fontStyle: 'normal',
          textAlign: 'center',
          fontWeight: 500,
        }}
      >
        {`Almost there, we’ve sent an email to “${email}”. Please
      click on the link in the email to complete your registration. If you can’t
      see the email in your inbox, please check your spam folder.`}
      </Typography>
      <BackToHomeButton />
    </Box>
  );
};

export default EmailVerification;
