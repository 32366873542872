import { styled } from '@mui/material/styles';
import { Card, Typography, Box } from '@mui/material';

export const StyledPricingCard = styled(Card)(({ theme }) => ({
  width: '100%',
  padding: '24px',
  borderRadius: '20px',
  background: 'linear-gradient(145deg, #ffffff 0%, #f8f9ff 100%)',
  border: `1px solid ${theme.palette.divider}`,
  marginTop: '24px',
  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
  '&:hover': {
    boxShadow: '0 8px 45px rgba(0, 0, 0, 0.07)',
  },
}));

export const CardTitle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  color: theme.palette.text.primary,
  fontWeight: 600,
  marginBottom: theme.spacing(2),
  '& svg': {
    color: theme.palette.primary.main,
    fontSize: 24,
  },
}));

export const StyledPricingRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: theme.spacing(0.75),
  paddingBottom: theme.spacing(0.75),
}));

interface RowLabelProps {
  isTotal?: boolean;
}

export const RowLabel = styled(Typography, {
  shouldForwardProp: prop => prop !== 'isTotal',
})<RowLabelProps>(({ theme, isTotal }) => ({
  color: isTotal ? theme.palette.text.primary : theme.palette.text.secondary,
  fontWeight: isTotal ? 600 : 500,
  fontSize: isTotal ? '1.1rem' : '1rem',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  '& svg': {
    fontSize: 18,
    color: theme.palette.error.main,
  },
}));

interface RowValueProps {
  isTotal?: boolean;
  isDiscount?: boolean;
}

export const RowValue = styled(Typography, {
  shouldForwardProp: prop => prop !== 'isTotal' && prop !== 'isDiscount',
})<RowValueProps>(({ theme, isTotal, isDiscount }) => ({
  color: isDiscount
    ? theme.palette.error.main
    : isTotal
      ? theme.palette.text.primary
      : theme.palette.text.secondary,
  fontWeight: isTotal ? 600 : 500,
  fontSize: isTotal ? '1.1rem' : '1rem',
  ...(isDiscount && {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    '&::before': {
      content: '"-"',
      color: theme.palette.error.main,
    },
  }),
}));
