import { Box, Typography } from '@mui/material';
import { Dayjs } from 'dayjs';
import React from 'react';
import { DayChipWrapper } from './style';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

interface IProps {
  day: Dayjs;
  isSelected: boolean;
  hasAvailableSlots: boolean;
  onDayClick: () => void;
}
export const DayChipTheme1: React.FC<IProps> = ({
  day,
  isSelected,
  hasAvailableSlots,
  onDayClick,
}) => {
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <DayChipWrapper
        onClick={onDayClick}
        isSelected={isSelected}
        className={`day-chip ${isSelected ? 'day-chip--selected' : ''}`}
      >
        <Typography
          sx={{
            marginBottom: '12px',
            fontFamily: 'Inter',
            fontSize: '18px !important',
            fontWeight: 500,
            lineHeight: '25.2px',
          }}
        >
          {day.format('ddd')}
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Inter',
            fontSize: '18px !important',
            fontWeight: 500,
            lineHeight: '25.2px',
          }}
        >
          {day.format('D')}
        </Typography>
      </DayChipWrapper>

      {hasAvailableSlots && (
        <FiberManualRecordIcon
          color="success"
          style={{
            fontSize: '12px',
            marginLeft: '-12px',
            marginTop: '5px',
          }}
        />
      )}
    </Box>
  );
};

export default DayChipTheme1;
