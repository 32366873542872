import React from 'react';
import { SessionCardDateProps } from './Type';
import { Stack, Typography, useTheme } from '@mui/material';
import dayjs from 'dayjs';

const DateCard: React.FC<SessionCardDateProps> = ({ day, month }) => {
  const theme = useTheme();
  return (
    <Stack
      style={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        padding: '20px 14px',
        textAlign: 'center',
        justifyContent: 'center',
        fontWeight: 'bold',
        alignSelf: 'stretch',
        borderRadius: '8px',
      }}
    >
      <Typography sx={{ fontSize: '24px', fontWeight: 500 }}>
        {dayjs(`2022/${month}/${day}`).format('MMM')}
      </Typography>
      <Typography sx={{ fontSize: '24px', fontWeight: 500 }}>{day}</Typography>
    </Stack>
  );
};

export default DateCard;
