import React from 'react';
import { CartTotalProps } from './Types';
import { Card, Typography } from '@mui/material';

const CartTotal: React.FC<CartTotalProps> = ({ title, value, currency }) => {
  return (
    <Card
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: '19px 10px',
        boxShadow: '0 6px 40px rgba(208, 208, 208, 0.2)',
        borderRadius: '12px',
      }}
    >
      <Typography variant="h5" gutterBottom sx={{ fontSize: '24px', color: '#253858' }}>
        {title}
      </Typography>
      <Typography component={'span'} sx={{ color: '#5182FF', fontWeight: 'bold' }}>
        {value === 0 ? 'Free' : `${value} ${currency}`}
      </Typography>
    </Card>
  );
};

export default CartTotal;
