import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import useAvailableStartingTimes, {
  AvailableStartingTimesOutput,
} from '../../hooks/useAvailableStartingTimes';
import DaySelector from '../../components/Calendar/DaySelector';
import { CalendarWrapper } from './style';
import { CheckoutContext, CheckoutContextProps } from '../../context/CheckoutContext';
import ServiceSelector from '../../components/Calendar/ServiceSelector';
import QuantitySelector from '../../components/Calendar/QuantitySelector';
import useTitle from '../../hooks/useTitle';
import useCart from '../../hooks/useCart';
import useGTMDataLayer from '../../hooks/useGTMDataLayer';
import CustomFooter from '../../components/shared/footer/CustomFooter';
import { useDaySelection } from '../../hooks/useDaySelection';
import { DUBAI_FITNESS_CHALLENGE_COMPANY_ID } from '../../Utils/constants';
import DFCFooter from '../../components/shared/footer/DFCFooter';
import { useDaysHasAvailabileSlots } from '../../hooks/useDaysHasAvailabileSlots';
import TimeSelector from '../../components/Calendar/TimeSelector';

interface IProps {
  title: string;
}

const Calendar: React.FC<IProps> = ({ title }) => {
  useTitle(title);

  const { selectedWeekStartDate, setSelectedWeekStartDate } = useDaySelection();

  const {
    company,
    selectedBranch,
    selectedService,
    selectedResource,
    selectedDay,
    setSelectedDay,
    isAllResourcesOptionEnabled,
    setShouldShowServiceDropdown,
    setIsAllResourcesOptionEnabled,
    bookingCart,
    branchesMap,
    servicesMap,
    resourcesMap,
    availableStartingTimesResource,
    isCartLoading,
    setMarketplaceName,
    marketplaceCompany,
  } = useContext(CheckoutContext) as CheckoutContextProps;

  const [searchParams] = useSearchParams();
  const branchId = searchParams.get('branchId');
  const serviceId = searchParams.get('serviceId');
  const resourceId = searchParams.get('resourceId');
  const sportId = searchParams.get('sportId');

  useEffect(() => {
    setShouldShowServiceDropdown(!!serviceId);
    setIsAllResourcesOptionEnabled(resourceId === null);
  }, []);

  const { addToCart } = useCart();

  const { loading: isLoadingAvailabilitySlots } = useAvailableStartingTimes({
    companyId: company?.id,
    branchId: selectedBranch,
    serviceId: selectedService,
    selectedDay,
    selectedResource,
    isAllResourcesOptionEnabled,
    bookingCart,
    selectedWeekStartDate,
  });

  const service = servicesMap?.get(selectedService);

  console.log('[Calendar] selectedDay', selectedDay);

  useGTMDataLayer('view_item_list');

  const { daysHasAvailableSlots } = useDaysHasAvailabileSlots(selectedWeekStartDate);

  return (
    <>
      <CalendarWrapper>
        <ServiceSelector
          type="standard"
          branchId={branchId || ''}
          serviceId={serviceId || ''}
          resourceId={resourceId || ''}
          sportId={sportId || ''}
        />

        <DaySelector
          onlySevenDaysInAdvance={false}
          dayHasAvailableSlots={daysHasAvailableSlots}
          selectedWeekStartDate={selectedWeekStartDate}
          setSelectedWeekStartDate={setSelectedWeekStartDate}
        />

        {service?.maxQuantity && service.maxQuantity > 1 && <QuantitySelector />}

        <TimeSelector
          availableStartingTimes={availableStartingTimesResource!}
          isLoadingAvailabilitySlots={isLoadingAvailabilitySlots || isCartLoading}
          timeUnit={company?.timeUnit == 'hour' ? 'Hour' : 'Session'}
          showDurationFilter={true}
        />

        {company?.hasFooter && <CustomFooter company={marketplaceCompany || company} />}

        {company?.id === DUBAI_FITNESS_CHALLENGE_COMPANY_ID && <DFCFooter />}
      </CalendarWrapper>
    </>
  );
};

export default Calendar;
