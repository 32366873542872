import styled from '@emotion/styled';

export const CompanySelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  margin-top: 15px;
  margin-bottom: 30px;

  box-shadow: 0px 6px 40px 0px rgba(208, 208, 208, 0.2);
  border-radius: 16px;
  background-color: #fff;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    width: 100%;

    .description {
      display: flex;
      flex-direction: column;
      align-items: start;

      .location {
        font-family: 'Roboto-medium';
        color: #808080;
        margin-top: 6px;
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
        text-align: left;
      }
    }

    .companyName {
      font-family: 'Roboto-medium';
      color: #808080;
      font-size: 24px;
      font-weight: 500;
      line-height: 28.8px;
      text-align: center;
    }
  }

  .availability {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 12px;

    .price {
      text-align: center;
      margin-top: 10px;
      color: #808080;
    }

    .slotsList {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      width: 100%;
      background: #ffffff;
      // box-shadow: 0px 6px 40px rgb(208 208 208 / 20%);
      border-radius: 16px;
      padding: 0;
      margin-bottom: 20px;
      margin-top: 15px;
    }
  }
`;
