import { Box, styled } from '@mui/material';

export const PackageSelectorWrapper = styled(Box)`
  margin-top: 10px;
  width: 100%;
  padding: 0 16px;

  .select {
    width: 100%;
  }
`;
