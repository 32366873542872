import { Stack, Typography } from '@mui/material';
import Heading from '../../components/shared/Heading';
import useTitle from '../../hooks/useTitle';

interface props {
  title?: string;
}
const ConsentForm: React.FC<props> = ({ title }) => {
  useTitle(title);
  return (
    <Stack sx={{ marginTop: 2, gap: 3 }}>
      <Heading heading="Consent Form" />

      <Stack>
        <Typography>
          I, the parent or legal guardian or as duly authorized agent on behalf of the parent or
          legal guardian of the child/ children listed in Dubai Sports World application, hereby
          accept for my child to play in Dubai Kids World, a play area in Dubai Sports World.
        </Typography>
        <Typography>
          I hereby acknowledge and understand that there are certain risks of injury inherent in the
          play in Dubai Kids World. Such risks could include, but are not limited to, cuts and
          bruises, falls, broken bones, injuries to wrists, arms, legs, ankles, backs, heads and
          necks, injuries from flips or contact with other participants, spinal injuries other
          serious injuries, permanent disability and/or even death. I hereby certify that my child
          is fully capable of playing in Dubai Kids World and that my child is healthy.
        </Typography>
        <Typography>
          In addition to the above, I hereby waive any and all claims, suits or demands arising out
          of, and the right to sue Dubai World Trade Centre LLC (“DWTC”) for, and release and
          discharge DWTC from: (i) any liability for any personal injury or death in any way
          whatsoever caused by or relating to my child’s participation in Dubai Kids World howsoever
          caused; and (ii) any property damage or other loss, including but not limited to any and
          all negligence, negligent supervision and/or negligent instruction, faulty equipment, or
          the activities of others.
        </Typography>

        <Typography>
          To the fullest extent permitted by law, I hereby indemnify, defend and hold harmless DWTC
          against all and any losses, claims, proceedings, damages, liabilities (whether joint or
          several), costs and expenses (including reasonable legal fees), whether as a result of my
          or my child’s own negligence, omission, act, conduct or behavior otherwise whilst playing
          in Dubai Kids World and/or arising in connection with or as a result of making a claim
          against DWTC despite the waiver, release and discharge I have given.
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ConsentForm;
