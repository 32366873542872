import React, { useContext, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { 
  Stack, 
  TextField, 
  Typography, 
  Button, 
  CircularProgress, 
  Paper, 
  Container, 
  Box,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  FormHelperText
} from '@mui/material';
import Heading from '../../components/shared/Heading';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosInstance from '../../services/axiosInstance';
import FormSelect from '../../components/FormCollection/FormSelect';
import { danubeSports } from '../../components/register/completeProfile/data';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { CheckoutContext, CheckoutContextProps } from '../../context/CheckoutContext';
import useTitle from '../../hooks/useTitle';
import BirthdayPhoneInput from '../../components/FormCollection/BirthdayPhoneInput';
import 'react-phone-input-2/lib/style.css';

// Shared styles for form elements
const sharedInputStyles = {
  '& .MuiOutlinedInput-root': {
    height: '56px',
    borderRadius: '16px',
    backgroundColor: '#fff',
    boxShadow: '0 6px 40px rgba(208, 208, 208, 0.4)',
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#2196F3',
      },
    },
    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#2196F3',
      },
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  '& .MuiInputLabel-root': {
    color: '#666',
    '&.Mui-focused': {
      color: '#2196F3',
    },
  },
};

const multilineInputStyles = {
  '& .MuiOutlinedInput-root': {
    height: 'auto',
    borderRadius: '16px',
    backgroundColor: '#fff',
    boxShadow: '0 6px 40px rgba(208, 208, 208, 0.4)',
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#2196F3',
      },
    },
    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#2196F3',
      },
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
};

// Define Yup schema
const useYupSchema = (bookingRangeStart: Date, bookingRangeEnd: Date) =>
  yup.object().shape({
    parentsFullName: yup.string().required('Parents full name is required'),
    childFullName: yup.string().required('Child\'s full name is required'),
    childAge: yup
      .number()
      .positive('Age must be positive')
      .integer('Age must be a whole number')
      .required('Child\'s age is required'),
    preferredSport: yup.string().required('Preferred sport is required'),
    contactNumber: yup.string().required('Contact number is required'),
    email: yup.string().email('Invalid email').required('Email is required'),
    preferredDate: yup
      .date()
      .min(bookingRangeStart, `Date must be after ${dayjs(bookingRangeStart).format('DD/MM/YYYY')}`)
      .max(bookingRangeEnd, `Date must be before ${dayjs(bookingRangeEnd).format('DD/MM/YYYY')}`)
      .required('Preferred date is required'),
    message: yup.string(),
  });

type FormData = {
  parentsFullName: string;
  childFullName: string;
  childAge: number;
  preferredSport: string;
  contactNumber: string;
  email: string;
  preferredDate: string;
  message: string;
};

interface props {
  title?: string;
}

const BirthdayCelebrationForm: React.FC<props> = ({ title }) => {
  useTitle(title);
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;

  const bookingRangeStart = company?.bookingRange?.start
    ? dayjs(company.bookingRange.start, 'YYYYMMDD').toDate()
    : dayjs().toDate();
  const bookingRangeEnd = company?.bookingRange?.end
    ? dayjs(company.bookingRange.end, 'YYYYMMDD').toDate()
    : dayjs().add(3, 'month').toDate();

  const validationSchema = useYupSchema(bookingRangeStart!, bookingRangeEnd!);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
  });

  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: FormData) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post('booking/dswBirthdayEnquiry', {...data, companyId: company?.id});
      toast('Your birthday celebration booking has been successfully submitted!', {
        theme: 'colored',
        type: 'success',
      });
      reset();
    } catch (error) {
      console.error(error);
      toast('An error occurred while submitting your booking. Please try again.', {
        theme: 'colored',
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Paper 
        elevation={0}
        sx={{ 
          padding: { xs: 2, sm: 4 }, 
          marginTop: 4, 
          marginBottom: 4, 
          borderRadius: '24px',
          background: '#fff',
          boxShadow: '0 6px 40px rgba(208, 208, 208, 0.4)',
        }}
      >
        <Stack spacing={4}>
          <Box textAlign="center">
            <Heading heading={"Birthday Celebration"} />
            <Typography 
              variant="subtitle1" 
              sx={{ 
                mt: 1,
                color: '#666',
                fontSize: '1.1rem',
                fontWeight: 400
              }}
            >
              Fill out the form below to book a memorable birthday celebration
            </Typography>
          </Box>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              {/* Parents Full Name */}
              <Controller
                name="parentsFullName"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Parents Full Name"
                    variant="outlined"
                    fullWidth
                    error={!!errors.parentsFullName}
                    helperText={errors.parentsFullName?.message}
                    sx={sharedInputStyles}
                  />
                )}
              />

              {/* Child's Full Name */}
              <Controller
                name="childFullName"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Child's Full Name"
                    variant="outlined"
                    fullWidth
                    error={!!errors.childFullName}
                    helperText={errors.childFullName?.message}
                    sx={sharedInputStyles}
                  />
                )}
              />

              {/* Child's Age */}
              <Controller
                name="childAge"
                control={control}
                defaultValue={undefined}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Child's Age"
                    type="number"
                    variant="outlined"
                    fullWidth
                    error={!!errors.childAge}
                    helperText={errors.childAge?.message}
                    sx={sharedInputStyles}
                  />
                )}
              />

              {/* Preferred Sport */}
              <Controller
                name="preferredSport"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <FormControl fullWidth error={!!errors.preferredSport}>
                    <InputLabel id="preferred-sport-label">Preferred Sport</InputLabel>
                    <Select
                      {...field}
                      labelId="preferred-sport-label"
                      label="Preferred Sport"
                      sx={{
                        height: '56px',
                        borderRadius: '16px',
                        backgroundColor: '#fff',
                        boxShadow: '0 6px 40px rgba(208, 208, 208, 0.4)',
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'transparent',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#2196F3',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#2196F3',
                        },
                      }}
                    >
                      {danubeSports.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.preferredSport && (
                      <FormHelperText>{errors.preferredSport.message}</FormHelperText>
                    )}
                  </FormControl>
                )}
              />

              {/* Contact Number */}
              <BirthdayPhoneInput
                control={control}
                error={errors.contactNumber}
                name="contactNumber"
                label="Contact Number"
                dataTestId="contact-number-input"
              />

              {/* Email */}
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Email"
                    variant="outlined"
                    fullWidth
                    type="email"
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    sx={sharedInputStyles}
                  />
                )}
              />

              {/* Preferred Date */}
              <Controller
                name="preferredDate"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Preferred Date"
                    type="date"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      min: dayjs(bookingRangeStart).format('YYYY-MM-DD'),
                      max: dayjs(bookingRangeEnd).format('YYYY-MM-DD'),
                    }}
                    error={!!errors.preferredDate}
                    helperText={errors.preferredDate?.message}
                    sx={sharedInputStyles}
                  />
                )}
              />

              {/* Message (not required) */}
              <Controller
                name="message"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Message (Optional)"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    sx={multilineInputStyles}
                  />
                )}
              />

              {/* Submit button */}
              <Button 
                type="submit" 
                variant="contained" 
                disabled={loading}
                size="large"
                sx={{ 
                  height: '56px',
                  borderRadius: '16px',
                  fontSize: '1rem',
                  fontWeight: 600,
                  textTransform: 'none',
                  marginTop: 2,
                  backgroundColor: '#2196F3',
                  boxShadow: '0 6px 40px rgba(33, 150, 243, 0.4)',
                  '&:hover': {
                    backgroundColor: '#1976D2',
                  }
                }}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Submit Inquiry'}
              </Button>
            </Stack>
          </form>
        </Stack>
      </Paper>
      <ToastContainer />
    </Container>
  );
};

export default BirthdayCelebrationForm;
