import { Button, Typography, useMediaQuery } from '@mui/material';
import { RegistrationStep } from '../../../../../Utils/constants';

interface AuthenticationToggleButtonProps {
  currentStep: RegistrationStep;
  setCurrentStep: (step: RegistrationStep) => void;
}

const AuthenticationToggleButton: React.FC<AuthenticationToggleButtonProps> = ({
  currentStep,
  setCurrentStep,
}) => {
  const isMd = useMediaQuery('(min-width:600px)');

  return (
    <Button
      variant="text"
      onClick={() =>
        setCurrentStep(
          currentStep === RegistrationStep.Login ? RegistrationStep.SignUp : RegistrationStep.Login
        )
      }
      sx={{
        padding: 0,
        textTransform: 'none',
        color: '#1a1a1a',
        display: 'block',
        margin: '12px auto 0',
        fontSize: '12px',
        mb: '24px',
        maxWidth: '570px',
        ...(isMd && {
          fontSize: '14px',
        }),
      }}
    >
      {currentStep === RegistrationStep.Login ? 'Don’t have a profile?' : 'Already have a profile?'}
      <Typography
        component="span"
        sx={{
          color: theme => theme.palette.primary.main,
          display: 'inline',
        }}
      >
        {currentStep === RegistrationStep.Login ? ' Sign up' : ' Login'}
      </Typography>
    </Button>
  );
};

export default AuthenticationToggleButton;
