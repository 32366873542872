import React, { useContext } from 'react';
import { ITermsCardProps } from './interfaces';
import { CheckoutContext, CheckoutContextProps } from '../../../context/CheckoutContext';
import TermsAgreementCardTheme1 from './variants/theme1';
import TermsAgreementCardTheme2 from './variants/theme2';
import { ThemeVariant } from '../../../hooks/useCompany';

const variantComponents: Record<ThemeVariant, React.ComponentType<ITermsCardProps>> = {
  default: TermsAgreementCardTheme1,
  theme1: TermsAgreementCardTheme1,
  theme2: TermsAgreementCardTheme2,
};

const TermsAgreementCard: React.FC<ITermsCardProps> = props => {
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;
  const companyTheme = company?.theme;
  const Component = variantComponents[(companyTheme as ThemeVariant) || 'default'];

  return <Component {...props} />;
};

export default TermsAgreementCard;
