import styled from '@emotion/styled';
import { Box, Button, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';

export const DurationFilterWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;

  .duration-label {
    color: #33334f;
    width: 100%;
    font-size: 1.25rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 12px;
    margin-top: 20px;
  }
`;

export const DurationListWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background-color: #fff;
  padding: 10px 20px;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  justify-content: space-between;
`;

interface DurationChipWrapperProps {
  isSelected: boolean;
  theme: Theme;
}

export const DurationChipWrapper = styled(Box)<DurationChipWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
  padding: 10px;
  margin-right: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;

  font-size: 14px;
  font-style: normal;
  text-align: center;
  font-weight: normal;
  line-height: 25px;
  color: ${({ isSelected }) => (isSelected ? '#fff' : '#808080')};
  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.palette.primary.main : '#fff'};

  box-shadow: 0px 6px 40px 0px rgba(208, 208, 208, 0.4);
  border-radius: 12px;

  height: 54px;
  width: auto;

  .duration {
    margin: 0;
    font-size: 14px;
    font-style: normal;
    text-align: center;
    font-weight: normal;
    line-height: 25px;
  }
`;
