import { useMemo } from 'react';
import { useTheme } from '@mui/material';
import { VoucherData } from '../../../../hooks/useProfile';

export const usePackagesSection = (vouchers: VoucherData[]) => {
  const theme = useTheme();
  const packages = useMemo(() => {
    return vouchers.filter(voucher => voucher.type === 'credit');
  }, [vouchers]);

  return { theme, packages };
};
