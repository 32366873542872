import { useContext, useMemo, useState } from 'react';
import { CheckoutContextProps } from '../../../../context/CheckoutContext';
import { CheckoutContext } from '../../../../context/CheckoutContext';
import {
  DSW_COMPANY_ID,
  DUBAI_FITNESS_CHALLENGE_COMPANY_ID,
  ROUTES_WITHOUT_CART_ICON,
  DANUBE_SPORTS_WORLD_COMPANY_ID
} from '../../../../Utils/constants';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Company } from '../../../../hooks/useCompany';

export const useHeader = (company?: Company) => {
  const { bookingCart, marketplaceName, countdownApi } = useContext(
    CheckoutContext
  ) as CheckoutContextProps;

  const location = useLocation();
  const navigate = useNavigate();

  const showCart = useMemo(
    () => !ROUTES_WITHOUT_CART_ICON.includes(location.pathname.toLowerCase()),
    [location.pathname]
  );

  const handleNavigateToCart = () => {
    if (marketplaceName) {
      navigate(`/Cart?companyId=${company?.id}&marketplaceName=${marketplaceName}`);
    } else {
      navigate(`/Cart?companyId=${company?.id}`);
    }
  };

  const handleLogoClick = () => {

    if ([DUBAI_FITNESS_CHALLENGE_COMPANY_ID, DSW_COMPANY_ID,DANUBE_SPORTS_WORLD_COMPANY_ID].includes(company?.id || '')) {
      navigate(`/?companyId=${company?.id}`);
    } else {
      window.location.href = company?.website || '/';
    }
  };

  return {
    showCart,
    handleNavigateToCart,
    handleLogoClick,
    bookingCart,
    marketplaceName,
    company,
    countdownApi,
    location,
  };
};

export default useHeader;
