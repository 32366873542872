import { Stack, useTheme } from '@mui/material';
import appleIcon from '../../../../../assets/icons/apple-only.svg';
import googleIcon from '../../../../../assets/icons/google.svg';
import { GoogleAuthProvider } from 'firebase/auth';
import { OAuthProvider } from 'firebase/auth';
import { SocialContainer, SocialDivider, SocialIconButton, SocialIconImage, AppleIconOverlay } from './style';

const SocialAuthentication = ({
  loginWithSocialMedia,
  isSocialLoading,
}: {
  loginWithSocialMedia: (provider: GoogleAuthProvider | OAuthProvider) => void;
  isSocialLoading: boolean;
}) => {
  const googleProvider = new GoogleAuthProvider();
  const appleProvider = new OAuthProvider('apple.com');
  const theme = useTheme();

  return (
    <>
      <SocialDivider theme={theme}>
        Or Continue with social account
      </SocialDivider>

      <SocialContainer component={'section'} theme={theme}>
        <Stack direction={'row'} mt={3} justifyContent="center" gap="4px">
          <SocialIconButton onClick={() => loginWithSocialMedia(googleProvider)} theme={theme}>
            <SocialIconImage src={googleIcon} theme={theme} />
          </SocialIconButton>
          <SocialIconButton onClick={() => loginWithSocialMedia(appleProvider)} theme={theme}>
            <SocialIconImage src={appleIcon} theme={theme} />
            <AppleIconOverlay theme={theme}     />
          </SocialIconButton>
        </Stack>
      </SocialContainer>
    </>
  );
};

export default SocialAuthentication;
