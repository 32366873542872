import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Box, Typography } from '@mui/material';
import { IDurationSelectorProps } from '../interfaces';

const DurationSelectorSkeleton: React.FC<Partial<IDurationSelectorProps>> = () => {
  return (
    <Box sx={{ width: '100%', p: 2 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Select Duration
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
          flexWrap: 'wrap',
          width: '100%',
          padding: '16px',
        }}
      >
        {/* Simulate 3 duration chips */}
        {Array.from({ length: 3 }).map((_, index) => (
          <Box
            key={index}
            sx={{
              p: 2,
              borderRadius: 1,
              border: '1px solid #e0e0e0',
              minWidth: '120px',
              textAlign: 'center',
            }}
          >
            <Skeleton height={24} width="100%" />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default DurationSelectorSkeleton;
