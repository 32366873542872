import React from 'react';
import { Box } from '@mui/material';
import { ModernPricingCardWrapper } from './style';
import { IPricingCardProps } from '../../interfaces';
import PriceRow from './PriceRow';
import { usePricingCard } from '../../hooks/usePricingCard';
const PricingCardTheme2: React.FC<IPricingCardProps> = ({
  total,
  discount,
  subTotal,
  currency,
  serviceFees,
}) => {
  return (
    <ModernPricingCardWrapper>
      <Box className="pricing-content">
        <PriceRow label="Subtotal" value={subTotal} currency={currency} />
        <PriceRow label="Discount" value={discount} isDiscount currency={currency} />
        {(serviceFees && serviceFees > 0 && total > 0) && (
          <PriceRow label="Service Fees" value={serviceFees} currency={currency} />
        )}
        <PriceRow
          label="Total"
          value={total + (total > 0 ? (serviceFees || 0) : 0)}
          currency={currency}
        />
      </Box>
    </ModernPricingCardWrapper>
  );
};

export default PricingCardTheme2;
