import { Typography, useTheme } from '@mui/material';
import React from 'react';
import SlotsList from './SlotsList';
import { SlotsSectionWrapper } from './style';
import { ISlotSectionProps } from '../../interfaces';

const SlotsSection: React.FC<ISlotSectionProps> = ({
  label,
  slots,
  areAllOffPeakSlots,
  timezone,
  handleAddToCart,
  selectedSlot,
  setSelectedSlot,
  icon,
  initialVisibleCount,
}) => {
  const theme = useTheme();

  return (
    <SlotsSectionWrapper theme={theme}>
      <Typography className="label">
        {icon}
        {label}
      </Typography>
      <SlotsList
        slots={slots}
        initialVisibleCount={initialVisibleCount}
        handleAddToCart={handleAddToCart}
        areAllOffPeakSlots={areAllOffPeakSlots}
        selectedSlot={selectedSlot}
        setSelectedSlot={setSelectedSlot}
        timezone={timezone}
      />
    </SlotsSectionWrapper>
  );
};

export default SlotsSection;
