import React, { useContext, useState } from 'react';
import { Box, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { UserInformationWrapper } from './style';
import Checkbox from '@mui/material/Checkbox';
import { CheckoutContext, CheckoutContextProps } from '../../../context/CheckoutContext';
import { isValidEmail } from '../../../Utils/validate';
import PhoneNumberField from '../../FormCollection/phoneNumberField';
import CustomizedTextField from '../../FormCollection/TextField';

export interface IUserForm {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  country: string;
}
interface IProps {
  error: any;
  isLoading: boolean;
  userType: string;
  buttonLabel: string;
  onClick: (formData: IUserForm) => void;
}

const UserInformationForm: React.FC<IProps> = ({
  error,
  isLoading,
  userType,
  buttonLabel,
  onClick,
}) => {
  const [isChecked, setIsChecked] = React.useState(false);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [country, setCountry] = useState('AE');

  const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  const { company } = useContext(CheckoutContext) as CheckoutContextProps;

  const isDisabled = () => {
    return (
      firstName.length === 0 ||
      lastName.length === 0 ||
      email.length === 0 ||
      phone.length === 0 ||
      !isChecked ||
      !isValidEmail(email)
      // ||
      // !company?.paymentsActive
    );
  };

  const handleClick = () => {
    onClick({ firstName, lastName, email, phone, country });
  };

  const goToPath = (path: string) => {
    window.open(path, '_blank');
  };

  return (
    <UserInformationWrapper>
      <Typography variant="h6" className="user-information-label">
        {`${userType} Information`}
      </Typography>
      <Box className="user-information">
        <Box className="user-information-row">
          <CustomizedTextField
            id="first_name"
            label="First Name"
            value={firstName}
            setValue={setFirstName}
            isRequired={true}
            placeholder="First Name"
          />

          <CustomizedTextField
            id="last_name"
            label="Last Name"
            value={lastName}
            setValue={setLastName}
            isRequired={true}
            placeholder="Last Name"
          />
        </Box>

        <Box className="user-information-row">
          <CustomizedTextField
            id="email"
            label="Email"
            value={email}
            setValue={setEmail}
            isRequired={true}
            placeholder="Email"
          />
          <PhoneNumberField
            phoneNumber={phone}
            setPhoneNumber={setPhone}
            country={country}
            setCountry={setCountry}
          />
        </Box>

        <Box className="user-information-terms">
          <Checkbox
            id={'terms_and_condition_checkbox'}
            checked={isChecked}
            onChange={onCheckboxChange}
          />
          <Typography className="user-information-terms-label">
            I agree to the{' '}
            <span
              className={'link'}
              onClick={() => {
                if (company?.footerPagesLinks?.privacy)
                  goToPath(company?.footerPagesLinks?.privacy!);
              }}
            >
              Privacy Policy
            </span>{' '}
            ,{' '}
            <span
              className={'link'}
              onClick={() => {
                if (company?.footerPagesLinks?.terms) goToPath(company?.footerPagesLinks?.terms!);
              }}
            >
              Terms & Conditions
            </span>{' '}
            , and{' '}
            <span
              className={'link'}
              onClick={() => {
                if (company?.footerPagesLinks?.refund) goToPath(company?.footerPagesLinks?.refund!);
              }}
            >
              Refund Policy
            </span>{' '}
            (Bookings can only be refunded {company?.cancellation_policy} hours before the booking
            time) .
          </Typography>
        </Box>

        <Box className="user-information-button">
          <LoadingButton
            variant="contained"
            disabled={isDisabled()}
            onClick={handleClick}
            className="confirm-button"
            loading={isLoading}
            id="checkout-button"
          >
            {buttonLabel}
          </LoadingButton>
        </Box>

        {error && <Typography className="user-information-error">{error}</Typography>}
      </Box>
    </UserInformationWrapper>
  );
};

export default UserInformationForm;
