import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const MembershipPurchaseWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;

  margin: 24px auto;
  padding: 0 10px;

  .membership-details-container {
    border: 1px solid #f0f0f0;
    padding: 24px;
    border-radius: 16px;
    background-color: #fff;
    margin-top: 16px;
    width: 100%;
  }
`;
