import { useCallback, useEffect, useState, useRef } from 'react';
import { useAuth } from '../../../../context/AuthContext';

export const useLoginForm = () => {
  const { resetPassword } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [isResetButtonDisabled, setIsResetButtonDisabled] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const handleResetPassword = useCallback(
    async (data: any) => {
      const isResetSuccess = await resetPassword(data.email);
      if (isResetSuccess) {
        setIsResetButtonDisabled(true);
      } else {
        return;
      }

      // Clear any existing timer
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      // Set new timer
      timerRef.current = setTimeout(() => {
        setIsResetButtonDisabled(false);
        timerRef.current = null;
        console.log('[Reset button disabled]');
      }, 60000); // 60000 ms = 1 minute
    },
    [resetPassword]
  );

  useEffect(() => {
    return () => {
      // Clear the timeout when component unmounts
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return { handleResetPassword, isResetButtonDisabled, showPassword, setShowPassword };
};
