import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles';

interface IProps {
  theme: Theme;
}

export const CalendarPriceCardWrapper = styled(Box)<IProps>`
  width: 80%;
  background: #ffffff;
  box-shadow: 0px 6px 40px rgb(208 208 208 / 20%);
  padding: 12px 16px;
  border-radius: 12px;

  .label {
    font-size: 16px;
    font-style: normal;
    text-align: center;
    font-weight: 500;
    color: ${props => props.theme.palette.primary.main} !important;
  }
`;
