import { object, string, ref, boolean, array, number } from 'yup';

import { UserData } from '../hooks/useUser';
import { BookingRange } from './constants';
import dayjs from 'dayjs';
import { Company } from '../hooks/useCompany';
import { AvailabilitySlot } from '../hooks/useAvailableStartingTimes';

export const isValidEmail = (email: string): boolean => {
  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(String(email).toLowerCase());
};

export const isValidUser = (user: UserData | undefined) => {
  return user && user.isProfileCompleted && user.isEmailVerified;
};

export const getUserSchema = (
  isSignupWithEmailAndPassword: boolean,
  company: Company,
  hasChildren: boolean,
  hasPods: boolean
) => {
  const createAccountFields = company.createAccountFields;

  // Initialize the schema fields with fields that are always required
  let schemaFields: Record<string, any> = {
    privacy: boolean().oneOf([true], 'Privacy is required'),
  };

  // Conditionally add fields based on createAccountFields configuration
  if (createAccountFields?.firstName) {
    schemaFields.firstName = string().required('First Name is Required');
  }

  if (createAccountFields?.lastName) {
    schemaFields.lastName = string().required('Last Name is Required');
  }

  if (createAccountFields?.phoneNumber) {
    schemaFields.phoneNumber = string().required('Phone Number is Required');
  }

  if (createAccountFields?.dateOfBirth) {
    schemaFields.dateOfBirth = string()
      .test(
        'is-sixteen-years-old',
        'You must be at least 16 years old',
        function (value: string | undefined) {
          if (!value) {
            return false;
          }
          const birthDate = dayjs(value);
          const currentDate = dayjs();
          const age = currentDate.diff(birthDate, 'year');
          return age >= 16;
        }
      )
      .required('Date of Birth is Required');
  }

  if (createAccountFields?.whereDidYouHearAboutUs) {
    schemaFields.whereDidYouHearAboutUs = string().required(
      'How did you hear about us is required'
    );
  }

  if (createAccountFields?.favoriteSports) {
    schemaFields.favoriteSports = array()
      .test('defined-elements', 'Favorite sports is required', function (value) {
        if (!Array.isArray(value)) return false;
        const definedElements = value.filter(v => v !== undefined).length;
        return definedElements >= 1;
      })
      .required('Favorite sports is required');
  }

  if (createAccountFields?.nationality) {
    schemaFields.nationality = string().required('Nationality is required');
  }

  if (createAccountFields?.residenceArea) {
    schemaFields.residenceArea = string().required('Residence area is required');
  }

  if (createAccountFields?.gender) {
    schemaFields.gender = string().required('Gender is required');
  }

  if (createAccountFields?.employeeId) {
    schemaFields.employeeId = string().required('Employee Id is required');
  }

  if (createAccountFields?.emergencyContactName) {
    schemaFields.emergencyContactName = string().required('Emergency Contact Name is required');
  }

  if (createAccountFields?.emergencyContactMobile) {
    schemaFields.emergencyContactMobile = string().required('Emergency Contact Mobile is required');
  }

  if (createAccountFields?.groupCode) {
    schemaFields.groupCode = string().optional();
  }

  if (createAccountFields?.marketingConsent) {
    schemaFields.marketingConsent = boolean().oneOf([true], 'Consent is required');
  }

  if (createAccountFields?.childrenInformation && hasChildren) {
    schemaFields.children = array()
      .of(
        object().shape({
          name: string().required('Child name is required'),
          age: number()
            .typeError('Age must be a number')
            .required('Child age is required')
            .min(4, 'Age cannot be negative')
            .max(16, 'Child must be under 16 years old'),
        })
      )
      .required('Children information is required');
  }

  // if (createAccountFields?.podsInformation && hasPods) {
  //   schemaFields.pods = array()
  //     .of(
  //       object().shape({
  //         name: string().required("Pod name is required"),
  //         age: number()
  //           .typeError("Age must be a number")
  //           .required("Pod age is required")
  //           .min(4, "Age cannot be negative")
  //           .max(16, "Pod must be under 16 years old"),
  //       })
  //     )
  //     .required("Pods information is required");
  // }

  if (hasChildren) {
    schemaFields.parentalWaiver = boolean().oneOf([true], 'Parental Waiver is required');
  }

  // Add email and password fields if signing up with email and password
  if (isSignupWithEmailAndPassword) {
    schemaFields.email = string().email('Invalid Email Address').required('Email is Required');
    schemaFields.password = getRelaxedPasswordValidation();
    schemaFields.passwordConfirm = getConfirmPasswordValidation();
  }

  // Build the Yup schema with the dynamically constructed fields
  const userSchema = object().shape(schemaFields).noUnknown();

  return userSchema;
};

export const getRegisterationSchema = (isLogin: boolean) => {
  const userSchema = object().shape({
    email: string().email('Invalid Email Address').required('Email is Required'),
  });

  if (isLogin) {
    userSchema.shape({
      password: string()
        .required()
        .min(8, 'Password must be at least 8 characters')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/\d/, 'Password must contain at least one number')
        .matches(/[^a-zA-Z0-9]/, 'Password must contain at least one special character'),
    });
  }

  return userSchema;
};

export const getResetPasswordSchema = () => {
  return object().shape({
    password: getRelaxedPasswordValidation(),
    passwordConfirm: getConfirmPasswordValidation(),
  });
};

export const getPasswordValidation = () => {
  return string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/\d/, 'Password must contain at least one number')
    .matches(/[^a-zA-Z0-9]/, 'Password must contain at least one special character');
};

export const getRelaxedPasswordValidation = () => {
  return string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(/[a-zA-Z]/, 'Password must contain at least one letter');
};

export const getConfirmPasswordValidation = () => {
  return string()
    .required('Password confirmation is required')
    .oneOf([ref('password')], "Passwords don't match");
};

export const isInBookingRange = (company: Company | undefined, date: string) => {
  if (!company?.bookingRange?.start || !company?.bookingRange?.end) {
    return true;
  }

  const start = dayjs(company.bookingRange.start, 'YYYYMMDD').startOf('day');
  const end = dayjs(company.bookingRange.end, 'YYYYMMDD').endOf('day');
  const dayToCheck = dayjs(date);
  return (
    (dayToCheck.isAfter(start) || dayToCheck.isSame(start)) &&
    (dayToCheck.isBefore(end) || dayToCheck.isSame(end))
  );
};

export const getCountdownTime = (company?: Company) => {
  return (company?.countdownTimeInMinutes || 20) * 60;
};

export const areAllSlotsOfType = (slots: AvailabilitySlot[], isPeak: boolean) =>
  slots.every(slot => slot.isPeak === isPeak);
