import { Typography, Box, useTheme } from '@mui/material';
import { CalendarPriceCardWrapper } from './style';
import { PricingCardProps } from '../../interfaces';
import { usePricingCard } from '../../hooks/usePricingCard';

const PricingCardTheme1: React.FC<PricingCardProps> = ({ slots, timeUnit }) => {
  const { peakPrice, offPeakPrice, currency, sessionSuffix, areAllPeakSlots, areAllOffPeakSlots } =
    usePricingCard(slots, timeUnit);
  const theme = useTheme();

  const renderPriceContent = () => {
    if (areAllPeakSlots || peakPrice === offPeakPrice) {
      return (
        <Typography className="label">{`${peakPrice} ${currency} ${sessionSuffix}`}</Typography>
      );
    } else if (areAllOffPeakSlots) {
      return (
        <Typography className="label">{`${offPeakPrice} ${currency} ${sessionSuffix}`}</Typography>
      );
    } else {
      return (
        <>
          <Typography className="label">{`${peakPrice} ${currency} ${sessionSuffix}`}</Typography>
          <Typography className="label">{`Off-Peak: ${offPeakPrice} ${currency} ${sessionSuffix}`}</Typography>
        </>
      );
    }
  };

  if (peakPrice === 0 && offPeakPrice === 0) {
    return null;
  }

  return (
    <CalendarPriceCardWrapper theme={theme}>
      <Typography className="label">Price</Typography>
      {renderPriceContent()}
    </CalendarPriceCardWrapper>
  );
};

export default PricingCardTheme1;
