import React, { useContext } from 'react';
import { ToastContainer } from 'react-toastify';
import Sidebar from '../shared/Sidebar/Sidebar';
import Header from '../shared/header';
import { ILayoutProps } from './Types';
import { useLocation } from 'react-router-dom';
import { Stack } from '@mui/material';
import { CheckoutContext, CheckoutContextProps } from '../../context/CheckoutContext';

const Layout: React.FC<ILayoutProps> = ({ isOpen, toggleDrawer, children }) => {
  const { pathname } = useLocation();

  const { company, marketplaceCompany } = useContext(CheckoutContext) as CheckoutContextProps;

  return (
    <Stack
      sx={{
        width: '97%',
        marginX: 'auto',
        marginBottom: pathname !== '/' ? '20px' : 0,
        justifyContent: 'flex-start',
        alignItems: 'center',
        minHeight: 'calc(100vh - 40px)',

        '@media (min-width: 768px)': {
          width: pathname !== '/' ? '80%' : '100%',
          maxWidth: pathname !== '/' ? '768px' : '100%',
        },
      }}
    >
      <ToastContainer hideProgressBar />
      {company?.showSidebar && <Sidebar isOpen={isOpen} toggleDrawer={toggleDrawer} />}
      <Header
        company={marketplaceCompany || company}
        isSidebarOpen={isOpen}
        toggleDrawer={toggleDrawer}
      />
      {children}
    </Stack>
  );
};

export default Layout;
