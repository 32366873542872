import React from 'react';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { Stack } from '@mui/material';
import { keyframes } from '@mui/system';

// Import SVG images from offers folder
import esaadSvg from '../../../assets/danube/offers/esaad.svg';
import fazaaSvg from '../../../assets/danube/offers/fazaa.svg';
import emiratesPlatinumSvg from '../../../assets/danube/offers/emirates-platinum.svg';
import noonSvg from '../../../assets/danube/offers/noon.svg';
import uaePassSvg from '../../../assets/danube/offers/uae-pass.svg';
import emiratesSkywardsSvg from '../../../assets/danube/offers/emirates-skywards.svg';
import emiratesNBD1 from '../../../assets/danube/offers/ENBD1.svg';
import emiratesNBD2 from '../../../assets/danube/offers/ENBD2.svg';

interface PromoCard {
  id: number;
  logo: string;
  name: string;
  altText: string;
}

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const promoCards: PromoCard[] = [
  {
    id: 1,
    logo: emiratesNBD1,
    name: 'Emirates NBD',
    altText: 'Emirates NBD Card',
  },
  {
    id: 1,
    logo: emiratesNBD2,
    name: 'Emirates NBD',
    altText: 'Emirates NBD Card',
  },
  
  {
    id: 1,
    logo: esaadSvg,
    name: 'Esaad',
    altText: 'Esaad Card',
  },
  {
    id: 2,
    logo: fazaaSvg,
    name: 'Fazaa',
    altText: 'Fazaa Card',
  },
  {
    id: 3,
    logo: emiratesPlatinumSvg,
    name: 'Emirates Platinum',
    altText: 'Emirates Platinum Card',
  },
  {
    id: 4,
    logo: noonSvg,
    name: 'Noon',
    altText: 'Noon Card',
  },
  {
    id: 5,
    logo: uaePassSvg,
    name: 'UAE Pass',
    altText: 'UAE Pass Card',
  },
  {
    id: 6,
    logo: emiratesSkywardsSvg,
    name: 'Emirates Skywards',
    altText: 'Emirates Skywards Card',
  },
];

const Promos: React.FC = () => {
  const isMd = useMediaQuery('(min-width:600px)');
  const isLg = useMediaQuery('(min-width:1024px)');

  return (
    <Box sx={{ py: 4, backgroundColor: '#f8f8f8' }}>
      <Box
        sx={{
          maxWidth: '1400px',
          margin: '0 auto',
          width: '100%',
          px: { xs: 2, md: 4 },
        }}
      >
        <Stack spacing={3} sx={{ mb: 4 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <Box sx={{ display: 'flex', gap: 0 }}>
              {[1, 2].map((_, i) => (
                <Box
                  key={i}
                  sx={{
                    width: 4,
                    height: 16,
                    bgcolor: '#C82E34',
                    transform: 'rotate(15deg)',
                    borderRadius: 10,
                    mx: 0.5,
                  }}
                />
              ))}
            </Box>
            <Typography
              sx={{
                color: '#000',
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '17px',
                letterSpacing: '0.1em',
                fontFamily: 'Dubai-Bold',
              }}
            >
              PROMOS
            </Typography>
          </Box>
          
          <Typography
            variant="h1"
            sx={{
              color: '#5C0000',
              fontSize: '32px',
              fontWeight: 700,
              lineHeight: '38px',
              fontFamily: 'Dubai-Bold',
              maxWidth: { xs: '343px', md: 'none' },
              whiteSpace: { md: 'nowrap' },
            }}
          >
            Exclusive Offers & Announcements
          </Typography>
          
          <Typography
            sx={{
              color: '#666',
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '24px',
              fontFamily: 'Dubai-Regular',
              mb: 2,
              textAlign: 'left',
              margin: '0',
            }}
          >
            Check out our latest promotions and announcements. Don't miss out on exciting offers and events happening at Danube Sports Hub!
          </Typography>

          <Box sx={{ width: '100%', mb: 4 }}>

            {/* Promo Cards Grid */}
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: {
                  xs: 'repeat(2, 1fr)',
                  sm: 'repeat(3, 1fr)',
                  md: 'repeat(6, 1fr)',
                },
                gap: { xs: 1.5, md: 2 },
                width: '100%',
              }}
            >
              {promoCards.map((card, index) => (
                <Box
                  key={card.id}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textDecoration: 'none',
                    animation: `${fadeIn} 0.6s ease-out forwards`,
                    animationDelay: `${index * 0.1}s`,
                    opacity: 0,
                    transform: 'translateY(20px)',
                    transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                    '&:hover': {
                      transform: 'translateY(-8px)',
                    },
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      aspectRatio: '1/1',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: '10px',
                    }}
                  >
                    <Box
                      component="img"
                      src={card.logo}
                      alt={card.altText}
                      sx={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                      }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontWeight: 900,
                      fontSize: { xs: '0.875rem', md: '1.25rem' },
                      color: '#000',
                      fontFamily: 'Dubai-Bold',
                      textTransform: 'uppercase',
                      textAlign: 'center',
                      marginTop: '8px',
                    }}
                  >
                    {card.name}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default Promos; 