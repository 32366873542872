import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { down } from 'styled-breakpoints';

export const UserInformationWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 35px;

  .user-information-label {
    color: #080133;
    font-size: 24px;
    font-style: normal;
    text-align: center;
    font-weight: normal;
  }

  .user-information {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 6px 40px rgb(208 208 208 / 20%);
    border-radius: 16px;
    padding-top: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .user-information-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 25px);
    background: #ffffff;
    margin-top: 15px;
    padding-left: 35px;

    ${down('sm')} {
      flex-direction: column;
      padding-left: 0;
    }
  }

  .user-information-terms {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: calc(100% - 25px);
    background: #ffffff;
    margin-top: 15px;
    margin-left: 25px;

    .user-information-terms-label {
      color: #808080;
      font-size: 14px;
      font-style: normal;
      font-family: Roboto-thin;
      font-weight: 400;
    }

    .link {
      color: #808080;
      font-weight: bold;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .user-information-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: calc(100% - 25px);
    background: #ffffff;
    margin-top: 15px;
    margin-left: 25px;
    margin-bottom: 20px;

    .confirm-button {
      font-size: 20px;
      font-weight: 500;
      color: #ffffff;
      height: 60px;
      margin-left: 5px;
      border-radius: 8px;
      box-shadow: 0px 6px 40px #d4d9e8;
      text-transform: capitalize;

      ${down('sm')} {
        width: 90%;
      }
    }
  }

  .user-information-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    color: #ff0000;
    font-size: 16px;
    font-weight: normal;
  }
`;
