import { useTheme } from '@mui/material';
import React from 'react';
import { getFormattedTime, getTimezone } from '../../../../../Utils/format';
import { SlotChipWrapper, SlotTimeTypography } from './style';
import { ISlotChipProps } from '../../interfaces';

const SlotChip: React.FC<ISlotChipProps> = ({
  slot,
  areAllOffPeakSlots,
  setSelectedSlot,
  timezone,
  isSelected,
}) => {
  const handleClick = () => {
    setSelectedSlot(isSelected ? undefined : slot);
  };

  const isOffPeak = !slot.isPeak && !areAllOffPeakSlots;
  const theme = useTheme();

  return (
    <SlotChipWrapper onClick={handleClick} isSelected={isSelected} theme={theme}>
      {isOffPeak && <span className="dot-indicator" />}
      <SlotTimeTypography isOffPeak={isOffPeak}>
        {getFormattedTime(slot.fullDate, 'hh:mm A', false, getTimezone(timezone))}
      </SlotTimeTypography>
    </SlotChipWrapper>
  );
};

export default SlotChip;
