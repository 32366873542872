import { useTheme } from '@mui/material';
import { CheckoutContextProps } from '../../../../context/CheckoutContext';

import { CheckoutContext } from '../../../../context/CheckoutContext';

import { useMemo, useContext } from 'react';
import { VoucherData } from '../../../../hooks/useProfile';

export const useMembershipsSection = (vouchers: VoucherData[]) => {
  const { servicesMap } = useContext(CheckoutContext) as CheckoutContextProps;
  const theme = useTheme();
  const memberships = useMemo(() => {
    return vouchers.filter(voucher => voucher.type === 'membership');
  }, [vouchers]);

  return { servicesMap, theme, memberships };
};
