import React from 'react';
import { CircularProgress } from '@mui/material';
import { PromocodeInputWrapper } from './style';
import { usePromoCode } from '../../hooks/usePromoCode';
import { IPromocodeRedemptionProps } from '../../interfaces';
import { Typography } from '@mui/material';

const PromocodeRedemptionTheme2: React.FC<IPromocodeRedemptionProps> = ({
  promocode,
  setPromocode,
  validationOutput,
  setPackageValidationOutput,
  type,
}: IPromocodeRedemptionProps) => {
  const { isValidating, isError, errorMessage, onClickApply, onClickCancel } = usePromoCode(
    promocode,
    setPromocode,
    setPackageValidationOutput
  );

  return (
    <PromocodeInputWrapper>
      <div className={`input-container ${isError ? 'has-error' : ''}`}>
        <div className="input-content">
          <div className="input-field-container">
            <input
              type="text"
              value={promocode}
              onChange={e => setPromocode(e.target.value)}
              placeholder="Promo code"
            />
          </div>
        </div>
        {isError && <Typography className="error-message">{errorMessage}</Typography>}
      </div>

      {validationOutput?.valid ? (
        <button className="custom-button" onClick={onClickCancel}>
          <div className="button-content">
            <span className="button-text">Cancel</span>
          </div>
        </button>
      ) : (
        <button className="custom-button" onClick={onClickApply} disabled={!(promocode.length > 0)}>
          <div className="button-content">
            {isValidating ? (
              <CircularProgress size={20} className="loading-spinner" sx={{ color: '#ffffff' }} />
            ) : (
              <span className="button-text">Apply</span>
            )}
          </div>
        </button>
      )}
    </PromocodeInputWrapper>
  );
};

export default PromocodeRedemptionTheme2;
