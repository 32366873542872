import { useContext } from 'react';
import { AvailabilityDuration } from '../../../../hooks/useAvailableStartingTimes';
import { CheckoutContextProps, CheckoutContext } from '../../../../context/CheckoutContext';
import { formatDurationTime } from '../../../../Utils/format';

export const useDurationSelector = (durations: AvailabilityDuration[]) => {
  const { company, selectedResource } = useContext(CheckoutContext) as CheckoutContextProps;
  const formattedDurationsMap = new Map<string, string>();
  durations.forEach(duration => {
    formattedDurationsMap.set(
      duration.id,
      formatDurationTime(duration.durationTime, company!, selectedResource)
    );
  });
  return { formattedDurationsMap };
};
