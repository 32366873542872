import React, { useState } from 'react';
import SlotChip from './SlotChip';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { SlotsListWrapper, ActionButtonsContainer, ActionButton } from './style';
import { ISlotListProps } from '../../interfaces';

const SlotsList: React.FC<ISlotListProps> = ({
  slots,
  handleAddToCart,
  areAllOffPeakSlots,
  selectedSlot,
  setSelectedSlot,
  timezone,
  initialVisibleCount,
}) => {
  const theme = useTheme();

  const [lessMode, setLessMode] = useState<boolean>(true);

  const visibleSlots = slots.slice(0, lessMode && initialVisibleCount ? 9 : slots.length);

  const handleShowMore = () => {
    setLessMode(false);
  };

  const handleLessMore = () => {
    setLessMode(true);
  };

  return (
    <>
      <SlotsListWrapper theme={theme}>
        {visibleSlots.map(slot => (
          <SlotChip
            key={slot.time}
            slot={slot}
            handleAddToCart={handleAddToCart}
            areAllOffPeakSlots={areAllOffPeakSlots}
            selectedSlot={selectedSlot}
            setSelectedSlot={setSelectedSlot}
            timezone={timezone}
            isSelected={selectedSlot?.time === slot.time}
          />
        ))}
      </SlotsListWrapper>

      <ActionButtonsContainer>
        {lessMode && !!initialVisibleCount && (
          <Box>
            <ActionButton
              color="inherit"
              onClick={handleShowMore}
              startIcon={<KeyboardArrowDownIcon />}
            >
              Show More
            </ActionButton>
          </Box>
        )}

        {!lessMode && !!initialVisibleCount && (
          <Box>
            <ActionButton
              color="inherit"
              onClick={handleLessMore}
              startIcon={<KeyboardArrowUpIcon />}
            >
              Show Less
            </ActionButton>
          </Box>
        )}
      </ActionButtonsContainer>
    </>
  );
};

export default SlotsList;
