import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckoutContext, CheckoutContextProps } from '../../../context/CheckoutContext';
import { useAuth } from '../../../context/AuthContext';

export const useDeleteAccountButton = () => {
  const [showDialog, setShowDialog] = useState(false);
  const navigate = useNavigate();
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;
  const { deleteAccount } = useAuth();

  const handleOpenDialog = () => setShowDialog(true);
  const handleCloseDialog = () => setShowDialog(false);

  const handleDeleteAccount = async () => {
    await deleteAccount();
    handleCloseDialog();
    navigate(`/?companyId=${company?.id}`);
  };

  return { showDialog, handleOpenDialog, handleCloseDialog, handleDeleteAccount };
};
