import { LoadingButton } from '@mui/lab';
import { Button, OutlinedInput, Typography } from '@mui/material';
import React from 'react';
import { VoucherError } from '../../hooks/useVoucherValidator';
import { PackageCodeInputWrapper } from './style';

interface IProps {
  isPackageCodeValid: boolean;
  isLoading: boolean;
  validationError: VoucherError;
  packageCode: string;
  setPackageCode: (packageCode: string) => void;
  onClickApply: () => void;
  onClickCancel: () => void;
}

const PackageCodeInput: React.FC<IProps> = ({
  isPackageCodeValid,
  isLoading,
  validationError,
  packageCode,
  setPackageCode,
  onClickApply,
  onClickCancel,
}) => {
  return (
    <PackageCodeInputWrapper>
      <Typography className="package-code-label">Package Code</Typography>
      <OutlinedInput
        placeholder="Enter Package Code"
        value={packageCode}
        onChange={e => setPackageCode(e.target.value)}
        className="package-code-input"
        endAdornment={
          isPackageCodeValid ? (
            <Button onClick={onClickCancel} color={'error'} className="package-code-button">
              Cancel
            </Button>
          ) : (
            <LoadingButton
              onClick={onClickApply}
              color={'primary'}
              loading={isLoading}
              className="package-code-button"
            >
              Apply
            </LoadingButton>
          )
        }
      />
      {validationError.type !== '' && (
        <Typography className="validation-error">{validationError.message}</Typography>
      )}
    </PackageCodeInputWrapper>
  );
};

export default PackageCodeInput;
