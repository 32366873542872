import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const TermsCardWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: '16px',
  marginBottom: '16px',
  marginTop: '16px',
  borderRadius: '12px',
  backgroundColor: '#ffffff',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.06)',
  transition: 'box-shadow 0.3s ease',

  '&:hover': {
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)',
  },

  '& .form-control-label': {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    padding: '8px 0',
    marginBottom: '24px',
    transition: 'transform 0.2s ease',

    '&:hover': {
      transform: 'translateX(2px)',
    },

    '& .checkbox-container': {
      width: '24px',
      height: '24px',
      marginRight: '12px',
      position: 'relative',

      '& .MuiCheckbox-root': {
        width: '24px',
        height: '24px',
        padding: '0',
        borderRadius: '6px',
        color: 'rgba(0, 0, 0, 0.38)',
        transition: 'all 0.2s ease',

        '&.Mui-checked': {
          color: '#1976d2',
          transform: 'scale(1.05)',
        },

        '&:hover': {
          backgroundColor: 'rgba(25, 118, 210, 0.04)',
        },
      },
    },

    '& .label-content': {
      flex: 1,
      height: 'auto',
      fontSize: '16px',
      letterSpacing: '-0.3px',
      color: 'rgba(0, 0, 0, 0.87)',
      lineHeight: '1.6',
      fontFamily: "'Roboto', sans-serif",
      fontWeight: '400',
      textAlign: 'left',

      '& .MuiLink-root': {
        textDecoration: 'none',
        fontWeight: '500',
        position: 'relative',
        transition: 'color 0.2s ease',

        '&:hover': {
          color: '#1565c0',
        },
      },

      '& .cancellation-text': {
        color: 'rgba(0, 0, 0, 0.87)',
        fontWeight: '400',
      },
    },
  },

  '& .payment-button': {
    width: '320px',
    height: '52px',
    backgroundColor: '#ff0000',
    border: 'none',
    borderRadius: '26px',
    padding: '14px 24px',
    boxShadow: '0 4px 12px rgba(255, 0, 0, 0.2)',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    cursor: 'pointer',
    margin: '0 auto',
    transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',

    '&:hover': {
      backgroundColor: '#e60000',
      transform: 'translateY(-1px)',
      boxShadow: '0 6px 16px rgba(255, 0, 0, 0.25)',
    },

    '&:active': {
      transform: 'translateY(1px)',
      boxShadow: '0 2px 8px rgba(255, 0, 0, 0.15)',
    },

    '&:disabled': {
      backgroundColor: '#ff0000',
      opacity: 0.6,
      cursor: 'not-allowed',
      transform: 'none',
      boxShadow: '0 2px 8px rgba(255, 0, 0, 0.1)',

      '&:hover': {
        backgroundColor: '#ff0000',
        transform: 'none',
      },

      '& .button-content': {
        opacity: 0.8,
      },
    },

    '& .button-content': {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'center',
      gap: '10px',
      transition: 'all 0.3s ease',

      '& .button-text': {
        color: '#ffffff',
        fontSize: '16px',
        fontFamily: "'Roboto', sans-serif",
        fontWeight: '500',
        letterSpacing: '0.2px',
      },

      '& .icon-container': {
        width: '20px',
        height: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#ffffff',
        transition: 'transform 0.2s ease',
      },

      '& .loading-spinner': {
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
      },
    },
  },

  '& .error-message': {
    marginTop: '10px',
    color: '#d32f2f',
    fontSize: '13px',
    fontFamily: "'Roboto', sans-serif",
    letterSpacing: '-0.2px',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    opacity: 0,
    animation: 'fadeIn 0.3s ease forwards',
  },

  '@keyframes fadeIn': {
    from: { opacity: 0, transform: 'translateY(-4px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
  },

  '@media (max-width: 600px)': {
    padding: '12px',
    
    '& .form-control-label .label-content': {
      fontSize: '15px',
      lineHeight: '1.5',
    },

    '& .payment-button': {
      width: '100%',
      height: '48px',
      padding: '12px 20px',

      '& .button-content .button-text': {
        fontSize: '15px',
      },
    },
  },
}));
