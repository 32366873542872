import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const RegisterWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 770px;
  transition: all 0.3s ease;
  margin: 15px auto;
  &:hover {
    box-shadow: rgba(17, 12, 46, 0.2) 0px 48px 100px 0px;
  }

  @media (max-width: 768px) {
    margin: 5px auto;
    padding: 10px;
    border-radius: 12px;
  }
`;
