import styled from '@emotion/styled';
import { Box, Stack, Theme } from '@mui/material';
import { keyframes } from '@emotion/react';

const shimmer = keyframes`
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`;

interface IProps {
  theme: Theme;
}

export const PackageValidationDetailsWrapper = styled(Box)<IProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px 16px;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(8px);
  border-radius: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  border: 1px solid ${props => props.theme.palette.divider};
  position: relative;
  overflow: hidden;
  transition: all 0.2s ease;
  margin-bottom: 8px;

  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: 1px dashed ${props => props.theme.palette.divider};

    .title {
      font-size: 13px;
      font-weight: 600;
      color: ${props => props.theme.palette.text.primary};
      display: flex;
      align-items: center;
      gap: 6px;

      .icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${props => `${props.theme.palette.primary.main}12`};
        padding: 6px;
        border-radius: 50%;
        
        svg {
          font-size: 14px;
          color: ${props => props.theme.palette.primary.main};
        }
      }
    }
  }

  .close-button {
    width: 24px;
    height: 24px;
    min-width: unset;
    padding: 0;
    border-radius: 50%;
    color: ${props => props.theme.palette.text.secondary};
    transition: all 0.2s ease;

    &:hover {
      background-color: ${props => props.theme.palette.error.light}20;
      color: ${props => props.theme.palette.error.main};
    }

    svg {
      font-size: 16px;
    }
  }

  .details-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
  }
`;

interface IItemProps {
  valueColor: string;
  theme: Theme;
}

export const PackageDetailsItemWrapper = styled(Stack)<IItemProps>`
  width: auto;
  padding: 8px;
  background: ${props => `${props.valueColor}08`};
  border-radius: 12px;
  transition: all 0.2s ease;
  position: relative;

  &:hover {
    background: ${props => `${props.valueColor}12`};
  }

  .package-details-item-label {
    font-weight: 500;
    font-size: 11px;
    color: ${props => props.theme.palette.text.secondary};
    letter-spacing: 0.2px;
    text-transform: uppercase;
  }

  .package-details-item-value {
    color: ${props => props.valueColor};
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 0.2px;
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4px;
    
    svg {
      font-size: 16px;
      color: ${props => props.valueColor};
    }
  }

  &.highlighted {
    background: ${props => `${props.theme.palette.primary.main}12`};
    border: 1px solid ${props => `${props.theme.palette.primary.main}30`};
  }
`;