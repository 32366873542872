import { useContext } from "react";
import { PackageValidationOutput, ValidationOutput } from "../../../../hooks/usePackage";
import { CheckoutContext, CheckoutContextProps } from "../../../../context/CheckoutContext";
import { getCurrency } from "../../../../Utils/format";

export const useSelectedPackageDetails = (selectedPackage: string, packageValidationOutput: ValidationOutput | undefined) => {
    const { company } = useContext(CheckoutContext) as CheckoutContextProps;
    const isValid = selectedPackage && packageValidationOutput?.valid;

  const currentPackageAmount = () => {
    if (packageValidationOutput?.type === 'duration') {
      return `${
        (packageValidationOutput as PackageValidationOutput)?.remainingDurationBeforeBooking! /
        (company?.serviceDurationMultiples || 15)
      } Session`;
    } else if (packageValidationOutput?.type === 'credit') {
      return `${(packageValidationOutput as PackageValidationOutput)
        ?.remainingCreditBeforeBooking!} ${getCurrency(company?.currency)}`;
    } else if (packageValidationOutput?.type === 'membership') {
      return `${(packageValidationOutput as PackageValidationOutput).remainingSessionsBefore!} Session`;
    }
  };

  const newBalance = () => {
    if (packageValidationOutput?.type === 'duration') {
      return `${
        (packageValidationOutput as PackageValidationOutput)?.remainingDurationAfterBooking! /
        (company?.serviceDurationMultiples || 15)
      } Session`;
    } else if (packageValidationOutput?.type === 'credit') {
      return `${(packageValidationOutput as PackageValidationOutput)
        ?.remainingCreditAfterBooking!} ${getCurrency(company?.currency)}`;
    } else if (packageValidationOutput?.type === 'membership') {
      return `${(packageValidationOutput as PackageValidationOutput).remainingSessionsAfter!} Session`;
    }
  };

  const amountRedeemed = () => {
    if (packageValidationOutput?.type === 'duration') {
      const currentPackageAmount = (packageValidationOutput as PackageValidationOutput)
        ?.remainingDurationBeforeBooking!;
      const newBalance = (packageValidationOutput as PackageValidationOutput)
        ?.remainingDurationAfterBooking!;

      return `${(currentPackageAmount - newBalance) / (company?.serviceDurationMultiples || 15)} Session `;
    } else if (packageValidationOutput?.type === 'credit') {
      const currentPackageAmount = (packageValidationOutput as PackageValidationOutput)
        ?.remainingCreditBeforeBooking!;
      const newBalance = (packageValidationOutput as PackageValidationOutput)
        ?.remainingCreditAfterBooking!;

      return `${currentPackageAmount - newBalance} ${getCurrency(company?.currency)}`;
    } else if (packageValidationOutput?.type === 'membership') {
      const currentPackageAmount = (packageValidationOutput as PackageValidationOutput)
        ?.remainingSessionsBefore!;
      const newBalance = (packageValidationOutput as PackageValidationOutput)
        ?.remainingSessionsAfter!;

      return `${currentPackageAmount - newBalance} Session`;
    }
  };

  return {
    currentPackageAmount,
    newBalance,
    amountRedeemed,
    isValid,
  };
};