import styled from '@emotion/styled';
import { down } from 'styled-breakpoints';
export const KidsCalendarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  // width: 80%;
  width: 100%;

  ${down('sm')} {
    // width: 95%;
  }

  .kids-label {
    font-size: 18px;
    color: #808080;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 15px;
  }
`;
