import { CircularProgress, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import React from 'react';
import { IPrimaryBtnProps } from './Types';
import { Link } from 'react-router-dom';

const PrimaryBtn: React.FC<IPrimaryBtnProps> = ({
  text,
  href,
  onClick,
  sx,
  type,
  isLoading = false,
  dataTestId,
  ...props
}) => {
  const theme = useTheme();
  return (
    <LoadingButton
      data-testid={dataTestId}
      component={href ? Link : 'button'}
      to={href}
      loading={isLoading}
      loadingIndicator={<CircularProgress size={24} style={{ color: 'white' }} />}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textDecoration: 'none',
        bgcolor: theme.palette.primary.main,
        borderRadius: '8px',
        color: 'white',
        fontSize: 20,
        fontWeight: 500,
        width: '100%',
        textTransform: 'unset',
        height: '55px',
        '&:hover': {
          bgcolor: `${theme.palette.primary.main} !important`,
          boxShadow: '0 4px 12px rgba(51, 51, 79, 0.2)',
        },
        '&.Mui-disabled': {
          bgcolor: '#D9D9D9',
        },
        ...sx,
      }}
      onClick={onClick}
      type={type}
      {...props}
    >
      {text}
    </LoadingButton>
  );
};

export default PrimaryBtn;
