import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Session } from '../../../hooks/useClassSessions';
import { ClassSessionChipWrapper, SoldOutLabel } from './style';
import { useContext, useState } from 'react';
import { CheckoutContext, CheckoutContextProps } from '../../../context/CheckoutContext';
import { useNavigate } from 'react-router-dom';
import { formatStringTime, getClassFullDate, getCurrency } from '../../../Utils/format';
import useCart from '../../../hooks/useCart';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import PlaceIcon from '@mui/icons-material/Place';

interface IProps {
  session: Session;
}
const ClassSessionChip: React.FC<IProps> = ({ session }) => {
  const navigate = useNavigate();
  const {
    setSelectedSession,
    setSelectedDuration,
    setSelectedSlot,
    selectedService,
    company,
    servicesMap,
    quantity,
    resourcesMap,
    selectedResource,
  } = useContext(CheckoutContext) as CheckoutContextProps;

  const { addToCart } = useCart();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isSoldOut = session.available <= 0;

  const service = servicesMap?.get(selectedService);
  const resource = resourcesMap?.get(selectedResource);
  const resourceName = resource?.name || service?.resources?.[0]?.name;

  const handleClick = async () => {
    setSelectedDuration(undefined);
    setSelectedSession(session);
    setIsLoading(true);

    if (service?.subType === 'kids') {
      navigate(`/KidsBooking?companyId=${company?.id}`);
    } else if (service?.subType === 'pods') {
      navigate(`/PodsBooking?companyId=${company?.id}`);
    } else if (service?.addons && service.addons.length > 0) {
      navigate(`/Addons?companyId=${company?.id}`);
    } else {
      const addedToCart = await addToCart({
        selectedService,
        selectedDuration: undefined,
        selectedSlot: undefined,
        quantity: quantity || 1,
        selectedSession: session,
        addonsList: [],
        childrenInformation: [],
        timezone: company?.timezone,
      });

      if (addedToCart) {
        setSelectedSession(undefined);
        setSelectedSlot(undefined);
        navigate(`/CartCheckout?companyId=${company?.id}`);
      } else {
        toast('Error while adding the item to the cart', {
          theme: 'colored',
          type: 'error',
        });
      }

      setIsLoading(false);
    }
  };

  return (
    <ClassSessionChipWrapper isSoldOut={isSoldOut} data-testid="class-session-chip">
      <Box className="description">
        <Typography className="header" data-testid="class-name">{`${session.name}`}</Typography>
        {resourceName && (
          <Typography className="resource-name">
            <PlaceIcon style={{ color: 'red', fontSize: '15px' }} /> {resourceName}
          </Typography>
        )}
      </Box>
      <Box className="description">
        <Box>{service?.image && <img src={service?.image} />}</Box>
        {(service?.description || session?.description) && (
          <Typography className="description-text" data-testid="class-description">
            {(session?.description || service?.description)?.split(/\s+/).map((word, index) => {
              const isUrl = word.match(/^(https?:\/\/[^\s]+)$/);
              return isUrl ? (
                <React.Fragment key={index}>
                  <a
                    href={word}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: '#1976d2', // Material UI primary blue
                      textDecoration: 'none',
                      fontWeight: 500,
                    }}
                  >
                    {word}
                  </a>{' '}
                </React.Fragment>
              ) : (
                <React.Fragment key={index}>{word} </React.Fragment>
              );
            })}
          </Typography>
        )}
      </Box>
      <Box className="details">
        <Typography className="item" data-testid="class-time">
          {formatStringTime(session.startTime)}
        </Typography>
        <Typography
          className="item"
          data-testid="class-duration"
        >{`${session.duration} Min`}</Typography>
        <Typography className="item" data-testid="class-price">
          {session.price === 0 ? 'Free' : `${session.price} ${getCurrency(company?.currency)}`}
        </Typography>
      </Box>
      {!service?.isBookingDisabled && (
        <Box className="book-button">
          <LoadingButton
            disabled={isLoading || isSoldOut}
            variant="contained"
            onClick={handleClick}
            sx={{
              color: '#ffffff',
            }}
            loading={isLoading}
            data-testid="book-now-button"
          >
            {isSoldOut ? 'Fully Booked' : 'Book Now'}
          </LoadingButton>
        </Box>
      )}
    </ClassSessionChipWrapper>
  );
};

export default ClassSessionChip;
