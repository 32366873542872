import { Stack, Typography, useMediaQuery } from '@mui/material';
import heroImg from '../../../assets/images/DFC.svg';

const StaticHeader = () => {
  const isMd = useMediaQuery('(min-width:600px)');
  const isLg = useMediaQuery('(min-width:1024px)');

  return (
    <Stack
      component={'header'}
      sx={{
        position: 'relative',
        width: '100%',
        padding: '0',
        alignItems: 'center',
        overflow: 'hidden',
      }}
    >
      <Stack
        sx={{
          width: '100%',
        }}
      >
        <img
          src={heroImg}
          alt="Aerial night view of the city"
          style={{
            objectFit: 'cover',
            objectPosition: isLg ? 'center 62%' : isMd ? 'center 70%' : 'center 60%',
          }}
        />
      </Stack>
      <Stack
        direction={isLg ? 'row' : 'column'}
        alignItems="center"
        justifyContent="center"
        spacing={2}
        sx={{
          width: '100%',
          padding: isLg ? '2rem' : '1rem',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
        }}
      >
        <Typography
          variant={isLg ? 'h4' : isMd ? 'h5' : 'h6'}
          align="center"
          fontFamily="Dubai-Bold"
        >
          DFC 30 x 30 Fitness Villages
        </Typography>
      </Stack>
    </Stack>
  );
};

export default StaticHeader;
