import { Tabs, useTheme } from '@mui/material';
import React from 'react';
import { RegistrationStep } from '../../../../../Utils/constants';
import { StyledTab } from './style';

interface IFormTabsProps {
  currentStep: RegistrationStep;
  setCurrentStep: (value: RegistrationStep) => void;
}

const AuthenticationFormTabs: React.FC<IFormTabsProps> = ({ currentStep, setCurrentStep }) => {
  const theme = useTheme();
  return (
    <Tabs value={currentStep} onChange={(_, value) => setCurrentStep(value)}>
      <StyledTab
        label={'Login'}
        value={RegistrationStep.Login}
        data-testid="login-tab"
        theme={theme}
      />
      <StyledTab
        label={'Sign Up'}
        value={RegistrationStep.SignUp}
        data-testid="signup-tab"
        theme={theme}
      />
    </Tabs>
  );
};

export default AuthenticationFormTabs;
