import React, { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { CheckoutContext, CheckoutContextProps } from '../../context/CheckoutContext';
import useClassSessions from '../../hooks/useClassSessions';
import useTitle from '../../hooks/useTitle';
import DaySelector from '../../components/Calendar/DaySelector';
import ClassSessionSelector from '../../components/Calendar/ClassSessionSelector';
import { FunActivitiesCalendarWrapper } from './style';
import CustomFooter from '../../components/shared/footer/CustomFooter';
import { useDaySelection } from '../../hooks/useDaySelection';
import DFCFooter from '../../components/shared/footer/DFCFooter';
import { DUBAI_FITNESS_CHALLENGE_COMPANY_ID } from '../../Utils/constants';
import ServiceSelector from '../../components/Calendar/ServiceSelector';

interface IProps {
  title: string;
}

const FunActivitiesCalendar: React.FC<IProps> = ({ title }) => {
  useTitle(title);

  const [searchParams] = useSearchParams();
  const serviceId = searchParams.get('serviceId');

  const { selectedWeekStartDate, setSelectedWeekStartDate } = useDaySelection();

  const { selectedService, company, selectedDay, setSelectedDay, marketplaceCompany } = useContext(
    CheckoutContext
  ) as CheckoutContextProps;

  const { isLoading: isLoadingSessions, sessions } = useClassSessions(
    company?.id || '',
    selectedService,
    dayjs(selectedDay, 'DD/MM/YYYY').format('YYYYMMDD')
  );

  return (
    <FunActivitiesCalendarWrapper>
      <ServiceSelector type="class" subType="kids" serviceId={serviceId || ''} />
      <DaySelector
        onlySevenDaysInAdvance={false}
        dayHasAvailableSlots={new Map()}
        selectedWeekStartDate={selectedWeekStartDate}
        setSelectedWeekStartDate={setSelectedWeekStartDate}
      />

      <ClassSessionSelector sessions={sessions} isLoading={isLoadingSessions} type="kids" />

      {company?.hasFooter && <CustomFooter company={marketplaceCompany || company} />}

      {company?.id === DUBAI_FITNESS_CHALLENGE_COMPANY_ID && <DFCFooter />}
    </FunActivitiesCalendarWrapper>
  );
};

export default FunActivitiesCalendar;
