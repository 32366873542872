import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const ConfirmationHeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 40px;

  .header {
    color: #080133;
    font-size: 30px;
    text-align: center;
    font-weight: normal;
  }

  .icon {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .email-label {
    color: #808080;
    // padding: 0px 12px;
    font-size: 18px;
    font-style: normal;
    text-align: center;
    font-family: Roboto-thin;
    font-weight: bolder;

    padding: 0;
  }

  .email-info {
    color: #080133;
    // margin: 5px 0px;
    // padding: 0px 10px;
    font-size: 19px;
    font-style: normal;
    text-align: center;
    // font-weight: normal;

    font-weight: 500;
    padding: 0;
  }
`;
