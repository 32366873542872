import React, { useContext, useState } from 'react';
import { PackagePurchaseWrapper } from './style';
import { useSearchParams } from 'react-router-dom';
import { CheckoutContext, CheckoutContextProps } from '../../../context/CheckoutContext';
import usePackage from '../../../hooks/usePackage';
import PackageDetails from '../../../components/discount/package/PackageDetails';
import CheckoutForm from '../../../components/Checkout/CheckoutForm';
import { Box } from '@mui/material';
import PricingCard from '../../../components/shared/PricingCard';
import { CustomerInformation } from '../../../hooks/useCart';
import useTitle from '../../../hooks/useTitle';

interface props {
  title?: string;
}

const PackagePurchase: React.FC<props> = ({ title }) => {
  useTitle(title);
  const [searchParams] = useSearchParams();

  const packageValue = Number(searchParams.get('packageValue'));
  const packagePrice = Number(searchParams.get('packagePrice'));
  const packageName = searchParams.get('packageName');
  const packageId = searchParams.get('packageId');
  const packageType = searchParams.get('packageType');
  const { purchasePackage } = usePackage();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { company, marketplaceName } = useContext(CheckoutContext) as CheckoutContextProps;

  const handleClick = async (customerInformation?: CustomerInformation) => {
    try {
      setIsLoading(true);
      await purchasePackage(company?.id!, packageId!, customerInformation, marketplaceName);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <PackagePurchaseWrapper>
      <Box className="package-details-container">
        <PackageDetails
          packagePrice={packagePrice}
          packageValue={packageValue}
          packageName={packageName || ''}
          type={packageType!}
        />
      </Box>

      <PricingCard subTotal={packagePrice} total={packagePrice} discount={0} vat={0} />

      <CheckoutForm handleClick={handleClick} isLoading={isLoading} totalPrice={packagePrice} forcingAuthentication={true} />
    </PackagePurchaseWrapper>
  );
};

export default PackagePurchase;
