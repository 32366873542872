import React from 'react';
import { useTheme } from '@mui/material/styles';
import { HeaderProps } from '../../interfaces';
import { ReactComponent as MenuIcon } from '../../../../../assets/icons/menu.svg';
import { ReactComponent as ShoppingCartIcon } from '../../../../../assets/icons/shopping-cart-v2.svg';
import useHeader from '../../hooks/useHeader';
import {
  ModernAppBar,
  HeaderContent,
  ActionGroup,
  ModernCartButton,
  ModernToggleButton,
  StyledBadge,
  CountdownWrapper,
} from './style';
import ControlledCountdown from './ControlledCountdown';
import LogoSection from './LogoSection';

const HeaderTheme2: React.FC<HeaderProps> = ({ company, toggleDrawer, isSidebarOpen }) => {
  const theme = useTheme();
  const { showCart, handleNavigateToCart, handleLogoClick, bookingCart, countdownApi, location } =
    useHeader(company);

  return (
    <>
      <ModernAppBar position="sticky" isHome={location.pathname === '/'} theme={theme}>
        <HeaderContent>
          {company?.showSidebar ? (
            <ModernToggleButton
              onClick={() => toggleDrawer(!isSidebarOpen)}
              aria-label="toggle sidebar"
              data-testid="sidebar-toggle"
            >
              <MenuIcon />
            </ModernToggleButton>
          ) : (
            <div style={{ minWidth: '40px' }} />
          )}
          
          <LogoSection company={company} onClick={handleLogoClick} />
          
          <ActionGroup>
            {showCart && (
              <ModernCartButton
                onClick={handleNavigateToCart}
                aria-label="shopping cart"
                data-testid="cart-button"
              >
                <StyledBadge badgeContent={bookingCart?.length} color="primary">
                  <ShoppingCartIcon />
                </StyledBadge>
              </ModernCartButton>
            )}
          </ActionGroup>
        </HeaderContent>
      </ModernAppBar>
      {showCart && (
        <CountdownWrapper>
          <ControlledCountdown countdownApi={countdownApi} company={company} />
        </CountdownWrapper>
      )}
    </>
  );
};

export default HeaderTheme2;
