import { useTheme } from '@mui/material/styles';
import ContentLoader from '../../../../shared/ContentLoader';
import { useTimeSelector } from '../../hooks/useTimeSelector';
import { ITimeSelectorProps } from '../../interfaces';
import { TimeSelectorWrapper } from './style';
import DurationSelector from '../../../DurationSelector';
import { Box, Typography } from '@mui/material';
import PricingCard from '../../../PricingCard';
import SlotSelector from '../../../SlotSelector';
import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined';
import NightsStayOutlinedIcon from '@mui/icons-material/NightsStayOutlined';
import { getResourceDisclaimer } from '../../../../../Utils/extract';

const TimeSelectorTheme1: React.FC<ITimeSelectorProps> = ({
  isLoadingAvailabilitySlots,
  showDurationFilter,
  timeUnit,
  startTime,
  endTime,
  availableStartingTimes,
}) => {
  const theme = useTheme();
  const {
    morningSlots,
    afternoonSlots,
    hasAvailableSlots,
    handleAddToCart,
    selectedDuration,
    setSelectedDuration,
    selectedSlot,
    setSelectedSlot,
    company,
    selectedResource,
  } = useTimeSelector(startTime, endTime);

  if (!hasAvailableSlots && !isLoadingAvailabilitySlots) {
    return (
      <TimeSelectorWrapper theme={theme}>
        <Typography className="no-slots-label">
          Ughh, Seems like we are fully booked today. Join on us on another day?
        </Typography>
      </TimeSelectorWrapper>
    );
  }

  return (
    <ContentLoader isLoading={isLoadingAvailabilitySlots}>
      <TimeSelectorWrapper theme={theme}>
        {showDurationFilter && (
          <DurationSelector
            durations={availableStartingTimes?.duration || []}
            setSelectedDuration={setSelectedDuration}
            selectedDuration={selectedDuration}
          />
        )}

        <Typography className="availability-label">Available Starting Times</Typography>
        <PricingCard slots={[...morningSlots, ...afternoonSlots]} timeUnit={timeUnit} />
        <Box className="slots-container">
          {morningSlots.length > 0 && (
            <SlotSelector
              label="Morning"
              slots={morningSlots}
              selectedSlot={selectedSlot}
              setSelectedSlot={setSelectedSlot}
              handleAddToCart={handleAddToCart}
              icon={<WbSunnyOutlinedIcon />}
            />
          )}
          {afternoonSlots.length > 0 && (
            <SlotSelector
              label="Afternoon"
              slots={afternoonSlots}
              selectedSlot={selectedSlot}
              setSelectedSlot={setSelectedSlot}
              handleAddToCart={handleAddToCart}
              icon={<NightsStayOutlinedIcon />}
            />
          )}
        </Box>
        <Typography className="resource-disclaimer">
          {getResourceDisclaimer(company!, selectedResource)}
        </Typography>
      </TimeSelectorWrapper>
    </ContentLoader>
  );
};

export default TimeSelectorTheme1;
