import { useContext } from 'react';
import { getCurrency } from '../../../../Utils/format';
import { areAllSlotsOfType } from '../../../../Utils/validate';
import { CheckoutContext, CheckoutContextProps } from '../../../../context/CheckoutContext';
import { AvailabilitySlot } from '../../../../hooks/useAvailableStartingTimes';
import { calculatePrice } from '../../../../Utils/format';

export const usePricingCard = (slots: AvailabilitySlot[], timeUnit: 'Hour' | 'Session') => {
  const { company, quantity, selectedService, servicesMap, selectedDuration } = useContext(
    CheckoutContext
  ) as CheckoutContextProps;

  const service = servicesMap?.get(selectedService);

  const areAllPeakSlots = areAllSlotsOfType(slots, true);
  const areAllOffPeakSlots = areAllSlotsOfType(slots, false);

  const peakPrice = calculatePrice(
    selectedDuration?.peakPricePerDurationMultiple!,
    selectedDuration?.durationTime!,
    timeUnit,
    company!,
    service!,
    quantity
  );
  const offPeakPrice = calculatePrice(
    selectedDuration?.offPeakPricePerDurationMultiple!,
    selectedDuration?.durationTime!,
    timeUnit,
    company!,
    service!,
    quantity
  );

  const currency = getCurrency(company?.currency);
  const sessionSuffix = timeUnit === 'Session' ? '/Session' : '';

  return {
    peakPrice,
    offPeakPrice,
    currency,
    sessionSuffix,
    areAllPeakSlots,
    areAllOffPeakSlots,
  };
};
