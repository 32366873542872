import { styled } from '@mui/material/styles';
import { Box, Card } from '@mui/material';

export const ModernCardWrapper = styled(Card)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  borderRadius: 6,
  padding: theme.spacing(2),
  boxShadow: '0 6px 40px rgba(208, 208, 208, 0.2)',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  '&:hover': {
    boxShadow: '0 8px 45px rgba(0, 0, 0, 0.07)',
  },

  '& .main-content': {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: '100%',
  },

  '& .header-section': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
    height: 32,

    '& .service-info': {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      width: '100%',

      '& .service-name': {
        fontSize: '1rem',
        fontWeight: 600,
        color: 'rgba(0, 0, 0, 0.87)',
        flex: 1,
        minWidth: 0,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'left',
      },

      '& .resource-name': {
        fontSize: '0.875rem',
        color: 'rgba(0, 0, 0, 1)',
        whiteSpace: 'nowrap',
      },

      '& .quantity-badge': {
        backgroundColor: `${theme.palette.primary.main}10`,
        color: theme.palette.primary.main,
        padding: theme.spacing(0.25, 0.75),
        borderRadius: theme.shape.borderRadius,
        fontSize: '0.8rem',
        fontWeight: 600,
        marginLeft: theme.spacing(1),
      },
    },
  },

  '& .details-section': {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },

  '& .detail-row': {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontSize: '0.9rem',
    height: 22,

    '&.date-row, &.time-row': {
      '& .MuiSvgIcon-root': {
        fontSize: '1rem',
        color: 'rgba(36, 32, 57, 1)',
      },
      '& .date-text, & .time-text, & .duration-text': {
        fontSize: '1rem',
        lineHeight: '22px',
        color: 'rgba(0, 0, 0, 1)',
      },
    },

    '&.time-row': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',

      '& .time-info': {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
      },

      '& .time-content': {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
      },

      '& .time-divider': {
        width: 1,
        height: 12,
        backgroundColor: 'rgba(217, 217, 217, 1)',
        margin: 0,
      },

      '& .booking-price': {
        fontSize: '0.875rem',
        color: theme.palette.primary.main,
        fontWeight: 600,
        marginLeft: 'auto',

        [theme.breakpoints.down('sm')]: {
          fontSize: '0.85rem',
        },
      },
    },

    '& .MuiSvgIcon-root': {
      fontSize: '1.1rem',
      color: theme.palette.text.secondary,
    },
  },

  '& .actions-section': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,

    '& .price': {
      fontSize: '1.1rem',
      fontWeight: 600,
      color: theme.palette.primary.main,
    },

    '& .action-buttons': {
      display: 'flex',
      gap: theme.spacing(1),

      '& .MuiIconButton-root': {
        width: 32,
        height: 32,
        padding: theme.spacing(0.75),
        border: `1px solid currentColor`,

        '&.cancel-button, &.remove-button': {
          color: theme.palette.error.main,
          backgroundColor: `${theme.palette.error.main}10`,
          '&:hover': {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.error.contrastText,
          },
        },
      },
    },
  },

  '.addons-section': {
    paddingTop: '10px',
    borderTop: `1px solid ${theme.palette.divider}`,

    '.addons-title': {
      fontSize: '1rem',
      fontWeight: 600,
      color: 'rgba(0, 0, 0, 0.87)',
      lineHeight: 1.2,
      textAlign: 'left',
    },

    '.addon-item': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(1, 0),

      '&:not(:last-child)': {
        borderBottom: `1px dashed ${theme.palette.divider}`,
      },

      '.addon-name': {
        fontSize: '0.875rem',
        color: 'rgba(0, 0, 0, 1)',
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(0.5),
        flexWrap: 'wrap',
        flex: 1,
        marginRight: theme.spacing(1),

        [theme.breakpoints.down('sm')]: {
          fontSize: '0.85rem',
        },

        '.addon-quantity': {
          backgroundColor: `${theme.palette.primary.main}10`,
          color: theme.palette.primary.main,
          padding: theme.spacing(0.25, 0.75),
          borderRadius: theme.shape.borderRadius,
          fontSize: '0.8rem',
          fontWeight: 600,
        },
      },

      '.addon-price': {
        fontSize: '0.875rem',
        color: theme.palette.primary.main,
        fontWeight: 600,
        whiteSpace: 'nowrap',

        [theme.breakpoints.down('sm')]: {
          fontSize: '0.85rem',
        },
      },
    },
  },

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1.5),
    gap: theme.spacing(1.5),

    '& .header-section': {
      '& .service-name': {
        fontSize: '1rem',
        '& .resource-badge': {
          fontSize: '0.85rem',
        },
      },
    },

    '& .detail-row': {
      fontSize: '0.85rem',
    },

    '& .actions-section': {
      paddingTop: theme.spacing(1.5),
      '& .price': {
        fontSize: '1rem',
      },
    },
  },
}));
