import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GroupWrapper = styled(Box)`
  margin-bottom: 15px;
  width: 100%;
  position: relative;
  background: linear-gradient(to bottom, rgba(81, 130, 255, 0.05), transparent);
  border-radius: 16px;
  padding: 24px 16px;
`;

export const ProviderTitle = styled(Typography)`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
  padding-left: 16px;
  background: ${({ theme }) =>
    `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  cursor: default;

  &:after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 16px;
    width: 40px;
    height: 3px;
    background: ${({ theme }) =>
      `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`};
    border-radius: 2px;
  }
`;

export const ProviderDescription = styled(Typography)`
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 20px;
  padding-left: 16px;
  color: ${({ theme }) => theme.palette.text.secondary};
  line-height: 1.6;
  position: relative;
`;

export const ScrollableWrapper = styled(Box)`
  display: flex;
  overflow-x: auto;
  padding: 16px 10px;
  gap: 24px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: relative;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50px;
    pointer-events: none;
    z-index: 1;
  }
`;

export const ScrollIndicator = styled(Box)`
  display: flex;
  justify-content: center;
  gap: 8px;
  padding: 8px;
`;

interface DotProps {
  active: boolean;
}

export const Dot = styled(Box)<DotProps>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ active, theme }) => (active ? theme.palette.primary.main : '#E0E0E0')};
  cursor: pointer;
  transform: ${({ active }) => (active ? 'scale(1.2)' : 'scale(1)')};
`;

export const MembershipCardWrapper = styled(Box)`
  min-width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;

  & > div {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .MuiCard-root {
    flex: 1;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(81, 130, 255, 0.1);
  }

  .MuiBox-root {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;
