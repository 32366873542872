import React from 'react';
import SocialAuthentication from './SocialAuthentication';
import { IAuthenticationFormProps } from '../../Types';
import { RegistrationStep } from '../../../../../Utils/constants';
import { useAuthenticationForm } from '../../hooks/useAuthenticationForm';
import AuthenticationFormTabs from './AuthenticationFormTabs';
import LoginForm from './LoginForm';
import SignupForm from './SignupForm';
import AuthenticationToggleButton from './AuthenticationToggleButton';
import { MainContainer } from './style';

const AuthenticationFormTheme1: React.FC<IAuthenticationFormProps> = ({
  redirectToCompleteProfile,
  currentStep,
  redirectToCalendar,
  setCurrentStep,
}) => {
  const {
    signUpForm,
    isLoading,
    loginWithSocialMedia,
    isSocialLoading,
    signUpWithEmailAndPassword,
    signInWithEmailAndPassword,
    supportSocialAuthentication,
  } = useAuthenticationForm(currentStep, redirectToCompleteProfile, redirectToCalendar);

  return (
    <MainContainer>
      <AuthenticationFormTabs currentStep={currentStep} setCurrentStep={setCurrentStep} />

      {currentStep === RegistrationStep.Login ? (
        <LoginForm form={signUpForm} onSubmit={signInWithEmailAndPassword} isLoading={isLoading} />
      ) : (
        <SignupForm form={signUpForm} onSubmit={signUpWithEmailAndPassword} isLoading={isLoading} />
      )}

      {supportSocialAuthentication && (
        <SocialAuthentication
          loginWithSocialMedia={loginWithSocialMedia}
          isSocialLoading={isSocialLoading}
        />
      )}

      <AuthenticationToggleButton currentStep={currentStep} setCurrentStep={setCurrentStep} />
    </MainContainer>
  );
};

export default AuthenticationFormTheme1;
