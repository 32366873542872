import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const PromocodeInputWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  width: 343px;
  height: 56px;
  margin-bottom: 16px;

  .input-container {
    width: 100%;
    max-width: 204px;
    height: 56px;
    background-color: #ffffff;
    border-radius: 1000px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;

    &.has-error {
      border: 1px solid #ff0000;
      box-shadow: 0 2px 4px rgba(255, 0, 0, 0.1);
    }

    .input-content {
      width: 100%;
      height: 56px;
      padding: 16px 0;
      display: flex;
      align-items: center;
      gap: 8px;

      .input-icon {
        color: rgba(0, 0, 0, 0.56);
      }

      .input-field-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .input-label {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.6);
          letter-spacing: -0.4px;
        }

        input {
          width: 100%;
          border: none;
          background: none;
          outline: none;
          textAlign: 'center',
          padding: 0;
          font-size: 16px;
          color: rgba(0, 0, 0, 0.87);

          &::placeholder {
            color: rgba(0, 0, 0, 0.6);
            letter-spacing: -0.4px;
            textAlign: 'center',
          }
        }
      }
    }
  }

  .custom-button {
    width: 123px;
    height: 50px;
    background-color: #ff0000;
    border: none;
    border-radius: 1000px;
    padding: 12px 22px;
    box-shadow: 0 3px 1px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14), 0 1px 5px rgba(0, 0, 0, 0.12);
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background-color: #e60000;
    }

    &:disabled {
      background-color: #ff0000;
      opacity: 0.5;
      cursor: not-allowed;
      box-shadow: 0 3px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.07), 0 1px 5px rgba(0, 0, 0, 0.06);

      &:hover {
        background-color: #ff0000;
      }

      .button-content {
        opacity: 0.7;
      }
    }

    .button-content {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
      gap: 8px;
      transition: opacity 0.3s ease;

      .button-text {
        color: #ffffff;
        font-size: 15px;
        font-family: 'Roboto', sans-serif;
        letter-spacing: -0.4px;
      }

      .loading-spinner {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .error-message {
    position: absolute;
    bottom: -28px;
    left: 12px;
    color: #ff0000;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    letter-spacing: -0.4px;
    background-color: rgba(255, 0, 0, 0.08);
    padding: 4px 12px;
    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(255, 0, 0, 0.1);
    backdrop-filter: blur(4px);
    white-space: nowrap;
    max-width: calc(100% - 24px);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: row;
    height: auto;
    gap: 24px;
  }
`;
