import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import axiosInstance from '../services/axiosInstance';
import { MarketPlaceNameToCompanyId } from '../Utils/constants';
import { CheckoutContext } from '../context/CheckoutContext';
import { CheckoutContextProps } from '../context/CheckoutContext';

const useMarketplace = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(true);
  const { setMarketplaceCompany, setMarketplaceName } = useContext(
    CheckoutContext
  ) as CheckoutContextProps;

  useEffect(() => {
    const fetchMarketplace = async () => {
      setLoading(true);
      try {
        const marketplaceName = searchParams.get('marketplaceName');
        if (!marketplaceName) {
          setMarketplaceCompany(undefined);
          setMarketplaceName(undefined);
          setLoading(false);
          return;
        }

        const companyId = MarketPlaceNameToCompanyId.get(marketplaceName);
        if (!companyId) {
          throw new Error('Invalid marketplace name');
        }

        const { data: response } = await axiosInstance.post(`/booking/getCompany`, {
          companyId,
        });

        setMarketplaceCompany(response.data);
        setMarketplaceName(marketplaceName);
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    };

    fetchMarketplace();
  }, [searchParams]);

  return { loading };
};

export default useMarketplace;
