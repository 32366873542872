import { Typography } from '@mui/material';
import React from 'react';
import SlotsList from './SlotsList';
import { SlotsSectionWrapper } from './style';
import { ISlotSectionProps } from '../../interfaces';

const SlotsSection: React.FC<ISlotSectionProps> = ({
  label,
  slots,
  areAllOffPeakSlots,
  timezone,
  handleAddToCart,
  selectedSlot,
  setSelectedSlot,
  initialVisibleCount,
}) => {
  return (
    <SlotsSectionWrapper className="slots-section">
      <Typography className="label">{label}</Typography>
      <SlotsList
        slots={slots}
        handleAddToCart={handleAddToCart}
        areAllOffPeakSlots={areAllOffPeakSlots}
        selectedSlot={selectedSlot}
        setSelectedSlot={setSelectedSlot}
        timezone={timezone}
        initialVisibleCount={initialVisibleCount}
      />
    </SlotsSectionWrapper>
  );
};

export default SlotsSection;
