import React from 'react';
import { useSearchParams } from 'react-router-dom';
import CancelPackageButton from '../../../components/package/CancelPackageButton';
import BookingDetails from '../../../components/shared/bookingDetails';
import useTitle from '../../../hooks/useTitle';

import { extractPackageCancellationDetails } from '../../../Utils/extract';
import { PackageCancellationWrapper } from './style';

interface IProps {
  title: string;
}

const PackageCancellation: React.FC<IProps> = ({ title }) => {
  useTitle(title);
  const [searchParams] = useSearchParams();

  const { numOfSessions, price, packageCode, companyId, companyName } =
    extractPackageCancellationDetails(searchParams);

  return (
    <PackageCancellationWrapper>
      <BookingDetails
        details={[
          {
            items: [
              {
                label: 'Company',
                value: companyName,
              },
              {
                label: 'Package Code',
                value: packageCode,
              },
            ],
          },
          {
            items: [
              {
                label: 'Package Credits',
                value: numOfSessions,
              },
              {
                label: 'Price',
                value: price,
              },
            ],
          },
        ]}
      />
      <CancelPackageButton companyId={companyId} packageCode={packageCode} />
    </PackageCancellationWrapper>
  );
};

export default PackageCancellation;
