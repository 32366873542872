import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const ConfirmationWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 85%;

  .email-label {
    color: #808080;
    // padding: 0px 12px;
    font-size: 19px;
    font-style: normal;
    text-align: center;
    font-family: Roboto-thin;
    font-weight: bolder;
  }
`;
