import React from 'react';
import { Typography } from '@mui/material';
import { StyledResourceCard, StyledResourceCardContent } from './style';

interface ResourceCardProps {
  title: string;
  isSelected: boolean;
  onClick: (event: React.SyntheticEvent) => void;
  icon?: React.ReactNode;
}

const ResourceCard: React.FC<ResourceCardProps> = ({ title, isSelected, onClick, icon }) => {
  return (
    <StyledResourceCard selected={isSelected} onClick={onClick}>
      <StyledResourceCardContent selected={isSelected}>
        {icon}
        <Typography variant="body2" component="div">
          {title}
        </Typography>
      </StyledResourceCardContent>
    </StyledResourceCard>
  );
};

export default ResourceCard;
