import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const FooterWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  // margin-top: auto;
  position: relative;
  padding-top: 24px;
  padding-bottom: 24px;
  background-color: #000;
  z-index: 1;

  .footer-company-logo {
    // margin-top: 30px;
    // margin-bottom: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  }

  .footer-details {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 15px;
    margin-top: 15px;

    .links-item {
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      padding-right: 10px;
      padding-left: 10px;
      color: #ffffff;
      &:hover {
        cursor: pointer;
      }
    }

    .links-item-separator {
      border-right: 1px solid #ffffff;
    }
  }

  .address {
    color: #ffffff;
    padding: 0 10px;
    font-size: 14px;
    text-wrap: wrap;
    font-style: normal;
    text-align: center;
    font-weight: 300;
    line-height: 23px;
    margin-bottom: 15px;

    .map-link {
      text-decoration: none;
      color: #ffffff;
    }
  }

  .question {
    color: #ffffff;
    padding: 0 10px;
    font-size: 14px;
    text-wrap: wrap;
    font-style: normal;
    text-align: center;
    font-weight: 300;
    line-height: 23px;
    margin-bottom: 15px;
  }

  .payment-methods {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 15px;

    .payment-methods-item {
      margin-right: 10px;
    }
  }
`;
