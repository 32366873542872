import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import DaySelector from '../../components/Calendar/DaySelector';
import { CheckoutContext, CheckoutContextProps } from '../../context/CheckoutContext';
import useTitle from '../../hooks/useTitle';
import dayjs from 'dayjs';
import CustomFooter from '../../components/shared/footer/CustomFooter';
import { CalendarWrapper } from './style';
import SportSelector from '../../components/MarketplaceCalendar/SportSelector';
import CompanySelector from '../../components/MarketplaceCalendar/CompanySelector';
import useMutliCompaniesAvailability from '../../hooks/useMutliCompaniesAvailability';
import ContentLoader from '../../components/shared/ContentLoader';
import { Typography } from '@mui/material';
import useSportsAndLocations, { Sport } from '../../hooks/useSportsAndLocations';
import Heading from '../../components/shared/Heading';
import useGTMDataLayer from '../../hooks/useGTMDataLayer';
import { useDaySelection } from '../../hooks/useDaySelection';

interface IProps {
  title: string;
}

const MarketplaceCalendar: React.FC<IProps> = ({ title }) => {
  useTitle(title);

  const [selectedLocationTag, setSelectedLocationTag] = React.useState<string>('');
  const [selectedSportsTag, setSelectedSportTag] = React.useState<string>('');

  const {
    company,
    selectedDay,
    setSelectedDay,
    setShouldShowServiceDropdown,
    setIsAllResourcesOptionEnabled,
    marketplaceName,
    setBookingCart,
    countdownApi,
    isCartLoading,
    marketplaceCompany,
  } = useContext(CheckoutContext) as CheckoutContextProps;

  const [searchParams] = useSearchParams();
  const serviceId = searchParams.get('serviceId');
  const resourceId = searchParams.get('resourceId');

  const { selectedWeekStartDate, setSelectedWeekStartDate } = useDaySelection();

  useEffect(() => {
    setShouldShowServiceDropdown(!!serviceId);
    setIsAllResourcesOptionEnabled(resourceId === null);

    setBookingCart([]);
    countdownApi.pause();
    localStorage.removeItem('cart');
  }, []);

  const { isLoading: isLoadingSportsAndLocations, sports } = useSportsAndLocations(
    marketplaceName || ''
  );
  const { loading: isLoadingAvailabilitySlots, companiesAvailability } =
    useMutliCompaniesAvailability({
      location: selectedLocationTag,
      sport: selectedSportsTag,
      date: selectedDay,
      marketplaceName: marketplaceName!,
    });

  useGTMDataLayer('view_marketplace_items_list');

  return (
    <ContentLoader isLoading={isLoadingSportsAndLocations || isCartLoading}>
      <CalendarWrapper>
        <SportSelector
          selectedLocationTag={selectedLocationTag}
          setSelectedLocationTag={setSelectedLocationTag}
          selectedSportTag={selectedSportsTag}
          setSelectedSportTag={setSelectedSportTag}
          sports={sports}
        />

        <DaySelector
          onlySevenDaysInAdvance={false}
          dayHasAvailableSlots={new Map()}
          selectedWeekStartDate={selectedWeekStartDate}
          setSelectedWeekStartDate={setSelectedWeekStartDate}
        />

        <ContentLoader isLoading={isLoadingAvailabilitySlots}>
          <>
            {companiesAvailability && companiesAvailability?.length > 0 ? null : (
              <Typography textAlign={'center'}>
                Sorry, we don't have any availability for the selected day please select another
                day.
              </Typography>
            )}
            {companiesAvailability?.map(companyAvailability => (
              <CompanySelector
                availableStartingTimes={companyAvailability.availableStartingTimesResource}
                slotsMap={companyAvailability.slotsMap}
              />
            ))}
          </>
        </ContentLoader>

        {company?.hasFooter && <CustomFooter company={marketplaceCompany || company} />}
      </CalendarWrapper>
    </ContentLoader>
  );
};

export default MarketplaceCalendar;
