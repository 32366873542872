import { Typography } from '@mui/material';
import React from 'react';
import { IHeadingProps } from './Types';

const Heading: React.FC<IHeadingProps> = ({ heading, sx }) => {
  return (
    <Typography
      component={'h2'}
      sx={{
        color: '#33334F',
        fontSize: '24px',
        fontWeight: 500,
        marginBottom: '24px',
        textAlign: 'center',
        ...sx,
      }}
    >
      {heading}
    </Typography>
  );
};

export default Heading;
