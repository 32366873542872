import { Badge } from '@mui/material';
import { ReactComponent as ShoppingCartIcon } from '../../../../../assets/icons/shopping-cart.svg';
import { CartButtonWrapper } from './style';

interface CartButtonProps {
  showCart: boolean;
  cartItemsCount?: number;
  onClick: () => void;
}

const CartButton: React.FC<CartButtonProps> = ({ showCart, cartItemsCount, onClick }) => (
  <CartButtonWrapper variant="text" onClick={onClick} data-testid="cart-button">
    {showCart && (
      <Badge badgeContent={cartItemsCount} color="primary">
        <ShoppingCartIcon />
      </Badge>
    )}
  </CartButtonWrapper>
);

export default CartButton;
