import React, { useContext } from 'react';
import { PackageConfirmationWrapper } from './style';
import PackageDetails from '../../../components/discount/package/PackageDetails';
import { Box, Typography } from '@mui/material';
import { extractPackageDetails } from '../../../Utils/extract';
import { useSearchParams } from 'react-router-dom';
import MembershipDetails from '../../../components/discount/membership/MembershipDetails';
import Heading from '../../../components/shared/Heading';

import PrimaryBtn from '../../../components/FormCollection/PrimaryBtn';
import { CheckoutContext, CheckoutContextProps } from '../../../context/CheckoutContext';
import useTitle from '../../../hooks/useTitle';
import BackToHomeButton from '../../../components/shared/BackToHomeButton';

interface props {
  title?: string;
}
const PackageConfirmation: React.FC<props> = ({ title }) => {
  useTitle(title);
  const [searchParams] = useSearchParams();
  const { customerEmail, packageCode, type } = extractPackageDetails(searchParams);

  const { company } = useContext(CheckoutContext) as CheckoutContextProps;

  return (
    <PackageConfirmationWrapper>
      <Heading
        heading={
          ['credit', 'duration'].includes(type || '')
            ? 'Package Confirmation'
            : 'Membership Confirmation'
        }
      />
      <Typography className="package-description">
        {`The Payment has been received. All the details have been sent to your email address at:`}
      </Typography>
      <Typography className="customer-email">{customerEmail}</Typography>
      <Box className="details-container">
        {type === 'credit' ? (
          <PackageDetails
            packagePrice={Number(searchParams.get('price') || '')}
            packageValue={Number(searchParams.get('totalCredit') || '')}
            packageCode={packageCode || ''}
            packageName={searchParams.get('packageName') || searchParams.get('voucherName') || ''}
            type={'credit'}
          />
        ) : type === 'duration' ? (
          <PackageDetails
            packagePrice={Number(searchParams.get('price') || '')}
            packageValue={Number(searchParams.get('duration') || '')}
            packageCode={packageCode || ''}
            packageName={searchParams.get('packageName') || searchParams.get('voucherName') || ''}
            type={'duration'}
          />
        ) : (
          <MembershipDetails
            title={searchParams.get('packageName') || searchParams.get('voucherName') || ''}
            membershipCode={packageCode || ''}
            membershipServiceName={searchParams.get('serviceName') || ''}
            membershipPrice={Number(searchParams.get('price') || '')}
            membershipValue={Number(searchParams.get('maxUsage') || '')}
            membershipDuration={
              searchParams.get('days') ? Number(searchParams.get('days')) : undefined
            }
            endDate={searchParams.get('endDate') || ''}
          />
        )}
      </Box>
      <Typography className="save-reminder">
        {company?.confirmationDisclaimer ||
          'Please save the code to use it on the checkout page of your next booking.'}
      </Typography>

      <BackToHomeButton />
    </PackageConfirmationWrapper>
  );
};

export default PackageConfirmation;
