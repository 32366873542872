import React, { useContext } from 'react';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { DatePickerWrapper } from './style';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { CheckoutContext, CheckoutContextProps } from '../../context/CheckoutContext';
interface IProps {
  selectedWeekStartDate: Date;
  setSelectedWeekStartDate: (date: any) => void;
  setSelectedDay: (day: string) => void;
  onlySevenDaysInAdvance?: boolean;
}

const CustomizedDatePicker: React.FC<IProps> = ({
  selectedWeekStartDate,
  setSelectedWeekStartDate,
  setSelectedDay,
  onlySevenDaysInAdvance,
}) => {
  const { selectedService, servicesMap, company } = useContext(
    CheckoutContext
  ) as CheckoutContextProps;

  return (
    <DatePickerWrapper data-testid="date-picker">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          disablePast
          value={dayjs(selectedWeekStartDate)}
          onChange={date => {
            setSelectedWeekStartDate(date);
            setSelectedDay(dayjs(date).format('DD/MM/YYYY'));
          }}
          slots={{
            openPickerIcon: CalendarMonthOutlinedIcon,
          }}
          slotProps={{
            openPickerIcon: {
              'data-testid': 'date-picker-icon',
            },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              padding: 0,
            },
            '& .MuiOutlinedInput-input': {
              width: 0,
              padding: 0,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              padding: 0,
              border: 0,
            },
            '& .MuiIconButton-root': {
              marginRight: '8px',
            },
          }}
          shouldDisableDate={(day: Dayjs | null) => {
            if (company?.bookingRange?.start && company?.bookingRange?.end) {
              const bookingStart = dayjs(company?.bookingRange?.start, 'YYYYMMDD');
              const bookingEnd = dayjs(company?.bookingRange?.end, 'YYYYMMDD');

              const isOutsideBookingRange =
                !!day && (day.isBefore(bookingStart, 'day') || day.isAfter(bookingEnd, 'day'));

              if (isOutsideBookingRange) return true;
            }

            const service = servicesMap?.get(selectedService);
            if (!service?.advanceBookingDays) return false;

            return !!day && day.isAfter(dayjs().add(service?.advanceBookingDays!, 'day'));
          }}
        />
      </LocalizationProvider>
    </DatePickerWrapper>
  );
};

export default CustomizedDatePicker;
