import { IconButton } from '@mui/material';
import { ReactComponent as MenuIcon } from '../../../../../assets/icons/menu.svg';

interface SidebarToggleButtonProps {
  showSidebar?: boolean;
  toggleDrawer: (open: boolean) => void;
}

const SidebarToggleButton: React.FC<SidebarToggleButtonProps> = ({ showSidebar, toggleDrawer }) => (
  <IconButton
    size="large"
    edge="start"
    color="default"
    aria-label="menu"
    onClick={() => toggleDrawer(true)}
    data-testid="top-nav-menu-button"
  >
    {showSidebar && <MenuIcon />}
  </IconButton>
);

export default SidebarToggleButton;
