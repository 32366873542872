import React, { useContext } from 'react';
import { IServiceSelectorProps } from './interfaces';
import { CheckoutContext } from '../../../context/CheckoutContext';
import { CheckoutContextProps } from '../../../context/CheckoutContext';
import ServiceSelectorTheme1 from './variants/theme1';
import ServiceSelectorTheme2 from './variants/theme2';
import { ThemeVariant } from '../../../hooks/useCompany';
import ServiceSelectorSkeleton from './loading-skeleton';

const variantComponents: Record<ThemeVariant, React.ComponentType<any>> = {
  default: ServiceSelectorTheme1,
  theme1: ServiceSelectorTheme1,
  theme2: ServiceSelectorTheme2,
};

const ServiceSelector: React.FC<IServiceSelectorProps> = ({
  type,
  subType,
  serviceId,
  branchId,
  resourceId,
  sportId,
  uponSelectCallback,
}) => {
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;
  const companyTheme = company?.theme;
  const Component = variantComponents[(companyTheme as ThemeVariant) || 'default'];

  return (
    <Component
      type={type}
      subType={subType}
      serviceId={serviceId}
      branchId={branchId}
      resourceId={resourceId}
      sportId={sportId}
      uponSelectCallback={uponSelectCallback}
    />
  );
};

export default ServiceSelector;
