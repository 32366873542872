import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ModernPricingCardWrapper = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  marginTop: '15px',
  marginBottom: '10px',
  width: '100%',
  boxShadow: '0 6px 40px rgba(208, 208, 208, 0.2)',
  borderRadius: '6px',
  '&:hover': {
    boxShadow: '0 8px 45px rgba(0, 0, 0, 0.07)',
  },

  '& .pricing-content': {
    padding: theme.spacing(2),

    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },

  '& .price-row': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 27,
    width: '100%',

    '& .label': {
      fontSize: '1rem',
      lineHeight: '27px',
      color: 'rgba(0, 0, 0, 1)',
    },

    '& .value': {
      fontSize: '1rem',
      lineHeight: '26px',
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
}));
