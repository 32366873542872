import styled from '@emotion/styled';

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 55%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px;
`;
