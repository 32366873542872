import React from 'react';
import { IPricingCardProps } from './interfaces';
import { usePricingCard } from './hooks/usePricingCard';
import PricingCardTheme1 from './variants/theme1';
import PricingCardTheme2 from './variants/theme2';
import { ThemeVariant } from '../../../hooks/useCompany';

const variantComponents: Record<ThemeVariant, React.ComponentType<IPricingCardProps>> = {
  default: PricingCardTheme1,
  theme1: PricingCardTheme1,
  theme2: PricingCardTheme2,
};

const PricingCard: React.FC<IPricingCardProps> = props => {
  const { company } = usePricingCard();
  const companyTheme = company?.theme;
  const Component = variantComponents[(companyTheme as ThemeVariant) || 'default'];

  return <Component {...props} />;
};

export default PricingCard;
