import { Stack, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import { PackageDetailsItemWrapper } from './style';

interface IProps {
  label: string;
  value: string;
  valueColor?: string;
  icon?: ReactElement;
  highlight?: boolean;
}

const PackageDetailsItem: React.FC<IProps> = ({ label, value, valueColor, icon, highlight }) => {
  return (
    <PackageDetailsItemWrapper
      direction="row"
      spacing={3}
      justifyContent="space-between"
      alignItems="center"
      valueColor={valueColor || '#33334f'}
      className={highlight ? 'highlighted' : ''}
    >
      {icon && <span className="icon-wrapper">{icon}</span>}
      <Typography className="package-details-item-label">
        {label}
      </Typography>
      <Typography className="package-details-item-value">{value}</Typography>
    </PackageDetailsItemWrapper>
  );
};

export default PackageDetailsItem;
