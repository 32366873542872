import { styled } from '@mui/material/styles';
import { Box, Typography, ButtonBase } from '@mui/material';

export const Container = styled(Box)`
  width: 100%;
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeaderContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  margin-bottom: 8px;
  width: 100%;
`;

export const MonthText = styled(Typography)`
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #1a1a1a;
  flex: 1;
  text-align: center;
  margin: 0 8px;
  letter-spacing: 0.1px;
`;

export const NavigationButton = styled(ButtonBase)`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1a1a1a;
  transition: all 0.2s ease;
  flex-shrink: 0;

  &:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const DaysContainer = styled(Box)`
  display: flex;
  gap: 0;
  overflow-x: auto;
  padding: 8px 16px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 600px) {
    justify-content: flex-start;
    padding-left: 0;
  }
`;

export const DayChipContainer = styled(Box)<{ isSelected?: boolean; isAvailable?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 8px 12px;
  cursor: pointer;
  border-bottom: 2px solid ${({ isSelected }) => (isSelected ? '#1A1A1A' : 'transparent')};
  opacity: ${({ isAvailable }) => (isAvailable ? 1 : 0.5)};
  flex: 1;
  min-width: 105px;
  transition: all 0.2s ease;

  &:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};
  }

  @media (max-width: 600px) {
    padding: 8px 8px;
    min-width: 90px;
  }
`;

export const DayName = styled(Typography)<{ isSelected?: boolean }>`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: ${({ isSelected }) => (isSelected ? 600 : 400)};
  color: ${({ isSelected }) => (isSelected ? '#1A1A1A' : '#757575')};
  letter-spacing: 0.1px;
`;

export const DayNumber = styled(Typography)<{ isSelected?: boolean }>`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: ${({ isSelected }) => (isSelected ? 600 : 400)};
  color: ${({ isSelected }) => (isSelected ? '#1A1A1A' : '#757575')};
  letter-spacing: 0.1px;
`;
