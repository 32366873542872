import React, { useState } from 'react';
import SlotChip from './SlotChip';
import { SlotsListWrapper, TimesControlWrapper } from './style';
import { Box, Button, useMediaQuery, useTheme } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ISlotListProps } from '../../interfaces';

const SlotsList: React.FC<ISlotListProps> = ({
  slots,
  handleAddToCart,
  areAllOffPeakSlots,
  selectedSlot,
  setSelectedSlot,
  timezone,
  initialVisibleCount,
}) => {
  const isMd = useMediaQuery('(min-width:600px)');
  const theme = useTheme();

  const [lessMode, setLessMode] = useState<boolean>(true);

  const visibleSlots = slots.slice(0, lessMode && initialVisibleCount ? 9 : slots.length);

  const handleShowMore = () => {
    setLessMode(false);
  };

  const handleLessMore = () => {
    setLessMode(true);
  };

  return (
    <>
      <SlotsListWrapper
        theme={theme}
        sx={
          !isMd && visibleSlots.length > 2
            ? {
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
              }
            : undefined
        }
      >
        {visibleSlots.map(slot => (
          <SlotChip
            key={slot.time}
            slot={slot}
            handleAddToCart={handleAddToCart}
            areAllOffPeakSlots={areAllOffPeakSlots}
            selectedSlot={selectedSlot}
            setSelectedSlot={setSelectedSlot}
            timezone={timezone}
            isSelected={selectedSlot?.time === slot.time}
          />
        ))}
      </SlotsListWrapper>

      <TimesControlWrapper>
        {lessMode && !!initialVisibleCount && (
          <Box>
            <Button
              className="control-button"
              color="inherit"
              onClick={handleShowMore}
              startIcon={<KeyboardArrowDownIcon />}
            >
              More Times
            </Button>
          </Box>
        )}

        {!lessMode && !!initialVisibleCount && (
          <Box>
            <Button
              className="control-button"
              color="inherit"
              onClick={handleLessMore}
              startIcon={<KeyboardArrowUpIcon />}
            >
              Less Times
            </Button>
          </Box>
        )}
      </TimesControlWrapper>
    </>
  );
};

export default SlotsList;
