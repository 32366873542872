import styled from '@emotion/styled';
import { Box, Stack } from '@mui/material';

export const PackageValidationDetailsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  padding: 0 16px;

  .cancel-button {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;


interface IProps {
  valueColor: string;
}

export const PackageDetailsItemWrapper = styled(Stack)<IProps>`
  margin-top: 10px;
  width: 100%;
  transition: all 0.3s ease;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
    &.highlighted {
      background: linear-gradient(135deg, rgba(51, 51, 79, 0.1), rgba(51, 51, 79, 0.05));
      border: 1px solid rgba(51, 51, 79, 0.2);
    }
  
    .package-details-item-label {
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      text-align: left;
      font-size: 16px;
      color: #33334f;
      display: flex;
      align-items: center;
    }
  
    .package-details-item-value {
      color: ${props => props.valueColor};
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      text-align: right;
      font-size: 16px;
    }
  
    .icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
  
      svg {
        font-size: 20px;
      color: #33334f;
    }
  }
`;
