import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const PackageCancellationWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 80%;
`;
