import { isWithinAdvanceBookingDays } from '../Utils/format';

import { useContext, useEffect, useState } from 'react';

import { CheckoutContext } from '../context/CheckoutContext';

import dayjs, { Dayjs } from 'dayjs';
import { CheckoutContextProps } from '../context/CheckoutContext';
import { AvailableStartingTimesOutput } from './useAvailableStartingTimes';
import { isInBookingRange } from '../Utils/validate';
import { withinWorkingHours } from '../Utils/format';
import { isAfterCutOff } from '../Utils/format';

export const useDaysHasAvailabileSlots = (
  selectedDatePicker: Date,
  startTime?: string,
  endTime?: string
) => {
  const { currentRangeAvailability, servicesMap, selectedService, company } = useContext(
    CheckoutContext
  ) as CheckoutContextProps;

  const service = servicesMap?.get(selectedService);

  const [daysHasAvailableSlots, setDaysHasAvailableSlots] = useState(new Map<string, boolean>());

  useEffect(() => {
    const newMap = new Map<string, boolean>();
    for (let i = 0; i < 7; i++) {
      const day = dayjs(selectedDatePicker).add(i, 'day');
      newMap.set(day.format('YYYYMMDD'), dayHasAvailableSlots(day));
    }
    setDaysHasAvailableSlots(newMap);
    console.log('[useDaysHasAvailabileSlots] daysHasAvailableSlots', newMap);
  }, [selectedDatePicker, startTime, endTime, currentRangeAvailability, service]);

  const dayHasAvailableSlots = (day: Dayjs) => {
    const dayAvailability: AvailableStartingTimesOutput = currentRangeAvailability?.filter(
      (dayAvailability: any) => dayAvailability.date === day.format('YYYYMMDD')
    )[0]?.availability;
    const durations = dayAvailability?.service.duration || [];
    for (const duration of durations) {
      const slots = duration.slots.filter(slot => isInBookingRange(company, slot.fullDate));
      for (const slot of slots) {
        if (
          isAfterCutOff(service?.cutoff, slot.fullDate, company?.timezone) &&
          withinWorkingHours(slot.time, startTime, endTime) &&
          (service?.hasOwnProperty('isActive') ? service.isActive : true) &&
          slot.available > 0 &&
          isWithinAdvanceBookingDays(slot.fullDate, service?.advanceBookingDays, company?.timezone)
        ) {
          return true;
        }
      }
    }
    return false;
  };

  return { daysHasAvailableSlots };
};
