import { useContext, useEffect } from 'react';
import usePackage, { ValidationOutput } from '../../../hooks/usePackage';
import { CheckoutContext, CheckoutContextProps } from '../../../context/CheckoutContext';
import { SelectChangeEvent } from '@mui/material';

export const usePackageSelector = (
  selectedPackage: string,
  setSelectedPackage: React.Dispatch<React.SetStateAction<string>>,
  setIsValidating: React.Dispatch<React.SetStateAction<boolean>>,
  setPackageValidationOutput: React.Dispatch<React.SetStateAction<ValidationOutput | undefined>>
) => {
  const { checkPackageValidity } = usePackage();
  const { company, bookingCart , servicesMap} = useContext(CheckoutContext) as CheckoutContextProps;

  const handleChange = async (event: SelectChangeEvent) => {
    setSelectedPackage(event.target.value);
  };

  useEffect(() => {
    const validateVoucher = async () => {
      try {
        setIsValidating(true);
        const voucherValidation = await checkPackageValidity({
          companyId: company ? company.id : '',
          cartId: bookingCart.length > 0 ? bookingCart[0].cartId : '',
          voucherCode: selectedPackage,
        });
        setPackageValidationOutput(voucherValidation);
      } catch (error) {
        setPackageValidationOutput(undefined);
      }
      setIsValidating(false);
    };

    if (selectedPackage && selectedPackage !== 'promo-code' && selectedPackage !== 'membership') {
      validateVoucher();
    } else if (selectedPackage && selectedPackage === 'promo-code') {
      setPackageValidationOutput(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPackage, bookingCart]);

  return {
    handleChange,
    servicesMap
  };
};
