import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { down } from 'styled-breakpoints';

interface TextFieldWrapperProps {
  theme: Theme;
}

export const CustomizedTextFieldWrapper = styled(Box)<TextFieldWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  margin: 8px 0px;

  ${down('md')} {
    width: 100%;
  }

  .textfield-label {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    padding-left: 10px;
    margin-bottom: 8px;
    color: ${({ theme }) => theme.palette.text.primary};

    span {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }

  .textfield-input {
    border: 1px solid ${({ theme }) => theme.palette.divider};
    box-sizing: border-box;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 4px;
    width: 100%;
    background-color: ${({ theme }) => theme.palette.background.paper};
    transition: all 0.2s ease-in-out;

    &:hover, &:focus {
      border-color: ${({ theme }) => theme.palette.primary.main};
    }

    input {
      padding: 12px 16px;
      font-size: 16px;
      
      &::placeholder {
        color: ${({ theme }) => theme.palette.text.secondary};
        opacity: 0.7;
      }
    }

    ${down('md')} {
      width: 100%;
    }
  }
`;
