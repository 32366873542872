import { useContext } from 'react';
import { CheckoutContext, CheckoutContextProps } from '../../../../context/CheckoutContext';
import { getCurrency } from '../../../../Utils/format';

export const usePricingCard = () => {
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;

  return {
    company,
    currency: getCurrency(company?.currency),
    shouldHide: (subTotal: number, discount: number) => subTotal === 0 && discount === 0,
  };
};
