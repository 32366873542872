import React from 'react';
import { Divider } from '@mui/material';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { StyledPricingCard, CardTitle } from './style';
import PricingCardRow from './PricingCardRow';
import { IPricingCardProps } from '../../interfaces';
import { usePricingCard } from '../../hooks/usePricingCard';

const PricingCardTheme1: React.FC<IPricingCardProps> = ({
  subTotal,
  discount,
  total,
  vat,
  isPaid = false,
}) => {
  const { currency, shouldHide } = usePricingCard();

  if (shouldHide(subTotal, discount)) {
    return null;
  }

  return (
    <StyledPricingCard elevation={0}>
      <CardTitle variant="h6">
        <ReceiptLongIcon /> Purchase Summary
      </CardTitle>

      <PricingCardRow label="Subtotal" value={subTotal} currency={currency} isDiscount={false} />

      {discount > 0 && (
        <PricingCardRow label="Discount" value={discount} currency={currency} isDiscount={true} />
      )}

      {vat && vat > 0 ? (
        <PricingCardRow label="VAT" value={vat} currency={currency} isDiscount={false} />
      ) : null}

      <Divider sx={{ my: 2, borderColor: 'rgba(0, 0, 0, 0.08)' }} />

      <PricingCardRow
        label={isPaid ? 'Total Paid' : 'Total to be paid'}
        value={total}
        currency={currency}
        isDiscount={false}
        isTotal={true}
      />
    </StyledPricingCard>
  );
};

export default PricingCardTheme1;
