import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const StatusHeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;

  .header {
    font-size: 18px;
    font-style: normal;
    font-weight: normal;
  }

  .title {
    color: #808080;
    margin: 0px 0px;
    padding: 0px 12px;
    font-size: 19px;
    font-style: normal;
    text-align: center;
    font-family: Roboto-thin;
    font-weight: normal;
    margin-top: 25px;
  }
`;

export const StatusWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SuccessfulStatusWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const UnSuccessfulPaymentWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;

  .header {
    color: #080133;
    margin: 5px 0px;
    padding: 0px 10px;
    font-size: 24px;
    font-style: normal;
    text-align: center;
    font-weight: normal;
  }

  .content {
    color: #808080;
    margin: 0px 0px;
    padding: 0px 12px;
    font-size: 16px;
    font-style: normal;
    text-align: center;
    font-family: Roboto-thin;
    font-weight: normal;
  }
`;
