import { IDurationSelectorProps } from '../../interfaces';
import { Typography, useTheme } from '@mui/material';
import { DurationFilterWrapper } from './style';
import { useDurationSelector } from '../../hooks/useDurationSelector';
import DurationsListTheme2 from './DurationsList';

const DurationSelectorTheme2: React.FC<IDurationSelectorProps> = ({
  durations,
  setSelectedDuration,
  selectedDuration,
}) => {
  const theme = useTheme();
  const { formattedDurationsMap } = useDurationSelector(durations);
  return (
    <DurationFilterWrapper theme={theme}>
      <DurationsListTheme2
        durations={durations}
        selectedDuration={selectedDuration}
        setSelectedDuration={setSelectedDuration}
        formattedDurationsMap={formattedDurationsMap}
      />
    </DurationFilterWrapper>
  );
};

export default DurationSelectorTheme2;
