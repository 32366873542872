import React, { useContext, useEffect } from 'react';
import { Typography } from '@mui/material';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Heading from '../../components/shared/Heading';
import useTitle from '../../hooks/useTitle';
import { IFormTypes } from '../../components/KidsBooking/Types';
import FormUI from '../../components/KidsBooking/FormUI';
import { CheckoutContext, CheckoutContextProps } from '../../context/CheckoutContext';
import useCart from '../../hooks/useCart';

interface props {
  title?: string;
}
const KidsBooking: React.FC<props> = ({ title }) => {
  useTitle(title);
  const navigate = useNavigate();
  const { addToCart } = useCart();
  const checkoutContext = useContext(CheckoutContext) as CheckoutContextProps;

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<IFormTypes>();
  const { fields, replace } = useFieldArray({ control, name: 'children' });

  useEffect(() => {
    const inputsArray = Array(Number(checkoutContext.quantity || 0))
      .fill(null)
      .map(() => ({ name: '', age: 0 }));

    if (!fields.length) replace(inputsArray);
  }, [fields.length, replace, checkoutContext.quantity]);

  const handleAddToCart = async (data: IFormTypes) => {
    const {
      company,
      selectedService,
      selectedDuration,
      selectedSlot,
      quantity,
      selectedSession,
      selectedResource,
      servicesMap,
    } = checkoutContext;
    const service = servicesMap?.get(selectedService);

    if (service?.addons && service.addons.length > 0) {
      navigate(`/AddOns?companyId=${company?.id}`, {
        state: { childrenInformation: data.children },
      });
      return;
    }

    const cartItem = {
      selectedService,
      selectedDuration,
      selectedSlot,
      quantity: quantity || 1,
      selectedSession,
      addonsList: [],
      childrenInformation: data.children,
      selectedResource,
      timezone: company?.timezone,
    };

    const addedToCart = await addToCart(cartItem);

    if (addedToCart) {
      checkoutContext.setSelectedSession(undefined);
      checkoutContext.setSelectedSlot(undefined);
      navigate(`/CartCheckout?companyId=${company?.id}`);
    } else {
      toast('Error while adding the item to the cart', { theme: 'colored', type: 'error' });
    }
  };

  return (
    <>
      <Heading heading="Kids Play Area" sx={{ marginTop: '24px' }} />
      <Typography sx={{ fontSize: '18px', color: '#808080', textAlign: 'center' }}>
        For the safety of children, we keep a record of everyone inside the play area. Please input
        the children's names below.
      </Typography>
      <FormUI
        onSubmitHandler={handleSubmit(handleAddToCart)}
        register={register}
        errors={errors}
        fields={fields}
        showConsentCheckbox={true}
      />
    </>
  );
};

export default KidsBooking;
